"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./item.component.scss.shim.ngstyle");
const i1 = require("@angular/core");
const i2 = require("@angular/common");
const i3 = require("@angular/router");
const i4 = require("../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
const i5 = require("@angular/material/icon");
const i6 = require("@ngx-translate/core");
const i7 = require("./item.component");
var styles_FuseNavHorizontalItemComponent = [i0.styles];
var RenderType_FuseNavHorizontalItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseNavHorizontalItemComponent, data: {} });
exports.RenderType_FuseNavHorizontalItemComponent = RenderType_FuseNavHorizontalItemComponent;
function View_FuseNavHorizontalItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 671744, [[2, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(4, 1), i1.ɵdid(5, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, [2, i3.RouterLink], [2, i3.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(8, { exact: 0 }), i1.ɵpad(9, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_3)), i1.ɵdid(11, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link"; var currVal_3 = _co.item.classes; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = (_co.item.openInNewTab ? "_blank" : "_self"); var currVal_5 = _ck(_v, 4, 0, _co.item.url); _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 8, 0, (_co.item.exactMatch || false)); var currVal_7 = _ck(_v, 9, 0, "active", "accent"); _ck(_v, 5, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v.parent, 11); _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavHorizontalItemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "nav-link"]], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_5)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link"; var currVal_3 = _co.item.classes; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v.parent, 11); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.url; var currVal_1 = (_co.item.openInNewTab ? "_blank" : "_self"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavHorizontalItemComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.item.function() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_7)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nav-link"; var currVal_1 = _co.item.classes; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 11); _ck(_v, 4, 0, currVal_2); }, null); }
function View_FuseNavHorizontalItemComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.item.function() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 671744, [[4, 4]], 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(4, 1), i1.ɵdid(5, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, [2, i3.RouterLink], [2, i3.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpod(8, { exact: 0 }), i1.ɵpad(9, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_9)), i1.ɵdid(11, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link"; var currVal_3 = _co.item.classes; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = (_co.item.openInNewTab ? "_blank" : "_self"); var currVal_5 = _ck(_v, 4, 0, _co.item.url); _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 8, 0, (_co.item.exactMatch || false)); var currVal_7 = _ck(_v, 9, 0, "active", "accent"); _ck(_v, 5, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v.parent, 11); _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavHorizontalItemComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_FuseNavHorizontalItemComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "nav-link"]], [[8, "href", 4], [8, "target", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.item.function() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_11)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "nav-link"; var currVal_3 = _co.item.classes; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v.parent, 11); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.url; var currVal_1 = (_co.item.openInNewTab ? "_blank" : "_self"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavHorizontalItemComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "nav-link-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.item.icon; _ck(_v, 2, 0, currVal_2); }); }
function View_FuseNavHorizontalItemComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "nav-link-badge"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "background-color": 0, "color": 1 }), i1.ɵdid(4, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.item.badge.bg, _co.item.badge.fg); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.item.badge.translate; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.badge.title; _ck(_v, 5, 0, currVal_2); }); }
function View_FuseNavHorizontalItemComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_13)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "nav-link-title"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_14)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.icon; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.item.translate; _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.item.badge; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.title; _ck(_v, 4, 0, currVal_2); }); }
function View_FuseNavHorizontalItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_10)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["itemContent", 2]], null, 0, null, View_FuseNavHorizontalItemComponent_12))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.item.url && !_co.item.externalUrl) && !_co.item.function); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.item.url && _co.item.externalUrl) && !_co.item.function); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.item.url && _co.item.function); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.item.url && !_co.item.externalUrl) && _co.item.function); _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.item.url && _co.item.externalUrl) && _co.item.function); _ck(_v, 10, 0, currVal_4); }, null); }
function View_FuseNavHorizontalItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavHorizontalItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_FuseNavHorizontalItemComponent_0 = View_FuseNavHorizontalItemComponent_0;
function View_FuseNavHorizontalItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-item", [], [[8, "className", 0]], null, null, View_FuseNavHorizontalItemComponent_0, RenderType_FuseNavHorizontalItemComponent)), i1.ɵdid(1, 49152, null, 0, i7.FuseNavHorizontalItemComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
exports.View_FuseNavHorizontalItemComponent_Host_0 = View_FuseNavHorizontalItemComponent_Host_0;
var FuseNavHorizontalItemComponentNgFactory = i1.ɵccf("fuse-nav-horizontal-item", i7.FuseNavHorizontalItemComponent, View_FuseNavHorizontalItemComponent_Host_0, { item: "item" }, {}, []);
exports.FuseNavHorizontalItemComponentNgFactory = FuseNavHorizontalItemComponentNgFactory;
