import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

import { filter, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FuseSplashScreenService {
    splashScreenParent: HTMLElement;
    splashScreenEl: HTMLElement;
    splashScreenElClone: Node;
    player: AnimationPlayer;

    /**
     * Constructor
     *
     * @param {AnimationBuilder} _animationBuilder
     * @param _document
     * @param {Router} _router
     */
    constructor(
        private _animationBuilder: AnimationBuilder,
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router
    ) {
        // Initialize
        this._init();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void {
        // Get the splash screen element
        this.splashScreenEl = this.getSplashElement();
        // If the splash screen element exists...
        if (this.splashScreenEl) {
            this.splashScreenElClone = this.splashScreenEl.cloneNode(true);
            this.splashScreenParent = this.splashScreenEl.parentElement;
            // Hide it on the first NavigationEnd event
            this._router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    take(1)
                )
                .subscribe(() => {
                    setTimeout(() => {
                        this.hide();
                    });
                });
        }
    }
    private getSplashElement(): HTMLElement | undefined {
        return this._document.body.querySelector('#fuse-splash-screen');
    }
    private removeSplashElement(): void {
        const splash = this.getSplashElement();
        if (splash) {
            splash.remove();
        }
    }
    private ensureSplashElement(): HTMLElement | undefined {
        const splash = this.getSplashElement();
        if (splash) {
            return splash;
        }
        if (!this.splashScreenElClone || !this.splashScreenParent) {
            return;
        }
        this.splashScreenParent.appendChild(this.splashScreenElClone);

        return this.getSplashElement();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void {
        this.splashScreenEl = this.ensureSplashElement();
        if (this.splashScreenEl) {
            this.player = this._animationBuilder
                .build([
                    style({
                        opacity: '0',
                        zIndex: '99999',
                    }),
                    animate('400ms ease', style({ opacity: '1', display: 'block' })),
                ])
                .create(this.splashScreenEl);

            setTimeout(() => {
                this.player.play();
            }, 0);
        }
    }

    /**
     * Hide the splash screen
     */
    hide(): void {
        this.player = this._animationBuilder
            .build([
                style({ opacity: '1' }),
                animate(
                    '400ms ease',
                    style({
                        opacity: '0',
                        zIndex: '-10',
                        display: 'none',
                    })
                ),
            ])
            .create(this.splashScreenEl);
        this.player.onDone(() => {
            this.removeSplashElement();
        });
        setTimeout(() => {
            this.player.play();
        }, 0);
    }
}
