"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Addon_Addon_Group_Constraint;
(function (Addon_Addon_Group_Constraint) {
    Addon_Addon_Group_Constraint["AddonAddonGroupPkey"] = "addon_addon_group_pkey";
})(Addon_Addon_Group_Constraint = exports.Addon_Addon_Group_Constraint || (exports.Addon_Addon_Group_Constraint = {}));
var Addon_Addon_Group_Select_Column;
(function (Addon_Addon_Group_Select_Column) {
    Addon_Addon_Group_Select_Column["AddonGroupId"] = "addon_group_id";
    Addon_Addon_Group_Select_Column["AddonId"] = "addon_id";
    Addon_Addon_Group_Select_Column["CreatedAt"] = "created_at";
    Addon_Addon_Group_Select_Column["Id"] = "id";
    Addon_Addon_Group_Select_Column["UpdatedAt"] = "updated_at";
})(Addon_Addon_Group_Select_Column = exports.Addon_Addon_Group_Select_Column || (exports.Addon_Addon_Group_Select_Column = {}));
var Addon_Addon_Group_Update_Column;
(function (Addon_Addon_Group_Update_Column) {
    Addon_Addon_Group_Update_Column["AddonGroupId"] = "addon_group_id";
    Addon_Addon_Group_Update_Column["AddonId"] = "addon_id";
    Addon_Addon_Group_Update_Column["CreatedAt"] = "created_at";
    Addon_Addon_Group_Update_Column["Id"] = "id";
    Addon_Addon_Group_Update_Column["UpdatedAt"] = "updated_at";
})(Addon_Addon_Group_Update_Column = exports.Addon_Addon_Group_Update_Column || (exports.Addon_Addon_Group_Update_Column = {}));
var Addon_Constraint;
(function (Addon_Constraint) {
    Addon_Constraint["AddonCodeKey"] = "addon_code_key";
    Addon_Constraint["AddonPkey"] = "addon_pkey";
})(Addon_Constraint = exports.Addon_Constraint || (exports.Addon_Constraint = {}));
var Addon_Group_Constraint;
(function (Addon_Group_Constraint) {
    Addon_Group_Constraint["AddonGroupPkey"] = "addon_group_pkey";
})(Addon_Group_Constraint = exports.Addon_Group_Constraint || (exports.Addon_Group_Constraint = {}));
var Addon_Group_Select_Column;
(function (Addon_Group_Select_Column) {
    Addon_Group_Select_Column["CreatedAt"] = "created_at";
    Addon_Group_Select_Column["Id"] = "id";
    Addon_Group_Select_Column["Order"] = "order";
    Addon_Group_Select_Column["ShortTitle"] = "short_title";
    Addon_Group_Select_Column["Subtitle"] = "subtitle";
    Addon_Group_Select_Column["Title"] = "title";
    Addon_Group_Select_Column["Type"] = "type";
    Addon_Group_Select_Column["UpdatedAt"] = "updated_at";
})(Addon_Group_Select_Column = exports.Addon_Group_Select_Column || (exports.Addon_Group_Select_Column = {}));
var Addon_Group_Type_Constraint;
(function (Addon_Group_Type_Constraint) {
    Addon_Group_Type_Constraint["AddonGroupTypePkey"] = "addon_group_type_pkey";
})(Addon_Group_Type_Constraint = exports.Addon_Group_Type_Constraint || (exports.Addon_Group_Type_Constraint = {}));
var Addon_Group_Type_Enum;
(function (Addon_Group_Type_Enum) {
    Addon_Group_Type_Enum["Portal"] = "PORTAL";
    Addon_Group_Type_Enum["SelectMany"] = "SELECT_MANY";
    Addon_Group_Type_Enum["SelectOne"] = "SELECT_ONE";
})(Addon_Group_Type_Enum = exports.Addon_Group_Type_Enum || (exports.Addon_Group_Type_Enum = {}));
var Addon_Group_Type_Select_Column;
(function (Addon_Group_Type_Select_Column) {
    Addon_Group_Type_Select_Column["Description"] = "description";
    Addon_Group_Type_Select_Column["Value"] = "value";
})(Addon_Group_Type_Select_Column = exports.Addon_Group_Type_Select_Column || (exports.Addon_Group_Type_Select_Column = {}));
var Addon_Group_Type_Update_Column;
(function (Addon_Group_Type_Update_Column) {
    Addon_Group_Type_Update_Column["Description"] = "description";
    Addon_Group_Type_Update_Column["Value"] = "value";
})(Addon_Group_Type_Update_Column = exports.Addon_Group_Type_Update_Column || (exports.Addon_Group_Type_Update_Column = {}));
var Addon_Group_Update_Column;
(function (Addon_Group_Update_Column) {
    Addon_Group_Update_Column["CreatedAt"] = "created_at";
    Addon_Group_Update_Column["Id"] = "id";
    Addon_Group_Update_Column["Order"] = "order";
    Addon_Group_Update_Column["ShortTitle"] = "short_title";
    Addon_Group_Update_Column["Subtitle"] = "subtitle";
    Addon_Group_Update_Column["Title"] = "title";
    Addon_Group_Update_Column["Type"] = "type";
    Addon_Group_Update_Column["UpdatedAt"] = "updated_at";
})(Addon_Group_Update_Column = exports.Addon_Group_Update_Column || (exports.Addon_Group_Update_Column = {}));
var Addon_Price_Constraint;
(function (Addon_Price_Constraint) {
    Addon_Price_Constraint["AddonPricePkey"] = "addon_price_pkey";
})(Addon_Price_Constraint = exports.Addon_Price_Constraint || (exports.Addon_Price_Constraint = {}));
var Addon_Price_Select_Column;
(function (Addon_Price_Select_Column) {
    Addon_Price_Select_Column["AddonType"] = "addon_type";
    Addon_Price_Select_Column["Allowed"] = "allowed";
    Addon_Price_Select_Column["Duration"] = "duration";
    Addon_Price_Select_Column["Id"] = "id";
    Addon_Price_Select_Column["Key"] = "key";
    Addon_Price_Select_Column["Product"] = "product";
    Addon_Price_Select_Column["UpdatedOn"] = "updated_on";
    Addon_Price_Select_Column["Value"] = "value";
})(Addon_Price_Select_Column = exports.Addon_Price_Select_Column || (exports.Addon_Price_Select_Column = {}));
var Addon_Price_Update_Column;
(function (Addon_Price_Update_Column) {
    Addon_Price_Update_Column["AddonType"] = "addon_type";
    Addon_Price_Update_Column["Allowed"] = "allowed";
    Addon_Price_Update_Column["Duration"] = "duration";
    Addon_Price_Update_Column["Id"] = "id";
    Addon_Price_Update_Column["Key"] = "key";
    Addon_Price_Update_Column["Product"] = "product";
    Addon_Price_Update_Column["UpdatedOn"] = "updated_on";
    Addon_Price_Update_Column["Value"] = "value";
})(Addon_Price_Update_Column = exports.Addon_Price_Update_Column || (exports.Addon_Price_Update_Column = {}));
var Addon_Select_Column;
(function (Addon_Select_Column) {
    Addon_Select_Column["AbsoluteOrder"] = "absolute_order";
    Addon_Select_Column["BackDescLargeScreen"] = "back_desc_large_screen";
    Addon_Select_Column["BackDescSmallScreen"] = "back_desc_small_screen";
    Addon_Select_Column["Code"] = "code";
    Addon_Select_Column["CreatedAt"] = "created_at";
    Addon_Select_Column["DefaultStatus"] = "default_status";
    Addon_Select_Column["ExclusionGroup"] = "exclusion_group";
    Addon_Select_Column["ExternalProductId"] = "external_product_id";
    Addon_Select_Column["FrontDescLargeScreen"] = "front_desc_large_screen";
    Addon_Select_Column["FrontDescSmallScreen"] = "front_desc_small_screen";
    Addon_Select_Column["GroupOrder"] = "group_order";
    Addon_Select_Column["Id"] = "id";
    Addon_Select_Column["Image"] = "image";
    Addon_Select_Column["IsOneTimePurchase"] = "is_one_time_purchase";
    Addon_Select_Column["MaxQty"] = "max_qty";
    Addon_Select_Column["MinQty"] = "min_qty";
    Addon_Select_Column["Name"] = "name";
    Addon_Select_Column["Price"] = "price";
    Addon_Select_Column["PriceBookEntrySalesforceId"] = "price_book_entry_salesforce_id";
    Addon_Select_Column["RecommendedFlag"] = "recommended_flag";
    Addon_Select_Column["SalesforceId"] = "salesforce_id";
    Addon_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Addon_Select_Column["Type"] = "type";
    Addon_Select_Column["UpdatedAt"] = "updated_at";
})(Addon_Select_Column = exports.Addon_Select_Column || (exports.Addon_Select_Column = {}));
var Addon_Update_Column;
(function (Addon_Update_Column) {
    Addon_Update_Column["AbsoluteOrder"] = "absolute_order";
    Addon_Update_Column["BackDescLargeScreen"] = "back_desc_large_screen";
    Addon_Update_Column["BackDescSmallScreen"] = "back_desc_small_screen";
    Addon_Update_Column["Code"] = "code";
    Addon_Update_Column["CreatedAt"] = "created_at";
    Addon_Update_Column["DefaultStatus"] = "default_status";
    Addon_Update_Column["ExclusionGroup"] = "exclusion_group";
    Addon_Update_Column["ExternalProductId"] = "external_product_id";
    Addon_Update_Column["FrontDescLargeScreen"] = "front_desc_large_screen";
    Addon_Update_Column["FrontDescSmallScreen"] = "front_desc_small_screen";
    Addon_Update_Column["GroupOrder"] = "group_order";
    Addon_Update_Column["Id"] = "id";
    Addon_Update_Column["Image"] = "image";
    Addon_Update_Column["IsOneTimePurchase"] = "is_one_time_purchase";
    Addon_Update_Column["MaxQty"] = "max_qty";
    Addon_Update_Column["MinQty"] = "min_qty";
    Addon_Update_Column["Name"] = "name";
    Addon_Update_Column["Price"] = "price";
    Addon_Update_Column["PriceBookEntrySalesforceId"] = "price_book_entry_salesforce_id";
    Addon_Update_Column["RecommendedFlag"] = "recommended_flag";
    Addon_Update_Column["SalesforceId"] = "salesforce_id";
    Addon_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    Addon_Update_Column["Type"] = "type";
    Addon_Update_Column["UpdatedAt"] = "updated_at";
})(Addon_Update_Column = exports.Addon_Update_Column || (exports.Addon_Update_Column = {}));
var Attachment_Constraint;
(function (Attachment_Constraint) {
    Attachment_Constraint["AttachmentPkey"] = "attachment_pkey";
})(Attachment_Constraint = exports.Attachment_Constraint || (exports.Attachment_Constraint = {}));
var Attachment_Parent_Type_Constraint;
(function (Attachment_Parent_Type_Constraint) {
    Attachment_Parent_Type_Constraint["AttachmentParentTypePkey"] = "attachment_parent_type_pkey";
})(Attachment_Parent_Type_Constraint = exports.Attachment_Parent_Type_Constraint || (exports.Attachment_Parent_Type_Constraint = {}));
var Attachment_Parent_Type_Enum;
(function (Attachment_Parent_Type_Enum) {
    Attachment_Parent_Type_Enum["ListingDocument"] = "listing_document";
    Attachment_Parent_Type_Enum["ListingMedia"] = "listing_media";
})(Attachment_Parent_Type_Enum = exports.Attachment_Parent_Type_Enum || (exports.Attachment_Parent_Type_Enum = {}));
var Attachment_Parent_Type_Select_Column;
(function (Attachment_Parent_Type_Select_Column) {
    Attachment_Parent_Type_Select_Column["Description"] = "description";
    Attachment_Parent_Type_Select_Column["Value"] = "value";
})(Attachment_Parent_Type_Select_Column = exports.Attachment_Parent_Type_Select_Column || (exports.Attachment_Parent_Type_Select_Column = {}));
var Attachment_Parent_Type_Update_Column;
(function (Attachment_Parent_Type_Update_Column) {
    Attachment_Parent_Type_Update_Column["Description"] = "description";
    Attachment_Parent_Type_Update_Column["Value"] = "value";
})(Attachment_Parent_Type_Update_Column = exports.Attachment_Parent_Type_Update_Column || (exports.Attachment_Parent_Type_Update_Column = {}));
var Attachment_Select_Column;
(function (Attachment_Select_Column) {
    Attachment_Select_Column["CreatedAt"] = "created_at";
    Attachment_Select_Column["DownloadUrl"] = "download_url";
    Attachment_Select_Column["Id"] = "id";
    Attachment_Select_Column["OwnerId"] = "owner_id";
    Attachment_Select_Column["ParentId"] = "parent_id";
    Attachment_Select_Column["ParentType"] = "parent_type";
    Attachment_Select_Column["Status"] = "status";
    Attachment_Select_Column["Type"] = "type";
    Attachment_Select_Column["UpdatedAt"] = "updated_at";
})(Attachment_Select_Column = exports.Attachment_Select_Column || (exports.Attachment_Select_Column = {}));
var Attachment_Status_Constraint;
(function (Attachment_Status_Constraint) {
    Attachment_Status_Constraint["AttachmentStatusPkey"] = "attachment_status_pkey";
})(Attachment_Status_Constraint = exports.Attachment_Status_Constraint || (exports.Attachment_Status_Constraint = {}));
var Attachment_Status_Enum;
(function (Attachment_Status_Enum) {
    Attachment_Status_Enum["Failed"] = "failed";
    Attachment_Status_Enum["Loaded"] = "loaded";
    Attachment_Status_Enum["Loading"] = "loading";
})(Attachment_Status_Enum = exports.Attachment_Status_Enum || (exports.Attachment_Status_Enum = {}));
var Attachment_Status_Select_Column;
(function (Attachment_Status_Select_Column) {
    Attachment_Status_Select_Column["Description"] = "description";
    Attachment_Status_Select_Column["Value"] = "value";
})(Attachment_Status_Select_Column = exports.Attachment_Status_Select_Column || (exports.Attachment_Status_Select_Column = {}));
var Attachment_Status_Update_Column;
(function (Attachment_Status_Update_Column) {
    Attachment_Status_Update_Column["Description"] = "description";
    Attachment_Status_Update_Column["Value"] = "value";
})(Attachment_Status_Update_Column = exports.Attachment_Status_Update_Column || (exports.Attachment_Status_Update_Column = {}));
var Attachment_Type_Constraint;
(function (Attachment_Type_Constraint) {
    Attachment_Type_Constraint["AttachmentTypePkey"] = "attachment_type_pkey";
})(Attachment_Type_Constraint = exports.Attachment_Type_Constraint || (exports.Attachment_Type_Constraint = {}));
var Attachment_Type_Enum;
(function (Attachment_Type_Enum) {
    Attachment_Type_Enum["EvidenceOfAuthority"] = "EVIDENCE_OF_AUTHORITY";
    Attachment_Type_Enum["FloorPlan"] = "FLOOR_PLAN";
    Attachment_Type_Enum["Photo"] = "PHOTO";
    Attachment_Type_Enum["ProofOfId"] = "PROOF_OF_ID";
    Attachment_Type_Enum["ProofOfOwnership"] = "PROOF_OF_OWNERSHIP";
    Attachment_Type_Enum["StatementOfInformation"] = "STATEMENT_OF_INFORMATION";
})(Attachment_Type_Enum = exports.Attachment_Type_Enum || (exports.Attachment_Type_Enum = {}));
var Attachment_Type_Select_Column;
(function (Attachment_Type_Select_Column) {
    Attachment_Type_Select_Column["Description"] = "description";
    Attachment_Type_Select_Column["Value"] = "value";
})(Attachment_Type_Select_Column = exports.Attachment_Type_Select_Column || (exports.Attachment_Type_Select_Column = {}));
var Attachment_Type_Update_Column;
(function (Attachment_Type_Update_Column) {
    Attachment_Type_Update_Column["Description"] = "description";
    Attachment_Type_Update_Column["Value"] = "value";
})(Attachment_Type_Update_Column = exports.Attachment_Type_Update_Column || (exports.Attachment_Type_Update_Column = {}));
var Attachment_Update_Column;
(function (Attachment_Update_Column) {
    Attachment_Update_Column["CreatedAt"] = "created_at";
    Attachment_Update_Column["DownloadUrl"] = "download_url";
    Attachment_Update_Column["Id"] = "id";
    Attachment_Update_Column["OwnerId"] = "owner_id";
    Attachment_Update_Column["ParentId"] = "parent_id";
    Attachment_Update_Column["ParentType"] = "parent_type";
    Attachment_Update_Column["Status"] = "status";
    Attachment_Update_Column["Type"] = "type";
    Attachment_Update_Column["UpdatedAt"] = "updated_at";
})(Attachment_Update_Column = exports.Attachment_Update_Column || (exports.Attachment_Update_Column = {}));
var Benefit_Constraint;
(function (Benefit_Constraint) {
    Benefit_Constraint["BenefitPkey"] = "benefit_pkey";
})(Benefit_Constraint = exports.Benefit_Constraint || (exports.Benefit_Constraint = {}));
var Benefit_Select_Column;
(function (Benefit_Select_Column) {
    Benefit_Select_Column["AddonId"] = "addon_id";
    Benefit_Select_Column["CreatedAt"] = "created_at";
    Benefit_Select_Column["Description"] = "description";
    Benefit_Select_Column["Id"] = "id";
    Benefit_Select_Column["Order"] = "order";
    Benefit_Select_Column["UpdatedAt"] = "updated_at";
})(Benefit_Select_Column = exports.Benefit_Select_Column || (exports.Benefit_Select_Column = {}));
var Benefit_Update_Column;
(function (Benefit_Update_Column) {
    Benefit_Update_Column["AddonId"] = "addon_id";
    Benefit_Update_Column["CreatedAt"] = "created_at";
    Benefit_Update_Column["Description"] = "description";
    Benefit_Update_Column["Id"] = "id";
    Benefit_Update_Column["Order"] = "order";
    Benefit_Update_Column["UpdatedAt"] = "updated_at";
})(Benefit_Update_Column = exports.Benefit_Update_Column || (exports.Benefit_Update_Column = {}));
var Bmp_Listing_Constraint;
(function (Bmp_Listing_Constraint) {
    Bmp_Listing_Constraint["BmpListingPkey"] = "bmp_listing_pkey";
})(Bmp_Listing_Constraint = exports.Bmp_Listing_Constraint || (exports.Bmp_Listing_Constraint = {}));
var Bmp_Listing_Contact_Select_Column;
(function (Bmp_Listing_Contact_Select_Column) {
    Bmp_Listing_Contact_Select_Column["CreatedAt"] = "created_at";
    Bmp_Listing_Contact_Select_Column["DomainAgencyId"] = "domainAgencyID";
    Bmp_Listing_Contact_Select_Column["DomainAgentId"] = "domainAgentId";
    Bmp_Listing_Contact_Select_Column["Email"] = "email";
    Bmp_Listing_Contact_Select_Column["Fax"] = "fax";
    Bmp_Listing_Contact_Select_Column["FirstName"] = "firstName";
    Bmp_Listing_Contact_Select_Column["Id"] = "id";
    Bmp_Listing_Contact_Select_Column["LastName"] = "lastName";
    Bmp_Listing_Contact_Select_Column["ListingId"] = "listing_id";
    Bmp_Listing_Contact_Select_Column["Mobile"] = "mobile";
    Bmp_Listing_Contact_Select_Column["Phone"] = "phone";
    Bmp_Listing_Contact_Select_Column["PhoneType"] = "phoneType";
    Bmp_Listing_Contact_Select_Column["Portal"] = "portal";
    Bmp_Listing_Contact_Select_Column["ReceiveEmails"] = "receiveEmails";
    Bmp_Listing_Contact_Select_Column["SocialMedia"] = "socialMedia";
    Bmp_Listing_Contact_Select_Column["SocialMediaUrl"] = "socialMedia_URL";
    Bmp_Listing_Contact_Select_Column["UpdatedAt"] = "updated_at";
})(Bmp_Listing_Contact_Select_Column = exports.Bmp_Listing_Contact_Select_Column || (exports.Bmp_Listing_Contact_Select_Column = {}));
var Bmp_Listing_Inspection_Select_Column;
(function (Bmp_Listing_Inspection_Select_Column) {
    Bmp_Listing_Inspection_Select_Column["AgentPointId"] = "agent_point_id";
    Bmp_Listing_Inspection_Select_Column["CreatedAt"] = "created_at";
    Bmp_Listing_Inspection_Select_Column["From"] = "from";
    Bmp_Listing_Inspection_Select_Column["Id"] = "id";
    Bmp_Listing_Inspection_Select_Column["IsPrivate"] = "is_private";
    Bmp_Listing_Inspection_Select_Column["ListingId"] = "listing_id";
    Bmp_Listing_Inspection_Select_Column["Repeat"] = "repeat";
    Bmp_Listing_Inspection_Select_Column["To"] = "to";
    Bmp_Listing_Inspection_Select_Column["UpdatedAt"] = "updated_at";
})(Bmp_Listing_Inspection_Select_Column = exports.Bmp_Listing_Inspection_Select_Column || (exports.Bmp_Listing_Inspection_Select_Column = {}));
var Bmp_Listing_Media_Select_Column;
(function (Bmp_Listing_Media_Select_Column) {
    Bmp_Listing_Media_Select_Column["AgentPointId"] = "agent_point_id";
    Bmp_Listing_Media_Select_Column["AttachmentId"] = "attachment_id";
    Bmp_Listing_Media_Select_Column["CreatedAt"] = "created_at";
    Bmp_Listing_Media_Select_Column["Desc"] = "desc";
    Bmp_Listing_Media_Select_Column["Id"] = "id";
    Bmp_Listing_Media_Select_Column["ImagesIdAttribute"] = "images_id_attribute";
    Bmp_Listing_Media_Select_Column["IsDownloaded"] = "is_downloaded";
    Bmp_Listing_Media_Select_Column["IsUploaded"] = "is_uploaded";
    Bmp_Listing_Media_Select_Column["ListingId"] = "listing_id";
    Bmp_Listing_Media_Select_Column["MimeType"] = "mime_type";
    Bmp_Listing_Media_Select_Column["ObjectsIdAttribute"] = "objects_id_attribute";
    Bmp_Listing_Media_Select_Column["Order"] = "order";
    Bmp_Listing_Media_Select_Column["OwnerId"] = "owner_id";
    Bmp_Listing_Media_Select_Column["ProcessedError"] = "processed_error";
    Bmp_Listing_Media_Select_Column["ProcessingStatus"] = "processing_status";
    Bmp_Listing_Media_Select_Column["Thumbnail_1212"] = "thumbnail_1212";
    Bmp_Listing_Media_Select_Column["Thumbnail_1818"] = "thumbnail_1818";
    Bmp_Listing_Media_Select_Column["Thumbnail_185"] = "thumbnail_185";
    Bmp_Listing_Media_Select_Column["Thumbnail_284"] = "thumbnail_284";
    Bmp_Listing_Media_Select_Column["Thumbnail_370"] = "thumbnail_370";
    Bmp_Listing_Media_Select_Column["Thumbnail_555"] = "thumbnail_555";
    Bmp_Listing_Media_Select_Column["Thumbnail_568"] = "thumbnail_568";
    Bmp_Listing_Media_Select_Column["Thumbnail_606"] = "thumbnail_606";
    Bmp_Listing_Media_Select_Column["Thumbnail_852"] = "thumbnail_852";
    Bmp_Listing_Media_Select_Column["Type"] = "type";
    Bmp_Listing_Media_Select_Column["UpdatedAt"] = "updated_at";
    Bmp_Listing_Media_Select_Column["Url"] = "url";
})(Bmp_Listing_Media_Select_Column = exports.Bmp_Listing_Media_Select_Column || (exports.Bmp_Listing_Media_Select_Column = {}));
var Bmp_Listing_Search_Select_Column;
(function (Bmp_Listing_Search_Select_Column) {
    Bmp_Listing_Search_Select_Column["Bathrooms"] = "bathrooms";
    Bmp_Listing_Search_Select_Column["Bedrooms"] = "bedrooms";
    Bmp_Listing_Search_Select_Column["CarSpaces"] = "carSpaces";
    Bmp_Listing_Search_Select_Column["Id"] = "id";
    Bmp_Listing_Search_Select_Column["Postcode"] = "postcode";
    Bmp_Listing_Search_Select_Column["PropertyType"] = "propertyType";
    Bmp_Listing_Search_Select_Column["SaleType"] = "saleType";
    Bmp_Listing_Search_Select_Column["SearchSource"] = "search_source";
    Bmp_Listing_Search_Select_Column["State"] = "state";
    Bmp_Listing_Search_Select_Column["Suburb"] = "suburb";
    Bmp_Listing_Search_Select_Column["Summary"] = "summary";
})(Bmp_Listing_Search_Select_Column = exports.Bmp_Listing_Search_Select_Column || (exports.Bmp_Listing_Search_Select_Column = {}));
var Bmp_Listing_Select_Column;
(function (Bmp_Listing_Select_Column) {
    Bmp_Listing_Select_Column["AgentPointId"] = "agent_point_id";
    Bmp_Listing_Select_Column["AuctionDateTime"] = "auction_dateTime";
    Bmp_Listing_Select_Column["AuctionLocation"] = "auction_location";
    Bmp_Listing_Select_Column["AuctionOnline"] = "auction_online";
    Bmp_Listing_Select_Column["Authority"] = "authority";
    Bmp_Listing_Select_Column["AvailableFrom"] = "availableFrom";
    Bmp_Listing_Select_Column["Bond"] = "bond";
    Bmp_Listing_Select_Column["CommercialStatus"] = "commercial_status";
    Bmp_Listing_Select_Column["ContactPreference"] = "contactPreference";
    Bmp_Listing_Select_Column["CreatedAt"] = "created_at";
    Bmp_Listing_Select_Column["CrossOver"] = "crossOver";
    Bmp_Listing_Select_Column["Description"] = "description";
    Bmp_Listing_Select_Column["DomainAdId"] = "domainAdId";
    Bmp_Listing_Select_Column["EnquiryCode"] = "enquiry_code";
    Bmp_Listing_Select_Column["Experiments"] = "experiments";
    Bmp_Listing_Select_Column["ExternalLink"] = "externalLink";
    Bmp_Listing_Select_Column["Features"] = "features";
    Bmp_Listing_Select_Column["Id"] = "id";
    Bmp_Listing_Select_Column["InspectionDetailsInspectionDescription"] = "inspectionDetails_inspectionDescription";
    Bmp_Listing_Select_Column["IsNewDevelopment"] = "isNewDevelopment";
    Bmp_Listing_Select_Column["IsRural"] = "isRural";
    Bmp_Listing_Select_Column["LifeStyleType"] = "lifeStyleType";
    Bmp_Listing_Select_Column["ListingAction"] = "listingAction";
    Bmp_Listing_Select_Column["OtherEnquiryEmail"] = "otherEnquiryEmail";
    Bmp_Listing_Select_Column["OwnerId"] = "owner_id";
    Bmp_Listing_Select_Column["PriceAsking"] = "price_asking";
    Bmp_Listing_Select_Column["PriceDisplayOption"] = "price_displayOption";
    Bmp_Listing_Select_Column["PriceDisplayText"] = "price_displayText";
    Bmp_Listing_Select_Column["PriceFrom"] = "price_from";
    Bmp_Listing_Select_Column["PriceSingle"] = "price_single";
    Bmp_Listing_Select_Column["PriceTextOption"] = "price_textOption";
    Bmp_Listing_Select_Column["PriceTo"] = "price_to";
    Bmp_Listing_Select_Column["PriceView"] = "price_view";
    Bmp_Listing_Select_Column["ProofOfOwnershipStatus"] = "proof_of_ownership_status";
    Bmp_Listing_Select_Column["PropertyDetailsAddress_1"] = "propertyDetails_address_1";
    Bmp_Listing_Select_Column["PropertyDetailsAddress_2"] = "propertyDetails_address_2";
    Bmp_Listing_Select_Column["PropertyDetailsAddressDisplayOption"] = "propertyDetails_address_displayOption";
    Bmp_Listing_Select_Column["PropertyDetailsAddressMunicipality"] = "propertyDetails_address_municipality";
    Bmp_Listing_Select_Column["PropertyDetailsAddressPostcode"] = "propertyDetails_address_postcode";
    Bmp_Listing_Select_Column["PropertyDetailsAddressPostcodeBeforeCheckout"] = "propertyDetails_address_postcode_before_checkout";
    Bmp_Listing_Select_Column["PropertyDetailsAddressState"] = "propertyDetails_address_state";
    Bmp_Listing_Select_Column["PropertyDetailsAddressStateBeforeCheckout"] = "propertyDetails_address_state_before_checkout";
    Bmp_Listing_Select_Column["PropertyDetailsAddressStreet"] = "propertyDetails_address_street";
    Bmp_Listing_Select_Column["PropertyDetailsAddressStreetDirectoryPage"] = "propertyDetails_address_streetDirectory_page";
    Bmp_Listing_Select_Column["PropertyDetailsAddressStreetDirectoryReference"] = "propertyDetails_address_streetDirectory_reference";
    Bmp_Listing_Select_Column["PropertyDetailsAddressStreetDirectoryType"] = "propertyDetails_address_streetDirectory_type";
    Bmp_Listing_Select_Column["PropertyDetailsAddressStreetNumber"] = "propertyDetails_address_streetNumber";
    Bmp_Listing_Select_Column["PropertyDetailsAddressSuburb"] = "propertyDetails_address_suburb";
    Bmp_Listing_Select_Column["PropertyDetailsAddressSuburbBeforeCheckout"] = "propertyDetails_address_suburb_before_checkout";
    Bmp_Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocation"] = "propertyDetails_address_suggestedGeoLocation";
    Bmp_Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocationLatitude"] = "propertyDetails_address_suggestedGeoLocation_latitude";
    Bmp_Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocationLongitude"] = "propertyDetails_address_suggestedGeoLocation_longitude";
    Bmp_Listing_Select_Column["PropertyDetailsAddressUnitNumber"] = "propertyDetails_address_unitNumber";
    Bmp_Listing_Select_Column["PropertyDetailsAreaFrom"] = "propertyDetails_area_from";
    Bmp_Listing_Select_Column["PropertyDetailsAreaTo"] = "propertyDetails_area_to";
    Bmp_Listing_Select_Column["PropertyDetailsAreaUnit"] = "propertyDetails_area_unit";
    Bmp_Listing_Select_Column["PropertyDetailsAreaValue"] = "propertyDetails_area_value";
    Bmp_Listing_Select_Column["PropertyDetailsBathRooms"] = "propertyDetails_bathRooms";
    Bmp_Listing_Select_Column["PropertyDetailsBedRooms"] = "propertyDetails_bedRooms";
    Bmp_Listing_Select_Column["PropertyDetailsCarSpaces"] = "propertyDetails_carSpaces";
    Bmp_Listing_Select_Column["PropertyDetailsCarportSpaces"] = "propertyDetails_carportSpaces";
    Bmp_Listing_Select_Column["PropertyDetailsEnergyEfficiencyRating"] = "propertyDetails_energyEfficiencyRating";
    Bmp_Listing_Select_Column["PropertyDetailsGarageSpaces"] = "propertyDetails_garageSpaces";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaDepthLeft"] = "propertyDetails_landArea_depth_left";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaDepthRear"] = "propertyDetails_landArea_depth_rear";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaDepthRight"] = "propertyDetails_landArea_depth_right";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaDepthUnit"] = "propertyDetails_landArea_depth_unit";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaFrom"] = "propertyDetails_landArea_from";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaFrontage"] = "propertyDetails_landArea_frontage";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaFrontageUnit"] = "propertyDetails_landArea_frontage_unit";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaTo"] = "propertyDetails_landArea_to";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaUnit"] = "propertyDetails_landArea_unit";
    Bmp_Listing_Select_Column["PropertyDetailsLandAreaValue"] = "propertyDetails_landArea_value";
    Bmp_Listing_Select_Column["PropertyDetailsParkingInfoInformation"] = "propertyDetails_parkingInfo_information";
    Bmp_Listing_Select_Column["PropertyDetailsPropertyType"] = "propertyDetails_propertyType";
    Bmp_Listing_Select_Column["PropertyDetailsPropertyTypeHotWaterServiceType"] = "propertyDetails_propertyType_hotWaterServiceType";
    Bmp_Listing_Select_Column["PropertyDetailsYearBuilt"] = "propertyDetails_yearBuilt";
    Bmp_Listing_Select_Column["ProviderAdId"] = "providerAdId";
    Bmp_Listing_Select_Column["PublishingStatus"] = "publishing_status";
    Bmp_Listing_Select_Column["SaleType"] = "saleType";
    Bmp_Listing_Select_Column["SalesforceId"] = "salesforce_id";
    Bmp_Listing_Select_Column["SearchPrice"] = "search_price";
    Bmp_Listing_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Bmp_Listing_Select_Column["SoiComparableData"] = "soi_comparableData";
    Bmp_Listing_Select_Column["SoiComparableDataComparablePropertyDateOf"] = "soi_comparableData_comparableProperty_dateOf";
    Bmp_Listing_Select_Column["SoiComparableDataComparablePropertySoldPr"] = "soi_comparableData_comparableProperty_soldPr";
    Bmp_Listing_Select_Column["SoiComparableDataDeclarationText"] = "soi_comparableData_declarationText";
    Bmp_Listing_Select_Column["SoiDocumentationUrl"] = "soi_documentationUrl";
    Bmp_Listing_Select_Column["SoiEstimatedPrice"] = "soi_estimatedPrice";
    Bmp_Listing_Select_Column["SoiEstimatedPriceFrom"] = "soi_estimatedPrice_from";
    Bmp_Listing_Select_Column["SoiEstimatedPriceTo"] = "soi_estimatedPrice_to";
    Bmp_Listing_Select_Column["SoiSuburbMedianPrice"] = "soi_suburbMedianPrice";
    Bmp_Listing_Select_Column["SoiSuburbMedianPriceDeclarationText"] = "soi_suburbMedianPrice_declarationText";
    Bmp_Listing_Select_Column["SoiSuburbMedianPriceMedianPrice"] = "soi_suburbMedianPrice_medianPrice";
    Bmp_Listing_Select_Column["SoiSuburbMedianPricePostcode"] = "soi_suburbMedianPrice_postcode";
    Bmp_Listing_Select_Column["SoiSuburbMedianPricePriceType"] = "soi_suburbMedianPrice_priceType";
    Bmp_Listing_Select_Column["SoiSuburbMedianPriceSource"] = "soi_suburbMedianPrice_source";
    Bmp_Listing_Select_Column["SoiSuburbMedianPriceSourceDateFrom"] = "soi_suburbMedianPrice_sourceDateFrom";
    Bmp_Listing_Select_Column["SoiSuburbMedianPriceSourceDateTo"] = "soi_suburbMedianPrice_sourceDateTo";
    Bmp_Listing_Select_Column["SoiSuburbMedianPriceSuburb"] = "soi_suburbMedianPrice_suburb";
    Bmp_Listing_Select_Column["Status"] = "status";
    Bmp_Listing_Select_Column["Summary"] = "summary";
    Bmp_Listing_Select_Column["SupplementaryDescription"] = "supplementary_description";
    Bmp_Listing_Select_Column["SupplementaryMetadataDescription"] = "supplementary_metadata_description";
    Bmp_Listing_Select_Column["SupplementaryMetadataMeasurement"] = "supplementary_metadata_measurement";
    Bmp_Listing_Select_Column["SupplementaryMetadataMeasurementUnit"] = "supplementary_metadata_measurementUnit";
    Bmp_Listing_Select_Column["SupplementaryMetadataName"] = "supplementary_metadata_name";
    Bmp_Listing_Select_Column["SupplementaryName"] = "supplementary_name";
    Bmp_Listing_Select_Column["SupplementaryTypes"] = "supplementary_types";
    Bmp_Listing_Select_Column["TemporaryAddOnFlag"] = "temporary_add_on_flag";
    Bmp_Listing_Select_Column["UpdatedAt"] = "updated_at";
    Bmp_Listing_Select_Column["Zone"] = "zone";
})(Bmp_Listing_Select_Column = exports.Bmp_Listing_Select_Column || (exports.Bmp_Listing_Select_Column = {}));
var Bmp_Listing_Update_Column;
(function (Bmp_Listing_Update_Column) {
    Bmp_Listing_Update_Column["AgentPointId"] = "agent_point_id";
    Bmp_Listing_Update_Column["AuctionDateTime"] = "auction_dateTime";
    Bmp_Listing_Update_Column["AuctionLocation"] = "auction_location";
    Bmp_Listing_Update_Column["AuctionOnline"] = "auction_online";
    Bmp_Listing_Update_Column["Authority"] = "authority";
    Bmp_Listing_Update_Column["AvailableFrom"] = "availableFrom";
    Bmp_Listing_Update_Column["Bond"] = "bond";
    Bmp_Listing_Update_Column["CommercialStatus"] = "commercial_status";
    Bmp_Listing_Update_Column["ContactPreference"] = "contactPreference";
    Bmp_Listing_Update_Column["CreatedAt"] = "created_at";
    Bmp_Listing_Update_Column["CrossOver"] = "crossOver";
    Bmp_Listing_Update_Column["Description"] = "description";
    Bmp_Listing_Update_Column["DomainAdId"] = "domainAdId";
    Bmp_Listing_Update_Column["EnquiryCode"] = "enquiry_code";
    Bmp_Listing_Update_Column["Experiments"] = "experiments";
    Bmp_Listing_Update_Column["ExternalLink"] = "externalLink";
    Bmp_Listing_Update_Column["Features"] = "features";
    Bmp_Listing_Update_Column["Id"] = "id";
    Bmp_Listing_Update_Column["InspectionDetailsInspectionDescription"] = "inspectionDetails_inspectionDescription";
    Bmp_Listing_Update_Column["IsNewDevelopment"] = "isNewDevelopment";
    Bmp_Listing_Update_Column["IsRural"] = "isRural";
    Bmp_Listing_Update_Column["LifeStyleType"] = "lifeStyleType";
    Bmp_Listing_Update_Column["ListingAction"] = "listingAction";
    Bmp_Listing_Update_Column["OtherEnquiryEmail"] = "otherEnquiryEmail";
    Bmp_Listing_Update_Column["OwnerId"] = "owner_id";
    Bmp_Listing_Update_Column["PriceAsking"] = "price_asking";
    Bmp_Listing_Update_Column["PriceDisplayOption"] = "price_displayOption";
    Bmp_Listing_Update_Column["PriceDisplayText"] = "price_displayText";
    Bmp_Listing_Update_Column["PriceFrom"] = "price_from";
    Bmp_Listing_Update_Column["PriceSingle"] = "price_single";
    Bmp_Listing_Update_Column["PriceTextOption"] = "price_textOption";
    Bmp_Listing_Update_Column["PriceTo"] = "price_to";
    Bmp_Listing_Update_Column["PriceView"] = "price_view";
    Bmp_Listing_Update_Column["ProofOfOwnershipStatus"] = "proof_of_ownership_status";
    Bmp_Listing_Update_Column["PropertyDetailsAddress_1"] = "propertyDetails_address_1";
    Bmp_Listing_Update_Column["PropertyDetailsAddress_2"] = "propertyDetails_address_2";
    Bmp_Listing_Update_Column["PropertyDetailsAddressDisplayOption"] = "propertyDetails_address_displayOption";
    Bmp_Listing_Update_Column["PropertyDetailsAddressMunicipality"] = "propertyDetails_address_municipality";
    Bmp_Listing_Update_Column["PropertyDetailsAddressPostcode"] = "propertyDetails_address_postcode";
    Bmp_Listing_Update_Column["PropertyDetailsAddressPostcodeBeforeCheckout"] = "propertyDetails_address_postcode_before_checkout";
    Bmp_Listing_Update_Column["PropertyDetailsAddressState"] = "propertyDetails_address_state";
    Bmp_Listing_Update_Column["PropertyDetailsAddressStateBeforeCheckout"] = "propertyDetails_address_state_before_checkout";
    Bmp_Listing_Update_Column["PropertyDetailsAddressStreet"] = "propertyDetails_address_street";
    Bmp_Listing_Update_Column["PropertyDetailsAddressStreetDirectoryPage"] = "propertyDetails_address_streetDirectory_page";
    Bmp_Listing_Update_Column["PropertyDetailsAddressStreetDirectoryReference"] = "propertyDetails_address_streetDirectory_reference";
    Bmp_Listing_Update_Column["PropertyDetailsAddressStreetDirectoryType"] = "propertyDetails_address_streetDirectory_type";
    Bmp_Listing_Update_Column["PropertyDetailsAddressStreetNumber"] = "propertyDetails_address_streetNumber";
    Bmp_Listing_Update_Column["PropertyDetailsAddressSuburb"] = "propertyDetails_address_suburb";
    Bmp_Listing_Update_Column["PropertyDetailsAddressSuburbBeforeCheckout"] = "propertyDetails_address_suburb_before_checkout";
    Bmp_Listing_Update_Column["PropertyDetailsAddressSuggestedGeoLocation"] = "propertyDetails_address_suggestedGeoLocation";
    Bmp_Listing_Update_Column["PropertyDetailsAddressSuggestedGeoLocationLatitude"] = "propertyDetails_address_suggestedGeoLocation_latitude";
    Bmp_Listing_Update_Column["PropertyDetailsAddressSuggestedGeoLocationLongitude"] = "propertyDetails_address_suggestedGeoLocation_longitude";
    Bmp_Listing_Update_Column["PropertyDetailsAddressUnitNumber"] = "propertyDetails_address_unitNumber";
    Bmp_Listing_Update_Column["PropertyDetailsAreaFrom"] = "propertyDetails_area_from";
    Bmp_Listing_Update_Column["PropertyDetailsAreaTo"] = "propertyDetails_area_to";
    Bmp_Listing_Update_Column["PropertyDetailsAreaUnit"] = "propertyDetails_area_unit";
    Bmp_Listing_Update_Column["PropertyDetailsAreaValue"] = "propertyDetails_area_value";
    Bmp_Listing_Update_Column["PropertyDetailsBathRooms"] = "propertyDetails_bathRooms";
    Bmp_Listing_Update_Column["PropertyDetailsBedRooms"] = "propertyDetails_bedRooms";
    Bmp_Listing_Update_Column["PropertyDetailsCarSpaces"] = "propertyDetails_carSpaces";
    Bmp_Listing_Update_Column["PropertyDetailsCarportSpaces"] = "propertyDetails_carportSpaces";
    Bmp_Listing_Update_Column["PropertyDetailsEnergyEfficiencyRating"] = "propertyDetails_energyEfficiencyRating";
    Bmp_Listing_Update_Column["PropertyDetailsGarageSpaces"] = "propertyDetails_garageSpaces";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaDepthLeft"] = "propertyDetails_landArea_depth_left";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaDepthRear"] = "propertyDetails_landArea_depth_rear";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaDepthRight"] = "propertyDetails_landArea_depth_right";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaDepthUnit"] = "propertyDetails_landArea_depth_unit";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaFrom"] = "propertyDetails_landArea_from";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaFrontage"] = "propertyDetails_landArea_frontage";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaFrontageUnit"] = "propertyDetails_landArea_frontage_unit";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaTo"] = "propertyDetails_landArea_to";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaUnit"] = "propertyDetails_landArea_unit";
    Bmp_Listing_Update_Column["PropertyDetailsLandAreaValue"] = "propertyDetails_landArea_value";
    Bmp_Listing_Update_Column["PropertyDetailsParkingInfoInformation"] = "propertyDetails_parkingInfo_information";
    Bmp_Listing_Update_Column["PropertyDetailsPropertyType"] = "propertyDetails_propertyType";
    Bmp_Listing_Update_Column["PropertyDetailsPropertyTypeHotWaterServiceType"] = "propertyDetails_propertyType_hotWaterServiceType";
    Bmp_Listing_Update_Column["PropertyDetailsYearBuilt"] = "propertyDetails_yearBuilt";
    Bmp_Listing_Update_Column["ProviderAdId"] = "providerAdId";
    Bmp_Listing_Update_Column["PublishingStatus"] = "publishing_status";
    Bmp_Listing_Update_Column["SaleType"] = "saleType";
    Bmp_Listing_Update_Column["SalesforceId"] = "salesforce_id";
    Bmp_Listing_Update_Column["SearchPrice"] = "search_price";
    Bmp_Listing_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    Bmp_Listing_Update_Column["SoiComparableData"] = "soi_comparableData";
    Bmp_Listing_Update_Column["SoiComparableDataComparablePropertyDateOf"] = "soi_comparableData_comparableProperty_dateOf";
    Bmp_Listing_Update_Column["SoiComparableDataComparablePropertySoldPr"] = "soi_comparableData_comparableProperty_soldPr";
    Bmp_Listing_Update_Column["SoiComparableDataDeclarationText"] = "soi_comparableData_declarationText";
    Bmp_Listing_Update_Column["SoiDocumentationUrl"] = "soi_documentationUrl";
    Bmp_Listing_Update_Column["SoiEstimatedPrice"] = "soi_estimatedPrice";
    Bmp_Listing_Update_Column["SoiEstimatedPriceFrom"] = "soi_estimatedPrice_from";
    Bmp_Listing_Update_Column["SoiEstimatedPriceTo"] = "soi_estimatedPrice_to";
    Bmp_Listing_Update_Column["SoiSuburbMedianPrice"] = "soi_suburbMedianPrice";
    Bmp_Listing_Update_Column["SoiSuburbMedianPriceDeclarationText"] = "soi_suburbMedianPrice_declarationText";
    Bmp_Listing_Update_Column["SoiSuburbMedianPriceMedianPrice"] = "soi_suburbMedianPrice_medianPrice";
    Bmp_Listing_Update_Column["SoiSuburbMedianPricePostcode"] = "soi_suburbMedianPrice_postcode";
    Bmp_Listing_Update_Column["SoiSuburbMedianPricePriceType"] = "soi_suburbMedianPrice_priceType";
    Bmp_Listing_Update_Column["SoiSuburbMedianPriceSource"] = "soi_suburbMedianPrice_source";
    Bmp_Listing_Update_Column["SoiSuburbMedianPriceSourceDateFrom"] = "soi_suburbMedianPrice_sourceDateFrom";
    Bmp_Listing_Update_Column["SoiSuburbMedianPriceSourceDateTo"] = "soi_suburbMedianPrice_sourceDateTo";
    Bmp_Listing_Update_Column["SoiSuburbMedianPriceSuburb"] = "soi_suburbMedianPrice_suburb";
    Bmp_Listing_Update_Column["Status"] = "status";
    Bmp_Listing_Update_Column["Summary"] = "summary";
    Bmp_Listing_Update_Column["SupplementaryDescription"] = "supplementary_description";
    Bmp_Listing_Update_Column["SupplementaryMetadataDescription"] = "supplementary_metadata_description";
    Bmp_Listing_Update_Column["SupplementaryMetadataMeasurement"] = "supplementary_metadata_measurement";
    Bmp_Listing_Update_Column["SupplementaryMetadataMeasurementUnit"] = "supplementary_metadata_measurementUnit";
    Bmp_Listing_Update_Column["SupplementaryMetadataName"] = "supplementary_metadata_name";
    Bmp_Listing_Update_Column["SupplementaryName"] = "supplementary_name";
    Bmp_Listing_Update_Column["SupplementaryTypes"] = "supplementary_types";
    Bmp_Listing_Update_Column["TemporaryAddOnFlag"] = "temporary_add_on_flag";
    Bmp_Listing_Update_Column["UpdatedAt"] = "updated_at";
    Bmp_Listing_Update_Column["Zone"] = "zone";
})(Bmp_Listing_Update_Column = exports.Bmp_Listing_Update_Column || (exports.Bmp_Listing_Update_Column = {}));
var Cache_Constraint;
(function (Cache_Constraint) {
    Cache_Constraint["CachePkey"] = "cache_pkey";
})(Cache_Constraint = exports.Cache_Constraint || (exports.Cache_Constraint = {}));
var Cache_Select_Column;
(function (Cache_Select_Column) {
    Cache_Select_Column["CreatedAt"] = "created_at";
    Cache_Select_Column["ExpiresAt"] = "expires_at";
    Cache_Select_Column["Key"] = "key";
    Cache_Select_Column["UpdatedAt"] = "updated_at";
    Cache_Select_Column["Value"] = "value";
})(Cache_Select_Column = exports.Cache_Select_Column || (exports.Cache_Select_Column = {}));
var Cache_Update_Column;
(function (Cache_Update_Column) {
    Cache_Update_Column["CreatedAt"] = "created_at";
    Cache_Update_Column["ExpiresAt"] = "expires_at";
    Cache_Update_Column["Key"] = "key";
    Cache_Update_Column["UpdatedAt"] = "updated_at";
    Cache_Update_Column["Value"] = "value";
})(Cache_Update_Column = exports.Cache_Update_Column || (exports.Cache_Update_Column = {}));
var CacheControlScope;
(function (CacheControlScope) {
    CacheControlScope["Private"] = "PRIVATE";
    CacheControlScope["Public"] = "PUBLIC";
})(CacheControlScope = exports.CacheControlScope || (exports.CacheControlScope = {}));
var Campaign_Addon_Group_Constraint;
(function (Campaign_Addon_Group_Constraint) {
    Campaign_Addon_Group_Constraint["CampaignAddonGroupPkey"] = "campaign_addon_group_pkey";
})(Campaign_Addon_Group_Constraint = exports.Campaign_Addon_Group_Constraint || (exports.Campaign_Addon_Group_Constraint = {}));
var Campaign_Addon_Group_Select_Column;
(function (Campaign_Addon_Group_Select_Column) {
    Campaign_Addon_Group_Select_Column["AddonGroupId"] = "addon_group_id";
    Campaign_Addon_Group_Select_Column["CampaignId"] = "campaign_id";
    Campaign_Addon_Group_Select_Column["CreatedAt"] = "created_at";
    Campaign_Addon_Group_Select_Column["Id"] = "id";
    Campaign_Addon_Group_Select_Column["UpdatedAt"] = "updated_at";
})(Campaign_Addon_Group_Select_Column = exports.Campaign_Addon_Group_Select_Column || (exports.Campaign_Addon_Group_Select_Column = {}));
var Campaign_Addon_Group_Update_Column;
(function (Campaign_Addon_Group_Update_Column) {
    Campaign_Addon_Group_Update_Column["AddonGroupId"] = "addon_group_id";
    Campaign_Addon_Group_Update_Column["CampaignId"] = "campaign_id";
    Campaign_Addon_Group_Update_Column["CreatedAt"] = "created_at";
    Campaign_Addon_Group_Update_Column["Id"] = "id";
    Campaign_Addon_Group_Update_Column["UpdatedAt"] = "updated_at";
})(Campaign_Addon_Group_Update_Column = exports.Campaign_Addon_Group_Update_Column || (exports.Campaign_Addon_Group_Update_Column = {}));
var Campaign_Constraint;
(function (Campaign_Constraint) {
    Campaign_Constraint["CampaignCodeKey"] = "campaign_code_key";
    Campaign_Constraint["CampaignPkey"] = "campaign_pkey";
})(Campaign_Constraint = exports.Campaign_Constraint || (exports.Campaign_Constraint = {}));
var Campaign_Inclusion_Group_Constraint;
(function (Campaign_Inclusion_Group_Constraint) {
    Campaign_Inclusion_Group_Constraint["CampaignInclusionGroupPkey"] = "campaign_inclusion_group_pkey";
})(Campaign_Inclusion_Group_Constraint = exports.Campaign_Inclusion_Group_Constraint || (exports.Campaign_Inclusion_Group_Constraint = {}));
var Campaign_Inclusion_Group_Select_Column;
(function (Campaign_Inclusion_Group_Select_Column) {
    Campaign_Inclusion_Group_Select_Column["CampaignId"] = "campaign_id";
    Campaign_Inclusion_Group_Select_Column["CreatedAt"] = "created_at";
    Campaign_Inclusion_Group_Select_Column["Id"] = "id";
    Campaign_Inclusion_Group_Select_Column["InclusionGroupId"] = "inclusion_group_id";
    Campaign_Inclusion_Group_Select_Column["UpdatedAt"] = "updated_at";
})(Campaign_Inclusion_Group_Select_Column = exports.Campaign_Inclusion_Group_Select_Column || (exports.Campaign_Inclusion_Group_Select_Column = {}));
var Campaign_Inclusion_Group_Update_Column;
(function (Campaign_Inclusion_Group_Update_Column) {
    Campaign_Inclusion_Group_Update_Column["CampaignId"] = "campaign_id";
    Campaign_Inclusion_Group_Update_Column["CreatedAt"] = "created_at";
    Campaign_Inclusion_Group_Update_Column["Id"] = "id";
    Campaign_Inclusion_Group_Update_Column["InclusionGroupId"] = "inclusion_group_id";
    Campaign_Inclusion_Group_Update_Column["UpdatedAt"] = "updated_at";
})(Campaign_Inclusion_Group_Update_Column = exports.Campaign_Inclusion_Group_Update_Column || (exports.Campaign_Inclusion_Group_Update_Column = {}));
var Campaign_Select_Column;
(function (Campaign_Select_Column) {
    Campaign_Select_Column["Code"] = "code";
    Campaign_Select_Column["CreatedAt"] = "created_at";
    Campaign_Select_Column["Desc"] = "desc";
    Campaign_Select_Column["Id"] = "id";
    Campaign_Select_Column["Image"] = "image";
    Campaign_Select_Column["ImageNonprimaryHeroApartment"] = "image_nonprimary_hero_apartment";
    Campaign_Select_Column["ImageNonprimaryHeroHouse"] = "image_nonprimary_hero_house";
    Campaign_Select_Column["ImageNonprimaryHeroLand"] = "image_nonprimary_hero_land";
    Campaign_Select_Column["ImageNonprimaryHeroTownhouse"] = "image_nonprimary_hero_townhouse";
    Campaign_Select_Column["ImageNonprimaryHeroUnit"] = "image_nonprimary_hero_unit";
    Campaign_Select_Column["ImagePrimaryHeroApartment"] = "image_primary_hero_apartment";
    Campaign_Select_Column["ImagePrimaryHeroHouse"] = "image_primary_hero_house";
    Campaign_Select_Column["ImagePrimaryHeroLand"] = "image_primary_hero_land";
    Campaign_Select_Column["ImagePrimaryHeroTownhouse"] = "image_primary_hero_townhouse";
    Campaign_Select_Column["ImagePrimaryHeroUnit"] = "image_primary_hero_unit";
    Campaign_Select_Column["ImagePrimaryOverlayApartment"] = "image_primary_overlay_apartment";
    Campaign_Select_Column["ImagePrimaryOverlayHouse"] = "image_primary_overlay_house";
    Campaign_Select_Column["ImagePrimaryOverlayLand"] = "image_primary_overlay_land";
    Campaign_Select_Column["ImagePrimaryOverlayTownhouse"] = "image_primary_overlay_townhouse";
    Campaign_Select_Column["ImagePrimaryOverlayUnit"] = "image_primary_overlay_unit";
    Campaign_Select_Column["Name"] = "name";
    Campaign_Select_Column["Order"] = "order";
    Campaign_Select_Column["Price"] = "price";
    Campaign_Select_Column["PriceBookEntrySalesforceId"] = "price_book_entry_salesforce_id";
    Campaign_Select_Column["PriceDescription"] = "price_description";
    Campaign_Select_Column["SalesforceId"] = "salesforce_id";
    Campaign_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Campaign_Select_Column["Type"] = "type";
    Campaign_Select_Column["UpdatedAt"] = "updated_at";
})(Campaign_Select_Column = exports.Campaign_Select_Column || (exports.Campaign_Select_Column = {}));
var Campaign_Selling_Point_Constraint;
(function (Campaign_Selling_Point_Constraint) {
    Campaign_Selling_Point_Constraint["CampaignSellingPointPkey"] = "campaign_selling_point_pkey";
})(Campaign_Selling_Point_Constraint = exports.Campaign_Selling_Point_Constraint || (exports.Campaign_Selling_Point_Constraint = {}));
var Campaign_Selling_Point_Select_Column;
(function (Campaign_Selling_Point_Select_Column) {
    Campaign_Selling_Point_Select_Column["CampaignId"] = "campaign_id";
    Campaign_Selling_Point_Select_Column["CreatedAt"] = "created_at";
    Campaign_Selling_Point_Select_Column["Id"] = "id";
    Campaign_Selling_Point_Select_Column["SellingPointId"] = "selling_point_id";
    Campaign_Selling_Point_Select_Column["UpdatedAt"] = "updated_at";
})(Campaign_Selling_Point_Select_Column = exports.Campaign_Selling_Point_Select_Column || (exports.Campaign_Selling_Point_Select_Column = {}));
var Campaign_Selling_Point_Update_Column;
(function (Campaign_Selling_Point_Update_Column) {
    Campaign_Selling_Point_Update_Column["CampaignId"] = "campaign_id";
    Campaign_Selling_Point_Update_Column["CreatedAt"] = "created_at";
    Campaign_Selling_Point_Update_Column["Id"] = "id";
    Campaign_Selling_Point_Update_Column["SellingPointId"] = "selling_point_id";
    Campaign_Selling_Point_Update_Column["UpdatedAt"] = "updated_at";
})(Campaign_Selling_Point_Update_Column = exports.Campaign_Selling_Point_Update_Column || (exports.Campaign_Selling_Point_Update_Column = {}));
var Campaign_Update_Column;
(function (Campaign_Update_Column) {
    Campaign_Update_Column["Code"] = "code";
    Campaign_Update_Column["CreatedAt"] = "created_at";
    Campaign_Update_Column["Desc"] = "desc";
    Campaign_Update_Column["Id"] = "id";
    Campaign_Update_Column["Image"] = "image";
    Campaign_Update_Column["ImageNonprimaryHeroApartment"] = "image_nonprimary_hero_apartment";
    Campaign_Update_Column["ImageNonprimaryHeroHouse"] = "image_nonprimary_hero_house";
    Campaign_Update_Column["ImageNonprimaryHeroLand"] = "image_nonprimary_hero_land";
    Campaign_Update_Column["ImageNonprimaryHeroTownhouse"] = "image_nonprimary_hero_townhouse";
    Campaign_Update_Column["ImageNonprimaryHeroUnit"] = "image_nonprimary_hero_unit";
    Campaign_Update_Column["ImagePrimaryHeroApartment"] = "image_primary_hero_apartment";
    Campaign_Update_Column["ImagePrimaryHeroHouse"] = "image_primary_hero_house";
    Campaign_Update_Column["ImagePrimaryHeroLand"] = "image_primary_hero_land";
    Campaign_Update_Column["ImagePrimaryHeroTownhouse"] = "image_primary_hero_townhouse";
    Campaign_Update_Column["ImagePrimaryHeroUnit"] = "image_primary_hero_unit";
    Campaign_Update_Column["ImagePrimaryOverlayApartment"] = "image_primary_overlay_apartment";
    Campaign_Update_Column["ImagePrimaryOverlayHouse"] = "image_primary_overlay_house";
    Campaign_Update_Column["ImagePrimaryOverlayLand"] = "image_primary_overlay_land";
    Campaign_Update_Column["ImagePrimaryOverlayTownhouse"] = "image_primary_overlay_townhouse";
    Campaign_Update_Column["ImagePrimaryOverlayUnit"] = "image_primary_overlay_unit";
    Campaign_Update_Column["Name"] = "name";
    Campaign_Update_Column["Order"] = "order";
    Campaign_Update_Column["Price"] = "price";
    Campaign_Update_Column["PriceBookEntrySalesforceId"] = "price_book_entry_salesforce_id";
    Campaign_Update_Column["PriceDescription"] = "price_description";
    Campaign_Update_Column["SalesforceId"] = "salesforce_id";
    Campaign_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    Campaign_Update_Column["Type"] = "type";
    Campaign_Update_Column["UpdatedAt"] = "updated_at";
})(Campaign_Update_Column = exports.Campaign_Update_Column || (exports.Campaign_Update_Column = {}));
var Commercial_Status_Type_Constraint;
(function (Commercial_Status_Type_Constraint) {
    Commercial_Status_Type_Constraint["CommercialStatusTypePkey"] = "commercial_status_type_pkey";
})(Commercial_Status_Type_Constraint = exports.Commercial_Status_Type_Constraint || (exports.Commercial_Status_Type_Constraint = {}));
var Commercial_Status_Type_Enum;
(function (Commercial_Status_Type_Enum) {
    Commercial_Status_Type_Enum["Leased"] = "LEASED";
    Commercial_Status_Type_Enum["Listed"] = "LISTED";
    Commercial_Status_Type_Enum["New"] = "NEW";
    Commercial_Status_Type_Enum["OnHold"] = "ON_HOLD";
    Commercial_Status_Type_Enum["Sold"] = "SOLD";
    Commercial_Status_Type_Enum["UnderOffer"] = "UNDER_OFFER";
    Commercial_Status_Type_Enum["Withdrawn"] = "WITHDRAWN";
})(Commercial_Status_Type_Enum = exports.Commercial_Status_Type_Enum || (exports.Commercial_Status_Type_Enum = {}));
var Commercial_Status_Type_Select_Column;
(function (Commercial_Status_Type_Select_Column) {
    Commercial_Status_Type_Select_Column["Description"] = "description";
    Commercial_Status_Type_Select_Column["Value"] = "value";
})(Commercial_Status_Type_Select_Column = exports.Commercial_Status_Type_Select_Column || (exports.Commercial_Status_Type_Select_Column = {}));
var Commercial_Status_Type_Update_Column;
(function (Commercial_Status_Type_Update_Column) {
    Commercial_Status_Type_Update_Column["Description"] = "description";
    Commercial_Status_Type_Update_Column["Value"] = "value";
})(Commercial_Status_Type_Update_Column = exports.Commercial_Status_Type_Update_Column || (exports.Commercial_Status_Type_Update_Column = {}));
var Coupon_Constraint;
(function (Coupon_Constraint) {
    Coupon_Constraint["CouponCodeKey"] = "coupon_code_key";
    Coupon_Constraint["CouponPkey"] = "coupon_pkey";
})(Coupon_Constraint = exports.Coupon_Constraint || (exports.Coupon_Constraint = {}));
var Coupon_Select_Column;
(function (Coupon_Select_Column) {
    Coupon_Select_Column["CampaignId"] = "campaign_id";
    Coupon_Select_Column["Code"] = "code";
    Coupon_Select_Column["CreatedAt"] = "created_at";
    Coupon_Select_Column["CreatedBy"] = "created_by";
    Coupon_Select_Column["Description"] = "description";
    Coupon_Select_Column["Id"] = "id";
    Coupon_Select_Column["IsActive"] = "is_active";
    Coupon_Select_Column["MaxUses"] = "max_uses";
    Coupon_Select_Column["PriceBookEntrySalesforceId"] = "price_book_entry_salesforce_id";
    Coupon_Select_Column["SalesforceId"] = "salesforce_id";
    Coupon_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Coupon_Select_Column["Type"] = "type";
    Coupon_Select_Column["UnlimitedUses"] = "unlimited_uses";
    Coupon_Select_Column["UpdatedAt"] = "updated_at";
    Coupon_Select_Column["ValidAfter"] = "valid_after";
    Coupon_Select_Column["ValidUntil"] = "valid_until";
    Coupon_Select_Column["Value"] = "value";
})(Coupon_Select_Column = exports.Coupon_Select_Column || (exports.Coupon_Select_Column = {}));
var Coupon_Type_Constraint;
(function (Coupon_Type_Constraint) {
    Coupon_Type_Constraint["CouponTypePkey"] = "coupon_type_pkey";
})(Coupon_Type_Constraint = exports.Coupon_Type_Constraint || (exports.Coupon_Type_Constraint = {}));
var Coupon_Type_Enum;
(function (Coupon_Type_Enum) {
    Coupon_Type_Enum["Amount"] = "AMOUNT";
    Coupon_Type_Enum["Percentage"] = "PERCENTAGE";
})(Coupon_Type_Enum = exports.Coupon_Type_Enum || (exports.Coupon_Type_Enum = {}));
var Coupon_Type_Select_Column;
(function (Coupon_Type_Select_Column) {
    Coupon_Type_Select_Column["Description"] = "description";
    Coupon_Type_Select_Column["Value"] = "value";
})(Coupon_Type_Select_Column = exports.Coupon_Type_Select_Column || (exports.Coupon_Type_Select_Column = {}));
var Coupon_Type_Update_Column;
(function (Coupon_Type_Update_Column) {
    Coupon_Type_Update_Column["Description"] = "description";
    Coupon_Type_Update_Column["Value"] = "value";
})(Coupon_Type_Update_Column = exports.Coupon_Type_Update_Column || (exports.Coupon_Type_Update_Column = {}));
var Coupon_Update_Column;
(function (Coupon_Update_Column) {
    Coupon_Update_Column["CampaignId"] = "campaign_id";
    Coupon_Update_Column["Code"] = "code";
    Coupon_Update_Column["CreatedAt"] = "created_at";
    Coupon_Update_Column["CreatedBy"] = "created_by";
    Coupon_Update_Column["Description"] = "description";
    Coupon_Update_Column["Id"] = "id";
    Coupon_Update_Column["IsActive"] = "is_active";
    Coupon_Update_Column["MaxUses"] = "max_uses";
    Coupon_Update_Column["PriceBookEntrySalesforceId"] = "price_book_entry_salesforce_id";
    Coupon_Update_Column["SalesforceId"] = "salesforce_id";
    Coupon_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    Coupon_Update_Column["Type"] = "type";
    Coupon_Update_Column["UnlimitedUses"] = "unlimited_uses";
    Coupon_Update_Column["UpdatedAt"] = "updated_at";
    Coupon_Update_Column["ValidAfter"] = "valid_after";
    Coupon_Update_Column["ValidUntil"] = "valid_until";
    Coupon_Update_Column["Value"] = "value";
})(Coupon_Update_Column = exports.Coupon_Update_Column || (exports.Coupon_Update_Column = {}));
var Enquiry_Call_Result_Constraint;
(function (Enquiry_Call_Result_Constraint) {
    Enquiry_Call_Result_Constraint["EnquiryCallResultPkey"] = "enquiry_call_result_pkey";
    Enquiry_Call_Result_Constraint["EnquiryCallResultValueKey"] = "enquiry_call_result_value_key";
})(Enquiry_Call_Result_Constraint = exports.Enquiry_Call_Result_Constraint || (exports.Enquiry_Call_Result_Constraint = {}));
var Enquiry_Call_Result_Enum;
(function (Enquiry_Call_Result_Enum) {
    Enquiry_Call_Result_Enum["CallConnected"] = "CALL_CONNECTED";
    Enquiry_Call_Result_Enum["CallMissed"] = "CALL_MISSED";
    Enquiry_Call_Result_Enum["ConnectionImpossible"] = "CONNECTION_IMPOSSIBLE";
})(Enquiry_Call_Result_Enum = exports.Enquiry_Call_Result_Enum || (exports.Enquiry_Call_Result_Enum = {}));
var Enquiry_Call_Result_Select_Column;
(function (Enquiry_Call_Result_Select_Column) {
    Enquiry_Call_Result_Select_Column["Comment"] = "comment";
    Enquiry_Call_Result_Select_Column["Value"] = "value";
})(Enquiry_Call_Result_Select_Column = exports.Enquiry_Call_Result_Select_Column || (exports.Enquiry_Call_Result_Select_Column = {}));
var Enquiry_Call_Result_Update_Column;
(function (Enquiry_Call_Result_Update_Column) {
    Enquiry_Call_Result_Update_Column["Comment"] = "comment";
    Enquiry_Call_Result_Update_Column["Value"] = "value";
})(Enquiry_Call_Result_Update_Column = exports.Enquiry_Call_Result_Update_Column || (exports.Enquiry_Call_Result_Update_Column = {}));
var Enquiry_Constraint;
(function (Enquiry_Constraint) {
    Enquiry_Constraint["EnquiryPkey"] = "enquiry_pkey";
    Enquiry_Constraint["EnquiryTwilioIdKey"] = "enquiry_twilio_id_key";
})(Enquiry_Constraint = exports.Enquiry_Constraint || (exports.Enquiry_Constraint = {}));
var Enquiry_Medium_Type_Constraint;
(function (Enquiry_Medium_Type_Constraint) {
    Enquiry_Medium_Type_Constraint["EnquiryMediumTypePkey"] = "enquiry_medium_type_pkey";
})(Enquiry_Medium_Type_Constraint = exports.Enquiry_Medium_Type_Constraint || (exports.Enquiry_Medium_Type_Constraint = {}));
var Enquiry_Medium_Type_Enum;
(function (Enquiry_Medium_Type_Enum) {
    Enquiry_Medium_Type_Enum["DirectCall"] = "DIRECT_CALL";
    Enquiry_Medium_Type_Enum["DirectEmail"] = "DIRECT_EMAIL";
    Enquiry_Medium_Type_Enum["PortalCall"] = "PORTAL_CALL";
    Enquiry_Medium_Type_Enum["PortalForm"] = "PORTAL_FORM";
})(Enquiry_Medium_Type_Enum = exports.Enquiry_Medium_Type_Enum || (exports.Enquiry_Medium_Type_Enum = {}));
var Enquiry_Medium_Type_Select_Column;
(function (Enquiry_Medium_Type_Select_Column) {
    Enquiry_Medium_Type_Select_Column["Description"] = "description";
    Enquiry_Medium_Type_Select_Column["Value"] = "value";
})(Enquiry_Medium_Type_Select_Column = exports.Enquiry_Medium_Type_Select_Column || (exports.Enquiry_Medium_Type_Select_Column = {}));
var Enquiry_Medium_Type_Update_Column;
(function (Enquiry_Medium_Type_Update_Column) {
    Enquiry_Medium_Type_Update_Column["Description"] = "description";
    Enquiry_Medium_Type_Update_Column["Value"] = "value";
})(Enquiry_Medium_Type_Update_Column = exports.Enquiry_Medium_Type_Update_Column || (exports.Enquiry_Medium_Type_Update_Column = {}));
var Enquiry_Select_Column;
(function (Enquiry_Select_Column) {
    Enquiry_Select_Column["CallResult"] = "call_result";
    Enquiry_Select_Column["CreatedAt"] = "created_at";
    Enquiry_Select_Column["ExternalId"] = "external_id";
    Enquiry_Select_Column["FormDetails"] = "form_details";
    Enquiry_Select_Column["Id"] = "id";
    Enquiry_Select_Column["LeadEmail"] = "lead_email";
    Enquiry_Select_Column["LeadMessage"] = "lead_message";
    Enquiry_Select_Column["LeadName"] = "lead_name";
    Enquiry_Select_Column["LeadPhoneNumber"] = "lead_phone_number";
    Enquiry_Select_Column["ListingId"] = "listing_id";
    Enquiry_Select_Column["Medium"] = "medium";
    Enquiry_Select_Column["Source"] = "source";
    Enquiry_Select_Column["UpdatedAt"] = "updated_at";
})(Enquiry_Select_Column = exports.Enquiry_Select_Column || (exports.Enquiry_Select_Column = {}));
var Enquiry_Update_Column;
(function (Enquiry_Update_Column) {
    Enquiry_Update_Column["CallResult"] = "call_result";
    Enquiry_Update_Column["CreatedAt"] = "created_at";
    Enquiry_Update_Column["ExternalId"] = "external_id";
    Enquiry_Update_Column["FormDetails"] = "form_details";
    Enquiry_Update_Column["Id"] = "id";
    Enquiry_Update_Column["LeadEmail"] = "lead_email";
    Enquiry_Update_Column["LeadMessage"] = "lead_message";
    Enquiry_Update_Column["LeadName"] = "lead_name";
    Enquiry_Update_Column["LeadPhoneNumber"] = "lead_phone_number";
    Enquiry_Update_Column["ListingId"] = "listing_id";
    Enquiry_Update_Column["Medium"] = "medium";
    Enquiry_Update_Column["Source"] = "source";
    Enquiry_Update_Column["UpdatedAt"] = "updated_at";
})(Enquiry_Update_Column = exports.Enquiry_Update_Column || (exports.Enquiry_Update_Column = {}));
var Experiment_Constraint;
(function (Experiment_Constraint) {
    Experiment_Constraint["ExperimentPkey"] = "experiment_pkey";
})(Experiment_Constraint = exports.Experiment_Constraint || (exports.Experiment_Constraint = {}));
var Experiment_Name_Constraint;
(function (Experiment_Name_Constraint) {
    Experiment_Name_Constraint["ExperimentNamePkey"] = "experiment_name_pkey";
})(Experiment_Name_Constraint = exports.Experiment_Name_Constraint || (exports.Experiment_Name_Constraint = {}));
var Experiment_Name_Enum;
(function (Experiment_Name_Enum) {
    Experiment_Name_Enum["OnboardingA"] = "Onboarding_A";
    Experiment_Name_Enum["OnboardingB"] = "Onboarding_B";
    Experiment_Name_Enum["RecommendationA"] = "Recommendation_A";
    Experiment_Name_Enum["RecommendationB"] = "Recommendation_B";
    Experiment_Name_Enum["RecommendationC"] = "Recommendation_C";
})(Experiment_Name_Enum = exports.Experiment_Name_Enum || (exports.Experiment_Name_Enum = {}));
var Experiment_Name_Select_Column;
(function (Experiment_Name_Select_Column) {
    Experiment_Name_Select_Column["Description"] = "description";
    Experiment_Name_Select_Column["Value"] = "value";
})(Experiment_Name_Select_Column = exports.Experiment_Name_Select_Column || (exports.Experiment_Name_Select_Column = {}));
var Experiment_Name_Update_Column;
(function (Experiment_Name_Update_Column) {
    Experiment_Name_Update_Column["Description"] = "description";
    Experiment_Name_Update_Column["Value"] = "value";
})(Experiment_Name_Update_Column = exports.Experiment_Name_Update_Column || (exports.Experiment_Name_Update_Column = {}));
var Experiment_Select_Column;
(function (Experiment_Select_Column) {
    Experiment_Select_Column["CreatedAt"] = "created_at";
    Experiment_Select_Column["Desc"] = "desc";
    Experiment_Select_Column["ExcludeSubmittedVia"] = "exclude_submitted_via";
    Experiment_Select_Column["IsActive"] = "is_active";
    Experiment_Select_Column["Name"] = "name";
    Experiment_Select_Column["UpdatedAt"] = "updated_at";
    Experiment_Select_Column["UsersEndingWith"] = "users_ending_with";
})(Experiment_Select_Column = exports.Experiment_Select_Column || (exports.Experiment_Select_Column = {}));
var Experiment_Stat_Select_Column;
(function (Experiment_Stat_Select_Column) {
    Experiment_Stat_Select_Column["Desc"] = "desc";
    Experiment_Stat_Select_Column["Name"] = "name";
    Experiment_Stat_Select_Column["Percent"] = "percent";
    Experiment_Stat_Select_Column["UserCount"] = "user_count";
    Experiment_Stat_Select_Column["UsersEndingWith"] = "users_ending_with";
})(Experiment_Stat_Select_Column = exports.Experiment_Stat_Select_Column || (exports.Experiment_Stat_Select_Column = {}));
var Experiment_Update_Column;
(function (Experiment_Update_Column) {
    Experiment_Update_Column["CreatedAt"] = "created_at";
    Experiment_Update_Column["Desc"] = "desc";
    Experiment_Update_Column["ExcludeSubmittedVia"] = "exclude_submitted_via";
    Experiment_Update_Column["IsActive"] = "is_active";
    Experiment_Update_Column["Name"] = "name";
    Experiment_Update_Column["UpdatedAt"] = "updated_at";
    Experiment_Update_Column["UsersEndingWith"] = "users_ending_with";
})(Experiment_Update_Column = exports.Experiment_Update_Column || (exports.Experiment_Update_Column = {}));
var External_Auth_Constraint;
(function (External_Auth_Constraint) {
    External_Auth_Constraint["ExternalAuthAuthSourceIdKey"] = "external_auth_auth_source_id_key";
    External_Auth_Constraint["ExternalAuthPkey"] = "external_auth_pkey";
})(External_Auth_Constraint = exports.External_Auth_Constraint || (exports.External_Auth_Constraint = {}));
var External_Auth_Select_Column;
(function (External_Auth_Select_Column) {
    External_Auth_Select_Column["AuthSource"] = "auth_source";
    External_Auth_Select_Column["AuthSourceId"] = "auth_source_id";
    External_Auth_Select_Column["RefreshToken"] = "refresh_token";
    External_Auth_Select_Column["TokenExpiryDate"] = "token_expiry_date";
    External_Auth_Select_Column["UserId"] = "user_id";
})(External_Auth_Select_Column = exports.External_Auth_Select_Column || (exports.External_Auth_Select_Column = {}));
var External_Auth_Update_Column;
(function (External_Auth_Update_Column) {
    External_Auth_Update_Column["AuthSource"] = "auth_source";
    External_Auth_Update_Column["AuthSourceId"] = "auth_source_id";
    External_Auth_Update_Column["RefreshToken"] = "refresh_token";
    External_Auth_Update_Column["TokenExpiryDate"] = "token_expiry_date";
    External_Auth_Update_Column["UserId"] = "user_id";
})(External_Auth_Update_Column = exports.External_Auth_Update_Column || (exports.External_Auth_Update_Column = {}));
var Feedback_Constraint;
(function (Feedback_Constraint) {
    Feedback_Constraint["FeedbackPkey"] = "feedback_pkey";
})(Feedback_Constraint = exports.Feedback_Constraint || (exports.Feedback_Constraint = {}));
var Feedback_Select_Column;
(function (Feedback_Select_Column) {
    Feedback_Select_Column["CreatedAt"] = "created_at";
    Feedback_Select_Column["EventType"] = "event_type";
    Feedback_Select_Column["FeedbackItems"] = "feedback_items";
    Feedback_Select_Column["Id"] = "id";
    Feedback_Select_Column["ListingId"] = "listing_id";
    Feedback_Select_Column["Message"] = "message";
    Feedback_Select_Column["MessageType"] = "message_type";
    Feedback_Select_Column["OwnerId"] = "owner_id";
    Feedback_Select_Column["Score"] = "score";
})(Feedback_Select_Column = exports.Feedback_Select_Column || (exports.Feedback_Select_Column = {}));
var Feedback_Update_Column;
(function (Feedback_Update_Column) {
    Feedback_Update_Column["CreatedAt"] = "created_at";
    Feedback_Update_Column["EventType"] = "event_type";
    Feedback_Update_Column["FeedbackItems"] = "feedback_items";
    Feedback_Update_Column["Id"] = "id";
    Feedback_Update_Column["ListingId"] = "listing_id";
    Feedback_Update_Column["Message"] = "message";
    Feedback_Update_Column["MessageType"] = "message_type";
    Feedback_Update_Column["OwnerId"] = "owner_id";
    Feedback_Update_Column["Score"] = "score";
})(Feedback_Update_Column = exports.Feedback_Update_Column || (exports.Feedback_Update_Column = {}));
var Inclusion_Addon_Constraint;
(function (Inclusion_Addon_Constraint) {
    Inclusion_Addon_Constraint["InclusionAddonPkey"] = "inclusion_addon_pkey";
})(Inclusion_Addon_Constraint = exports.Inclusion_Addon_Constraint || (exports.Inclusion_Addon_Constraint = {}));
var Inclusion_Addon_Select_Column;
(function (Inclusion_Addon_Select_Column) {
    Inclusion_Addon_Select_Column["AddonId"] = "addon_id";
    Inclusion_Addon_Select_Column["CreatedAt"] = "created_at";
    Inclusion_Addon_Select_Column["Id"] = "id";
    Inclusion_Addon_Select_Column["InclusionId"] = "inclusion_id";
    Inclusion_Addon_Select_Column["UpdatedAt"] = "updated_at";
})(Inclusion_Addon_Select_Column = exports.Inclusion_Addon_Select_Column || (exports.Inclusion_Addon_Select_Column = {}));
var Inclusion_Addon_Update_Column;
(function (Inclusion_Addon_Update_Column) {
    Inclusion_Addon_Update_Column["AddonId"] = "addon_id";
    Inclusion_Addon_Update_Column["CreatedAt"] = "created_at";
    Inclusion_Addon_Update_Column["Id"] = "id";
    Inclusion_Addon_Update_Column["InclusionId"] = "inclusion_id";
    Inclusion_Addon_Update_Column["UpdatedAt"] = "updated_at";
})(Inclusion_Addon_Update_Column = exports.Inclusion_Addon_Update_Column || (exports.Inclusion_Addon_Update_Column = {}));
var Inclusion_Constraint;
(function (Inclusion_Constraint) {
    Inclusion_Constraint["InclusionPkey"] = "inclusion_pkey";
})(Inclusion_Constraint = exports.Inclusion_Constraint || (exports.Inclusion_Constraint = {}));
var Inclusion_Group_Constraint;
(function (Inclusion_Group_Constraint) {
    Inclusion_Group_Constraint["InclusionGroupPkey"] = "inclusion_group_pkey";
})(Inclusion_Group_Constraint = exports.Inclusion_Group_Constraint || (exports.Inclusion_Group_Constraint = {}));
var Inclusion_Group_Select_Column;
(function (Inclusion_Group_Select_Column) {
    Inclusion_Group_Select_Column["CreatedAt"] = "created_at";
    Inclusion_Group_Select_Column["Id"] = "id";
    Inclusion_Group_Select_Column["Name"] = "name";
    Inclusion_Group_Select_Column["Order"] = "order";
    Inclusion_Group_Select_Column["UpdatedAt"] = "updated_at";
})(Inclusion_Group_Select_Column = exports.Inclusion_Group_Select_Column || (exports.Inclusion_Group_Select_Column = {}));
var Inclusion_Group_Update_Column;
(function (Inclusion_Group_Update_Column) {
    Inclusion_Group_Update_Column["CreatedAt"] = "created_at";
    Inclusion_Group_Update_Column["Id"] = "id";
    Inclusion_Group_Update_Column["Name"] = "name";
    Inclusion_Group_Update_Column["Order"] = "order";
    Inclusion_Group_Update_Column["UpdatedAt"] = "updated_at";
})(Inclusion_Group_Update_Column = exports.Inclusion_Group_Update_Column || (exports.Inclusion_Group_Update_Column = {}));
var Inclusion_Select_Column;
(function (Inclusion_Select_Column) {
    Inclusion_Select_Column["CreatedAt"] = "created_at";
    Inclusion_Select_Column["Description"] = "description";
    Inclusion_Select_Column["GroupId"] = "group_id";
    Inclusion_Select_Column["Id"] = "id";
    Inclusion_Select_Column["Metro"] = "metro";
    Inclusion_Select_Column["Name"] = "name";
    Inclusion_Select_Column["Order"] = "order";
    Inclusion_Select_Column["UpdatedAt"] = "updated_at";
    Inclusion_Select_Column["Zone"] = "zone";
})(Inclusion_Select_Column = exports.Inclusion_Select_Column || (exports.Inclusion_Select_Column = {}));
var Inclusion_Update_Column;
(function (Inclusion_Update_Column) {
    Inclusion_Update_Column["CreatedAt"] = "created_at";
    Inclusion_Update_Column["Description"] = "description";
    Inclusion_Update_Column["GroupId"] = "group_id";
    Inclusion_Update_Column["Id"] = "id";
    Inclusion_Update_Column["Metro"] = "metro";
    Inclusion_Update_Column["Name"] = "name";
    Inclusion_Update_Column["Order"] = "order";
    Inclusion_Update_Column["UpdatedAt"] = "updated_at";
    Inclusion_Update_Column["Zone"] = "zone";
})(Inclusion_Update_Column = exports.Inclusion_Update_Column || (exports.Inclusion_Update_Column = {}));
var Listing_Constraint;
(function (Listing_Constraint) {
    Listing_Constraint["ListingAgentPointIdKey"] = "listing_agent_point_id_key";
    Listing_Constraint["ListingEnquiryCodeKey"] = "listing_enquiry_code_key";
    Listing_Constraint["ListingIdKey"] = "listing_id_key";
    Listing_Constraint["ListingPkey"] = "listing_pkey";
})(Listing_Constraint = exports.Listing_Constraint || (exports.Listing_Constraint = {}));
var Listing_Contact_Constraint;
(function (Listing_Contact_Constraint) {
    Listing_Contact_Constraint["ListingContactPkey"] = "listing_contact_pkey";
})(Listing_Contact_Constraint = exports.Listing_Contact_Constraint || (exports.Listing_Contact_Constraint = {}));
var Listing_Contact_Select_Column;
(function (Listing_Contact_Select_Column) {
    Listing_Contact_Select_Column["CreatedAt"] = "created_at";
    Listing_Contact_Select_Column["DomainAgencyId"] = "domainAgencyID";
    Listing_Contact_Select_Column["DomainAgentId"] = "domainAgentId";
    Listing_Contact_Select_Column["Email"] = "email";
    Listing_Contact_Select_Column["Fax"] = "fax";
    Listing_Contact_Select_Column["FirstName"] = "firstName";
    Listing_Contact_Select_Column["Id"] = "id";
    Listing_Contact_Select_Column["LastName"] = "lastName";
    Listing_Contact_Select_Column["ListingId"] = "listing_id";
    Listing_Contact_Select_Column["Mobile"] = "mobile";
    Listing_Contact_Select_Column["Phone"] = "phone";
    Listing_Contact_Select_Column["PhoneType"] = "phoneType";
    Listing_Contact_Select_Column["Portal"] = "portal";
    Listing_Contact_Select_Column["ReceiveEmails"] = "receiveEmails";
    Listing_Contact_Select_Column["SocialMedia"] = "socialMedia";
    Listing_Contact_Select_Column["SocialMediaUrl"] = "socialMedia_URL";
    Listing_Contact_Select_Column["UpdatedAt"] = "updated_at";
})(Listing_Contact_Select_Column = exports.Listing_Contact_Select_Column || (exports.Listing_Contact_Select_Column = {}));
var Listing_Contact_Update_Column;
(function (Listing_Contact_Update_Column) {
    Listing_Contact_Update_Column["CreatedAt"] = "created_at";
    Listing_Contact_Update_Column["DomainAgencyId"] = "domainAgencyID";
    Listing_Contact_Update_Column["DomainAgentId"] = "domainAgentId";
    Listing_Contact_Update_Column["Email"] = "email";
    Listing_Contact_Update_Column["Fax"] = "fax";
    Listing_Contact_Update_Column["FirstName"] = "firstName";
    Listing_Contact_Update_Column["Id"] = "id";
    Listing_Contact_Update_Column["LastName"] = "lastName";
    Listing_Contact_Update_Column["ListingId"] = "listing_id";
    Listing_Contact_Update_Column["Mobile"] = "mobile";
    Listing_Contact_Update_Column["Phone"] = "phone";
    Listing_Contact_Update_Column["PhoneType"] = "phoneType";
    Listing_Contact_Update_Column["Portal"] = "portal";
    Listing_Contact_Update_Column["ReceiveEmails"] = "receiveEmails";
    Listing_Contact_Update_Column["SocialMedia"] = "socialMedia";
    Listing_Contact_Update_Column["SocialMediaUrl"] = "socialMedia_URL";
    Listing_Contact_Update_Column["UpdatedAt"] = "updated_at";
})(Listing_Contact_Update_Column = exports.Listing_Contact_Update_Column || (exports.Listing_Contact_Update_Column = {}));
var Listing_Detail_Select_Column;
(function (Listing_Detail_Select_Column) {
    Listing_Detail_Select_Column["AgentPointId"] = "agent_point_id";
    Listing_Detail_Select_Column["CommercialStatus"] = "commercial_status";
    Listing_Detail_Select_Column["CreatedAt"] = "created_at";
    Listing_Detail_Select_Column["Email"] = "email";
    Listing_Detail_Select_Column["EnquiryCode"] = "enquiry_code";
    Listing_Detail_Select_Column["FirstName"] = "first_name";
    Listing_Detail_Select_Column["FullName"] = "full_name";
    Listing_Detail_Select_Column["Id"] = "id";
    Listing_Detail_Select_Column["LastName"] = "last_name";
    Listing_Detail_Select_Column["OwnerId"] = "owner_id";
    Listing_Detail_Select_Column["PhoneNumber"] = "phone_number";
    Listing_Detail_Select_Column["ProofOfOwnershipStatus"] = "proof_of_ownership_status";
    Listing_Detail_Select_Column["PropertyDetailsAddress_1"] = "propertyDetails_address_1";
    Listing_Detail_Select_Column["PropertyDetailsAddress_2"] = "propertyDetails_address_2";
    Listing_Detail_Select_Column["PropertyDetailsAddressPostcode"] = "propertyDetails_address_postcode";
    Listing_Detail_Select_Column["PropertyDetailsAddressState"] = "propertyDetails_address_state";
    Listing_Detail_Select_Column["PropertyDetailsAddressStreet"] = "propertyDetails_address_street";
    Listing_Detail_Select_Column["PropertyDetailsAddressStreetNumber"] = "propertyDetails_address_streetNumber";
    Listing_Detail_Select_Column["PropertyDetailsAddressSuburb"] = "propertyDetails_address_suburb";
    Listing_Detail_Select_Column["PropertyDetailsAddressUnitNumber"] = "propertyDetails_address_unitNumber";
    Listing_Detail_Select_Column["PublishingStatus"] = "publishing_status";
    Listing_Detail_Select_Column["UserId"] = "user_id";
    Listing_Detail_Select_Column["Zone"] = "zone";
})(Listing_Detail_Select_Column = exports.Listing_Detail_Select_Column || (exports.Listing_Detail_Select_Column = {}));
var Listing_Document_Constraint;
(function (Listing_Document_Constraint) {
    Listing_Document_Constraint["ListingDocumentPkey"] = "listing_document_pkey";
})(Listing_Document_Constraint = exports.Listing_Document_Constraint || (exports.Listing_Document_Constraint = {}));
var Listing_Document_Select_Column;
(function (Listing_Document_Select_Column) {
    Listing_Document_Select_Column["CreatedAt"] = "created_at";
    Listing_Document_Select_Column["Description"] = "description";
    Listing_Document_Select_Column["Id"] = "id";
    Listing_Document_Select_Column["ListingId"] = "listing_id";
    Listing_Document_Select_Column["Status"] = "status";
    Listing_Document_Select_Column["Type"] = "type";
    Listing_Document_Select_Column["UpdatedAt"] = "updated_at";
    Listing_Document_Select_Column["Url"] = "url";
})(Listing_Document_Select_Column = exports.Listing_Document_Select_Column || (exports.Listing_Document_Select_Column = {}));
var Listing_Document_Status_Constraint;
(function (Listing_Document_Status_Constraint) {
    Listing_Document_Status_Constraint["ListingDocumentStatusPkey"] = "listing_document_status_pkey";
})(Listing_Document_Status_Constraint = exports.Listing_Document_Status_Constraint || (exports.Listing_Document_Status_Constraint = {}));
var Listing_Document_Status_Enum;
(function (Listing_Document_Status_Enum) {
    Listing_Document_Status_Enum["Approved"] = "APPROVED";
    Listing_Document_Status_Enum["Declined"] = "DECLINED";
    Listing_Document_Status_Enum["NotSubmitted"] = "NOT_SUBMITTED";
    Listing_Document_Status_Enum["PendingReview"] = "PENDING_REVIEW";
    Listing_Document_Status_Enum["Uploaded"] = "UPLOADED";
})(Listing_Document_Status_Enum = exports.Listing_Document_Status_Enum || (exports.Listing_Document_Status_Enum = {}));
var Listing_Document_Status_Select_Column;
(function (Listing_Document_Status_Select_Column) {
    Listing_Document_Status_Select_Column["Description"] = "description";
    Listing_Document_Status_Select_Column["Value"] = "value";
})(Listing_Document_Status_Select_Column = exports.Listing_Document_Status_Select_Column || (exports.Listing_Document_Status_Select_Column = {}));
var Listing_Document_Status_Update_Column;
(function (Listing_Document_Status_Update_Column) {
    Listing_Document_Status_Update_Column["Description"] = "description";
    Listing_Document_Status_Update_Column["Value"] = "value";
})(Listing_Document_Status_Update_Column = exports.Listing_Document_Status_Update_Column || (exports.Listing_Document_Status_Update_Column = {}));
var Listing_Document_Type_Constraint;
(function (Listing_Document_Type_Constraint) {
    Listing_Document_Type_Constraint["ListingDocumentTypePkey"] = "listing_document_type_pkey";
})(Listing_Document_Type_Constraint = exports.Listing_Document_Type_Constraint || (exports.Listing_Document_Type_Constraint = {}));
var Listing_Document_Type_Enum;
(function (Listing_Document_Type_Enum) {
    Listing_Document_Type_Enum["EvidenceOfAuthority"] = "EVIDENCE_OF_AUTHORITY";
    Listing_Document_Type_Enum["ProofOfId"] = "PROOF_OF_ID";
    Listing_Document_Type_Enum["ProofOfOwnership"] = "PROOF_OF_OWNERSHIP";
})(Listing_Document_Type_Enum = exports.Listing_Document_Type_Enum || (exports.Listing_Document_Type_Enum = {}));
var Listing_Document_Type_Select_Column;
(function (Listing_Document_Type_Select_Column) {
    Listing_Document_Type_Select_Column["Description"] = "description";
    Listing_Document_Type_Select_Column["Value"] = "value";
})(Listing_Document_Type_Select_Column = exports.Listing_Document_Type_Select_Column || (exports.Listing_Document_Type_Select_Column = {}));
var Listing_Document_Type_Update_Column;
(function (Listing_Document_Type_Update_Column) {
    Listing_Document_Type_Update_Column["Description"] = "description";
    Listing_Document_Type_Update_Column["Value"] = "value";
})(Listing_Document_Type_Update_Column = exports.Listing_Document_Type_Update_Column || (exports.Listing_Document_Type_Update_Column = {}));
var Listing_Document_Update_Column;
(function (Listing_Document_Update_Column) {
    Listing_Document_Update_Column["CreatedAt"] = "created_at";
    Listing_Document_Update_Column["Description"] = "description";
    Listing_Document_Update_Column["Id"] = "id";
    Listing_Document_Update_Column["ListingId"] = "listing_id";
    Listing_Document_Update_Column["Status"] = "status";
    Listing_Document_Update_Column["Type"] = "type";
    Listing_Document_Update_Column["UpdatedAt"] = "updated_at";
    Listing_Document_Update_Column["Url"] = "url";
})(Listing_Document_Update_Column = exports.Listing_Document_Update_Column || (exports.Listing_Document_Update_Column = {}));
var Listing_Event_Constraint;
(function (Listing_Event_Constraint) {
    Listing_Event_Constraint["ListingEventPkey"] = "listing_event_pkey";
})(Listing_Event_Constraint = exports.Listing_Event_Constraint || (exports.Listing_Event_Constraint = {}));
var Listing_Event_Select_Column;
(function (Listing_Event_Select_Column) {
    Listing_Event_Select_Column["CreatedAt"] = "created_at";
    Listing_Event_Select_Column["EventData"] = "event_data";
    Listing_Event_Select_Column["EventType"] = "event_type";
    Listing_Event_Select_Column["Id"] = "id";
    Listing_Event_Select_Column["ListingId"] = "listingId";
    Listing_Event_Select_Column["OwnerId"] = "owner_id";
})(Listing_Event_Select_Column = exports.Listing_Event_Select_Column || (exports.Listing_Event_Select_Column = {}));
var Listing_Event_Type_Constraint;
(function (Listing_Event_Type_Constraint) {
    Listing_Event_Type_Constraint["ListingEventTypePkey"] = "listing_event_type_pkey";
})(Listing_Event_Type_Constraint = exports.Listing_Event_Type_Constraint || (exports.Listing_Event_Type_Constraint = {}));
var Listing_Event_Type_Enum;
(function (Listing_Event_Type_Enum) {
    Listing_Event_Type_Enum["FailedPublishing"] = "FAILED_PUBLISHING";
    Listing_Event_Type_Enum["Leased"] = "LEASED";
    Listing_Event_Type_Enum["Listed"] = "LISTED";
    Listing_Event_Type_Enum["New"] = "NEW";
    Listing_Event_Type_Enum["OnboardingComplete"] = "ONBOARDING_COMPLETE";
    Listing_Event_Type_Enum["OnholdInProgress"] = "ONHOLD_IN_PROGRESS";
    Listing_Event_Type_Enum["OnHold"] = "ON_HOLD";
    Listing_Event_Type_Enum["PaidListing"] = "PAID_LISTING";
    Listing_Event_Type_Enum["SentUnderOfferReminder"] = "SENT_UNDER_OFFER_REMINDER";
    Listing_Event_Type_Enum["Sold"] = "SOLD";
    Listing_Event_Type_Enum["UnderOffer"] = "UNDER_OFFER";
    Listing_Event_Type_Enum["UnderOfferInProgress"] = "UNDER_OFFER_IN_PROGRESS";
    Listing_Event_Type_Enum["UnderReview"] = "UNDER_REVIEW";
    Listing_Event_Type_Enum["Withdrawn"] = "WITHDRAWN";
    Listing_Event_Type_Enum["WithdrawnInProgress"] = "WITHDRAWN_IN_PROGRESS";
    Listing_Event_Type_Enum["AuctionWarningQld"] = "auction_warning_qld";
    Listing_Event_Type_Enum["ProofOfOwnershipDeclined"] = "proof_of_ownership_declined";
    Listing_Event_Type_Enum["ProofOfOwnershipSubmitted"] = "proof_of_ownership_submitted";
    Listing_Event_Type_Enum["VisitedCampaign"] = "visited_campaign";
    Listing_Event_Type_Enum["VisitedPreview"] = "visited_preview";
})(Listing_Event_Type_Enum = exports.Listing_Event_Type_Enum || (exports.Listing_Event_Type_Enum = {}));
var Listing_Event_Type_Select_Column;
(function (Listing_Event_Type_Select_Column) {
    Listing_Event_Type_Select_Column["Description"] = "description";
    Listing_Event_Type_Select_Column["Value"] = "value";
})(Listing_Event_Type_Select_Column = exports.Listing_Event_Type_Select_Column || (exports.Listing_Event_Type_Select_Column = {}));
var Listing_Event_Type_Update_Column;
(function (Listing_Event_Type_Update_Column) {
    Listing_Event_Type_Update_Column["Description"] = "description";
    Listing_Event_Type_Update_Column["Value"] = "value";
})(Listing_Event_Type_Update_Column = exports.Listing_Event_Type_Update_Column || (exports.Listing_Event_Type_Update_Column = {}));
var Listing_Event_Update_Column;
(function (Listing_Event_Update_Column) {
    Listing_Event_Update_Column["CreatedAt"] = "created_at";
    Listing_Event_Update_Column["EventData"] = "event_data";
    Listing_Event_Update_Column["EventType"] = "event_type";
    Listing_Event_Update_Column["Id"] = "id";
    Listing_Event_Update_Column["ListingId"] = "listingId";
    Listing_Event_Update_Column["OwnerId"] = "owner_id";
})(Listing_Event_Update_Column = exports.Listing_Event_Update_Column || (exports.Listing_Event_Update_Column = {}));
var Listing_Inspection_Constraint;
(function (Listing_Inspection_Constraint) {
    Listing_Inspection_Constraint["ListingInspectionAgentPointIdKey"] = "listing_inspection_agent_point_id_key";
    Listing_Inspection_Constraint["ListingInspectionPkey"] = "listing_inspection_pkey";
})(Listing_Inspection_Constraint = exports.Listing_Inspection_Constraint || (exports.Listing_Inspection_Constraint = {}));
var Listing_Inspection_Select_Column;
(function (Listing_Inspection_Select_Column) {
    Listing_Inspection_Select_Column["AgentPointId"] = "agent_point_id";
    Listing_Inspection_Select_Column["CreatedAt"] = "created_at";
    Listing_Inspection_Select_Column["From"] = "from";
    Listing_Inspection_Select_Column["Id"] = "id";
    Listing_Inspection_Select_Column["IsPrivate"] = "is_private";
    Listing_Inspection_Select_Column["ListingId"] = "listing_id";
    Listing_Inspection_Select_Column["Repeat"] = "repeat";
    Listing_Inspection_Select_Column["To"] = "to";
    Listing_Inspection_Select_Column["UpdatedAt"] = "updated_at";
})(Listing_Inspection_Select_Column = exports.Listing_Inspection_Select_Column || (exports.Listing_Inspection_Select_Column = {}));
var Listing_Inspection_Update_Column;
(function (Listing_Inspection_Update_Column) {
    Listing_Inspection_Update_Column["AgentPointId"] = "agent_point_id";
    Listing_Inspection_Update_Column["CreatedAt"] = "created_at";
    Listing_Inspection_Update_Column["From"] = "from";
    Listing_Inspection_Update_Column["Id"] = "id";
    Listing_Inspection_Update_Column["IsPrivate"] = "is_private";
    Listing_Inspection_Update_Column["ListingId"] = "listing_id";
    Listing_Inspection_Update_Column["Repeat"] = "repeat";
    Listing_Inspection_Update_Column["To"] = "to";
    Listing_Inspection_Update_Column["UpdatedAt"] = "updated_at";
})(Listing_Inspection_Update_Column = exports.Listing_Inspection_Update_Column || (exports.Listing_Inspection_Update_Column = {}));
var Listing_Media_Constraint;
(function (Listing_Media_Constraint) {
    Listing_Media_Constraint["ListingMediaAgentPointIdKey"] = "listing_media_agent_point_id_key";
    Listing_Media_Constraint["ListingMediaPkey"] = "listing_media_pkey";
})(Listing_Media_Constraint = exports.Listing_Media_Constraint || (exports.Listing_Media_Constraint = {}));
var Listing_Media_Select_Column;
(function (Listing_Media_Select_Column) {
    Listing_Media_Select_Column["AgentPointId"] = "agent_point_id";
    Listing_Media_Select_Column["AttachmentId"] = "attachment_id";
    Listing_Media_Select_Column["CreatedAt"] = "created_at";
    Listing_Media_Select_Column["Desc"] = "desc";
    Listing_Media_Select_Column["Id"] = "id";
    Listing_Media_Select_Column["ImagesIdAttribute"] = "images_id_attribute";
    Listing_Media_Select_Column["IsDownloaded"] = "is_downloaded";
    Listing_Media_Select_Column["IsUploaded"] = "is_uploaded";
    Listing_Media_Select_Column["ListingId"] = "listing_id";
    Listing_Media_Select_Column["MimeType"] = "mime_type";
    Listing_Media_Select_Column["ObjectsIdAttribute"] = "objects_id_attribute";
    Listing_Media_Select_Column["Order"] = "order";
    Listing_Media_Select_Column["OwnerId"] = "owner_id";
    Listing_Media_Select_Column["ProcessedError"] = "processed_error";
    Listing_Media_Select_Column["ProcessingStatus"] = "processing_status";
    Listing_Media_Select_Column["Thumbnail_1212"] = "thumbnail_1212";
    Listing_Media_Select_Column["Thumbnail_1818"] = "thumbnail_1818";
    Listing_Media_Select_Column["Thumbnail_185"] = "thumbnail_185";
    Listing_Media_Select_Column["Thumbnail_284"] = "thumbnail_284";
    Listing_Media_Select_Column["Thumbnail_370"] = "thumbnail_370";
    Listing_Media_Select_Column["Thumbnail_555"] = "thumbnail_555";
    Listing_Media_Select_Column["Thumbnail_568"] = "thumbnail_568";
    Listing_Media_Select_Column["Thumbnail_606"] = "thumbnail_606";
    Listing_Media_Select_Column["Thumbnail_852"] = "thumbnail_852";
    Listing_Media_Select_Column["Type"] = "type";
    Listing_Media_Select_Column["UpdatedAt"] = "updated_at";
    Listing_Media_Select_Column["Url"] = "url";
})(Listing_Media_Select_Column = exports.Listing_Media_Select_Column || (exports.Listing_Media_Select_Column = {}));
var Listing_Media_Type_Constraint;
(function (Listing_Media_Type_Constraint) {
    Listing_Media_Type_Constraint["ListingMediaTypePkey"] = "listing_media_type_pkey";
})(Listing_Media_Type_Constraint = exports.Listing_Media_Type_Constraint || (exports.Listing_Media_Type_Constraint = {}));
var Listing_Media_Type_Enum;
(function (Listing_Media_Type_Enum) {
    Listing_Media_Type_Enum["FloorPlan"] = "FLOOR_PLAN";
    Listing_Media_Type_Enum["Photo"] = "PHOTO";
    Listing_Media_Type_Enum["StatementOfInformation"] = "STATEMENT_OF_INFORMATION";
    Listing_Media_Type_Enum["StockPhoto"] = "STOCK_PHOTO";
    Listing_Media_Type_Enum["VideoLink"] = "VIDEO_LINK";
    Listing_Media_Type_Enum["VirtualTourLink"] = "VIRTUAL_TOUR_LINK";
})(Listing_Media_Type_Enum = exports.Listing_Media_Type_Enum || (exports.Listing_Media_Type_Enum = {}));
var Listing_Media_Type_Select_Column;
(function (Listing_Media_Type_Select_Column) {
    Listing_Media_Type_Select_Column["Description"] = "description";
    Listing_Media_Type_Select_Column["Value"] = "value";
})(Listing_Media_Type_Select_Column = exports.Listing_Media_Type_Select_Column || (exports.Listing_Media_Type_Select_Column = {}));
var Listing_Media_Type_Update_Column;
(function (Listing_Media_Type_Update_Column) {
    Listing_Media_Type_Update_Column["Description"] = "description";
    Listing_Media_Type_Update_Column["Value"] = "value";
})(Listing_Media_Type_Update_Column = exports.Listing_Media_Type_Update_Column || (exports.Listing_Media_Type_Update_Column = {}));
var Listing_Media_Update_Column;
(function (Listing_Media_Update_Column) {
    Listing_Media_Update_Column["AgentPointId"] = "agent_point_id";
    Listing_Media_Update_Column["AttachmentId"] = "attachment_id";
    Listing_Media_Update_Column["CreatedAt"] = "created_at";
    Listing_Media_Update_Column["Desc"] = "desc";
    Listing_Media_Update_Column["Id"] = "id";
    Listing_Media_Update_Column["ImagesIdAttribute"] = "images_id_attribute";
    Listing_Media_Update_Column["IsDownloaded"] = "is_downloaded";
    Listing_Media_Update_Column["IsUploaded"] = "is_uploaded";
    Listing_Media_Update_Column["ListingId"] = "listing_id";
    Listing_Media_Update_Column["MimeType"] = "mime_type";
    Listing_Media_Update_Column["ObjectsIdAttribute"] = "objects_id_attribute";
    Listing_Media_Update_Column["Order"] = "order";
    Listing_Media_Update_Column["OwnerId"] = "owner_id";
    Listing_Media_Update_Column["ProcessedError"] = "processed_error";
    Listing_Media_Update_Column["ProcessingStatus"] = "processing_status";
    Listing_Media_Update_Column["Thumbnail_1212"] = "thumbnail_1212";
    Listing_Media_Update_Column["Thumbnail_1818"] = "thumbnail_1818";
    Listing_Media_Update_Column["Thumbnail_185"] = "thumbnail_185";
    Listing_Media_Update_Column["Thumbnail_284"] = "thumbnail_284";
    Listing_Media_Update_Column["Thumbnail_370"] = "thumbnail_370";
    Listing_Media_Update_Column["Thumbnail_555"] = "thumbnail_555";
    Listing_Media_Update_Column["Thumbnail_568"] = "thumbnail_568";
    Listing_Media_Update_Column["Thumbnail_606"] = "thumbnail_606";
    Listing_Media_Update_Column["Thumbnail_852"] = "thumbnail_852";
    Listing_Media_Update_Column["Type"] = "type";
    Listing_Media_Update_Column["UpdatedAt"] = "updated_at";
    Listing_Media_Update_Column["Url"] = "url";
})(Listing_Media_Update_Column = exports.Listing_Media_Update_Column || (exports.Listing_Media_Update_Column = {}));
var Listing_Metadata_Constraint;
(function (Listing_Metadata_Constraint) {
    Listing_Metadata_Constraint["ListingMetadataPkey"] = "listing_metadata_pkey";
})(Listing_Metadata_Constraint = exports.Listing_Metadata_Constraint || (exports.Listing_Metadata_Constraint = {}));
var Listing_Metadata_Select_Column;
(function (Listing_Metadata_Select_Column) {
    Listing_Metadata_Select_Column["CreatedAt"] = "created_at";
    Listing_Metadata_Select_Column["Id"] = "id";
    Listing_Metadata_Select_Column["IsProcessed"] = "is_processed";
    Listing_Metadata_Select_Column["ListingId"] = "listing_id";
    Listing_Metadata_Select_Column["Type"] = "type";
    Listing_Metadata_Select_Column["Value"] = "value";
})(Listing_Metadata_Select_Column = exports.Listing_Metadata_Select_Column || (exports.Listing_Metadata_Select_Column = {}));
var Listing_Metadata_Update_Column;
(function (Listing_Metadata_Update_Column) {
    Listing_Metadata_Update_Column["CreatedAt"] = "created_at";
    Listing_Metadata_Update_Column["Id"] = "id";
    Listing_Metadata_Update_Column["IsProcessed"] = "is_processed";
    Listing_Metadata_Update_Column["ListingId"] = "listing_id";
    Listing_Metadata_Update_Column["Type"] = "type";
    Listing_Metadata_Update_Column["Value"] = "value";
})(Listing_Metadata_Update_Column = exports.Listing_Metadata_Update_Column || (exports.Listing_Metadata_Update_Column = {}));
var Listing_Onboarding_Constraint;
(function (Listing_Onboarding_Constraint) {
    Listing_Onboarding_Constraint["ListingOnboardingPkey"] = "listing_onboarding_pkey";
})(Listing_Onboarding_Constraint = exports.Listing_Onboarding_Constraint || (exports.Listing_Onboarding_Constraint = {}));
var Listing_Onboarding_Select_Column;
(function (Listing_Onboarding_Select_Column) {
    Listing_Onboarding_Select_Column["BudgetSize"] = "budget_size";
    Listing_Onboarding_Select_Column["CreatedAt"] = "created_at";
    Listing_Onboarding_Select_Column["Finished"] = "finished";
    Listing_Onboarding_Select_Column["Id"] = "id";
    Listing_Onboarding_Select_Column["ListingId"] = "listing_id";
    Listing_Onboarding_Select_Column["RatingInspection"] = "rating_inspection";
    Listing_Onboarding_Select_Column["RatingNegotiation"] = "rating_negotiation";
    Listing_Onboarding_Select_Column["RatingOverall"] = "rating_overall";
    Listing_Onboarding_Select_Column["RatingTechnology"] = "rating_technology";
    Listing_Onboarding_Select_Column["RatingValuing"] = "rating_valuing";
    Listing_Onboarding_Select_Column["ReasonSelling"] = "reason_selling";
    Listing_Onboarding_Select_Column["ReasonSellingOther"] = "reason_selling_other";
    Listing_Onboarding_Select_Column["Skipped"] = "skipped";
    Listing_Onboarding_Select_Column["StepCompleted"] = "step_completed";
    Listing_Onboarding_Select_Column["TimeframeSelling"] = "timeframe_selling";
    Listing_Onboarding_Select_Column["UpdatedAt"] = "updated_at";
    Listing_Onboarding_Select_Column["UserId"] = "user_id";
})(Listing_Onboarding_Select_Column = exports.Listing_Onboarding_Select_Column || (exports.Listing_Onboarding_Select_Column = {}));
var Listing_Onboarding_Update_Column;
(function (Listing_Onboarding_Update_Column) {
    Listing_Onboarding_Update_Column["BudgetSize"] = "budget_size";
    Listing_Onboarding_Update_Column["CreatedAt"] = "created_at";
    Listing_Onboarding_Update_Column["Finished"] = "finished";
    Listing_Onboarding_Update_Column["Id"] = "id";
    Listing_Onboarding_Update_Column["ListingId"] = "listing_id";
    Listing_Onboarding_Update_Column["RatingInspection"] = "rating_inspection";
    Listing_Onboarding_Update_Column["RatingNegotiation"] = "rating_negotiation";
    Listing_Onboarding_Update_Column["RatingOverall"] = "rating_overall";
    Listing_Onboarding_Update_Column["RatingTechnology"] = "rating_technology";
    Listing_Onboarding_Update_Column["RatingValuing"] = "rating_valuing";
    Listing_Onboarding_Update_Column["ReasonSelling"] = "reason_selling";
    Listing_Onboarding_Update_Column["ReasonSellingOther"] = "reason_selling_other";
    Listing_Onboarding_Update_Column["Skipped"] = "skipped";
    Listing_Onboarding_Update_Column["StepCompleted"] = "step_completed";
    Listing_Onboarding_Update_Column["TimeframeSelling"] = "timeframe_selling";
    Listing_Onboarding_Update_Column["UpdatedAt"] = "updated_at";
    Listing_Onboarding_Update_Column["UserId"] = "user_id";
})(Listing_Onboarding_Update_Column = exports.Listing_Onboarding_Update_Column || (exports.Listing_Onboarding_Update_Column = {}));
var Listing_Parking_Constraint;
(function (Listing_Parking_Constraint) {
    Listing_Parking_Constraint["ListingParkingPkey"] = "listing_parking_pkey";
})(Listing_Parking_Constraint = exports.Listing_Parking_Constraint || (exports.Listing_Parking_Constraint = {}));
var Listing_Parking_Select_Column;
(function (Listing_Parking_Select_Column) {
    Listing_Parking_Select_Column["CreatedAt"] = "created_at";
    Listing_Parking_Select_Column["Id"] = "id";
    Listing_Parking_Select_Column["ListingId"] = "listing_id";
    Listing_Parking_Select_Column["NumberOfSpaces"] = "numberOfSpaces";
    Listing_Parking_Select_Column["ParkingType"] = "parkingType";
    Listing_Parking_Select_Column["RemoteGarage"] = "remote_garage";
    Listing_Parking_Select_Column["SecureParking"] = "secure_parking";
    Listing_Parking_Select_Column["UpdatedAt"] = "updated_at";
})(Listing_Parking_Select_Column = exports.Listing_Parking_Select_Column || (exports.Listing_Parking_Select_Column = {}));
var Listing_Parking_Update_Column;
(function (Listing_Parking_Update_Column) {
    Listing_Parking_Update_Column["CreatedAt"] = "created_at";
    Listing_Parking_Update_Column["Id"] = "id";
    Listing_Parking_Update_Column["ListingId"] = "listing_id";
    Listing_Parking_Update_Column["NumberOfSpaces"] = "numberOfSpaces";
    Listing_Parking_Update_Column["ParkingType"] = "parkingType";
    Listing_Parking_Update_Column["RemoteGarage"] = "remote_garage";
    Listing_Parking_Update_Column["SecureParking"] = "secure_parking";
    Listing_Parking_Update_Column["UpdatedAt"] = "updated_at";
})(Listing_Parking_Update_Column = exports.Listing_Parking_Update_Column || (exports.Listing_Parking_Update_Column = {}));
var Listing_Publish_Errors_Constraint;
(function (Listing_Publish_Errors_Constraint) {
    Listing_Publish_Errors_Constraint["ListingPublishErrorsIdKey"] = "listing_publish_errors_id_key";
    Listing_Publish_Errors_Constraint["ListingPublishErrorsPkey"] = "listing_publish_errors_pkey";
})(Listing_Publish_Errors_Constraint = exports.Listing_Publish_Errors_Constraint || (exports.Listing_Publish_Errors_Constraint = {}));
var Listing_Publish_Errors_Select_Column;
(function (Listing_Publish_Errors_Select_Column) {
    Listing_Publish_Errors_Select_Column["AgentId"] = "agent_id";
    Listing_Publish_Errors_Select_Column["CreatedAt"] = "created_at";
    Listing_Publish_Errors_Select_Column["ErrorField"] = "error_field";
    Listing_Publish_Errors_Select_Column["ErrorMessage"] = "error_message";
    Listing_Publish_Errors_Select_Column["Id"] = "id";
    Listing_Publish_Errors_Select_Column["ListingId"] = "listing_id";
    Listing_Publish_Errors_Select_Column["PortalSource"] = "portal_source";
    Listing_Publish_Errors_Select_Column["UpdatedAt"] = "updated_at";
})(Listing_Publish_Errors_Select_Column = exports.Listing_Publish_Errors_Select_Column || (exports.Listing_Publish_Errors_Select_Column = {}));
var Listing_Publish_Errors_Update_Column;
(function (Listing_Publish_Errors_Update_Column) {
    Listing_Publish_Errors_Update_Column["AgentId"] = "agent_id";
    Listing_Publish_Errors_Update_Column["CreatedAt"] = "created_at";
    Listing_Publish_Errors_Update_Column["ErrorField"] = "error_field";
    Listing_Publish_Errors_Update_Column["ErrorMessage"] = "error_message";
    Listing_Publish_Errors_Update_Column["Id"] = "id";
    Listing_Publish_Errors_Update_Column["ListingId"] = "listing_id";
    Listing_Publish_Errors_Update_Column["PortalSource"] = "portal_source";
    Listing_Publish_Errors_Update_Column["UpdatedAt"] = "updated_at";
})(Listing_Publish_Errors_Update_Column = exports.Listing_Publish_Errors_Update_Column || (exports.Listing_Publish_Errors_Update_Column = {}));
var Listing_Publish_Portal_Source_Constraint;
(function (Listing_Publish_Portal_Source_Constraint) {
    Listing_Publish_Portal_Source_Constraint["ListingPublishPortalSourcePkey"] = "listing_publish_portal_source_pkey";
})(Listing_Publish_Portal_Source_Constraint = exports.Listing_Publish_Portal_Source_Constraint || (exports.Listing_Publish_Portal_Source_Constraint = {}));
var Listing_Publish_Portal_Source_Enum;
(function (Listing_Publish_Portal_Source_Enum) {
    Listing_Publish_Portal_Source_Enum["Allhomes"] = "allhomes";
    Listing_Publish_Portal_Source_Enum["Bmp"] = "bmp";
    Listing_Publish_Portal_Source_Enum["Domain"] = "domain";
    Listing_Publish_Portal_Source_Enum["Homely"] = "homely";
    Listing_Publish_Portal_Source_Enum["Homesales"] = "homesales";
    Listing_Publish_Portal_Source_Enum["Rea"] = "rea";
})(Listing_Publish_Portal_Source_Enum = exports.Listing_Publish_Portal_Source_Enum || (exports.Listing_Publish_Portal_Source_Enum = {}));
var Listing_Publish_Portal_Source_Select_Column;
(function (Listing_Publish_Portal_Source_Select_Column) {
    Listing_Publish_Portal_Source_Select_Column["Description"] = "description";
    Listing_Publish_Portal_Source_Select_Column["Value"] = "value";
})(Listing_Publish_Portal_Source_Select_Column = exports.Listing_Publish_Portal_Source_Select_Column || (exports.Listing_Publish_Portal_Source_Select_Column = {}));
var Listing_Publish_Portal_Source_Update_Column;
(function (Listing_Publish_Portal_Source_Update_Column) {
    Listing_Publish_Portal_Source_Update_Column["Description"] = "description";
    Listing_Publish_Portal_Source_Update_Column["Value"] = "value";
})(Listing_Publish_Portal_Source_Update_Column = exports.Listing_Publish_Portal_Source_Update_Column || (exports.Listing_Publish_Portal_Source_Update_Column = {}));
var Listing_Publish_Status_Constraint;
(function (Listing_Publish_Status_Constraint) {
    Listing_Publish_Status_Constraint["ListingPublishStatusIdKey"] = "listing_publish_status_id_key";
    Listing_Publish_Status_Constraint["ListingPublishStatusPkey"] = "listing_publish_status_pkey";
})(Listing_Publish_Status_Constraint = exports.Listing_Publish_Status_Constraint || (exports.Listing_Publish_Status_Constraint = {}));
var Listing_Publish_Status_Select_Column;
(function (Listing_Publish_Status_Select_Column) {
    Listing_Publish_Status_Select_Column["CreatedAt"] = "created_at";
    Listing_Publish_Status_Select_Column["CurrentPortalStatus"] = "current_portal_status";
    Listing_Publish_Status_Select_Column["CustomPortalStatus"] = "custom_portal_status";
    Listing_Publish_Status_Select_Column["Enabled"] = "enabled";
    Listing_Publish_Status_Select_Column["Id"] = "id";
    Listing_Publish_Status_Select_Column["IsTest"] = "is_test";
    Listing_Publish_Status_Select_Column["ListingId"] = "listing_id";
    Listing_Publish_Status_Select_Column["PortalSource"] = "portal_source";
    Listing_Publish_Status_Select_Column["PortalSourceId"] = "portal_source_id";
    Listing_Publish_Status_Select_Column["ProcessJobId"] = "process_job_id";
    Listing_Publish_Status_Select_Column["PublishStatus"] = "publish_status";
    Listing_Publish_Status_Select_Column["Request"] = "request";
    Listing_Publish_Status_Select_Column["Response"] = "response";
    Listing_Publish_Status_Select_Column["TestXml"] = "test_xml";
    Listing_Publish_Status_Select_Column["TestXmlName"] = "test_xml_name";
    Listing_Publish_Status_Select_Column["UniqueId"] = "unique_id";
    Listing_Publish_Status_Select_Column["UpdateDate"] = "update_date";
    Listing_Publish_Status_Select_Column["UpdatedAt"] = "updated_at";
    Listing_Publish_Status_Select_Column["XmlFileName"] = "xml_file_name";
})(Listing_Publish_Status_Select_Column = exports.Listing_Publish_Status_Select_Column || (exports.Listing_Publish_Status_Select_Column = {}));
var Listing_Publish_Status_Type_Constraint;
(function (Listing_Publish_Status_Type_Constraint) {
    Listing_Publish_Status_Type_Constraint["ListingPublishStatusTypePkey"] = "listing_publish_status_type_pkey";
})(Listing_Publish_Status_Type_Constraint = exports.Listing_Publish_Status_Type_Constraint || (exports.Listing_Publish_Status_Type_Constraint = {}));
var Listing_Publish_Status_Type_Enum;
(function (Listing_Publish_Status_Type_Enum) {
    Listing_Publish_Status_Type_Enum["Draft"] = "draft";
    Listing_Publish_Status_Type_Enum["Error"] = "error";
    Listing_Publish_Status_Type_Enum["Processed"] = "processed";
    Listing_Publish_Status_Type_Enum["Processing"] = "processing";
    Listing_Publish_Status_Type_Enum["Queued"] = "queued";
})(Listing_Publish_Status_Type_Enum = exports.Listing_Publish_Status_Type_Enum || (exports.Listing_Publish_Status_Type_Enum = {}));
var Listing_Publish_Status_Type_Select_Column;
(function (Listing_Publish_Status_Type_Select_Column) {
    Listing_Publish_Status_Type_Select_Column["Description"] = "description";
    Listing_Publish_Status_Type_Select_Column["Value"] = "value";
})(Listing_Publish_Status_Type_Select_Column = exports.Listing_Publish_Status_Type_Select_Column || (exports.Listing_Publish_Status_Type_Select_Column = {}));
var Listing_Publish_Status_Type_Update_Column;
(function (Listing_Publish_Status_Type_Update_Column) {
    Listing_Publish_Status_Type_Update_Column["Description"] = "description";
    Listing_Publish_Status_Type_Update_Column["Value"] = "value";
})(Listing_Publish_Status_Type_Update_Column = exports.Listing_Publish_Status_Type_Update_Column || (exports.Listing_Publish_Status_Type_Update_Column = {}));
var Listing_Publish_Status_Update_Column;
(function (Listing_Publish_Status_Update_Column) {
    Listing_Publish_Status_Update_Column["CreatedAt"] = "created_at";
    Listing_Publish_Status_Update_Column["CurrentPortalStatus"] = "current_portal_status";
    Listing_Publish_Status_Update_Column["CustomPortalStatus"] = "custom_portal_status";
    Listing_Publish_Status_Update_Column["Enabled"] = "enabled";
    Listing_Publish_Status_Update_Column["Id"] = "id";
    Listing_Publish_Status_Update_Column["IsTest"] = "is_test";
    Listing_Publish_Status_Update_Column["ListingId"] = "listing_id";
    Listing_Publish_Status_Update_Column["PortalSource"] = "portal_source";
    Listing_Publish_Status_Update_Column["PortalSourceId"] = "portal_source_id";
    Listing_Publish_Status_Update_Column["ProcessJobId"] = "process_job_id";
    Listing_Publish_Status_Update_Column["PublishStatus"] = "publish_status";
    Listing_Publish_Status_Update_Column["Request"] = "request";
    Listing_Publish_Status_Update_Column["Response"] = "response";
    Listing_Publish_Status_Update_Column["TestXml"] = "test_xml";
    Listing_Publish_Status_Update_Column["TestXmlName"] = "test_xml_name";
    Listing_Publish_Status_Update_Column["UniqueId"] = "unique_id";
    Listing_Publish_Status_Update_Column["UpdateDate"] = "update_date";
    Listing_Publish_Status_Update_Column["UpdatedAt"] = "updated_at";
    Listing_Publish_Status_Update_Column["XmlFileName"] = "xml_file_name";
})(Listing_Publish_Status_Update_Column = exports.Listing_Publish_Status_Update_Column || (exports.Listing_Publish_Status_Update_Column = {}));
var Listing_Search_Select_Column;
(function (Listing_Search_Select_Column) {
    Listing_Search_Select_Column["Id"] = "id";
    Listing_Search_Select_Column["SearchSource"] = "search_source";
})(Listing_Search_Select_Column = exports.Listing_Search_Select_Column || (exports.Listing_Search_Select_Column = {}));
var Listing_Select_Column;
(function (Listing_Select_Column) {
    Listing_Select_Column["AgentPointId"] = "agent_point_id";
    Listing_Select_Column["AuctionDateTime"] = "auction_dateTime";
    Listing_Select_Column["AuctionLocation"] = "auction_location";
    Listing_Select_Column["AuctionOnline"] = "auction_online";
    Listing_Select_Column["Authority"] = "authority";
    Listing_Select_Column["AvailableFrom"] = "availableFrom";
    Listing_Select_Column["Bond"] = "bond";
    Listing_Select_Column["CommercialStatus"] = "commercial_status";
    Listing_Select_Column["ContactPreference"] = "contactPreference";
    Listing_Select_Column["CreatedAt"] = "created_at";
    Listing_Select_Column["CrossOver"] = "crossOver";
    Listing_Select_Column["DeliveryAddress_1"] = "delivery_address_1";
    Listing_Select_Column["DeliveryAddress_2"] = "delivery_address_2";
    Listing_Select_Column["DeliveryAddressPostcode"] = "delivery_address_postcode";
    Listing_Select_Column["DeliveryAddressState"] = "delivery_address_state";
    Listing_Select_Column["DeliveryAddressStreet"] = "delivery_address_street";
    Listing_Select_Column["DeliveryAddressStreetNumber"] = "delivery_address_streetNumber";
    Listing_Select_Column["DeliveryAddressSuburb"] = "delivery_address_suburb";
    Listing_Select_Column["DeliveryAddressSuggestedGeoLocationLatitude"] = "delivery_address_suggestedGeoLocation_latitude";
    Listing_Select_Column["DeliveryAddressSuggestedGeoLocationLongitude"] = "delivery_address_suggestedGeoLocation_longitude";
    Listing_Select_Column["DeliveryAddressUnitNumber"] = "delivery_address_unitNumber";
    Listing_Select_Column["DeliveryToPropertyAddress"] = "delivery_to_property_address";
    Listing_Select_Column["Description"] = "description";
    Listing_Select_Column["DomainAdId"] = "domainAdId";
    Listing_Select_Column["EnquiryCode"] = "enquiry_code";
    Listing_Select_Column["Experiments"] = "experiments";
    Listing_Select_Column["ExternalLink"] = "externalLink";
    Listing_Select_Column["Features"] = "features";
    Listing_Select_Column["Id"] = "id";
    Listing_Select_Column["InspectionDetailsInspectionDescription"] = "inspectionDetails_inspectionDescription";
    Listing_Select_Column["IsNewDevelopment"] = "isNewDevelopment";
    Listing_Select_Column["IsRural"] = "isRural";
    Listing_Select_Column["LifeStyleType"] = "lifeStyleType";
    Listing_Select_Column["ListingAction"] = "listingAction";
    Listing_Select_Column["OtherEnquiryEmail"] = "otherEnquiryEmail";
    Listing_Select_Column["OwnerId"] = "owner_id";
    Listing_Select_Column["PriceAsking"] = "price_asking";
    Listing_Select_Column["PriceDisplayOption"] = "price_displayOption";
    Listing_Select_Column["PriceDisplayText"] = "price_displayText";
    Listing_Select_Column["PriceFrom"] = "price_from";
    Listing_Select_Column["PriceSingle"] = "price_single";
    Listing_Select_Column["PriceTextOption"] = "price_textOption";
    Listing_Select_Column["PriceTo"] = "price_to";
    Listing_Select_Column["PriceView"] = "price_view";
    Listing_Select_Column["ProofOfOwnershipStatus"] = "proof_of_ownership_status";
    Listing_Select_Column["PropertyDetailsAddress_1"] = "propertyDetails_address_1";
    Listing_Select_Column["PropertyDetailsAddress_2"] = "propertyDetails_address_2";
    Listing_Select_Column["PropertyDetailsAddressDisplayOption"] = "propertyDetails_address_displayOption";
    Listing_Select_Column["PropertyDetailsAddressMunicipality"] = "propertyDetails_address_municipality";
    Listing_Select_Column["PropertyDetailsAddressPostcode"] = "propertyDetails_address_postcode";
    Listing_Select_Column["PropertyDetailsAddressPostcodeBeforeCheckout"] = "propertyDetails_address_postcode_before_checkout";
    Listing_Select_Column["PropertyDetailsAddressState"] = "propertyDetails_address_state";
    Listing_Select_Column["PropertyDetailsAddressStateBeforeCheckout"] = "propertyDetails_address_state_before_checkout";
    Listing_Select_Column["PropertyDetailsAddressStreet"] = "propertyDetails_address_street";
    Listing_Select_Column["PropertyDetailsAddressStreetDirectoryPage"] = "propertyDetails_address_streetDirectory_page";
    Listing_Select_Column["PropertyDetailsAddressStreetDirectoryReference"] = "propertyDetails_address_streetDirectory_reference";
    Listing_Select_Column["PropertyDetailsAddressStreetDirectoryType"] = "propertyDetails_address_streetDirectory_type";
    Listing_Select_Column["PropertyDetailsAddressStreetNumber"] = "propertyDetails_address_streetNumber";
    Listing_Select_Column["PropertyDetailsAddressSuburb"] = "propertyDetails_address_suburb";
    Listing_Select_Column["PropertyDetailsAddressSuburbBeforeCheckout"] = "propertyDetails_address_suburb_before_checkout";
    Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocation"] = "propertyDetails_address_suggestedGeoLocation";
    Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocationLatitude"] = "propertyDetails_address_suggestedGeoLocation_latitude";
    Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocationLongitude"] = "propertyDetails_address_suggestedGeoLocation_longitude";
    Listing_Select_Column["PropertyDetailsAddressUnitNumber"] = "propertyDetails_address_unitNumber";
    Listing_Select_Column["PropertyDetailsAreaFrom"] = "propertyDetails_area_from";
    Listing_Select_Column["PropertyDetailsAreaTo"] = "propertyDetails_area_to";
    Listing_Select_Column["PropertyDetailsAreaUnit"] = "propertyDetails_area_unit";
    Listing_Select_Column["PropertyDetailsAreaValue"] = "propertyDetails_area_value";
    Listing_Select_Column["PropertyDetailsBathRooms"] = "propertyDetails_bathRooms";
    Listing_Select_Column["PropertyDetailsBedRooms"] = "propertyDetails_bedRooms";
    Listing_Select_Column["PropertyDetailsCarSpaces"] = "propertyDetails_carSpaces";
    Listing_Select_Column["PropertyDetailsCarportSpaces"] = "propertyDetails_carportSpaces";
    Listing_Select_Column["PropertyDetailsEnergyEfficiencyRating"] = "propertyDetails_energyEfficiencyRating";
    Listing_Select_Column["PropertyDetailsGarageSpaces"] = "propertyDetails_garageSpaces";
    Listing_Select_Column["PropertyDetailsLandAreaDepthLeft"] = "propertyDetails_landArea_depth_left";
    Listing_Select_Column["PropertyDetailsLandAreaDepthRear"] = "propertyDetails_landArea_depth_rear";
    Listing_Select_Column["PropertyDetailsLandAreaDepthRight"] = "propertyDetails_landArea_depth_right";
    Listing_Select_Column["PropertyDetailsLandAreaDepthUnit"] = "propertyDetails_landArea_depth_unit";
    Listing_Select_Column["PropertyDetailsLandAreaFrom"] = "propertyDetails_landArea_from";
    Listing_Select_Column["PropertyDetailsLandAreaFrontage"] = "propertyDetails_landArea_frontage";
    Listing_Select_Column["PropertyDetailsLandAreaFrontageUnit"] = "propertyDetails_landArea_frontage_unit";
    Listing_Select_Column["PropertyDetailsLandAreaTo"] = "propertyDetails_landArea_to";
    Listing_Select_Column["PropertyDetailsLandAreaUnit"] = "propertyDetails_landArea_unit";
    Listing_Select_Column["PropertyDetailsLandAreaValue"] = "propertyDetails_landArea_value";
    Listing_Select_Column["PropertyDetailsParkingInfoInformation"] = "propertyDetails_parkingInfo_information";
    Listing_Select_Column["PropertyDetailsPropertyType"] = "propertyDetails_propertyType";
    Listing_Select_Column["PropertyDetailsPropertyTypeHotWaterServiceType"] = "propertyDetails_propertyType_hotWaterServiceType";
    Listing_Select_Column["PropertyDetailsYearBuilt"] = "propertyDetails_yearBuilt";
    Listing_Select_Column["ProviderAdId"] = "providerAdId";
    Listing_Select_Column["PublishingStatus"] = "publishing_status";
    Listing_Select_Column["SaleType"] = "saleType";
    Listing_Select_Column["SalesforceId"] = "salesforce_id";
    Listing_Select_Column["SearchPrice"] = "search_price";
    Listing_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Listing_Select_Column["SoiComparableData"] = "soi_comparableData";
    Listing_Select_Column["SoiComparableDataComparablePropertyDateOf"] = "soi_comparableData_comparableProperty_dateOf";
    Listing_Select_Column["SoiComparableDataComparablePropertySoldPr"] = "soi_comparableData_comparableProperty_soldPr";
    Listing_Select_Column["SoiComparableDataDeclarationText"] = "soi_comparableData_declarationText";
    Listing_Select_Column["SoiDocumentationUrl"] = "soi_documentationUrl";
    Listing_Select_Column["SoiEstimatedPrice"] = "soi_estimatedPrice";
    Listing_Select_Column["SoiEstimatedPriceFrom"] = "soi_estimatedPrice_from";
    Listing_Select_Column["SoiEstimatedPriceTo"] = "soi_estimatedPrice_to";
    Listing_Select_Column["SoiSuburbMedianPrice"] = "soi_suburbMedianPrice";
    Listing_Select_Column["SoiSuburbMedianPriceDeclarationText"] = "soi_suburbMedianPrice_declarationText";
    Listing_Select_Column["SoiSuburbMedianPriceMedianPrice"] = "soi_suburbMedianPrice_medianPrice";
    Listing_Select_Column["SoiSuburbMedianPricePostcode"] = "soi_suburbMedianPrice_postcode";
    Listing_Select_Column["SoiSuburbMedianPricePriceType"] = "soi_suburbMedianPrice_priceType";
    Listing_Select_Column["SoiSuburbMedianPriceSource"] = "soi_suburbMedianPrice_source";
    Listing_Select_Column["SoiSuburbMedianPriceSourceDateFrom"] = "soi_suburbMedianPrice_sourceDateFrom";
    Listing_Select_Column["SoiSuburbMedianPriceSourceDateTo"] = "soi_suburbMedianPrice_sourceDateTo";
    Listing_Select_Column["SoiSuburbMedianPriceSuburb"] = "soi_suburbMedianPrice_suburb";
    Listing_Select_Column["Status"] = "status";
    Listing_Select_Column["Summary"] = "summary";
    Listing_Select_Column["SupplementaryDescription"] = "supplementary_description";
    Listing_Select_Column["SupplementaryMetadataDescription"] = "supplementary_metadata_description";
    Listing_Select_Column["SupplementaryMetadataMeasurement"] = "supplementary_metadata_measurement";
    Listing_Select_Column["SupplementaryMetadataMeasurementUnit"] = "supplementary_metadata_measurementUnit";
    Listing_Select_Column["SupplementaryMetadataName"] = "supplementary_metadata_name";
    Listing_Select_Column["SupplementaryName"] = "supplementary_name";
    Listing_Select_Column["SupplementaryTypes"] = "supplementary_types";
    Listing_Select_Column["TemporaryAddOnFlag"] = "temporary_add_on_flag";
    Listing_Select_Column["UpdatedAt"] = "updated_at";
    Listing_Select_Column["Zone"] = "zone";
})(Listing_Select_Column = exports.Listing_Select_Column || (exports.Listing_Select_Column = {}));
var Listing_Soi_Comparable_Property_Constraint;
(function (Listing_Soi_Comparable_Property_Constraint) {
    Listing_Soi_Comparable_Property_Constraint["ListingSoiComparablePropertyPkey"] = "listing_soi_comparable_property_pkey";
})(Listing_Soi_Comparable_Property_Constraint = exports.Listing_Soi_Comparable_Property_Constraint || (exports.Listing_Soi_Comparable_Property_Constraint = {}));
var Listing_Soi_Comparable_Property_Select_Column;
(function (Listing_Soi_Comparable_Property_Select_Column) {
    Listing_Soi_Comparable_Property_Select_Column["Address"] = "address";
    Listing_Soi_Comparable_Property_Select_Column["AddressPostcode"] = "address_postcode";
    Listing_Soi_Comparable_Property_Select_Column["AddressState"] = "address_state";
    Listing_Soi_Comparable_Property_Select_Column["AddressStreet"] = "address_street";
    Listing_Soi_Comparable_Property_Select_Column["AddressStreetNumber"] = "address_streetNumber";
    Listing_Soi_Comparable_Property_Select_Column["AddressSuburb"] = "address_suburb";
    Listing_Soi_Comparable_Property_Select_Column["AddressUnitNumber"] = "address_unitNumber";
    Listing_Soi_Comparable_Property_Select_Column["CreatedAt"] = "created_at";
    Listing_Soi_Comparable_Property_Select_Column["DateOfSale"] = "date_of_sale";
    Listing_Soi_Comparable_Property_Select_Column["Id"] = "id";
    Listing_Soi_Comparable_Property_Select_Column["ListingId"] = "listing_id";
    Listing_Soi_Comparable_Property_Select_Column["SoldPrice"] = "sold_price";
    Listing_Soi_Comparable_Property_Select_Column["UpdatedAt"] = "updated_at";
})(Listing_Soi_Comparable_Property_Select_Column = exports.Listing_Soi_Comparable_Property_Select_Column || (exports.Listing_Soi_Comparable_Property_Select_Column = {}));
var Listing_Soi_Comparable_Property_Update_Column;
(function (Listing_Soi_Comparable_Property_Update_Column) {
    Listing_Soi_Comparable_Property_Update_Column["Address"] = "address";
    Listing_Soi_Comparable_Property_Update_Column["AddressPostcode"] = "address_postcode";
    Listing_Soi_Comparable_Property_Update_Column["AddressState"] = "address_state";
    Listing_Soi_Comparable_Property_Update_Column["AddressStreet"] = "address_street";
    Listing_Soi_Comparable_Property_Update_Column["AddressStreetNumber"] = "address_streetNumber";
    Listing_Soi_Comparable_Property_Update_Column["AddressSuburb"] = "address_suburb";
    Listing_Soi_Comparable_Property_Update_Column["AddressUnitNumber"] = "address_unitNumber";
    Listing_Soi_Comparable_Property_Update_Column["CreatedAt"] = "created_at";
    Listing_Soi_Comparable_Property_Update_Column["DateOfSale"] = "date_of_sale";
    Listing_Soi_Comparable_Property_Update_Column["Id"] = "id";
    Listing_Soi_Comparable_Property_Update_Column["ListingId"] = "listing_id";
    Listing_Soi_Comparable_Property_Update_Column["SoldPrice"] = "sold_price";
    Listing_Soi_Comparable_Property_Update_Column["UpdatedAt"] = "updated_at";
})(Listing_Soi_Comparable_Property_Update_Column = exports.Listing_Soi_Comparable_Property_Update_Column || (exports.Listing_Soi_Comparable_Property_Update_Column = {}));
var Listing_Update_Column;
(function (Listing_Update_Column) {
    Listing_Update_Column["AgentPointId"] = "agent_point_id";
    Listing_Update_Column["AuctionDateTime"] = "auction_dateTime";
    Listing_Update_Column["AuctionLocation"] = "auction_location";
    Listing_Update_Column["AuctionOnline"] = "auction_online";
    Listing_Update_Column["Authority"] = "authority";
    Listing_Update_Column["AvailableFrom"] = "availableFrom";
    Listing_Update_Column["Bond"] = "bond";
    Listing_Update_Column["CommercialStatus"] = "commercial_status";
    Listing_Update_Column["ContactPreference"] = "contactPreference";
    Listing_Update_Column["CreatedAt"] = "created_at";
    Listing_Update_Column["CrossOver"] = "crossOver";
    Listing_Update_Column["DeliveryAddress_1"] = "delivery_address_1";
    Listing_Update_Column["DeliveryAddress_2"] = "delivery_address_2";
    Listing_Update_Column["DeliveryAddressPostcode"] = "delivery_address_postcode";
    Listing_Update_Column["DeliveryAddressState"] = "delivery_address_state";
    Listing_Update_Column["DeliveryAddressStreet"] = "delivery_address_street";
    Listing_Update_Column["DeliveryAddressStreetNumber"] = "delivery_address_streetNumber";
    Listing_Update_Column["DeliveryAddressSuburb"] = "delivery_address_suburb";
    Listing_Update_Column["DeliveryAddressSuggestedGeoLocationLatitude"] = "delivery_address_suggestedGeoLocation_latitude";
    Listing_Update_Column["DeliveryAddressSuggestedGeoLocationLongitude"] = "delivery_address_suggestedGeoLocation_longitude";
    Listing_Update_Column["DeliveryAddressUnitNumber"] = "delivery_address_unitNumber";
    Listing_Update_Column["DeliveryToPropertyAddress"] = "delivery_to_property_address";
    Listing_Update_Column["Description"] = "description";
    Listing_Update_Column["DomainAdId"] = "domainAdId";
    Listing_Update_Column["EnquiryCode"] = "enquiry_code";
    Listing_Update_Column["Experiments"] = "experiments";
    Listing_Update_Column["ExternalLink"] = "externalLink";
    Listing_Update_Column["Features"] = "features";
    Listing_Update_Column["Id"] = "id";
    Listing_Update_Column["InspectionDetailsInspectionDescription"] = "inspectionDetails_inspectionDescription";
    Listing_Update_Column["IsNewDevelopment"] = "isNewDevelopment";
    Listing_Update_Column["IsRural"] = "isRural";
    Listing_Update_Column["LifeStyleType"] = "lifeStyleType";
    Listing_Update_Column["ListingAction"] = "listingAction";
    Listing_Update_Column["OtherEnquiryEmail"] = "otherEnquiryEmail";
    Listing_Update_Column["OwnerId"] = "owner_id";
    Listing_Update_Column["PriceAsking"] = "price_asking";
    Listing_Update_Column["PriceDisplayOption"] = "price_displayOption";
    Listing_Update_Column["PriceDisplayText"] = "price_displayText";
    Listing_Update_Column["PriceFrom"] = "price_from";
    Listing_Update_Column["PriceSingle"] = "price_single";
    Listing_Update_Column["PriceTextOption"] = "price_textOption";
    Listing_Update_Column["PriceTo"] = "price_to";
    Listing_Update_Column["PriceView"] = "price_view";
    Listing_Update_Column["ProofOfOwnershipStatus"] = "proof_of_ownership_status";
    Listing_Update_Column["PropertyDetailsAddress_1"] = "propertyDetails_address_1";
    Listing_Update_Column["PropertyDetailsAddress_2"] = "propertyDetails_address_2";
    Listing_Update_Column["PropertyDetailsAddressDisplayOption"] = "propertyDetails_address_displayOption";
    Listing_Update_Column["PropertyDetailsAddressMunicipality"] = "propertyDetails_address_municipality";
    Listing_Update_Column["PropertyDetailsAddressPostcode"] = "propertyDetails_address_postcode";
    Listing_Update_Column["PropertyDetailsAddressPostcodeBeforeCheckout"] = "propertyDetails_address_postcode_before_checkout";
    Listing_Update_Column["PropertyDetailsAddressState"] = "propertyDetails_address_state";
    Listing_Update_Column["PropertyDetailsAddressStateBeforeCheckout"] = "propertyDetails_address_state_before_checkout";
    Listing_Update_Column["PropertyDetailsAddressStreet"] = "propertyDetails_address_street";
    Listing_Update_Column["PropertyDetailsAddressStreetDirectoryPage"] = "propertyDetails_address_streetDirectory_page";
    Listing_Update_Column["PropertyDetailsAddressStreetDirectoryReference"] = "propertyDetails_address_streetDirectory_reference";
    Listing_Update_Column["PropertyDetailsAddressStreetDirectoryType"] = "propertyDetails_address_streetDirectory_type";
    Listing_Update_Column["PropertyDetailsAddressStreetNumber"] = "propertyDetails_address_streetNumber";
    Listing_Update_Column["PropertyDetailsAddressSuburb"] = "propertyDetails_address_suburb";
    Listing_Update_Column["PropertyDetailsAddressSuburbBeforeCheckout"] = "propertyDetails_address_suburb_before_checkout";
    Listing_Update_Column["PropertyDetailsAddressSuggestedGeoLocation"] = "propertyDetails_address_suggestedGeoLocation";
    Listing_Update_Column["PropertyDetailsAddressSuggestedGeoLocationLatitude"] = "propertyDetails_address_suggestedGeoLocation_latitude";
    Listing_Update_Column["PropertyDetailsAddressSuggestedGeoLocationLongitude"] = "propertyDetails_address_suggestedGeoLocation_longitude";
    Listing_Update_Column["PropertyDetailsAddressUnitNumber"] = "propertyDetails_address_unitNumber";
    Listing_Update_Column["PropertyDetailsAreaFrom"] = "propertyDetails_area_from";
    Listing_Update_Column["PropertyDetailsAreaTo"] = "propertyDetails_area_to";
    Listing_Update_Column["PropertyDetailsAreaUnit"] = "propertyDetails_area_unit";
    Listing_Update_Column["PropertyDetailsAreaValue"] = "propertyDetails_area_value";
    Listing_Update_Column["PropertyDetailsBathRooms"] = "propertyDetails_bathRooms";
    Listing_Update_Column["PropertyDetailsBedRooms"] = "propertyDetails_bedRooms";
    Listing_Update_Column["PropertyDetailsCarSpaces"] = "propertyDetails_carSpaces";
    Listing_Update_Column["PropertyDetailsCarportSpaces"] = "propertyDetails_carportSpaces";
    Listing_Update_Column["PropertyDetailsEnergyEfficiencyRating"] = "propertyDetails_energyEfficiencyRating";
    Listing_Update_Column["PropertyDetailsGarageSpaces"] = "propertyDetails_garageSpaces";
    Listing_Update_Column["PropertyDetailsLandAreaDepthLeft"] = "propertyDetails_landArea_depth_left";
    Listing_Update_Column["PropertyDetailsLandAreaDepthRear"] = "propertyDetails_landArea_depth_rear";
    Listing_Update_Column["PropertyDetailsLandAreaDepthRight"] = "propertyDetails_landArea_depth_right";
    Listing_Update_Column["PropertyDetailsLandAreaDepthUnit"] = "propertyDetails_landArea_depth_unit";
    Listing_Update_Column["PropertyDetailsLandAreaFrom"] = "propertyDetails_landArea_from";
    Listing_Update_Column["PropertyDetailsLandAreaFrontage"] = "propertyDetails_landArea_frontage";
    Listing_Update_Column["PropertyDetailsLandAreaFrontageUnit"] = "propertyDetails_landArea_frontage_unit";
    Listing_Update_Column["PropertyDetailsLandAreaTo"] = "propertyDetails_landArea_to";
    Listing_Update_Column["PropertyDetailsLandAreaUnit"] = "propertyDetails_landArea_unit";
    Listing_Update_Column["PropertyDetailsLandAreaValue"] = "propertyDetails_landArea_value";
    Listing_Update_Column["PropertyDetailsParkingInfoInformation"] = "propertyDetails_parkingInfo_information";
    Listing_Update_Column["PropertyDetailsPropertyType"] = "propertyDetails_propertyType";
    Listing_Update_Column["PropertyDetailsPropertyTypeHotWaterServiceType"] = "propertyDetails_propertyType_hotWaterServiceType";
    Listing_Update_Column["PropertyDetailsYearBuilt"] = "propertyDetails_yearBuilt";
    Listing_Update_Column["ProviderAdId"] = "providerAdId";
    Listing_Update_Column["PublishingStatus"] = "publishing_status";
    Listing_Update_Column["SaleType"] = "saleType";
    Listing_Update_Column["SalesforceId"] = "salesforce_id";
    Listing_Update_Column["SearchPrice"] = "search_price";
    Listing_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    Listing_Update_Column["SoiComparableData"] = "soi_comparableData";
    Listing_Update_Column["SoiComparableDataComparablePropertyDateOf"] = "soi_comparableData_comparableProperty_dateOf";
    Listing_Update_Column["SoiComparableDataComparablePropertySoldPr"] = "soi_comparableData_comparableProperty_soldPr";
    Listing_Update_Column["SoiComparableDataDeclarationText"] = "soi_comparableData_declarationText";
    Listing_Update_Column["SoiDocumentationUrl"] = "soi_documentationUrl";
    Listing_Update_Column["SoiEstimatedPrice"] = "soi_estimatedPrice";
    Listing_Update_Column["SoiEstimatedPriceFrom"] = "soi_estimatedPrice_from";
    Listing_Update_Column["SoiEstimatedPriceTo"] = "soi_estimatedPrice_to";
    Listing_Update_Column["SoiSuburbMedianPrice"] = "soi_suburbMedianPrice";
    Listing_Update_Column["SoiSuburbMedianPriceDeclarationText"] = "soi_suburbMedianPrice_declarationText";
    Listing_Update_Column["SoiSuburbMedianPriceMedianPrice"] = "soi_suburbMedianPrice_medianPrice";
    Listing_Update_Column["SoiSuburbMedianPricePostcode"] = "soi_suburbMedianPrice_postcode";
    Listing_Update_Column["SoiSuburbMedianPricePriceType"] = "soi_suburbMedianPrice_priceType";
    Listing_Update_Column["SoiSuburbMedianPriceSource"] = "soi_suburbMedianPrice_source";
    Listing_Update_Column["SoiSuburbMedianPriceSourceDateFrom"] = "soi_suburbMedianPrice_sourceDateFrom";
    Listing_Update_Column["SoiSuburbMedianPriceSourceDateTo"] = "soi_suburbMedianPrice_sourceDateTo";
    Listing_Update_Column["SoiSuburbMedianPriceSuburb"] = "soi_suburbMedianPrice_suburb";
    Listing_Update_Column["Status"] = "status";
    Listing_Update_Column["Summary"] = "summary";
    Listing_Update_Column["SupplementaryDescription"] = "supplementary_description";
    Listing_Update_Column["SupplementaryMetadataDescription"] = "supplementary_metadata_description";
    Listing_Update_Column["SupplementaryMetadataMeasurement"] = "supplementary_metadata_measurement";
    Listing_Update_Column["SupplementaryMetadataMeasurementUnit"] = "supplementary_metadata_measurementUnit";
    Listing_Update_Column["SupplementaryMetadataName"] = "supplementary_metadata_name";
    Listing_Update_Column["SupplementaryName"] = "supplementary_name";
    Listing_Update_Column["SupplementaryTypes"] = "supplementary_types";
    Listing_Update_Column["TemporaryAddOnFlag"] = "temporary_add_on_flag";
    Listing_Update_Column["UpdatedAt"] = "updated_at";
    Listing_Update_Column["Zone"] = "zone";
})(Listing_Update_Column = exports.Listing_Update_Column || (exports.Listing_Update_Column = {}));
var Metro_Type_Constraint;
(function (Metro_Type_Constraint) {
    Metro_Type_Constraint["MetroTypePkey"] = "MetroType_pkey";
})(Metro_Type_Constraint = exports.Metro_Type_Constraint || (exports.Metro_Type_Constraint = {}));
var Metro_Type_Enum;
(function (Metro_Type_Enum) {
    Metro_Type_Enum["Melbourne"] = "Melbourne";
    Metro_Type_Enum["Sydney"] = "Sydney";
})(Metro_Type_Enum = exports.Metro_Type_Enum || (exports.Metro_Type_Enum = {}));
var Metro_Type_Select_Column;
(function (Metro_Type_Select_Column) {
    Metro_Type_Select_Column["Description"] = "description";
    Metro_Type_Select_Column["Value"] = "value";
})(Metro_Type_Select_Column = exports.Metro_Type_Select_Column || (exports.Metro_Type_Select_Column = {}));
var Metro_Type_Update_Column;
(function (Metro_Type_Update_Column) {
    Metro_Type_Update_Column["Description"] = "description";
    Metro_Type_Update_Column["Value"] = "value";
})(Metro_Type_Update_Column = exports.Metro_Type_Update_Column || (exports.Metro_Type_Update_Column = {}));
var Onboarding_Budget_Size_Constraint;
(function (Onboarding_Budget_Size_Constraint) {
    Onboarding_Budget_Size_Constraint["OnboardingBudgetSizePkey"] = "onboarding_budget_size_pkey";
})(Onboarding_Budget_Size_Constraint = exports.Onboarding_Budget_Size_Constraint || (exports.Onboarding_Budget_Size_Constraint = {}));
var Onboarding_Budget_Size_Enum;
(function (Onboarding_Budget_Size_Enum) {
    Onboarding_Budget_Size_Enum["Large"] = "LARGE";
    Onboarding_Budget_Size_Enum["Medium"] = "MEDIUM";
    Onboarding_Budget_Size_Enum["Small"] = "SMALL";
})(Onboarding_Budget_Size_Enum = exports.Onboarding_Budget_Size_Enum || (exports.Onboarding_Budget_Size_Enum = {}));
var Onboarding_Budget_Size_Select_Column;
(function (Onboarding_Budget_Size_Select_Column) {
    Onboarding_Budget_Size_Select_Column["Description"] = "description";
    Onboarding_Budget_Size_Select_Column["Value"] = "value";
})(Onboarding_Budget_Size_Select_Column = exports.Onboarding_Budget_Size_Select_Column || (exports.Onboarding_Budget_Size_Select_Column = {}));
var Onboarding_Budget_Size_Update_Column;
(function (Onboarding_Budget_Size_Update_Column) {
    Onboarding_Budget_Size_Update_Column["Description"] = "description";
    Onboarding_Budget_Size_Update_Column["Value"] = "value";
})(Onboarding_Budget_Size_Update_Column = exports.Onboarding_Budget_Size_Update_Column || (exports.Onboarding_Budget_Size_Update_Column = {}));
var Onboarding_Reason_Selling_Constraint;
(function (Onboarding_Reason_Selling_Constraint) {
    Onboarding_Reason_Selling_Constraint["OnboardingReasonSellingPkey"] = "onboarding_reason_selling_pkey";
})(Onboarding_Reason_Selling_Constraint = exports.Onboarding_Reason_Selling_Constraint || (exports.Onboarding_Reason_Selling_Constraint = {}));
var Onboarding_Reason_Selling_Enum;
(function (Onboarding_Reason_Selling_Enum) {
    Onboarding_Reason_Selling_Enum["Downsizing"] = "DOWNSIZING";
    Onboarding_Reason_Selling_Enum["Money"] = "MONEY";
    Onboarding_Reason_Selling_Enum["Other"] = "OTHER";
    Onboarding_Reason_Selling_Enum["Relocating"] = "RELOCATING";
    Onboarding_Reason_Selling_Enum["Upsizing"] = "UPSIZING";
})(Onboarding_Reason_Selling_Enum = exports.Onboarding_Reason_Selling_Enum || (exports.Onboarding_Reason_Selling_Enum = {}));
var Onboarding_Reason_Selling_Select_Column;
(function (Onboarding_Reason_Selling_Select_Column) {
    Onboarding_Reason_Selling_Select_Column["Description"] = "description";
    Onboarding_Reason_Selling_Select_Column["Value"] = "value";
})(Onboarding_Reason_Selling_Select_Column = exports.Onboarding_Reason_Selling_Select_Column || (exports.Onboarding_Reason_Selling_Select_Column = {}));
var Onboarding_Reason_Selling_Update_Column;
(function (Onboarding_Reason_Selling_Update_Column) {
    Onboarding_Reason_Selling_Update_Column["Description"] = "description";
    Onboarding_Reason_Selling_Update_Column["Value"] = "value";
})(Onboarding_Reason_Selling_Update_Column = exports.Onboarding_Reason_Selling_Update_Column || (exports.Onboarding_Reason_Selling_Update_Column = {}));
var Onboarding_Timeframe_Selling_Constraint;
(function (Onboarding_Timeframe_Selling_Constraint) {
    Onboarding_Timeframe_Selling_Constraint["OnboardingTimeframeSellingPkey"] = "onboarding_timeframe_selling_pkey";
})(Onboarding_Timeframe_Selling_Constraint = exports.Onboarding_Timeframe_Selling_Constraint || (exports.Onboarding_Timeframe_Selling_Constraint = {}));
var Onboarding_Timeframe_Selling_Enum;
(function (Onboarding_Timeframe_Selling_Enum) {
    Onboarding_Timeframe_Selling_Enum["In_3Months"] = "IN_3_MONTHS";
    Onboarding_Timeframe_Selling_Enum["In_4_6Months"] = "IN_4_6_MONTHS";
    Onboarding_Timeframe_Selling_Enum["In_4Weeks"] = "IN_4_WEEKS";
    Onboarding_Timeframe_Selling_Enum["In_6PlusMonths"] = "IN_6_PLUS_MONTHS";
})(Onboarding_Timeframe_Selling_Enum = exports.Onboarding_Timeframe_Selling_Enum || (exports.Onboarding_Timeframe_Selling_Enum = {}));
var Onboarding_Timeframe_Selling_Select_Column;
(function (Onboarding_Timeframe_Selling_Select_Column) {
    Onboarding_Timeframe_Selling_Select_Column["Description"] = "description";
    Onboarding_Timeframe_Selling_Select_Column["Value"] = "value";
})(Onboarding_Timeframe_Selling_Select_Column = exports.Onboarding_Timeframe_Selling_Select_Column || (exports.Onboarding_Timeframe_Selling_Select_Column = {}));
var Onboarding_Timeframe_Selling_Update_Column;
(function (Onboarding_Timeframe_Selling_Update_Column) {
    Onboarding_Timeframe_Selling_Update_Column["Description"] = "description";
    Onboarding_Timeframe_Selling_Update_Column["Value"] = "value";
})(Onboarding_Timeframe_Selling_Update_Column = exports.Onboarding_Timeframe_Selling_Update_Column || (exports.Onboarding_Timeframe_Selling_Update_Column = {}));
var Order_By;
(function (Order_By) {
    Order_By["Asc"] = "asc";
    Order_By["AscNullsFirst"] = "asc_nulls_first";
    Order_By["AscNullsLast"] = "asc_nulls_last";
    Order_By["Desc"] = "desc";
    Order_By["DescNullsFirst"] = "desc_nulls_first";
    Order_By["DescNullsLast"] = "desc_nulls_last";
})(Order_By = exports.Order_By || (exports.Order_By = {}));
var Order_Constraint;
(function (Order_Constraint) {
    Order_Constraint["OrderPkey"] = "order_pkey";
})(Order_Constraint = exports.Order_Constraint || (exports.Order_Constraint = {}));
var Order_Detail_Select_Column;
(function (Order_Detail_Select_Column) {
    Order_Detail_Select_Column["CreatedAt"] = "created_at";
    Order_Detail_Select_Column["CreatedFromTemplate"] = "created_from_template";
    Order_Detail_Select_Column["Email"] = "email";
    Order_Detail_Select_Column["EnquiryCode"] = "enquiry_code";
    Order_Detail_Select_Column["FirstName"] = "first_name";
    Order_Detail_Select_Column["Id"] = "id";
    Order_Detail_Select_Column["LastName"] = "last_name";
    Order_Detail_Select_Column["ListingId"] = "listing_id";
    Order_Detail_Select_Column["OrderTotal"] = "order_total";
    Order_Detail_Select_Column["OrderTotalStatic"] = "order_total_static";
    Order_Detail_Select_Column["OrderTotalWithoutDiscount"] = "order_total_without_discount";
    Order_Detail_Select_Column["OrderTotalWithoutDiscountStatic"] = "order_total_without_discount_static";
    Order_Detail_Select_Column["OwnerId"] = "owner_id";
    Order_Detail_Select_Column["ProcessedName"] = "processed_name";
    Order_Detail_Select_Column["PropertyDetailsAddressDisplayOption"] = "propertyDetails_address_displayOption";
    Order_Detail_Select_Column["PropertyDetailsAddressPostcode"] = "propertyDetails_address_postcode";
    Order_Detail_Select_Column["PropertyDetailsAddressState"] = "propertyDetails_address_state";
    Order_Detail_Select_Column["PropertyDetailsAddressStreet"] = "propertyDetails_address_street";
    Order_Detail_Select_Column["PropertyDetailsAddressStreetNumber"] = "propertyDetails_address_streetNumber";
    Order_Detail_Select_Column["PropertyDetailsAddressSuburb"] = "propertyDetails_address_suburb";
    Order_Detail_Select_Column["PropertyDetailsAddressUnitNumber"] = "propertyDetails_address_unitNumber";
    Order_Detail_Select_Column["Status"] = "status";
    Order_Detail_Select_Column["UpdatedAt"] = "updated_at";
})(Order_Detail_Select_Column = exports.Order_Detail_Select_Column || (exports.Order_Detail_Select_Column = {}));
var Order_Fulfilment_Constraint;
(function (Order_Fulfilment_Constraint) {
    Order_Fulfilment_Constraint["OrderFulfilmentAbcPhotoOrderIdKey"] = "order_fulfilment_abc_photo_order_id_key";
    Order_Fulfilment_Constraint["OrderFulfilmentPkey"] = "order_fulfilment_pkey";
})(Order_Fulfilment_Constraint = exports.Order_Fulfilment_Constraint || (exports.Order_Fulfilment_Constraint = {}));
var Order_Fulfilment_Select_Column;
(function (Order_Fulfilment_Select_Column) {
    Order_Fulfilment_Select_Column["AbcOrderId"] = "abc_order_id";
    Order_Fulfilment_Select_Column["AbcPhotoOrderId"] = "abc_photo_order_id";
    Order_Fulfilment_Select_Column["AbcPropertyId"] = "abc_property_id";
    Order_Fulfilment_Select_Column["AbcRequest"] = "abc_request";
    Order_Fulfilment_Select_Column["CreatedAt"] = "created_at";
    Order_Fulfilment_Select_Column["Id"] = "id";
    Order_Fulfilment_Select_Column["OrderId"] = "order_id";
    Order_Fulfilment_Select_Column["OrderStatus"] = "order_status";
    Order_Fulfilment_Select_Column["UpdatedAt"] = "updated_at";
})(Order_Fulfilment_Select_Column = exports.Order_Fulfilment_Select_Column || (exports.Order_Fulfilment_Select_Column = {}));
var Order_Fulfilment_Update_Column;
(function (Order_Fulfilment_Update_Column) {
    Order_Fulfilment_Update_Column["AbcOrderId"] = "abc_order_id";
    Order_Fulfilment_Update_Column["AbcPhotoOrderId"] = "abc_photo_order_id";
    Order_Fulfilment_Update_Column["AbcPropertyId"] = "abc_property_id";
    Order_Fulfilment_Update_Column["AbcRequest"] = "abc_request";
    Order_Fulfilment_Update_Column["CreatedAt"] = "created_at";
    Order_Fulfilment_Update_Column["Id"] = "id";
    Order_Fulfilment_Update_Column["OrderId"] = "order_id";
    Order_Fulfilment_Update_Column["OrderStatus"] = "order_status";
    Order_Fulfilment_Update_Column["UpdatedAt"] = "updated_at";
})(Order_Fulfilment_Update_Column = exports.Order_Fulfilment_Update_Column || (exports.Order_Fulfilment_Update_Column = {}));
var Order_Item_Constraint;
(function (Order_Item_Constraint) {
    Order_Item_Constraint["OrderItemPkey"] = "order_item_pkey";
})(Order_Item_Constraint = exports.Order_Item_Constraint || (exports.Order_Item_Constraint = {}));
var Order_Item_Select_Column;
(function (Order_Item_Select_Column) {
    Order_Item_Select_Column["AddonId"] = "addon_id";
    Order_Item_Select_Column["CampaignId"] = "campaign_id";
    Order_Item_Select_Column["CouponId"] = "coupon_id";
    Order_Item_Select_Column["CreatedAt"] = "created_at";
    Order_Item_Select_Column["FulfillmentStatus"] = "fulfillment_status";
    Order_Item_Select_Column["Id"] = "id";
    Order_Item_Select_Column["ItemQty"] = "item_qty";
    Order_Item_Select_Column["Name"] = "name";
    Order_Item_Select_Column["OrderId"] = "order_id";
    Order_Item_Select_Column["Price"] = "price";
    Order_Item_Select_Column["ProcessedById"] = "processed_by_id";
    Order_Item_Select_Column["QtyProcessedByAdmin"] = "qty_processed_by_admin";
    Order_Item_Select_Column["RefundStatus"] = "refund_status";
    Order_Item_Select_Column["Removed"] = "removed";
    Order_Item_Select_Column["SalesforceId"] = "salesforce_id";
    Order_Item_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Order_Item_Select_Column["Status"] = "status";
    Order_Item_Select_Column["Type"] = "type";
    Order_Item_Select_Column["UpdatedAt"] = "updated_at";
})(Order_Item_Select_Column = exports.Order_Item_Select_Column || (exports.Order_Item_Select_Column = {}));
var Order_Item_Type_Constraint;
(function (Order_Item_Type_Constraint) {
    Order_Item_Type_Constraint["OrderItemTypePkey"] = "order_item_type_pkey";
})(Order_Item_Type_Constraint = exports.Order_Item_Type_Constraint || (exports.Order_Item_Type_Constraint = {}));
var Order_Item_Type_Enum;
(function (Order_Item_Type_Enum) {
    Order_Item_Type_Enum["Addon"] = "ADDON";
    Order_Item_Type_Enum["Campaign"] = "CAMPAIGN";
    Order_Item_Type_Enum["Coupon"] = "COUPON";
    Order_Item_Type_Enum["PriceAdjustment"] = "PRICE_ADJUSTMENT";
    Order_Item_Type_Enum["RemovedInclusion"] = "REMOVED_INCLUSION";
})(Order_Item_Type_Enum = exports.Order_Item_Type_Enum || (exports.Order_Item_Type_Enum = {}));
var Order_Item_Type_Select_Column;
(function (Order_Item_Type_Select_Column) {
    Order_Item_Type_Select_Column["Description"] = "description";
    Order_Item_Type_Select_Column["Value"] = "value";
})(Order_Item_Type_Select_Column = exports.Order_Item_Type_Select_Column || (exports.Order_Item_Type_Select_Column = {}));
var Order_Item_Type_Update_Column;
(function (Order_Item_Type_Update_Column) {
    Order_Item_Type_Update_Column["Description"] = "description";
    Order_Item_Type_Update_Column["Value"] = "value";
})(Order_Item_Type_Update_Column = exports.Order_Item_Type_Update_Column || (exports.Order_Item_Type_Update_Column = {}));
var Order_Item_Update_Column;
(function (Order_Item_Update_Column) {
    Order_Item_Update_Column["AddonId"] = "addon_id";
    Order_Item_Update_Column["CampaignId"] = "campaign_id";
    Order_Item_Update_Column["CouponId"] = "coupon_id";
    Order_Item_Update_Column["CreatedAt"] = "created_at";
    Order_Item_Update_Column["FulfillmentStatus"] = "fulfillment_status";
    Order_Item_Update_Column["Id"] = "id";
    Order_Item_Update_Column["ItemQty"] = "item_qty";
    Order_Item_Update_Column["Name"] = "name";
    Order_Item_Update_Column["OrderId"] = "order_id";
    Order_Item_Update_Column["Price"] = "price";
    Order_Item_Update_Column["ProcessedById"] = "processed_by_id";
    Order_Item_Update_Column["QtyProcessedByAdmin"] = "qty_processed_by_admin";
    Order_Item_Update_Column["RefundStatus"] = "refund_status";
    Order_Item_Update_Column["Removed"] = "removed";
    Order_Item_Update_Column["SalesforceId"] = "salesforce_id";
    Order_Item_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    Order_Item_Update_Column["Status"] = "status";
    Order_Item_Update_Column["Type"] = "type";
    Order_Item_Update_Column["UpdatedAt"] = "updated_at";
})(Order_Item_Update_Column = exports.Order_Item_Update_Column || (exports.Order_Item_Update_Column = {}));
var Order_Select_Column;
(function (Order_Select_Column) {
    Order_Select_Column["CreatedAt"] = "created_at";
    Order_Select_Column["CreatedFromTemplate"] = "created_from_template";
    Order_Select_Column["Experiments"] = "experiments";
    Order_Select_Column["Id"] = "id";
    Order_Select_Column["ListingId"] = "listing_id";
    Order_Select_Column["OrderTotal"] = "order_total";
    Order_Select_Column["OrderTotalWithoutDiscount"] = "order_total_without_discount";
    Order_Select_Column["ProcessedById"] = "processed_by_id";
    Order_Select_Column["SalesforceId"] = "salesforce_id";
    Order_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Order_Select_Column["Status"] = "status";
    Order_Select_Column["UpdatedAt"] = "updated_at";
})(Order_Select_Column = exports.Order_Select_Column || (exports.Order_Select_Column = {}));
var Order_Status_Constraint;
(function (Order_Status_Constraint) {
    Order_Status_Constraint["OrderStatusPkey"] = "order_status_pkey";
})(Order_Status_Constraint = exports.Order_Status_Constraint || (exports.Order_Status_Constraint = {}));
var Order_Status_Enum;
(function (Order_Status_Enum) {
    Order_Status_Enum["CancellationPending"] = "Cancellation_Pending";
    Order_Status_Enum["Cancelled"] = "Cancelled";
    Order_Status_Enum["Open"] = "Open";
    Order_Status_Enum["Paid"] = "Paid";
    Order_Status_Enum["Unpaid"] = "Unpaid";
})(Order_Status_Enum = exports.Order_Status_Enum || (exports.Order_Status_Enum = {}));
var Order_Status_Select_Column;
(function (Order_Status_Select_Column) {
    Order_Status_Select_Column["Description"] = "description";
    Order_Status_Select_Column["Value"] = "value";
})(Order_Status_Select_Column = exports.Order_Status_Select_Column || (exports.Order_Status_Select_Column = {}));
var Order_Status_Update_Column;
(function (Order_Status_Update_Column) {
    Order_Status_Update_Column["Description"] = "description";
    Order_Status_Update_Column["Value"] = "value";
})(Order_Status_Update_Column = exports.Order_Status_Update_Column || (exports.Order_Status_Update_Column = {}));
var Order_Update_Column;
(function (Order_Update_Column) {
    Order_Update_Column["CreatedAt"] = "created_at";
    Order_Update_Column["CreatedFromTemplate"] = "created_from_template";
    Order_Update_Column["Experiments"] = "experiments";
    Order_Update_Column["Id"] = "id";
    Order_Update_Column["ListingId"] = "listing_id";
    Order_Update_Column["OrderTotal"] = "order_total";
    Order_Update_Column["OrderTotalWithoutDiscount"] = "order_total_without_discount";
    Order_Update_Column["ProcessedById"] = "processed_by_id";
    Order_Update_Column["SalesforceId"] = "salesforce_id";
    Order_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    Order_Update_Column["Status"] = "status";
    Order_Update_Column["UpdatedAt"] = "updated_at";
})(Order_Update_Column = exports.Order_Update_Column || (exports.Order_Update_Column = {}));
var Publishing_Status_Type_Constraint;
(function (Publishing_Status_Type_Constraint) {
    Publishing_Status_Type_Constraint["PublishingStatusTypePkey"] = "publishing_status_type_pkey";
})(Publishing_Status_Type_Constraint = exports.Publishing_Status_Type_Constraint || (exports.Publishing_Status_Type_Constraint = {}));
var Publishing_Status_Type_Enum;
(function (Publishing_Status_Type_Enum) {
    Publishing_Status_Type_Enum["Draft"] = "DRAFT";
    Publishing_Status_Type_Enum["FailedPublishing"] = "FAILED_PUBLISHING";
    Publishing_Status_Type_Enum["PendingPublishing"] = "PENDING_PUBLISHING";
    Publishing_Status_Type_Enum["Published"] = "PUBLISHED";
    Publishing_Status_Type_Enum["UnderReview"] = "UNDER_REVIEW";
})(Publishing_Status_Type_Enum = exports.Publishing_Status_Type_Enum || (exports.Publishing_Status_Type_Enum = {}));
var Publishing_Status_Type_Select_Column;
(function (Publishing_Status_Type_Select_Column) {
    Publishing_Status_Type_Select_Column["Description"] = "description";
    Publishing_Status_Type_Select_Column["Value"] = "value";
})(Publishing_Status_Type_Select_Column = exports.Publishing_Status_Type_Select_Column || (exports.Publishing_Status_Type_Select_Column = {}));
var Publishing_Status_Type_Update_Column;
(function (Publishing_Status_Type_Update_Column) {
    Publishing_Status_Type_Update_Column["Description"] = "description";
    Publishing_Status_Type_Update_Column["Value"] = "value";
})(Publishing_Status_Type_Update_Column = exports.Publishing_Status_Type_Update_Column || (exports.Publishing_Status_Type_Update_Column = {}));
var Raw_Bordering_Postcode_Constraint;
(function (Raw_Bordering_Postcode_Constraint) {
    Raw_Bordering_Postcode_Constraint["RawBorderingPostcodePkey"] = "raw_bordering_postcode_pkey";
})(Raw_Bordering_Postcode_Constraint = exports.Raw_Bordering_Postcode_Constraint || (exports.Raw_Bordering_Postcode_Constraint = {}));
var Raw_Bordering_Postcode_Select_Column;
(function (Raw_Bordering_Postcode_Select_Column) {
    Raw_Bordering_Postcode_Select_Column["BorderLocality"] = "Border_Locality";
    Raw_Bordering_Postcode_Select_Column["BorderPcode"] = "Border_Pcode";
    Raw_Bordering_Postcode_Select_Column["BorderState"] = "Border_State";
    Raw_Bordering_Postcode_Select_Column["Category"] = "Category";
    Raw_Bordering_Postcode_Select_Column["Comments"] = "Comments";
    Raw_Bordering_Postcode_Select_Column["Locality"] = "Locality";
    Raw_Bordering_Postcode_Select_Column["Pcode"] = "Pcode";
    Raw_Bordering_Postcode_Select_Column["State"] = "State";
    Raw_Bordering_Postcode_Select_Column["Id"] = "id";
})(Raw_Bordering_Postcode_Select_Column = exports.Raw_Bordering_Postcode_Select_Column || (exports.Raw_Bordering_Postcode_Select_Column = {}));
var Raw_Bordering_Postcode_Update_Column;
(function (Raw_Bordering_Postcode_Update_Column) {
    Raw_Bordering_Postcode_Update_Column["BorderLocality"] = "Border_Locality";
    Raw_Bordering_Postcode_Update_Column["BorderPcode"] = "Border_Pcode";
    Raw_Bordering_Postcode_Update_Column["BorderState"] = "Border_State";
    Raw_Bordering_Postcode_Update_Column["Category"] = "Category";
    Raw_Bordering_Postcode_Update_Column["Comments"] = "Comments";
    Raw_Bordering_Postcode_Update_Column["Locality"] = "Locality";
    Raw_Bordering_Postcode_Update_Column["Pcode"] = "Pcode";
    Raw_Bordering_Postcode_Update_Column["State"] = "State";
    Raw_Bordering_Postcode_Update_Column["Id"] = "id";
})(Raw_Bordering_Postcode_Update_Column = exports.Raw_Bordering_Postcode_Update_Column || (exports.Raw_Bordering_Postcode_Update_Column = {}));
var Rea_Price_Constraint;
(function (Rea_Price_Constraint) {
    Rea_Price_Constraint["ReaPricePkey"] = "rea_price_pkey";
})(Rea_Price_Constraint = exports.Rea_Price_Constraint || (exports.Rea_Price_Constraint = {}));
var Rea_Price_Select_Column;
(function (Rea_Price_Select_Column) {
    Rea_Price_Select_Column["Key"] = "key";
    Rea_Price_Select_Column["Postcode"] = "postcode";
    Rea_Price_Select_Column["Prices"] = "prices";
    Rea_Price_Select_Column["Response"] = "response";
    Rea_Price_Select_Column["State"] = "state";
    Rea_Price_Select_Column["Suburb"] = "suburb";
    Rea_Price_Select_Column["UpdatedAt"] = "updated_at";
})(Rea_Price_Select_Column = exports.Rea_Price_Select_Column || (exports.Rea_Price_Select_Column = {}));
var Rea_Price_Update_Column;
(function (Rea_Price_Update_Column) {
    Rea_Price_Update_Column["Key"] = "key";
    Rea_Price_Update_Column["Postcode"] = "postcode";
    Rea_Price_Update_Column["Prices"] = "prices";
    Rea_Price_Update_Column["Response"] = "response";
    Rea_Price_Update_Column["State"] = "state";
    Rea_Price_Update_Column["Suburb"] = "suburb";
    Rea_Price_Update_Column["UpdatedAt"] = "updated_at";
})(Rea_Price_Update_Column = exports.Rea_Price_Update_Column || (exports.Rea_Price_Update_Column = {}));
var Salesforce_Bulksync_Records_Constraint;
(function (Salesforce_Bulksync_Records_Constraint) {
    Salesforce_Bulksync_Records_Constraint["SalesforceBulksyncRecordsOperationIdKey"] = "salesforce_bulksync_records_operation_id_key";
    Salesforce_Bulksync_Records_Constraint["SalesforceBulksyncRecordsPkey"] = "salesforce_bulksync_records_pkey";
})(Salesforce_Bulksync_Records_Constraint = exports.Salesforce_Bulksync_Records_Constraint || (exports.Salesforce_Bulksync_Records_Constraint = {}));
var Salesforce_Bulksync_Records_Select_Column;
(function (Salesforce_Bulksync_Records_Select_Column) {
    Salesforce_Bulksync_Records_Select_Column["CreatedAt"] = "created_at";
    Salesforce_Bulksync_Records_Select_Column["HasuraId"] = "hasura_id";
    Salesforce_Bulksync_Records_Select_Column["HasuraObjNew"] = "hasura_obj_new";
    Salesforce_Bulksync_Records_Select_Column["HasuraObjOld"] = "hasura_obj_old";
    Salesforce_Bulksync_Records_Select_Column["HasuraTableName"] = "hasura_table_name";
    Salesforce_Bulksync_Records_Select_Column["Operation"] = "operation";
    Salesforce_Bulksync_Records_Select_Column["OperationId"] = "operation_id";
    Salesforce_Bulksync_Records_Select_Column["Retries"] = "retries";
    Salesforce_Bulksync_Records_Select_Column["SalesforceId"] = "salesforce_id";
    Salesforce_Bulksync_Records_Select_Column["Status"] = "status";
    Salesforce_Bulksync_Records_Select_Column["SyncError"] = "sync_error";
    Salesforce_Bulksync_Records_Select_Column["UpdatedAt"] = "updated_at";
})(Salesforce_Bulksync_Records_Select_Column = exports.Salesforce_Bulksync_Records_Select_Column || (exports.Salesforce_Bulksync_Records_Select_Column = {}));
var Salesforce_Bulksync_Records_Update_Column;
(function (Salesforce_Bulksync_Records_Update_Column) {
    Salesforce_Bulksync_Records_Update_Column["CreatedAt"] = "created_at";
    Salesforce_Bulksync_Records_Update_Column["HasuraId"] = "hasura_id";
    Salesforce_Bulksync_Records_Update_Column["HasuraObjNew"] = "hasura_obj_new";
    Salesforce_Bulksync_Records_Update_Column["HasuraObjOld"] = "hasura_obj_old";
    Salesforce_Bulksync_Records_Update_Column["HasuraTableName"] = "hasura_table_name";
    Salesforce_Bulksync_Records_Update_Column["Operation"] = "operation";
    Salesforce_Bulksync_Records_Update_Column["OperationId"] = "operation_id";
    Salesforce_Bulksync_Records_Update_Column["Retries"] = "retries";
    Salesforce_Bulksync_Records_Update_Column["SalesforceId"] = "salesforce_id";
    Salesforce_Bulksync_Records_Update_Column["Status"] = "status";
    Salesforce_Bulksync_Records_Update_Column["SyncError"] = "sync_error";
    Salesforce_Bulksync_Records_Update_Column["UpdatedAt"] = "updated_at";
})(Salesforce_Bulksync_Records_Update_Column = exports.Salesforce_Bulksync_Records_Update_Column || (exports.Salesforce_Bulksync_Records_Update_Column = {}));
var Salesforce_Connection_Token_Constraint;
(function (Salesforce_Connection_Token_Constraint) {
    Salesforce_Connection_Token_Constraint["SalesforceConnectionTokenInstanceUrlKey"] = "salesforce_connection_token_instance_url_key";
    Salesforce_Connection_Token_Constraint["SalesforceConnectionTokenPkey"] = "salesforce_connection_token_pkey";
    Salesforce_Connection_Token_Constraint["SalesforceConnectionTokenRefreshTokenKey"] = "salesforce_connection_token_refresh_token_key";
})(Salesforce_Connection_Token_Constraint = exports.Salesforce_Connection_Token_Constraint || (exports.Salesforce_Connection_Token_Constraint = {}));
var Salesforce_Connection_Token_Select_Column;
(function (Salesforce_Connection_Token_Select_Column) {
    Salesforce_Connection_Token_Select_Column["AccessToken"] = "access_token";
    Salesforce_Connection_Token_Select_Column["InstanceUrl"] = "instance_url";
    Salesforce_Connection_Token_Select_Column["LastRanTime"] = "last_ran_time";
    Salesforce_Connection_Token_Select_Column["RefreshToken"] = "refresh_token";
    Salesforce_Connection_Token_Select_Column["StartTime"] = "start_time";
})(Salesforce_Connection_Token_Select_Column = exports.Salesforce_Connection_Token_Select_Column || (exports.Salesforce_Connection_Token_Select_Column = {}));
var Salesforce_Connection_Token_Update_Column;
(function (Salesforce_Connection_Token_Update_Column) {
    Salesforce_Connection_Token_Update_Column["AccessToken"] = "access_token";
    Salesforce_Connection_Token_Update_Column["InstanceUrl"] = "instance_url";
    Salesforce_Connection_Token_Update_Column["LastRanTime"] = "last_ran_time";
    Salesforce_Connection_Token_Update_Column["RefreshToken"] = "refresh_token";
    Salesforce_Connection_Token_Update_Column["StartTime"] = "start_time";
})(Salesforce_Connection_Token_Update_Column = exports.Salesforce_Connection_Token_Update_Column || (exports.Salesforce_Connection_Token_Update_Column = {}));
var Salesforce_Sync_Status_Constraint;
(function (Salesforce_Sync_Status_Constraint) {
    Salesforce_Sync_Status_Constraint["SalesforceSyncStatusPkey"] = "salesforce_sync_status_pkey";
})(Salesforce_Sync_Status_Constraint = exports.Salesforce_Sync_Status_Constraint || (exports.Salesforce_Sync_Status_Constraint = {}));
var Salesforce_Sync_Status_Enum;
(function (Salesforce_Sync_Status_Enum) {
    Salesforce_Sync_Status_Enum["Failed"] = "FAILED";
    Salesforce_Sync_Status_Enum["InProcess"] = "IN_PROCESS";
    Salesforce_Sync_Status_Enum["Queued"] = "QUEUED";
    Salesforce_Sync_Status_Enum["Synced"] = "SYNCED";
})(Salesforce_Sync_Status_Enum = exports.Salesforce_Sync_Status_Enum || (exports.Salesforce_Sync_Status_Enum = {}));
var Salesforce_Sync_Status_Select_Column;
(function (Salesforce_Sync_Status_Select_Column) {
    Salesforce_Sync_Status_Select_Column["Description"] = "description";
    Salesforce_Sync_Status_Select_Column["Value"] = "value";
})(Salesforce_Sync_Status_Select_Column = exports.Salesforce_Sync_Status_Select_Column || (exports.Salesforce_Sync_Status_Select_Column = {}));
var Salesforce_Sync_Status_Update_Column;
(function (Salesforce_Sync_Status_Update_Column) {
    Salesforce_Sync_Status_Update_Column["Description"] = "description";
    Salesforce_Sync_Status_Update_Column["Value"] = "value";
})(Salesforce_Sync_Status_Update_Column = exports.Salesforce_Sync_Status_Update_Column || (exports.Salesforce_Sync_Status_Update_Column = {}));
var Selling_Point_Constraint;
(function (Selling_Point_Constraint) {
    Selling_Point_Constraint["SellingPointPkey"] = "selling_point_pkey";
})(Selling_Point_Constraint = exports.Selling_Point_Constraint || (exports.Selling_Point_Constraint = {}));
var Selling_Point_Select_Column;
(function (Selling_Point_Select_Column) {
    Selling_Point_Select_Column["CreatedAt"] = "created_at";
    Selling_Point_Select_Column["Description"] = "description";
    Selling_Point_Select_Column["Id"] = "id";
    Selling_Point_Select_Column["Image"] = "image";
    Selling_Point_Select_Column["Name"] = "name";
    Selling_Point_Select_Column["Order"] = "order";
    Selling_Point_Select_Column["UpdatedAt"] = "updated_at";
})(Selling_Point_Select_Column = exports.Selling_Point_Select_Column || (exports.Selling_Point_Select_Column = {}));
var Selling_Point_Update_Column;
(function (Selling_Point_Update_Column) {
    Selling_Point_Update_Column["CreatedAt"] = "created_at";
    Selling_Point_Update_Column["Description"] = "description";
    Selling_Point_Update_Column["Id"] = "id";
    Selling_Point_Update_Column["Image"] = "image";
    Selling_Point_Update_Column["Name"] = "name";
    Selling_Point_Update_Column["Order"] = "order";
    Selling_Point_Update_Column["UpdatedAt"] = "updated_at";
})(Selling_Point_Update_Column = exports.Selling_Point_Update_Column || (exports.Selling_Point_Update_Column = {}));
var Stock_Photo_Constraint;
(function (Stock_Photo_Constraint) {
    Stock_Photo_Constraint["StockPhotoPkey"] = "stock_photo_pkey";
})(Stock_Photo_Constraint = exports.Stock_Photo_Constraint || (exports.Stock_Photo_Constraint = {}));
var Stock_Photo_Select_Column;
(function (Stock_Photo_Select_Column) {
    Stock_Photo_Select_Column["CreatedAt"] = "created_at";
    Stock_Photo_Select_Column["Id"] = "id";
    Stock_Photo_Select_Column["Thumbnail_1212"] = "thumbnail_1212";
    Stock_Photo_Select_Column["Thumbnail_1818"] = "thumbnail_1818";
    Stock_Photo_Select_Column["Thumbnail_185"] = "thumbnail_185";
    Stock_Photo_Select_Column["Thumbnail_284"] = "thumbnail_284";
    Stock_Photo_Select_Column["Thumbnail_370"] = "thumbnail_370";
    Stock_Photo_Select_Column["Thumbnail_555"] = "thumbnail_555";
    Stock_Photo_Select_Column["Thumbnail_568"] = "thumbnail_568";
    Stock_Photo_Select_Column["Thumbnail_606"] = "thumbnail_606";
    Stock_Photo_Select_Column["Thumbnail_852"] = "thumbnail_852";
    Stock_Photo_Select_Column["Url"] = "url";
})(Stock_Photo_Select_Column = exports.Stock_Photo_Select_Column || (exports.Stock_Photo_Select_Column = {}));
var Stock_Photo_Update_Column;
(function (Stock_Photo_Update_Column) {
    Stock_Photo_Update_Column["CreatedAt"] = "created_at";
    Stock_Photo_Update_Column["Id"] = "id";
    Stock_Photo_Update_Column["Thumbnail_1212"] = "thumbnail_1212";
    Stock_Photo_Update_Column["Thumbnail_1818"] = "thumbnail_1818";
    Stock_Photo_Update_Column["Thumbnail_185"] = "thumbnail_185";
    Stock_Photo_Update_Column["Thumbnail_284"] = "thumbnail_284";
    Stock_Photo_Update_Column["Thumbnail_370"] = "thumbnail_370";
    Stock_Photo_Update_Column["Thumbnail_555"] = "thumbnail_555";
    Stock_Photo_Update_Column["Thumbnail_568"] = "thumbnail_568";
    Stock_Photo_Update_Column["Thumbnail_606"] = "thumbnail_606";
    Stock_Photo_Update_Column["Thumbnail_852"] = "thumbnail_852";
    Stock_Photo_Update_Column["Url"] = "url";
})(Stock_Photo_Update_Column = exports.Stock_Photo_Update_Column || (exports.Stock_Photo_Update_Column = {}));
var Surrounding_Suburb_Constraint;
(function (Surrounding_Suburb_Constraint) {
    Surrounding_Suburb_Constraint["SurroundingSuburbPkey"] = "surrounding_suburb_pkey";
})(Surrounding_Suburb_Constraint = exports.Surrounding_Suburb_Constraint || (exports.Surrounding_Suburb_Constraint = {}));
var Surrounding_Suburb_Listing_Select_Column;
(function (Surrounding_Suburb_Listing_Select_Column) {
    Surrounding_Suburb_Listing_Select_Column["AgentPointId"] = "agent_point_id";
    Surrounding_Suburb_Listing_Select_Column["AuctionDateTime"] = "auction_dateTime";
    Surrounding_Suburb_Listing_Select_Column["AuctionLocation"] = "auction_location";
    Surrounding_Suburb_Listing_Select_Column["AuctionOnline"] = "auction_online";
    Surrounding_Suburb_Listing_Select_Column["Authority"] = "authority";
    Surrounding_Suburb_Listing_Select_Column["AvailableFrom"] = "availableFrom";
    Surrounding_Suburb_Listing_Select_Column["Bond"] = "bond";
    Surrounding_Suburb_Listing_Select_Column["CommercialStatus"] = "commercial_status";
    Surrounding_Suburb_Listing_Select_Column["ContactPreference"] = "contactPreference";
    Surrounding_Suburb_Listing_Select_Column["CreatedAt"] = "created_at";
    Surrounding_Suburb_Listing_Select_Column["CrossOver"] = "crossOver";
    Surrounding_Suburb_Listing_Select_Column["Description"] = "description";
    Surrounding_Suburb_Listing_Select_Column["Distance"] = "distance";
    Surrounding_Suburb_Listing_Select_Column["DomainAdId"] = "domainAdId";
    Surrounding_Suburb_Listing_Select_Column["EnquiryCode"] = "enquiry_code";
    Surrounding_Suburb_Listing_Select_Column["ExternalLink"] = "externalLink";
    Surrounding_Suburb_Listing_Select_Column["Features"] = "features";
    Surrounding_Suburb_Listing_Select_Column["Id"] = "id";
    Surrounding_Suburb_Listing_Select_Column["InspectionDetailsInspectionDescription"] = "inspectionDetails_inspectionDescription";
    Surrounding_Suburb_Listing_Select_Column["IsNewDevelopment"] = "isNewDevelopment";
    Surrounding_Suburb_Listing_Select_Column["IsRural"] = "isRural";
    Surrounding_Suburb_Listing_Select_Column["LifeStyleType"] = "lifeStyleType";
    Surrounding_Suburb_Listing_Select_Column["ListingAction"] = "listingAction";
    Surrounding_Suburb_Listing_Select_Column["MediaUrl"] = "media_url";
    Surrounding_Suburb_Listing_Select_Column["OtherEnquiryEmail"] = "otherEnquiryEmail";
    Surrounding_Suburb_Listing_Select_Column["OwnerId"] = "owner_id";
    Surrounding_Suburb_Listing_Select_Column["PriceAsking"] = "price_asking";
    Surrounding_Suburb_Listing_Select_Column["PriceDisplayOption"] = "price_displayOption";
    Surrounding_Suburb_Listing_Select_Column["PriceDisplayText"] = "price_displayText";
    Surrounding_Suburb_Listing_Select_Column["PriceFrom"] = "price_from";
    Surrounding_Suburb_Listing_Select_Column["PriceSingle"] = "price_single";
    Surrounding_Suburb_Listing_Select_Column["PriceTextOption"] = "price_textOption";
    Surrounding_Suburb_Listing_Select_Column["PriceTo"] = "price_to";
    Surrounding_Suburb_Listing_Select_Column["PriceView"] = "price_view";
    Surrounding_Suburb_Listing_Select_Column["ProofOfOwnershipStatus"] = "proof_of_ownership_status";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddress_1"] = "propertyDetails_address_1";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddress_2"] = "propertyDetails_address_2";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressDisplayOption"] = "propertyDetails_address_displayOption";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressMunicipality"] = "propertyDetails_address_municipality";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressPostcode"] = "propertyDetails_address_postcode";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressPostcodeBeforeCheckout"] = "propertyDetails_address_postcode_before_checkout";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressState"] = "propertyDetails_address_state";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressStateBeforeCheckout"] = "propertyDetails_address_state_before_checkout";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressStreet"] = "propertyDetails_address_street";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressStreetDirectoryPage"] = "propertyDetails_address_streetDirectory_page";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressStreetDirectoryReference"] = "propertyDetails_address_streetDirectory_reference";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressStreetDirectoryType"] = "propertyDetails_address_streetDirectory_type";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressStreetNumber"] = "propertyDetails_address_streetNumber";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressSuburb"] = "propertyDetails_address_suburb";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressSuburbBeforeCheckout"] = "propertyDetails_address_suburb_before_checkout";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocation"] = "propertyDetails_address_suggestedGeoLocation";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocationLatitude"] = "propertyDetails_address_suggestedGeoLocation_latitude";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressSuggestedGeoLocationLongitude"] = "propertyDetails_address_suggestedGeoLocation_longitude";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAddressUnitNumber"] = "propertyDetails_address_unitNumber";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAreaFrom"] = "propertyDetails_area_from";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAreaTo"] = "propertyDetails_area_to";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAreaUnit"] = "propertyDetails_area_unit";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsAreaValue"] = "propertyDetails_area_value";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsBathRooms"] = "propertyDetails_bathRooms";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsBedRooms"] = "propertyDetails_bedRooms";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsCarSpaces"] = "propertyDetails_carSpaces";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsCarportSpaces"] = "propertyDetails_carportSpaces";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsEnergyEfficiencyRating"] = "propertyDetails_energyEfficiencyRating";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsGarageSpaces"] = "propertyDetails_garageSpaces";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaDepthLeft"] = "propertyDetails_landArea_depth_left";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaDepthRear"] = "propertyDetails_landArea_depth_rear";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaDepthRight"] = "propertyDetails_landArea_depth_right";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaDepthUnit"] = "propertyDetails_landArea_depth_unit";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaFrom"] = "propertyDetails_landArea_from";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaFrontage"] = "propertyDetails_landArea_frontage";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaFrontageUnit"] = "propertyDetails_landArea_frontage_unit";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaTo"] = "propertyDetails_landArea_to";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaUnit"] = "propertyDetails_landArea_unit";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsLandAreaValue"] = "propertyDetails_landArea_value";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsParkingInfoInformation"] = "propertyDetails_parkingInfo_information";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsPropertyType"] = "propertyDetails_propertyType";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsPropertyTypeHotWaterServiceType"] = "propertyDetails_propertyType_hotWaterServiceType";
    Surrounding_Suburb_Listing_Select_Column["PropertyDetailsYearBuilt"] = "propertyDetails_yearBuilt";
    Surrounding_Suburb_Listing_Select_Column["ProviderAdId"] = "providerAdId";
    Surrounding_Suburb_Listing_Select_Column["PublishingStatus"] = "publishing_status";
    Surrounding_Suburb_Listing_Select_Column["SaleType"] = "saleType";
    Surrounding_Suburb_Listing_Select_Column["SalesforceId"] = "salesforce_id";
    Surrounding_Suburb_Listing_Select_Column["SearchPrice"] = "search_price";
    Surrounding_Suburb_Listing_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    Surrounding_Suburb_Listing_Select_Column["SoiComparableData"] = "soi_comparableData";
    Surrounding_Suburb_Listing_Select_Column["SoiComparableDataComparablePropertyDateOf"] = "soi_comparableData_comparableProperty_dateOf";
    Surrounding_Suburb_Listing_Select_Column["SoiComparableDataComparablePropertySoldPr"] = "soi_comparableData_comparableProperty_soldPr";
    Surrounding_Suburb_Listing_Select_Column["SoiComparableDataDeclarationText"] = "soi_comparableData_declarationText";
    Surrounding_Suburb_Listing_Select_Column["SoiDocumentationUrl"] = "soi_documentationUrl";
    Surrounding_Suburb_Listing_Select_Column["SoiEstimatedPrice"] = "soi_estimatedPrice";
    Surrounding_Suburb_Listing_Select_Column["SoiEstimatedPriceFrom"] = "soi_estimatedPrice_from";
    Surrounding_Suburb_Listing_Select_Column["SoiEstimatedPriceTo"] = "soi_estimatedPrice_to";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPrice"] = "soi_suburbMedianPrice";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPriceDeclarationText"] = "soi_suburbMedianPrice_declarationText";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPriceMedianPrice"] = "soi_suburbMedianPrice_medianPrice";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPricePostcode"] = "soi_suburbMedianPrice_postcode";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPricePriceType"] = "soi_suburbMedianPrice_priceType";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPriceSource"] = "soi_suburbMedianPrice_source";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPriceSourceDateFrom"] = "soi_suburbMedianPrice_sourceDateFrom";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPriceSourceDateTo"] = "soi_suburbMedianPrice_sourceDateTo";
    Surrounding_Suburb_Listing_Select_Column["SoiSuburbMedianPriceSuburb"] = "soi_suburbMedianPrice_suburb";
    Surrounding_Suburb_Listing_Select_Column["Status"] = "status";
    Surrounding_Suburb_Listing_Select_Column["Summary"] = "summary";
    Surrounding_Suburb_Listing_Select_Column["SupplementaryDescription"] = "supplementary_description";
    Surrounding_Suburb_Listing_Select_Column["SupplementaryMetadataDescription"] = "supplementary_metadata_description";
    Surrounding_Suburb_Listing_Select_Column["SupplementaryMetadataMeasurement"] = "supplementary_metadata_measurement";
    Surrounding_Suburb_Listing_Select_Column["SupplementaryMetadataMeasurementUnit"] = "supplementary_metadata_measurementUnit";
    Surrounding_Suburb_Listing_Select_Column["SupplementaryMetadataName"] = "supplementary_metadata_name";
    Surrounding_Suburb_Listing_Select_Column["SupplementaryName"] = "supplementary_name";
    Surrounding_Suburb_Listing_Select_Column["SupplementaryTypes"] = "supplementary_types";
    Surrounding_Suburb_Listing_Select_Column["TemporaryAddOnFlag"] = "temporary_add_on_flag";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_1212"] = "thumbnail_1212";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_1818"] = "thumbnail_1818";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_185"] = "thumbnail_185";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_284"] = "thumbnail_284";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_370"] = "thumbnail_370";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_555"] = "thumbnail_555";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_568"] = "thumbnail_568";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_606"] = "thumbnail_606";
    Surrounding_Suburb_Listing_Select_Column["Thumbnail_852"] = "thumbnail_852";
    Surrounding_Suburb_Listing_Select_Column["UpdatedAt"] = "updated_at";
    Surrounding_Suburb_Listing_Select_Column["Zone"] = "zone";
})(Surrounding_Suburb_Listing_Select_Column = exports.Surrounding_Suburb_Listing_Select_Column || (exports.Surrounding_Suburb_Listing_Select_Column = {}));
var Surrounding_Suburb_Select_Column;
(function (Surrounding_Suburb_Select_Column) {
    Surrounding_Suburb_Select_Column["CreatedAt"] = "created_at";
    Surrounding_Suburb_Select_Column["Distance"] = "distance";
    Surrounding_Suburb_Select_Column["EndLocality"] = "endLocality";
    Surrounding_Suburb_Select_Column["EndPCode"] = "endPCode";
    Surrounding_Suburb_Select_Column["EndSuburbName"] = "endSuburbName";
    Surrounding_Suburb_Select_Column["Id"] = "id";
    Surrounding_Suburb_Select_Column["StartLocality"] = "startLocality";
    Surrounding_Suburb_Select_Column["StartPCode"] = "startPCode";
    Surrounding_Suburb_Select_Column["StartSuburbName"] = "startSuburbName";
    Surrounding_Suburb_Select_Column["UpdatedAt"] = "updated_at";
})(Surrounding_Suburb_Select_Column = exports.Surrounding_Suburb_Select_Column || (exports.Surrounding_Suburb_Select_Column = {}));
var Surrounding_Suburb_Update_Column;
(function (Surrounding_Suburb_Update_Column) {
    Surrounding_Suburb_Update_Column["CreatedAt"] = "created_at";
    Surrounding_Suburb_Update_Column["Distance"] = "distance";
    Surrounding_Suburb_Update_Column["EndLocality"] = "endLocality";
    Surrounding_Suburb_Update_Column["EndPCode"] = "endPCode";
    Surrounding_Suburb_Update_Column["EndSuburbName"] = "endSuburbName";
    Surrounding_Suburb_Update_Column["Id"] = "id";
    Surrounding_Suburb_Update_Column["StartLocality"] = "startLocality";
    Surrounding_Suburb_Update_Column["StartPCode"] = "startPCode";
    Surrounding_Suburb_Update_Column["StartSuburbName"] = "startSuburbName";
    Surrounding_Suburb_Update_Column["UpdatedAt"] = "updated_at";
})(Surrounding_Suburb_Update_Column = exports.Surrounding_Suburb_Update_Column || (exports.Surrounding_Suburb_Update_Column = {}));
var Transaction_Constraint;
(function (Transaction_Constraint) {
    Transaction_Constraint["TransactionOriginIdOriginTypeKey"] = "transaction_origin_id_origin_type_key";
    Transaction_Constraint["TransactionPkey"] = "transaction_pkey";
})(Transaction_Constraint = exports.Transaction_Constraint || (exports.Transaction_Constraint = {}));
var Transaction_Select_Column;
(function (Transaction_Select_Column) {
    Transaction_Select_Column["Amount"] = "amount";
    Transaction_Select_Column["CreatedAt"] = "created_at";
    Transaction_Select_Column["Id"] = "id";
    Transaction_Select_Column["Json"] = "json";
    Transaction_Select_Column["OrderId"] = "order_id";
    Transaction_Select_Column["OriginId"] = "origin_id";
    Transaction_Select_Column["OriginStatus"] = "origin_status";
    Transaction_Select_Column["OriginType"] = "origin_type";
    Transaction_Select_Column["PaymentUrl"] = "payment_url";
    Transaction_Select_Column["Status"] = "status";
    Transaction_Select_Column["UpdatedAt"] = "updated_at";
})(Transaction_Select_Column = exports.Transaction_Select_Column || (exports.Transaction_Select_Column = {}));
var Transaction_Status_Constraint;
(function (Transaction_Status_Constraint) {
    Transaction_Status_Constraint["TransactionStatusPkey"] = "transaction_status_pkey";
})(Transaction_Status_Constraint = exports.Transaction_Status_Constraint || (exports.Transaction_Status_Constraint = {}));
var Transaction_Status_Enum;
(function (Transaction_Status_Enum) {
    Transaction_Status_Enum["Approved"] = "Approved";
    Transaction_Status_Enum["Cancelled"] = "Cancelled";
    Transaction_Status_Enum["Created"] = "Created";
    Transaction_Status_Enum["Paid"] = "Paid";
})(Transaction_Status_Enum = exports.Transaction_Status_Enum || (exports.Transaction_Status_Enum = {}));
var Transaction_Status_Select_Column;
(function (Transaction_Status_Select_Column) {
    Transaction_Status_Select_Column["Description"] = "description";
    Transaction_Status_Select_Column["Value"] = "value";
})(Transaction_Status_Select_Column = exports.Transaction_Status_Select_Column || (exports.Transaction_Status_Select_Column = {}));
var Transaction_Status_Update_Column;
(function (Transaction_Status_Update_Column) {
    Transaction_Status_Update_Column["Description"] = "description";
    Transaction_Status_Update_Column["Value"] = "value";
})(Transaction_Status_Update_Column = exports.Transaction_Status_Update_Column || (exports.Transaction_Status_Update_Column = {}));
var Transaction_Update_Column;
(function (Transaction_Update_Column) {
    Transaction_Update_Column["Amount"] = "amount";
    Transaction_Update_Column["CreatedAt"] = "created_at";
    Transaction_Update_Column["Id"] = "id";
    Transaction_Update_Column["Json"] = "json";
    Transaction_Update_Column["OrderId"] = "order_id";
    Transaction_Update_Column["OriginId"] = "origin_id";
    Transaction_Update_Column["OriginStatus"] = "origin_status";
    Transaction_Update_Column["OriginType"] = "origin_type";
    Transaction_Update_Column["PaymentUrl"] = "payment_url";
    Transaction_Update_Column["Status"] = "status";
    Transaction_Update_Column["UpdatedAt"] = "updated_at";
})(Transaction_Update_Column = exports.Transaction_Update_Column || (exports.Transaction_Update_Column = {}));
var User_Constraint;
(function (User_Constraint) {
    User_Constraint["UserAgentPointIdKey"] = "user_agent_point_id_key";
    User_Constraint["UserEmailKey"] = "user_email_key";
    User_Constraint["UserHashKey"] = "user_hash_key";
    User_Constraint["UserIdKey"] = "user_id_key";
    User_Constraint["UserPkey"] = "user_pkey";
    User_Constraint["UserUniqueNumberKey"] = "user_unique_number_key";
})(User_Constraint = exports.User_Constraint || (exports.User_Constraint = {}));
var User_Detail_Select_Column;
(function (User_Detail_Select_Column) {
    User_Detail_Select_Column["AgentPointId"] = "agent_point_id";
    User_Detail_Select_Column["AllowedRoles"] = "allowed_roles";
    User_Detail_Select_Column["CodeTimestamp"] = "code_timestamp";
    User_Detail_Select_Column["CreatedAt"] = "created_at";
    User_Detail_Select_Column["DefaultRole"] = "default_role";
    User_Detail_Select_Column["Email"] = "email";
    User_Detail_Select_Column["EmailVerified"] = "email_verified";
    User_Detail_Select_Column["FirstName"] = "first_name";
    User_Detail_Select_Column["FullName"] = "full_name";
    User_Detail_Select_Column["Hash"] = "hash";
    User_Detail_Select_Column["Id"] = "id";
    User_Detail_Select_Column["LastName"] = "last_name";
    User_Detail_Select_Column["MiddleName"] = "middle_name";
    User_Detail_Select_Column["OnboardingEmailSentOn"] = "onboarding_email_sent_on";
    User_Detail_Select_Column["Password"] = "password";
    User_Detail_Select_Column["PhoneNumber"] = "phone_number";
    User_Detail_Select_Column["PhoneNumberVerified"] = "phone_number_verified";
    User_Detail_Select_Column["SalesforceId"] = "salesforce_id";
    User_Detail_Select_Column["SendWelcomeEmail"] = "send_welcome_email";
    User_Detail_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    User_Detail_Select_Column["UpdatedAt"] = "updated_at";
    User_Detail_Select_Column["Verified"] = "verified";
})(User_Detail_Select_Column = exports.User_Detail_Select_Column || (exports.User_Detail_Select_Column = {}));
var User_Select_Column;
(function (User_Select_Column) {
    User_Select_Column["AgentPointId"] = "agent_point_id";
    User_Select_Column["AllowedRoles"] = "allowed_roles";
    User_Select_Column["CodeTimestamp"] = "code_timestamp";
    User_Select_Column["CreatedAt"] = "created_at";
    User_Select_Column["DefaultRole"] = "default_role";
    User_Select_Column["Email"] = "email";
    User_Select_Column["EmailVerified"] = "email_verified";
    User_Select_Column["Experiments"] = "experiments";
    User_Select_Column["FirstName"] = "first_name";
    User_Select_Column["GaClientId"] = "ga_client_id";
    User_Select_Column["Gclid"] = "gclid";
    User_Select_Column["Hash"] = "hash";
    User_Select_Column["Id"] = "id";
    User_Select_Column["InitialSubmittedVia"] = "initial_submitted_via";
    User_Select_Column["LastName"] = "last_name";
    User_Select_Column["MiddleName"] = "middle_name";
    User_Select_Column["OnboardingEmailSentOn"] = "onboarding_email_sent_on";
    User_Select_Column["Password"] = "password";
    User_Select_Column["PhoneNumber"] = "phone_number";
    User_Select_Column["PhoneNumberVerified"] = "phone_number_verified";
    User_Select_Column["SalesforceId"] = "salesforce_id";
    User_Select_Column["SendWelcomeEmail"] = "send_welcome_email";
    User_Select_Column["SfUpdatedAt"] = "sf_updated_at";
    User_Select_Column["SubmittedVia"] = "submitted_via";
    User_Select_Column["UniqueNumber"] = "unique_number";
    User_Select_Column["UpdatedAt"] = "updated_at";
    User_Select_Column["UtmCampaign"] = "utm_campaign";
    User_Select_Column["UtmContent"] = "utm_content";
    User_Select_Column["UtmMedium"] = "utm_medium";
    User_Select_Column["UtmSource"] = "utm_source";
    User_Select_Column["UtmTerm"] = "utm_term";
    User_Select_Column["Verified"] = "verified";
})(User_Select_Column = exports.User_Select_Column || (exports.User_Select_Column = {}));
var User_Update_Column;
(function (User_Update_Column) {
    User_Update_Column["AgentPointId"] = "agent_point_id";
    User_Update_Column["AllowedRoles"] = "allowed_roles";
    User_Update_Column["CodeTimestamp"] = "code_timestamp";
    User_Update_Column["CreatedAt"] = "created_at";
    User_Update_Column["DefaultRole"] = "default_role";
    User_Update_Column["Email"] = "email";
    User_Update_Column["EmailVerified"] = "email_verified";
    User_Update_Column["Experiments"] = "experiments";
    User_Update_Column["FirstName"] = "first_name";
    User_Update_Column["GaClientId"] = "ga_client_id";
    User_Update_Column["Gclid"] = "gclid";
    User_Update_Column["Hash"] = "hash";
    User_Update_Column["Id"] = "id";
    User_Update_Column["InitialSubmittedVia"] = "initial_submitted_via";
    User_Update_Column["LastName"] = "last_name";
    User_Update_Column["MiddleName"] = "middle_name";
    User_Update_Column["OnboardingEmailSentOn"] = "onboarding_email_sent_on";
    User_Update_Column["Password"] = "password";
    User_Update_Column["PhoneNumber"] = "phone_number";
    User_Update_Column["PhoneNumberVerified"] = "phone_number_verified";
    User_Update_Column["SalesforceId"] = "salesforce_id";
    User_Update_Column["SendWelcomeEmail"] = "send_welcome_email";
    User_Update_Column["SfUpdatedAt"] = "sf_updated_at";
    User_Update_Column["SubmittedVia"] = "submitted_via";
    User_Update_Column["UniqueNumber"] = "unique_number";
    User_Update_Column["UpdatedAt"] = "updated_at";
    User_Update_Column["UtmCampaign"] = "utm_campaign";
    User_Update_Column["UtmContent"] = "utm_content";
    User_Update_Column["UtmMedium"] = "utm_medium";
    User_Update_Column["UtmSource"] = "utm_source";
    User_Update_Column["UtmTerm"] = "utm_term";
    User_Update_Column["Verified"] = "verified";
})(User_Update_Column = exports.User_Update_Column || (exports.User_Update_Column = {}));
var Zone_Type_Constraint;
(function (Zone_Type_Constraint) {
    Zone_Type_Constraint["ZoneTypePkey"] = "zone_type_pkey";
})(Zone_Type_Constraint = exports.Zone_Type_Constraint || (exports.Zone_Type_Constraint = {}));
var Zone_Type_Enum;
(function (Zone_Type_Enum) {
    Zone_Type_Enum["Zone_1"] = "ZONE_1";
    Zone_Type_Enum["Zone_2"] = "ZONE_2";
})(Zone_Type_Enum = exports.Zone_Type_Enum || (exports.Zone_Type_Enum = {}));
var Zone_Type_Select_Column;
(function (Zone_Type_Select_Column) {
    Zone_Type_Select_Column["Description"] = "description";
    Zone_Type_Select_Column["Value"] = "value";
})(Zone_Type_Select_Column = exports.Zone_Type_Select_Column || (exports.Zone_Type_Select_Column = {}));
var Zone_Type_Update_Column;
(function (Zone_Type_Update_Column) {
    Zone_Type_Update_Column["Description"] = "description";
    Zone_Type_Update_Column["Value"] = "value";
})(Zone_Type_Update_Column = exports.Zone_Type_Update_Column || (exports.Zone_Type_Update_Column = {}));
/** An enum describing what kind of type a given `__Type` is. */
var __TypeKind;
(function (__TypeKind) {
    /** Indicates this type is a scalar. */
    __TypeKind["Scalar"] = "SCALAR";
    /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
    __TypeKind["Object"] = "OBJECT";
    /** Indicates this type is an interface. `fields` and `possibleTypes` are valid fields. */
    __TypeKind["Interface"] = "INTERFACE";
    /** Indicates this type is a union. `possibleTypes` is a valid field. */
    __TypeKind["Union"] = "UNION";
    /** Indicates this type is an enum. `enumValues` is a valid field. */
    __TypeKind["Enum"] = "ENUM";
    /** Indicates this type is an input object. `inputFields` is a valid field. */
    __TypeKind["InputObject"] = "INPUT_OBJECT";
    /** Indicates this type is a list. `ofType` is a valid field. */
    __TypeKind["List"] = "LIST";
    /** Indicates this type is a non-null. `ofType` is a valid field. */
    __TypeKind["NonNull"] = "NON_NULL";
})(__TypeKind = exports.__TypeKind || (exports.__TypeKind = {}));
