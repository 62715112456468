"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("@angular/core");
const i1 = require("./jwt.service");
const i2 = require("@angular/router");
const i3 = require("subscriptions-transport-ws/dist/client");
class AuthenticationGuard {
    constructor(auth, router, wsClient) {
        this.auth = auth;
        this.router = router;
        this.wsClient = wsClient;
    }
    canActivate(route, state) {
        if (this.auth.isAuthenticated()) {
            return true;
        }
        else {
            // clear session so after redirect, user can login
            // if we do not clear session here, old token will be used for a login request
            this.auth.deleteSession();
            this.wsClient.close();
            // do a full app refresh, due to the fact that we can not re-connect Apollo client
            // with new credentials at runtime
            this.router.navigate([`/auth/login`], { queryParams: { return: `${state.url}` } });
            return false;
        }
    }
}
AuthenticationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationGuard_Factory() { return new AuthenticationGuard(i0.ɵɵinject(i1.JwtService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SubscriptionClient)); }, token: AuthenticationGuard, providedIn: "root" });
exports.AuthenticationGuard = AuthenticationGuard;
