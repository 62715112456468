"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const router_1 = require("@angular/router");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const _ = require("lodash");
const i0 = require("@angular/core");
const i1 = require("@angular/cdk/platform");
const i2 = require("@angular/router");
// Create the injection token for the custom settings
exports.FUSE_CONFIG = new core_1.InjectionToken('fuseCustomConfig');
class FuseConfigService {
    /**
     * Constructor
     *
     * @param {Platform} _platform
     * @param {Router} _router
     * @param _config
     */
    constructor(_platform, _router, _config) {
        this._platform = _platform;
        this._router = _router;
        this._config = _config;
        // Set the default config from the user provided config (from forRoot)
        this._defaultConfig = _config;
        // Initialize the service
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Set and get the config
     */
    set config(value) {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();
        // Merge the new config
        config = _.merge({}, config, value);
        // Notify the observers
        this._configSubject.next(config);
    }
    get config() {
        return this._configSubject.asObservable();
    }
    /**
     * Get default config
     *
     * @returns {any}
     */
    get defaultConfig() {
        return this._defaultConfig;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    _init() {
        /**
         * Disable custom scrollbars if browser is mobile
         */
        if (this._platform.ANDROID || this._platform.IOS) {
            this._defaultConfig.customScrollbars = false;
        }
        // Set the config from the default config
        this._configSubject = new rxjs_1.BehaviorSubject(_.cloneDeep(this._defaultConfig));
        // Reload the default layout config on every RoutesRecognized event
        // if the current layout config is different from the default one
        this._router.events.pipe(operators_1.filter((event) => event instanceof router_1.ResolveEnd)).subscribe(() => {
            if (!_.isEqual(this._configSubject.getValue().layout, this._defaultConfig.layout)) {
                // Clone the current config
                const config = _.cloneDeep(this._configSubject.getValue());
                // Reset the layout from the default config
                config.layout = _.cloneDeep(this._defaultConfig.layout);
                // Set the config
                this._configSubject.next(config);
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Set config
     *
     * @param value
     * @param {{emitEvent: boolean}} opts
     */
    setConfig(value, opts = { emitEvent: true }) {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();
        // Merge the new config
        config = _.merge({}, config, value);
        // If emitEvent option is true...
        if (opts.emitEvent === true) {
            // Notify the observers
            this._configSubject.next(config);
        }
    }
    /**
     * Get config
     *
     * @returns {Observable<any>}
     */
    getConfig() {
        return this._configSubject.asObservable();
    }
    /**
     * Reset to the default config
     */
    resetToDefaults() {
        // Set the config from the default config
        this._configSubject.next(_.cloneDeep(this._defaultConfig));
    }
}
FuseConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseConfigService_Factory() { return new FuseConfigService(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.Router), i0.ɵɵinject(exports.FUSE_CONFIG)); }, token: FuseConfigService, providedIn: "root" });
exports.FuseConfigService = FuseConfigService;
