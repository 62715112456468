import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasicDialogData } from '../../../../../shared/basic-dialog/basic-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface FeedbackDialogData {
    title: string;
    text: SafeHtml | string;
    okBtnText?: string;
    cancelBtnText?: string;
    hideCancel?: boolean;
    allowClose?: boolean;
    isCheckboxVisible?: boolean;
    checkboxLabel?: string;
    hideFeedbackInput?: boolean;
}

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent {
    form: FormGroup;
    feedbackField = new FormControl('', [Validators.required]);
    // checkbox is on by default
    checkboxValue = true;

    constructor(
        public dialogRef: MatDialogRef<FeedbackDialogComponent>,
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: FeedbackDialogData
    ) {
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }

        // compose reactive form
        this.form = new FormGroup({
            feedback: this.feedbackField,
        });
    }

    closeDialog(result: boolean, message = ''): void {
        if (this.data.allowClose !== false) {
            this.dialogRef.close({
                result,
                message,
                checkboxValue: this.checkboxValue,
            });
        }
    }
}
