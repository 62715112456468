"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const config_service_1 = require("@fuse/services/config.service");
class FuseModule {
    constructor(parentModule) {
        if (parentModule) {
            throw new Error('FuseModule is already loaded. Import it in the AppModule only!');
        }
    }
    static forRoot(config) {
        return {
            ngModule: FuseModule,
            providers: [
                {
                    provide: config_service_1.FUSE_CONFIG,
                    useValue: config,
                },
            ],
        };
    }
}
exports.FuseModule = FuseModule;
