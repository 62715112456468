"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const animations_1 = require("@angular/animations");
const router_1 = require("@angular/router");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("@angular/animations");
const i2 = require("@angular/common");
const i3 = require("@angular/router");
class FuseSplashScreenService {
    /**
     * Constructor
     *
     * @param {AnimationBuilder} _animationBuilder
     * @param _document
     * @param {Router} _router
     */
    constructor(_animationBuilder, _document, _router) {
        this._animationBuilder = _animationBuilder;
        this._document = _document;
        this._router = _router;
        // Initialize
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    _init() {
        // Get the splash screen element
        this.splashScreenEl = this.getSplashElement();
        // If the splash screen element exists...
        if (this.splashScreenEl) {
            this.splashScreenElClone = this.splashScreenEl.cloneNode(true);
            this.splashScreenParent = this.splashScreenEl.parentElement;
            // Hide it on the first NavigationEnd event
            this._router.events
                .pipe(operators_1.filter((event) => event instanceof router_1.NavigationEnd), operators_1.take(1))
                .subscribe(() => {
                setTimeout(() => {
                    this.hide();
                });
            });
        }
    }
    getSplashElement() {
        return this._document.body.querySelector('#fuse-splash-screen');
    }
    removeSplashElement() {
        const splash = this.getSplashElement();
        if (splash) {
            splash.remove();
        }
    }
    ensureSplashElement() {
        const splash = this.getSplashElement();
        if (splash) {
            return splash;
        }
        if (!this.splashScreenElClone || !this.splashScreenParent) {
            return;
        }
        this.splashScreenParent.appendChild(this.splashScreenElClone);
        return this.getSplashElement();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Show the splash screen
     */
    show() {
        this.splashScreenEl = this.ensureSplashElement();
        if (this.splashScreenEl) {
            this.player = this._animationBuilder
                .build([
                animations_1.style({
                    opacity: '0',
                    zIndex: '99999',
                }),
                animations_1.animate('400ms ease', animations_1.style({ opacity: '1', display: 'block' })),
            ])
                .create(this.splashScreenEl);
            setTimeout(() => {
                this.player.play();
            }, 0);
        }
    }
    /**
     * Hide the splash screen
     */
    hide() {
        this.player = this._animationBuilder
            .build([
            animations_1.style({ opacity: '1' }),
            animations_1.animate('400ms ease', animations_1.style({
                opacity: '0',
                zIndex: '-10',
                display: 'none',
            })),
        ])
            .create(this.splashScreenEl);
        this.player.onDone(() => {
            this.removeSplashElement();
        });
        setTimeout(() => {
            this.player.play();
        }, 0);
    }
}
FuseSplashScreenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseSplashScreenService_Factory() { return new FuseSplashScreenService(i0.ɵɵinject(i1.AnimationBuilder), i0.ɵɵinject(i2.DOCUMENT), i0.ɵɵinject(i3.Router)); }, token: FuseSplashScreenService, providedIn: "root" });
exports.FuseSplashScreenService = FuseSplashScreenService;
