"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const navigation_1 = require("app/navigation/navigation");
const en_1 = require("app/navigation/i18n/en");
const tr_1 = require("app/navigation/i18n/tr");
const router_1 = require("@angular/router");
const router_2 = require("@angular/router");
class AppComponent {
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(document, _fuseConfigService, _fuseNavigationService, _fuseSidebarService, _fuseSplashScreenService, _fuseTranslationLoaderService, _translateService, _platform, _jwt, router, analytics, activatedRoute) {
        this.document = document;
        this._fuseConfigService = _fuseConfigService;
        this._fuseNavigationService = _fuseNavigationService;
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseSplashScreenService = _fuseSplashScreenService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translateService = _translateService;
        this._platform = _platform;
        this.router = router;
        this.analytics = analytics;
        this.activatedRoute = activatedRoute;
        // Get default navigation
        this.navigation = navigation_1.navigation;
        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');
        // Add languages
        this._translateService.addLangs(['en', 'tr']);
        // Set the default language
        this._translateService.setDefaultLang('en');
        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(en_1.locale, tr_1.locale);
        // Use a language
        this._translateService.use('en');
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */
        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */
        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         * setTimeout(() => {
         *   this._translateService.setDefaultLang('en');
         *   this._translateService.setDefaultLang('tr');
         * });
         */
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */
        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
        // Set the private defaults
        this._unsubscribeAll = new rxjs_1.Subject();
        this.showAdminSuperPanel$ = this.router.events.pipe(operators_1.filter((e) => e instanceof router_1.ActivationStart && e.snapshot !== undefined), operators_1.map((e) => (e instanceof router_1.ActivationStart ? e.snapshot : undefined)), operators_1.map((snapshot) => {
            if (snapshot.data) {
                return Boolean(snapshot.data['showAdminSuperPanel']) && _jwt.isAdminImpersonatingUser();
            }
            return false;
        }));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        /**
         * analytics code to track user's events
         */
        // ANALYTICS_START
        this.router.events
            .pipe(operators_1.takeUntil(this._unsubscribeAll), operators_1.filter((event) => event instanceof router_2.NavigationEnd))
            .subscribe((val) => {
            if (window.hasOwnProperty('piTracker')) {
                // track page views in pardot
                piTracker();
            }
            this.analytics.run(() => {
                this.analytics.do.page();
            });
        });
        // ANALYTICS_END
        // Store UTM paramters from query string
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const utmParameters = {
                utm_source: params.get('utm_source'),
                utm_medium: params.get('utm_medium'),
                utm_campaign: params.get('utm_campaign'),
                utm_term: params.get('utm_term'),
                utm_content: params.get('utm_content'),
                submitted_via: params.get('submitted_via'),
                gclid: params.get('gclid'),
            };
            this.analytics.setUTMParameters(utmParameters);
            // Below code is to remove utm query parameters from url
            // if (params && Object.keys(params).length > 0) {
            //     const urlWithoutQueryParams = this.router.url.substring(0, this.router.url.indexOf('?'));
            //     let queryParams = Object.keys(params).map((key) => [key, params[key]]);
            //     let data = '?';
            //     for (let i = 0; i < queryParams.length; i++) {
            //         if (!queryParams[i][0].startsWith('utm_')) {
            //             if (data != '?') data += '&';
            //             data += queryParams[i][0] + '=' + queryParams[i][1];
            //         }
            //     }
            //     this.router.navigateByUrl(urlWithoutQueryParams + data).then(() => { });
            // }
        });
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(operators_1.takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;
            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            }
            else {
                this.document.body.classList.remove('boxed');
            }
            // Color theme - Use normal for loop for IE11 compatibility
            for (const className of Array.from(this.document.body.classList)) {
                if (className.startsWith('theme-')) {
                    this.document.body.classList.remove(className);
                }
            }
            this.document.body.classList.add(this.fuseConfig.colorTheme);
        });
    }
    ngAfterViewInit() {
        /**
         * hide widget help-button from application by setting default display to 'none' in css class
         */
        // setTimeout(() => {
        //     const jiraElement: any = document.getElementById('jsd-widget');
        //     const style = jiraElement.contentDocument.createElement('style');
        //     style.type = 'text/css';
        //     style.innerHTML = '#help-button { display: none; }';
        //     const mobStyle = jiraElement.contentDocument.createElement('style');
        //     mobStyle.type = 'text/css';
        //     mobStyle.innerHTML = `.mobile #search-container{
        //         width: 358px;
        //         height: auto;
        //         margin: 0;float:
        //         right;
        //         display: block;
        //         right: 0px;
        //         position: absolute;
        //         bottom: 0px!important;}`;
        //     jiraElement.contentDocument.getElementsByTagName('head')[0].appendChild(style);
        //     jiraElement.contentDocument.getElementsByTagName('head')[0].appendChild(mobStyle);
        // });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
exports.AppComponent = AppComponent;
