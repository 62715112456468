"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dialog_1 = require("@angular/material/dialog");
var CancelDiscardSaveDialogStatus;
(function (CancelDiscardSaveDialogStatus) {
    CancelDiscardSaveDialogStatus["Cancel"] = "close";
    CancelDiscardSaveDialogStatus["Discard"] = "discard";
    CancelDiscardSaveDialogStatus["Save"] = "save";
})(CancelDiscardSaveDialogStatus = exports.CancelDiscardSaveDialogStatus || (exports.CancelDiscardSaveDialogStatus = {}));
class CancelDiscardSaveDialogComponent {
    constructor(dialogRef, domSanitizer, data) {
        this.dialogRef = dialogRef;
        this.domSanitizer = domSanitizer;
        this.data = data;
        this.dialogStates = CancelDiscardSaveDialogStatus;
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
    }
    closeDialog(result) {
        this.dialogRef.close(result);
    }
}
exports.CancelDiscardSaveDialogComponent = CancelDiscardSaveDialogComponent;
