import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'users',
                title: 'Users',
                type: 'item',
                icon: 'people',
                url: '/admin/dashboard/users',
                exactMatch: false,
            },
            {
                id: 'listings',
                title: 'Listings',
                type: 'item',
                icon: 'list',
                url: '/admin/dashboard/listings',
                exactMatch: false,
            },
            {
                id: 'coupons',
                title: 'Coupons',
                type: 'item',
                icon: 'local_offer',
                url: '/admin/dashboard/coupons',
                exactMatch: false,
            },
            {
                id: 'orders',
                title: 'Orders',
                type: 'item',
                icon: 'add_shopping_cart',
                url: '/admin/dashboard/orders',
                exactMatch: false,
            },
            // {
            //     id: 'Help',
            //     title: 'Help',
            //     type: 'item',
            //     icon: 'help',
            //     function: () => {
            //         document
            //             .getElementById('jsd-widget')
            //             ['contentWindow'].document.getElementById('help-button')
            //             .click();
            //     },
            //     exactMatch: false,
            // },
        ],
    },
];
