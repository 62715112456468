import { Component, OnInit, Input } from '@angular/core';
import { UserInfo, Theme } from '../top-nav/top-nav.component';

@Component({
    selector: 'profile-circle',
    templateUrl: './profile-circle.component.html',
    styleUrls: ['./profile-circle.component.scss'],
})
export class ProfileCircleComponent implements OnInit {
    @Input() public theme: Theme;
    @Input() public userInfo: UserInfo;
    @Input() public isAdmin: boolean;
    constructor() {}

    ngOnInit(): void {}
}
