"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./style-1.component.scss.ngstyle");
const i1 = require("@angular/core");
const i2 = require("@angular/common");
const i3 = require("@angular/flex-layout/extended");
const i4 = require("@angular/flex-layout/core");
const i5 = require("../../../../../../@fuse/components/navigation/navigation.component.ngfactory");
const i6 = require("../../../../../../@fuse/components/navigation/navigation.component");
const i7 = require("../../../../../../@fuse/components/navigation/navigation.service");
const i8 = require("./style-1.component");
const i9 = require("../../../../../../@fuse/services/config.service");
const i10 = require("../../../../../../@fuse/components/sidebar/sidebar.service");
var styles_NavbarHorizontalStyle1Component = [i0.styles];
var RenderType_NavbarHorizontalStyle1Component = i1.ɵcrt({ encapsulation: 2, styles: styles_NavbarHorizontalStyle1Component, data: {} });
exports.RenderType_NavbarHorizontalStyle1Component = RenderType_NavbarHorizontalStyle1Component;
function View_NavbarHorizontalStyle1Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "fuse-navigation", [["layout", "horizontal"]], null, null, null, i5.View_FuseNavigationComponent_0, i5.RenderType_FuseNavigationComponent)), i1.ɵdid(5, 114688, null, 0, i6.FuseNavigationComponent, [i1.ChangeDetectorRef, i7.FuseNavigationService], { layout: [0, "layout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fuseConfig.layout.navbar.primaryBackground; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.fuseConfig.layout.navbar.primaryBackground; _ck(_v, 3, 0, currVal_1); var currVal_2 = "horizontal"; _ck(_v, 5, 0, currVal_2); }, null); }
exports.View_NavbarHorizontalStyle1Component_0 = View_NavbarHorizontalStyle1Component_0;
function View_NavbarHorizontalStyle1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar-horizontal-style-1", [], null, null, null, View_NavbarHorizontalStyle1Component_0, RenderType_NavbarHorizontalStyle1Component)), i1.ɵdid(1, 245760, null, 0, i8.NavbarHorizontalStyle1Component, [i9.FuseConfigService, i7.FuseNavigationService, i10.FuseSidebarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NavbarHorizontalStyle1Component_Host_0 = View_NavbarHorizontalStyle1Component_Host_0;
var NavbarHorizontalStyle1ComponentNgFactory = i1.ɵccf("navbar-horizontal-style-1", i8.NavbarHorizontalStyle1Component, View_NavbarHorizontalStyle1Component_Host_0, {}, {}, []);
exports.NavbarHorizontalStyle1ComponentNgFactory = NavbarHorizontalStyle1ComponentNgFactory;
