import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import {
    CodeVerificationQuery,
    CodeVerificationQueryVariables,
    GetGoogleAuthUrlQuery,
    GetGoogleAuthUrlQueryVariables,
    ImpersonateSignInQuery,
    ImpersonateSignInQueryVariables,
    ImpersonateSignOutQuery,
    ImpersonateSignOutQueryVariables,
    MobileVerificationQuery,
    MobileVerificationQueryVariables,
    ResendEmailVerificationLinkQuery,
    ResendEmailVerificationLinkQueryVariables,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
    SendEmailQuery,
    SendEmailQueryVariables,
    SetPasswordMutation,
    SetPasswordMutationVariables,
    SignInQuery,
    SignInQueryVariables,
    SignUpMutation,
    SignUpMutationVariables,
    UpdateGaClientIdMutation,
    UpdateGaClientIdMutationVariables,
    UpdateUserPasswordPhoneNumberMutation,
    UpdateUserPasswordPhoneNumberMutationVariables,
    UpdateUserUtmParamsMutation,
    UpdateUserUtmParamsMutationVariables,
    VerifyHashQuery,
    VerifyHashQueryVariables,
    VerifyUserEmailMutation,
    VerifyUserEmailMutationVariables,
} from '../../generated/graphql';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private apollo: Apollo) {}

    /**
     * Some URLs for Email verification and password reset contains token
     * this method verifies if this token is valid and we can proceed
     * @param hash
     */
    async verifyHash(hash: string): Promise<ApolloQueryResult<VerifyHashQuery>> {
        return this.apollo
            .query<VerifyHashQuery, VerifyHashQueryVariables>({
                query: VERIFY_HASH,
                variables: { hash },
            })
            .toPromise();
    }

    async signUp(
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        utmSource: string,
        utmMedium: string,
        utmCampaign: string,
        utmTerm: string,
        utmContent: string,
        submittedVia: string,
        gaClientId: string,
        gclid: string
    ): Promise<FetchResult<SignUpMutation>> {
        return this.apollo
            .mutate<SignUpMutation, SignUpMutationVariables>({
                mutation: SIGN_UP_MUTATION,
                variables: {
                    email,
                    firstName,
                    lastName,
                    password,
                    utmSource,
                    utmMedium,
                    utmCampaign,
                    utmTerm,
                    utmContent,
                    submittedVia,
                    gaClientId,
                    gclid,
                },
            })
            .toPromise();
    }

    async UpdateUserPasswordPhone(
        userId: string,
        password: string,
        mobileNumber: string
    ): Promise<FetchResult<UpdateUserPasswordPhoneNumberMutation>> {
        return this.apollo
            .mutate<UpdateUserPasswordPhoneNumberMutation, UpdateUserPasswordPhoneNumberMutationVariables>({
                mutation: UPDATE_USER_PASSWORD_PHONE_NUMBER,
                variables: { userId, mobileNumber, password },
            })
            .toPromise();
    }

    async setPassword(password: string, verifyToken: string): Promise<FetchResult<SetPasswordMutation>> {
        return this.apollo
            .mutate<SetPasswordMutation, SetPasswordMutationVariables>({
                mutation: SET_PASSWORD_MUTATION,
                variables: { password, verifyToken },
            })
            .toPromise();
    }

    async verifyUserEmail(hash: string): Promise<FetchResult<VerifyUserEmailMutation>> {
        return this.apollo
            .mutate<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>({
                mutation: VERIFY_USER_EMAIL,
                variables: { hash },
            })
            .toPromise();
    }

    async resetPassword(password: string, verifyToken: string): Promise<FetchResult<ResetPasswordMutation>> {
        return this.apollo
            .mutate<ResetPasswordMutation, ResetPasswordMutationVariables>({
                mutation: RESET_PASSWORD_MUTATION,
                variables: { password, verifyToken },
            })
            .toPromise();
    }

    async mobileVerification(
        mobileNumber: string,
        type: string,
        channel?: string
    ): Promise<ApolloQueryResult<MobileVerificationQuery>> {
        return this.apollo
            .query<MobileVerificationQuery, MobileVerificationQueryVariables>({
                query: MOBILE_VERIFICATION_QUERY,
                variables: { mobileNumber, type, channel: channel || 'sms' },
            })
            .toPromise();
    }

    async sendEmail(email: string, type: string): Promise<ApolloQueryResult<SendEmailQuery>> {
        return this.apollo
            .query<SendEmailQuery, SendEmailQueryVariables>({
                query: SEND_EMAIL_QUERY,
                variables: { email, type },
            })
            .toPromise();
    }

    async codeVerification(
        mobileNumber: string,
        code: string,
        verificationSid: string
    ): Promise<FetchResult<CodeVerificationQuery>> {
        return this.apollo
            .mutate<CodeVerificationQuery, CodeVerificationQueryVariables>({
                mutation: CODE_VERIFICATION_QUERY,
                variables: { mobileNumber, code, verificationSid },
            })
            .toPromise();
    }

    async signIn(email: string, password: string, rememberLogin): Promise<ApolloQueryResult<SignInQuery>> {
        return this.apollo
            .query<SignInQuery, SignInQueryVariables>({
                query: SIGN_IN_QUERY,
                variables: { email, password, rememberLogin },
            })
            .toPromise();
    }

    async impersonateAsUserWithId(userId: string): Promise<ApolloQueryResult<ImpersonateSignInQuery>> {
        return this.apollo
            .query<ImpersonateSignInQuery, ImpersonateSignInQueryVariables>({
                query: IMPERSONATE_SIGN_IN,
                variables: {
                    userId,
                },
            })
            .toPromise();
    }

    async impersonateSignOut(): Promise<ApolloQueryResult<ImpersonateSignOutQuery>> {
        return this.apollo
            .query<ImpersonateSignOutQuery, ImpersonateSignOutQueryVariables>({
                query: IMPERSONATE_SIGN_OUT,
            })
            .toPromise();
    }

    async googleAuthUrl(webAppUrl: string): Promise<ApolloQueryResult<GetGoogleAuthUrlQuery>> {
        return this.apollo
            .query<GetGoogleAuthUrlQuery, GetGoogleAuthUrlQueryVariables>({
                query: GOOGLE_AUTH_URL,
                variables: {
                    webAppUrl,
                },
            })
            .toPromise();
    }

    async loginWithGoogle(webAppRedirectUrl: string): Promise<void> {
        try {
            const googleResp = await this.googleAuthUrl(webAppRedirectUrl);
            if (googleResp.data && googleResp.data.google_auth_url && googleResp.data.google_auth_url.url) {
                window.location.href = googleResp.data.google_auth_url.url;
            } else {
                alert(googleResp.data.google_auth_url.error);
            }
        } catch (e) {
            alert('Unable to connect to the database');
        }
    }

    async resendEmailVerification(redirectUrl?: string): Promise<ApolloQueryResult<ResendEmailVerificationLinkQuery>> {
        return this.apollo
            .query<ResendEmailVerificationLinkQuery, ResendEmailVerificationLinkQueryVariables>({
                query: RESEND_EMAIL_VERIFICATION_LINK_QUERY,
                variables: { redirectUrl },
            })
            .toPromise();
    }

    updateUserUTMParams(
        userId: string,
        utmSource: string,
        utmMedium: string,
        utmCampaign: string,
        utmTerm: string,
        utmContent: string,
        submittedVia: string,
        gclid: string
    ): Observable<FetchResult<UpdateUserUtmParamsMutation>> {
        return this.apollo.mutate<UpdateUserUtmParamsMutation, UpdateUserUtmParamsMutationVariables>({
            mutation: UPDATE_USER_UTM_PARAMS,
            variables: {
                userId,
                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmContent,
                submittedVia,
                gclid,
            },
        });
    }

    updateGaClientId(userId: string, gaClientId: string): Observable<FetchResult<UpdateGaClientIdMutation>> {
        return this.apollo.mutate<UpdateGaClientIdMutation, UpdateGaClientIdMutationVariables>({
            mutation: UPDATE_GA_CLIENT_ID,
            variables: {
                userId,
                gaClientId,
            },
        });
    }
}

//
// Queries moved here, to keep the most important at the top
//

const SIGN_UP_MUTATION = gql`
    mutation signUp(
        $email: String!
        $firstName: String!
        $lastName: String!
        $password: String!
        $utmCampaign: String
        $utmContent: String
        $utmMedium: String
        $utmSource: String
        $utmTerm: String
        $submittedVia: String
        $gaClientId: String
        $gclid: String
    ) {
        sign_up(
            email: $email
            firstName: $firstName
            lastName: $lastName
            password: $password
            utmCampaign: $utmCampaign
            utmContent: $utmContent
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmTerm: $utmTerm
            submittedVia: $submittedVia
            gaClientId: $gaClientId
            gclid: $gclid
        ) {
            jwt
            redirectUrl
        }
    }
`;

const SET_PASSWORD_MUTATION = gql`
    mutation setPassword($password: String!, $verifyToken: String!) {
        set_password(password: $password, verifyToken: $verifyToken) {
            jwt
        }
    }
`;

const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($password: String!, $verifyToken: String!) {
        reset_password(password: $password, verifyToken: $verifyToken) {
            success
            error
        }
    }
`;

const VERIFY_USER_EMAIL = gql`
    mutation verifyUserEmail($hash: String!) {
        verify_user_email(hash: $hash) {
            success
            message
            data
        }
    }
`;

const MOBILE_VERIFICATION_QUERY = gql`
    query mobileVerification($mobileNumber: String!, $type: String!, $channel: String!) {
        verify_mobile_number(mobileNumber: $mobileNumber, type: $type, channel: $channel) {
            serviceSid
            status
            to
            channel
            error
        }
    }
`;

const CODE_VERIFICATION_QUERY = gql`
    query codeVerification($mobileNumber: String!, $code: String!, $verificationSid: String!) {
        mobile_code_verification(mobileNumber: $mobileNumber, code: $code, sid: $verificationSid) {
            status
            error
            jwt
        }
    }
`;

const SEND_EMAIL_QUERY = gql`
    query sendEmail($email: String!, $type: String!) {
        send_email(email: $email, type: $type) {
            success
            error
        }
    }
`;

const SIGN_IN_QUERY = gql`
    query signIn($email: String!, $password: String!, $rememberLogin: Boolean!) {
        sign_in(email: $email, password: $password, rememberLogin: $rememberLogin) {
            jwt
        }
    }
`;

const IMPERSONATE_SIGN_IN = gql`
    query impersonateSignIn($userId: uuid!) {
        impersonate_sign_in(userId: $userId) {
            jwt
        }
    }
`;

const IMPERSONATE_SIGN_OUT = gql`
    query impersonateSignOut {
        impersonate_logout {
            jwt
        }
    }
`;

const GOOGLE_AUTH_URL = gql`
    query getGoogleAuthUrl($webAppUrl: String!) {
        google_auth_url(webAppUrl: $webAppUrl) {
            url
            error
        }
    }
`;

const RESEND_EMAIL_VERIFICATION_LINK_QUERY = gql`
    query resendEmailVerificationLink($redirectUrl: String) {
        resend_email_verification_link(redirectUrl: $redirectUrl) {
            success
            error
        }
    }
`;

const VERIFY_HASH = gql`
    query verifyHash($hash: String!) {
        verify_hash(hash: $hash) {
            success
            message
            data
        }
    }
`;

const UPDATE_USER_UTM_PARAMS = gql`
    mutation updateUserUTMParams(
        $userId: uuid!
        $utmCampaign: String
        $utmContent: String
        $utmMedium: String
        $utmSource: String
        $utmTerm: String
        $submittedVia: String
        $gclid: String
    ) {
        update: update_user(
            where: { id: { _eq: $userId }, _or: [{ utm_source: { _is_null: true } }, { utm_source: { _ilike: "" } }] }
            _set: {
                utm_campaign: $utmCampaign
                utm_content: $utmContent
                utm_medium: $utmMedium
                utm_source: $utmSource
                utm_term: $utmTerm
                gclid: $gclid
            }
        ) {
            affected_rows
        }
        update_submittedvia: update_user(
            where: {
                id: { _eq: $userId }
                _or: [{ submitted_via: { _is_null: true } }, { submitted_via: { _ilike: "" } }]
            }
            _set: { submitted_via: $submittedVia }
        ) {
            affected_rows
        }
        update_gclid: update_user(
            where: { id: { _eq: $userId }, _or: [{ gclid: { _is_null: true } }, { gclid: { _ilike: "" } }] }
            _set: { gclid: $gclid }
        ) {
            affected_rows
        }
    }
`;

const UPDATE_GA_CLIENT_ID = gql`
    mutation updateGaClientId($userId: uuid!, $gaClientId: String) {
        update_ga_client_id: update_user(
            where: {
                id: { _eq: $userId }
                _or: [{ ga_client_id: { _is_null: true } }, { ga_client_id: { _ilike: "" } }]
            }
            _set: { ga_client_id: $gaClientId }
        ) {
            affected_rows
        }
    }
`;

const UPDATE_USER_PASSWORD_PHONE_NUMBER = gql`
    mutation updateUserPasswordPhoneNumber($userId: String!, $mobileNumber: String!, $password: String!) {
        update_user_password_phone(userId: $userId, phone_number: $mobileNumber, password: $password) {
            message
        }
    }
`;
