"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jwt_decode = require("jwt-decode");
const user_model_1 = require("app/shared/models/user.model");
const rxjs_1 = require("rxjs");
const i0 = require("@angular/core");
const i1 = require("@auth0/angular-jwt/src/jwthelper.service");
const i2 = require("./analytics.service");
class JwtService {
    constructor(jwtHelper, analytics) {
        this.jwtHelper = jwtHelper;
        this.analytics = analytics;
        this.isLoggedIn = new rxjs_1.BehaviorSubject(null);
    }
    isAuthenticated() {
        const token = localStorage.getItem('token');
        // Check whether the token is expired and return
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
    }
    getUserId() {
        const currentSession = this.getSession();
        if (!currentSession) {
            return null;
        }
        const splitJwt = currentSession.split('.');
        const decodedJwtBodyString = atob(splitJwt[1]);
        const decodedJwtBody = JSON.parse(decodedJwtBodyString);
        return decodedJwtBody['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    }
    getSession() {
        if (sessionStorage.getItem('impersonateToken')) {
            return sessionStorage.getItem('impersonateToken');
        }
        return localStorage.getItem('token');
    }
    setSession(jwt) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            localStorage.setItem('token', jwt);
            this.analytics.run(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.analytics.identifyUser(jwt);
            }));
            this.isLoggedIn.next(new Object());
        });
    }
    setUserSession(jwt) {
        sessionStorage.setItem('impersonateToken', jwt);
        this.isLoggedIn.next(new Object());
    }
    deleteSession(checkImpersonationSession = false) {
        this.analytics.run(() => {
            this.analytics.do.reset();
        });
        if (checkImpersonationSession && this.isAdminImpersonatingUser()) {
            sessionStorage.removeItem('impersonateToken');
        }
        else {
            localStorage.removeItem('token');
            sessionStorage.removeItem('impersonateToken');
            localStorage.removeItem('redirectUrl');
        }
    }
    isAdmin() {
        if (localStorage.getItem('token')) {
            const decoded = jwt_decode(localStorage.getItem('token'));
            const role = decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'];
            return role === user_model_1.UserRole.StaffUser || role === user_model_1.UserRole.Admin;
        }
    }
    isAdminImpersonatingUser() {
        const impersonateToken = sessionStorage.getItem('impersonateToken');
        const token = localStorage.getItem('token');
        return impersonateToken && token ? true : false;
    }
    saveCampaignInSession(campaignCode) {
        sessionStorage.setItem('selectedCampaignCode', campaignCode);
    }
    getCampaignFromSession() {
        return sessionStorage.getItem('selectedCampaignCode');
    }
    removeCampaignFromSession() {
        sessionStorage.removeItem('selectedCampaignCode');
    }
}
JwtService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtService_Factory() { return new JwtService(i0.ɵɵinject(i1.JwtHelperService), i0.ɵɵinject(i2.AnalyticsService)); }, token: JwtService, providedIn: "root" });
exports.JwtService = JwtService;
