"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const router_1 = require("@angular/router");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const fuse_perfect_scrollbar_directive_1 = require("@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
class NavbarVerticalStyle2Component {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(_fuseConfigService, _fuseNavigationService, _fuseSidebarService, _router) {
        this._fuseConfigService = _fuseConfigService;
        this._fuseNavigationService = _fuseNavigationService;
        this._fuseSidebarService = _fuseSidebarService;
        this._router = _router;
        // Set the private defaults
        this._unsubscribeAll = new rxjs_1.Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    // Directive
    set directive(theDirective) {
        if (!theDirective) {
            return;
        }
        this._fusePerfectScrollbar = theDirective;
        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(operators_1.delay(500), operators_1.takeUntil(this._unsubscribeAll))
            .subscribe(() => {
            this._fusePerfectScrollbar.update();
        });
        // Scroll to the active item position
        this._router.events
            .pipe(operators_1.filter((event) => event instanceof router_1.NavigationEnd), operators_1.take(1))
            .subscribe(() => {
            setTimeout(() => {
                this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
            });
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        this._router.events
            .pipe(operators_1.filter((event) => event instanceof router_1.NavigationEnd), operators_1.takeUntil(this._unsubscribeAll))
            .subscribe(() => {
            if (this._fuseSidebarService.getSidebar('navbar')) {
                this._fuseSidebarService.getSidebar('navbar').close();
            }
        });
        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(operators_1.filter((value) => value !== null), operators_1.takeUntil(this._unsubscribeAll))
            .subscribe(() => {
            this.navigation = this._fuseNavigationService.getCurrentNavigation();
        });
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(operators_1.takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened() {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }
    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded() {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
}
exports.NavbarVerticalStyle2Component = NavbarVerticalStyle2Component;
