import { Injectable } from '@angular/core';
import { Apollo, SubscriptionResult } from 'apollo-angular';
import gql from 'graphql-tag';
import {
    AddCampaignToOrderMutation,
    AddCampaignToOrderMutationVariables,
    CancelOrderMutation,
    CancelOrderMutationVariables,
    CheckAddonPurchasedQuery,
    CheckAddonPurchasedQueryVariables,
    CheckoutOrderMutation,
    CheckoutOrderMutationVariables,
    ClearAddonsFromOrderMutation,
    ClearAddonsFromOrderMutationVariables,
    ClearNormalAddonsFromOrderMutation,
    ClearNormalAddonsFromOrderMutationVariables,
    CreateOrderMutation,
    CreateOrderMutationVariables,
    DeleteOrderMutation,
    DeleteOrderMutationVariables,
    FindOpenTransactionByOrderIdQuery,
    FindOpenTransactionByOrderIdQueryVariables,
    GetAddonQtyByIdQuery,
    GetAddonQtyByIdQueryVariables,
    GetAllOpenOrdersQuery,
    GetAllOpenOrdersQueryVariables,
    GetListingOrdersQuery,
    GetListingOrdersQueryVariables,
    GetOpenOrdersQuery,
    GetOpenOrdersQueryVariables,
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables,
    GetOrderByIdSubSubscription,
    GetOrderByIdSubSubscriptionVariables,
    GetPaidOrdersQuery,
    GetPaidOrdersQueryVariables,
    GetPurchasedAddonQtyByListingIdQuery,
    GetPurchasedAddonQtyByListingIdQueryVariables,
    GetTransactionByListingAndStatusQuery,
    GetTransactionByListingAndStatusQueryVariables,
    GetUnpaidOrderSubSubscription,
    GetUnpaidOrderSubSubscriptionVariables,
    HasPaidOrderQuery,
    HasPaidOrderQueryVariables,
    IsOrderRefundedQuery,
    IsOrderRefundedQueryVariables,
    ModifyOrderItemAddonMutation,
    ModifyOrderItemAddonMutationVariables,
    Order_Status_Enum,
    PayOrderMutation,
    PayOrderMutationVariables,
    RemoveAllOrderItemsFromOrderMutation,
    RemoveAllOrderItemsFromOrderMutationVariables,
    RemoveOrderItemMutation,
    RemoveOrderItemMutationVariables,
    RequestCancelOrderMutation,
    RequestCancelOrderMutationVariables,
    Transaction_Status_Enum,
    UpdateOrderItemFulfillmentStatusMutation,
    UpdateOrderItemFulfillmentStatusMutationVariables,
    UpdateOrderItemRefundStatusMutation,
    UpdateOrderItemRefundStatusMutationVariables,
    UpdateOrderItemStatusMutation,
    UpdateOrderItemStatusMutationVariables,
    UpdateOrderTotalByOrderIdMutation,
    UpdateOrderTotalByOrderIdMutationVariables,
} from '../../generated/graphql';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { SegmentOrderEventModel } from '../shared/models/segment-order-event.model';
import { SegmentOrderProductModel } from '../shared/models/segment-order-product.model';

const CREATE_ORDER_MUTATION = gql`
    mutation createOrder($listingId: uuid!, $campaignId: uuid, $createdFromTemplate: Boolean) {
        create_order(listingId: $listingId, campaignId: $campaignId, createdFromTemplate: $createdFromTemplate) {
            id
        }
    }
`;

const GET_ORDER_BY_ID_QUERY = gql`
    query getOrderById($orderId: uuid!) {
        order: order_detail(where: { id: { _eq: $orderId } }) {
            id
            listing_id
            status
            created_at
            updated_at
            order_total
            created_from_template
            listing {
                id
                enquiry_code
            }
            order_items {
                id
                price
                item_qty
                campaign {
                    id
                    name
                    code
                }
                addon {
                    id
                    name
                    code
                }
                processed_by_id
                qty_processed_by_admin
            }
            transactions {
                id
                amount
                order_id
                origin_id
                origin_type
                origin_status
                status
                json
            }
        }
    }
`;

const GET_ORDER_BY_ID_SUB = gql`
    subscription getOrderByIdSub($orderId: uuid!) {
        order: order_detail(where: { id: { _eq: $orderId } }) {
            id
            listing_id
            status
            created_at
            updated_at
            order_total
            order_total_without_discount
            order_items {
                id
                price
                item_qty
                type
                removed
                name
                addon {
                    id
                    name
                    price
                    max_qty
                    min_qty
                    code
                    order: group_order
                    addon_addon_groups {
                        addon_group {
                            id
                            short_title
                            order
                            type
                            campaign: campaign_addon_groups {
                                id: campaign_id
                            }
                        }
                    }
                    inclusion_addons {
                        inclusion {
                            inclusion_group {
                                campaign_inclusion_groups {
                                    campaign_id
                                }
                            }
                        }
                    }
                }
                campaign {
                    id
                    name
                    price
                    price_description
                    code
                }
                coupon {
                    code
                    description
                    campaign_id
                }
                processed_by_id
                qty_processed_by_admin
            }
            transactions {
                id
                amount
                order_id
                origin_id
                origin_type
                origin_status
                status
            }
            paidOrders: listing {
                orders(
                    where: {
                        _and: [{ order_items: { campaign: { id: { _is_null: false } } } }, { status: { _eq: Paid } }]
                    }
                ) {
                    id
                    status
                }
            }
        }
    }
`;

const DELETE_ORDER = gql`
    mutation deleteOrder($orderId: uuid!) {
        remove_order(orderId: $orderId) {
            affected_rows
        }
    }
`;

const GET_LISTING_ORDERS = gql`
    query getListingOrders($listingId: uuid!) {
        order(where: { listing_id: { _eq: $listingId } }) {
            id
            status
            created_at
            updated_at
            order_items {
                id
                price
                campaign {
                    id
                    name
                }
            }
        }
    }
`;

const ADD_CAMPAIGN_TO_ORDER = gql`
    mutation addCampaignToOrder($orderId: uuid!, $campaignId: uuid!) {
        add_campaign_to_order(orderId: $orderId, campaignId: $campaignId) {
            affected_rows
        }
    }
`;

const MODIFY_ORDER_ITEM_ADDON = gql`
    mutation modifyOrderItemAddon($addonId: uuid!, $orderId: uuid!, $qty: Int!) {
        modify_order_item_addon(addonId: $addonId, orderId: $orderId, qty: $qty) {
            affected_rows
        }
    }
`;

const GET_UNPAID_ORDER_SUB = gql`
    subscription getUnpaidOrderSub($listingId: uuid!) {
        order: order_detail(
            where: { _and: [{ listing_id: { _eq: $listingId } }, { status: { _in: ["Open", "Unpaid"] } }] }
        ) {
            id
            listing_id
            status
            order_total
            order_total_without_discount
            created_from_template
            order_items {
                id
                price
                item_qty
                type
                removed
                name
                addon {
                    id
                    name
                    code
                    price
                    max_qty
                    min_qty
                    order: group_order
                    addon_addon_groups {
                        addon_group {
                            id
                            short_title
                            order
                            type
                        }
                    }
                    inclusion_addons {
                        inclusion {
                            inclusion_group {
                                campaign_inclusion_groups {
                                    campaign_id
                                }
                            }
                        }
                    }
                }
                campaign {
                    id
                    name
                    price
                    price_description
                    code
                }
                coupon {
                    code
                    description
                    campaign_id
                }
                processed_by_id
                qty_processed_by_admin
            }
        }
    }
`;

const GET_TRANSACTION_BY_LISTING_STATUS = gql`
    query getTransactionByListingAndStatus($listingId: uuid!, $status: transaction_status_enum) {
        transaction(where: { status: { _eq: $status }, order: { listing_id: { _eq: $listingId } } }) {
            id
            order_id
            origin_id
            origin_type
            order {
                order_items(where: { campaign_id: { _is_null: false } }) {
                    campaign_id
                }
            }
        }
    }
`;

const FIND_OPEN_TRANSACTION_BY_ORDERID = gql`
    query findOpenTransactionByOrderId($orderId: uuid!) {
        transaction(where: { order_id: { _eq: $orderId }, status: { _eq: Created } }) {
            id
            order_id
            amount
            status
            origin_status
            origin_id
            origin_type
            payment_url
            json
        }
    }
`;

const REMOVE_ORDER_ITEM = gql`
    mutation removeOrderItem($orderItemId: uuid!) {
        delete_order_item_by_pk(id: $orderItemId) {
            id
        }
    }
`;

const REMOVE_ALL_ORDER_ITEMS_FROM_ORDER = gql`
    mutation removeAllOrderItemsFromOrder($orderId: uuid!) {
        delete_order_item(where: { order_id: { _eq: $orderId } }) {
            affected_rows
        }
    }
`;

const CLEAR_ADDONS_FROM_ORDER = gql`
    mutation clearAddonsFromOrder($orderId: uuid!) {
        clear_addons_from_order(orderId: $orderId) {
            affected_rows
        }
    }
`;

const REQUEST_CANCEL_ORDER = gql`
    mutation requestCancelOrder($orderId: uuid!) {
        request_cancel_order(orderId: $orderId) {
            affected_rows
        }
    }
`;

const CANCEL_ORDER = gql`
    mutation cancelOrder($orderId: uuid!) {
        cancel_order(orderId: $orderId) {
            affected_rows
        }
    }
`;

const PAY_ORDER = gql`
    mutation payOrder($orderId: uuid!) {
        pay_order(orderId: $orderId) {
            affected_rows
        }
    }
`;

const GET_PAID_ORDERS = gql`
    query getPaidOrders($listingId: uuid!) {
        order(where: { _and: { listing_id: { _eq: $listingId }, status: { _eq: Paid } } }) {
            id
        }
    }
`;

const GET_OPEN_ORDER = gql`
    query getOpenOrders($listingId: uuid!) {
        order(where: { _and: { listing_id: { _eq: $listingId }, status: { _eq: Open } } }) {
            id
            order_items {
                campaign_id
            }
        }
    }
`;

const GET_ADDON_QTY_BY_ID = gql`
    query getAddonQtyById($orderId: uuid!, $addonId: uuid!) {
        order_item(where: { _and: { addon_id: { _eq: $addonId }, order_id: { _eq: $orderId } } }) {
            item_qty
        }
    }
`;

const CHECKOUT_ORDER = gql`
    mutation checkoutOrder($orderId: uuid!) {
        checkout_order(orderId: $orderId) {
            success
            fromPaymentMethod
            originId
            orderId
        }
    }
`;

const CLEAR_NORMAL_ADDONS_FROM_ORDER = gql`
    mutation clearNormalAddonsFromOrder($orderId: uuid!) {
        clear_normal_addons_from_order(orderId: $orderId) {
            affected_rows
        }
    }
`;

const UPDATE_ORDER_TOTAL_BY_ORDER_ID = gql`
    mutation updateOrderTotalByOrderId($orderId: uuid!, $newOrderTotal: numeric) {
        update_order_total(orderId: $orderId, newOrderTotal: $newOrderTotal) {
            affected_rows
        }
    }
`;

const UPDATE_ORDER_ITEM_STATUS = gql`
    mutation updateOrderItemStatus($id: uuid!, $status: order_status_enum!) {
        update_order_item(where: { id: { _eq: $id } }, _set: { status: $status }) {
            affected_rows
        }
    }
`;

const UPDATE_ORDER_ITEM_REFUND_STATUS = gql`
    mutation updateOrderItemRefundStatus($id: uuid!, $status: String) {
        update_order_item(where: { id: { _eq: $id } }, _set: { refund_status: $status }) {
            affected_rows
        }
    }
`;

const UPDATE_ORDER_ITEM_FULFILLMENT_STATUS = gql`
    mutation updateOrderItemFulfillmentStatus($id: uuid!, $status: String) {
        update_order_item(where: { id: { _eq: $id } }, _set: { fulfillment_status: $status }) {
            affected_rows
        }
    }
`;

const IS_ORDER_REFUNDED = gql`
    query isOrderRefunded($orderId: uuid!) {
        order_item(where: { order_id: { _eq: $orderId } }) {
            refund_status
        }
    }
`;

const CHECK_ADDON_PURCHASED = gql`
    query checkAddonPurchased($listingId: uuid!, $addonCodes: [String!]) {
        order_item_aggregate(
            where: {
                addon: { code: { _in: $addonCodes } }
                order: { listing_id: { _eq: $listingId }, status: { _eq: Paid } }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const GET_PURCHASED_ADDON_QTY_BY_LISTING_ID = gql`
    query getPurchasedAddonQtyByListingId($listingId: uuid!, $addonCode: String!) {
        order_item_aggregate(
            where: {
                order: { listing_id: { _eq: $listingId }, status: { _eq: Paid } }
                addon: { code: { _eq: $addonCode } }
            }
        ) {
            aggregate {
                sum {
                    item_qty
                }
            }
        }
    }
`;

const GET_ALL_OPEN_ORDERS_FOR_USER = gql`
    query getAllOpenOrders {
        order(where: { status: { _eq: Open } }, order_by: { updated_at: desc }) {
            listing_id
            created_from_template
            order_items {
                campaign_id
                type
                campaign {
                    code
                }
            }
        }
    }
`;

const HAS_PAID_ORDER = gql`
    query hasPaidOrder($listingId: uuid!) {
        listing_by_pk(id: $listingId) {
            orders(where: { status: { _eq: Paid } }) {
                status
            }
        }
    }
`;

export interface ICampaignItem {
    id: string;
    name: string;
    price?: number;
    allow_multiple_purchases: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(private apollo: Apollo) {}

    createOrder(
        listingId: string,
        campaignId = null,
        createdFromTemplate: boolean = false
    ): Promise<FetchResult<CreateOrderMutation>> {
        return this.apollo
            .mutate<CreateOrderMutation, CreateOrderMutationVariables>({
                mutation: CREATE_ORDER_MUTATION,
                variables: {
                    listingId,
                    campaignId,
                    createdFromTemplate,
                },
            })
            .toPromise();
    }

    async findById(orderId: string): Promise<ApolloQueryResult<GetOrderByIdQuery>> {
        return this.apollo
            .query<GetOrderByIdQuery, GetOrderByIdQueryVariables>({
                query: GET_ORDER_BY_ID_QUERY,
                variables: {
                    orderId,
                },
            })
            .toPromise();
    }

    getOrderByIdSub(orderId: string): Observable<SubscriptionResult<GetOrderByIdSubSubscription>> {
        return this.apollo.subscribe<GetOrderByIdSubSubscription, GetOrderByIdSubSubscriptionVariables>({
            query: GET_ORDER_BY_ID_SUB,
            variables: {
                orderId,
            },
        });
    }

    async deleteOrder(orderId: string): Promise<FetchResult<DeleteOrderMutation>> {
        return this.apollo
            .mutate<DeleteOrderMutation, DeleteOrderMutationVariables>({
                mutation: DELETE_ORDER,
                variables: {
                    orderId,
                },
            })
            .toPromise();
    }

    async getListingOrders(listingId: string): Promise<ApolloQueryResult<GetListingOrdersQuery>> {
        return this.apollo
            .query<GetListingOrdersQuery, GetListingOrdersQueryVariables>({
                query: GET_LISTING_ORDERS,
                variables: {
                    listingId,
                },
            })
            .toPromise();
    }

    async addCampaignToOrder(orderId: string, campaignId: string): Promise<FetchResult<AddCampaignToOrderMutation>> {
        return this.apollo
            .mutate<AddCampaignToOrderMutation, AddCampaignToOrderMutationVariables>({
                mutation: ADD_CAMPAIGN_TO_ORDER,
                variables: { orderId, campaignId },
            })
            .toPromise();
    }

    async modifyOrderItemAddon(
        orderId: string,
        addonId: string,
        qty: number
    ): Promise<FetchResult<ModifyOrderItemAddonMutation>> {
        return this.apollo
            .mutate<ModifyOrderItemAddonMutation, ModifyOrderItemAddonMutationVariables>({
                mutation: MODIFY_ORDER_ITEM_ADDON,
                variables: {
                    orderId,
                    addonId,
                    qty,
                },
            })
            .toPromise();
    }

    getUnpaidOrderSubscription(listingId: string): Observable<SubscriptionResult<GetUnpaidOrderSubSubscription>> {
        return this.apollo.subscribe<GetUnpaidOrderSubSubscription, GetUnpaidOrderSubSubscriptionVariables>({
            query: GET_UNPAID_ORDER_SUB,
            variables: {
                listingId,
            },
        });
    }

    async getTransactionByListingAndStatus(
        listingId: string,
        status: Transaction_Status_Enum
    ): Promise<ApolloQueryResult<GetTransactionByListingAndStatusQuery>> {
        return this.apollo
            .query<GetTransactionByListingAndStatusQuery, GetTransactionByListingAndStatusQueryVariables>({
                query: GET_TRANSACTION_BY_LISTING_STATUS,
                variables: {
                    listingId,
                    status,
                },
            })
            .toPromise();
    }

    async findOpenTransactionByOrderId(orderId: string): Promise<ApolloQueryResult<FindOpenTransactionByOrderIdQuery>> {
        return this.apollo
            .query<FindOpenTransactionByOrderIdQuery, FindOpenTransactionByOrderIdQueryVariables>({
                query: FIND_OPEN_TRANSACTION_BY_ORDERID,
                variables: { orderId },
            })
            .toPromise();
    }

    async clearAddonsFromOrder(orderId: string): Promise<FetchResult<ClearAddonsFromOrderMutation>> {
        return this.apollo
            .mutate<ClearAddonsFromOrderMutation, ClearAddonsFromOrderMutationVariables>({
                mutation: CLEAR_ADDONS_FROM_ORDER,
                variables: { orderId },
            })
            .toPromise();
    }

    removeAllOrderItemsFromOrder(orderId: string): Promise<FetchResult<RemoveAllOrderItemsFromOrderMutation>> {
        return this.apollo
            .mutate<RemoveAllOrderItemsFromOrderMutation, RemoveAllOrderItemsFromOrderMutationVariables>({
                mutation: REMOVE_ALL_ORDER_ITEMS_FROM_ORDER,
                variables: { orderId },
            })
            .toPromise();
    }

    removeOrderItem(orderItemId: string): Promise<FetchResult<RemoveOrderItemMutation>> {
        return this.apollo
            .mutate<RemoveOrderItemMutation, RemoveOrderItemMutationVariables>({
                mutation: REMOVE_ORDER_ITEM,
                variables: {
                    orderItemId,
                },
            })
            .toPromise();
    }

    async requestCancelOrder(orderId: string): Promise<FetchResult<RequestCancelOrderMutation>> {
        return this.apollo
            .mutate<RequestCancelOrderMutation, RequestCancelOrderMutationVariables>({
                mutation: REQUEST_CANCEL_ORDER,
                variables: {
                    orderId,
                },
            })
            .toPromise();
    }

    async cancelOrder(orderId: string): Promise<FetchResult<CancelOrderMutation>> {
        return this.apollo
            .mutate<CancelOrderMutation, CancelOrderMutationVariables>({
                mutation: CANCEL_ORDER,
                variables: {
                    orderId,
                },
            })
            .toPromise();
    }

    async payOrder(orderId: string): Promise<FetchResult<PayOrderMutation>> {
        return this.apollo
            .mutate<PayOrderMutation, PayOrderMutationVariables>({
                mutation: PAY_ORDER,
                variables: {
                    orderId,
                },
            })
            .toPromise();
    }

    async getPaidOrders(listingId: string): Promise<ApolloQueryResult<GetPaidOrdersQuery>> {
        return this.apollo
            .query<GetPaidOrdersQuery, GetPaidOrdersQueryVariables>({
                query: GET_PAID_ORDERS,
                variables: {
                    listingId,
                },
            })
            .toPromise();
    }

    async getOpenOrder(listingId: string): Promise<ApolloQueryResult<GetOpenOrdersQuery>> {
        return this.apollo
            .query<GetOpenOrdersQuery, GetOpenOrdersQueryVariables>({
                query: GET_OPEN_ORDER,
                variables: {
                    listingId,
                },
            })
            .toPromise();
    }

    async getAddonsQtyById(orderId: string, addonId: string): Promise<ApolloQueryResult<GetAddonQtyByIdQuery>> {
        return this.apollo
            .query<GetAddonQtyByIdQuery, GetAddonQtyByIdQueryVariables>({
                query: GET_ADDON_QTY_BY_ID,
                variables: {
                    orderId,
                    addonId,
                },
            })
            .toPromise();
    }

    getAnaltyicsAction(orderItems: GetAddonQtyByIdQuery['order_item'], qtyToAdd: number): string {
        if (orderItems.length) {
            const addOnQty = orderItems[0].item_qty;
            if (addOnQty === 1 && qtyToAdd < 0) {
                return 'Add-on removed from cart';
            }
            return 'Add-on quantity updated';
        }
        return 'Add-on added to cart';
    }

    checkoutOrder(orderId: string): Promise<FetchResult<CheckoutOrderMutation>> {
        return this.apollo
            .mutate<CheckoutOrderMutation, CheckoutOrderMutationVariables>({
                mutation: CHECKOUT_ORDER,
                variables: {
                    orderId,
                },
            })
            .toPromise();
    }

    async clearNormalAddonsFromOrder(orderId: string): Promise<FetchResult<ClearNormalAddonsFromOrderMutation>> {
        return this.apollo
            .mutate<ClearNormalAddonsFromOrderMutation, ClearNormalAddonsFromOrderMutationVariables>({
                mutation: CLEAR_NORMAL_ADDONS_FROM_ORDER,
                variables: { orderId },
            })
            .toPromise();
    }

    async updateOrderTotalByOrderId(
        orderId: string,
        newOrderTotal: number
    ): Promise<FetchResult<UpdateOrderTotalByOrderIdMutation>> {
        return this.apollo
            .mutate<UpdateOrderTotalByOrderIdMutation, UpdateOrderTotalByOrderIdMutationVariables>({
                mutation: UPDATE_ORDER_TOTAL_BY_ORDER_ID,
                variables: { orderId, newOrderTotal },
            })
            .toPromise();
    }

    async updateOrderItemStatus(
        id: string,
        status: Order_Status_Enum
    ): Promise<FetchResult<UpdateOrderItemStatusMutation>> {
        return this.apollo
            .mutate<UpdateOrderItemStatusMutation, UpdateOrderItemStatusMutationVariables>({
                mutation: UPDATE_ORDER_ITEM_STATUS,
                variables: { id, status },
            })
            .toPromise();
    }

    async updateOrderItemRefundStatus(
        id: string,
        status: string
    ): Promise<FetchResult<UpdateOrderItemRefundStatusMutation>> {
        return this.apollo
            .mutate<UpdateOrderItemRefundStatusMutation, UpdateOrderItemRefundStatusMutationVariables>({
                mutation: UPDATE_ORDER_ITEM_REFUND_STATUS,
                variables: { id, status },
            })
            .toPromise();
    }

    async updateOrderItemFulfillmentStatus(
        id: string,
        status: string
    ): Promise<FetchResult<UpdateOrderItemFulfillmentStatusMutation>> {
        return this.apollo
            .mutate<UpdateOrderItemFulfillmentStatusMutation, UpdateOrderItemFulfillmentStatusMutationVariables>({
                mutation: UPDATE_ORDER_ITEM_FULFILLMENT_STATUS,
                variables: { id, status },
            })
            .toPromise();
    }

    async isOrderRefunded(orderId: string): Promise<boolean> {
        const resp = await this.apollo
            .query<IsOrderRefundedQuery, IsOrderRefundedQueryVariables>({
                query: IS_ORDER_REFUNDED,
                variables: { orderId },
            })
            .toPromise();
        // look for non refunded item, and return false
        for (const orderItem of resp.data.order_item) {
            if (orderItem.refund_status !== 'Refunded') {
                return false;
            }
        }
        // default
        return true;
    }

    async getOrderEventForAnalytics(orderId: string): Promise<SegmentOrderEventModel> {
        const orderResp = await this.findById(orderId);
        const order = orderResp.data.order[0];
        const products: SegmentOrderProductModel[] = order.order_items.map((item) => {
            if (item.campaign) {
                return {
                    product_id: item.campaign.id,
                    sku: item.campaign.code,
                    name: item.campaign.name,
                    price: item.price / 100, // turn cents into dollars
                    quantity: item.item_qty,
                    category: 'Campaign',
                };
            }
            if (item.addon) {
                return {
                    product_id: item.addon.id,
                    sku: item.addon.code,
                    name: item.addon.name,
                    price: item.price / 100, // turn cents into dollars
                    quantity: item.item_qty,
                    category: 'Addon',
                };
            }
        });
        const event: SegmentOrderEventModel = {
            orderId,
            listingId: order.listing_id,
            order_id: orderId,
            total: order.order_total / 100, // turn cents into dollars
            products,
        };
        return event;
    }

    async checkAddonPurchased(
        listingId: string,
        addonCodes: string[]
    ): Promise<ApolloQueryResult<CheckAddonPurchasedQuery>> {
        return await this.apollo
            .query<CheckAddonPurchasedQuery, CheckAddonPurchasedQueryVariables>({
                query: CHECK_ADDON_PURCHASED,
                variables: { listingId, addonCodes },
            })
            .toPromise();
    }

    async getPurchasedAddonQtyByListingId(
        listingId: string,
        addonCode: string
    ): Promise<ApolloQueryResult<GetPurchasedAddonQtyByListingIdQuery>> {
        return await this.apollo
            .query<GetPurchasedAddonQtyByListingIdQuery, GetPurchasedAddonQtyByListingIdQueryVariables>({
                query: GET_PURCHASED_ADDON_QTY_BY_LISTING_ID,
                variables: { listingId, addonCode },
            })
            .toPromise();
    }

    async getOpenOrdersForUser(): Promise<ApolloQueryResult<GetAllOpenOrdersQuery>> {
        return await this.apollo
            .query<GetAllOpenOrdersQuery, GetAllOpenOrdersQueryVariables>({
                query: GET_ALL_OPEN_ORDERS_FOR_USER,
                variables: {},
            })
            .toPromise();
    }

    async hasPaidOrder(listingId: string): Promise<boolean> {
        const resp = await this.apollo
            .query<HasPaidOrderQuery, HasPaidOrderQueryVariables>({
                query: HAS_PAID_ORDER,
                variables: {
                    listingId,
                },
            })
            .toPromise();
        return !!resp.data.listing_by_pk.orders.length;
    }
}
