"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".user-info-container[_ngcontent-%COMP%]{display:flex;flex-direction:row;justify-content:center}.user-info-overlay[_ngcontent-%COMP%]{background:#fff;box-shadow:0 3px 5px rgba(0,0,0,.2),0 1px 18px rgba(0,0,0,.12),0 6px 10px rgba(0,0,0,.14);border-radius:4px;display:flex;flex-direction:column;align-items:center}.impersonating-title[_ngcontent-%COMP%]{display:flex;flex-direction:row;justify-content:center;font-size:12px;letter-spacing:.18px;padding-top:14px}.user-name[_ngcontent-%COMP%]{font-weight:500;font-size:16px;line-height:24px;letter-spacing:.18px;color:#000d52;text-align:center;width:258px;overflow-wrap:break-word;padding-left:24px;padding-right:24px}@media screen and (max-width:599px){.user-name[_ngcontent-%COMP%]{width:176px}}.user-email[_ngcontent-%COMP%]{font-size:13px;line-height:24px;text-align:center;letter-spacing:.18px;color:#000d52;margin-bottom:8px;width:258px;overflow-wrap:break-word;padding-left:24px;padding-right:24px}@media screen and (max-width:599px){.user-email[_ngcontent-%COMP%]{width:176px}}.logout-btn[_ngcontent-%COMP%]{margin-top:22px;width:128px;margin-bottom:24px}.lock-icon[_ngcontent-%COMP%]{height:100%}.padding-top-place-holder[_ngcontent-%COMP%]{height:38px}"];
exports.styles = styles;
