"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const graphql_tag_1 = require("graphql-tag");
const i0 = require("@angular/core");
const i1 = require("apollo-angular");
const CREATE_ORDER_MUTATION = graphql_tag_1.default `
    mutation createOrder($listingId: uuid!, $campaignId: uuid, $createdFromTemplate: Boolean) {
        create_order(listingId: $listingId, campaignId: $campaignId, createdFromTemplate: $createdFromTemplate) {
            id
        }
    }
`;
const GET_ORDER_BY_ID_QUERY = graphql_tag_1.default `
    query getOrderById($orderId: uuid!) {
        order: order_detail(where: { id: { _eq: $orderId } }) {
            id
            listing_id
            status
            created_at
            updated_at
            order_total
            created_from_template
            listing {
                id
                enquiry_code
            }
            order_items {
                id
                price
                item_qty
                campaign {
                    id
                    name
                    code
                }
                addon {
                    id
                    name
                    code
                }
                processed_by_id
                qty_processed_by_admin
            }
            transactions {
                id
                amount
                order_id
                origin_id
                origin_type
                origin_status
                status
                json
            }
        }
    }
`;
const GET_ORDER_BY_ID_SUB = graphql_tag_1.default `
    subscription getOrderByIdSub($orderId: uuid!) {
        order: order_detail(where: { id: { _eq: $orderId } }) {
            id
            listing_id
            status
            created_at
            updated_at
            order_total
            order_total_without_discount
            order_items {
                id
                price
                item_qty
                type
                removed
                name
                addon {
                    id
                    name
                    price
                    max_qty
                    min_qty
                    code
                    order: group_order
                    addon_addon_groups {
                        addon_group {
                            id
                            short_title
                            order
                            type
                            campaign: campaign_addon_groups {
                                id: campaign_id
                            }
                        }
                    }
                    inclusion_addons {
                        inclusion {
                            inclusion_group {
                                campaign_inclusion_groups {
                                    campaign_id
                                }
                            }
                        }
                    }
                }
                campaign {
                    id
                    name
                    price
                    price_description
                    code
                }
                coupon {
                    code
                    description
                    campaign_id
                }
                processed_by_id
                qty_processed_by_admin
            }
            transactions {
                id
                amount
                order_id
                origin_id
                origin_type
                origin_status
                status
            }
            paidOrders: listing {
                orders(
                    where: {
                        _and: [{ order_items: { campaign: { id: { _is_null: false } } } }, { status: { _eq: Paid } }]
                    }
                ) {
                    id
                    status
                }
            }
        }
    }
`;
const DELETE_ORDER = graphql_tag_1.default `
    mutation deleteOrder($orderId: uuid!) {
        remove_order(orderId: $orderId) {
            affected_rows
        }
    }
`;
const GET_LISTING_ORDERS = graphql_tag_1.default `
    query getListingOrders($listingId: uuid!) {
        order(where: { listing_id: { _eq: $listingId } }) {
            id
            status
            created_at
            updated_at
            order_items {
                id
                price
                campaign {
                    id
                    name
                }
            }
        }
    }
`;
const ADD_CAMPAIGN_TO_ORDER = graphql_tag_1.default `
    mutation addCampaignToOrder($orderId: uuid!, $campaignId: uuid!) {
        add_campaign_to_order(orderId: $orderId, campaignId: $campaignId) {
            affected_rows
        }
    }
`;
const MODIFY_ORDER_ITEM_ADDON = graphql_tag_1.default `
    mutation modifyOrderItemAddon($addonId: uuid!, $orderId: uuid!, $qty: Int!) {
        modify_order_item_addon(addonId: $addonId, orderId: $orderId, qty: $qty) {
            affected_rows
        }
    }
`;
const GET_UNPAID_ORDER_SUB = graphql_tag_1.default `
    subscription getUnpaidOrderSub($listingId: uuid!) {
        order: order_detail(
            where: { _and: [{ listing_id: { _eq: $listingId } }, { status: { _in: ["Open", "Unpaid"] } }] }
        ) {
            id
            listing_id
            status
            order_total
            order_total_without_discount
            created_from_template
            order_items {
                id
                price
                item_qty
                type
                removed
                name
                addon {
                    id
                    name
                    code
                    price
                    max_qty
                    min_qty
                    order: group_order
                    addon_addon_groups {
                        addon_group {
                            id
                            short_title
                            order
                            type
                        }
                    }
                    inclusion_addons {
                        inclusion {
                            inclusion_group {
                                campaign_inclusion_groups {
                                    campaign_id
                                }
                            }
                        }
                    }
                }
                campaign {
                    id
                    name
                    price
                    price_description
                    code
                }
                coupon {
                    code
                    description
                    campaign_id
                }
                processed_by_id
                qty_processed_by_admin
            }
        }
    }
`;
const GET_TRANSACTION_BY_LISTING_STATUS = graphql_tag_1.default `
    query getTransactionByListingAndStatus($listingId: uuid!, $status: transaction_status_enum) {
        transaction(where: { status: { _eq: $status }, order: { listing_id: { _eq: $listingId } } }) {
            id
            order_id
            origin_id
            origin_type
            order {
                order_items(where: { campaign_id: { _is_null: false } }) {
                    campaign_id
                }
            }
        }
    }
`;
const FIND_OPEN_TRANSACTION_BY_ORDERID = graphql_tag_1.default `
    query findOpenTransactionByOrderId($orderId: uuid!) {
        transaction(where: { order_id: { _eq: $orderId }, status: { _eq: Created } }) {
            id
            order_id
            amount
            status
            origin_status
            origin_id
            origin_type
            payment_url
            json
        }
    }
`;
const REMOVE_ORDER_ITEM = graphql_tag_1.default `
    mutation removeOrderItem($orderItemId: uuid!) {
        delete_order_item_by_pk(id: $orderItemId) {
            id
        }
    }
`;
const REMOVE_ALL_ORDER_ITEMS_FROM_ORDER = graphql_tag_1.default `
    mutation removeAllOrderItemsFromOrder($orderId: uuid!) {
        delete_order_item(where: { order_id: { _eq: $orderId } }) {
            affected_rows
        }
    }
`;
const CLEAR_ADDONS_FROM_ORDER = graphql_tag_1.default `
    mutation clearAddonsFromOrder($orderId: uuid!) {
        clear_addons_from_order(orderId: $orderId) {
            affected_rows
        }
    }
`;
const REQUEST_CANCEL_ORDER = graphql_tag_1.default `
    mutation requestCancelOrder($orderId: uuid!) {
        request_cancel_order(orderId: $orderId) {
            affected_rows
        }
    }
`;
const CANCEL_ORDER = graphql_tag_1.default `
    mutation cancelOrder($orderId: uuid!) {
        cancel_order(orderId: $orderId) {
            affected_rows
        }
    }
`;
const PAY_ORDER = graphql_tag_1.default `
    mutation payOrder($orderId: uuid!) {
        pay_order(orderId: $orderId) {
            affected_rows
        }
    }
`;
const GET_PAID_ORDERS = graphql_tag_1.default `
    query getPaidOrders($listingId: uuid!) {
        order(where: { _and: { listing_id: { _eq: $listingId }, status: { _eq: Paid } } }) {
            id
        }
    }
`;
const GET_OPEN_ORDER = graphql_tag_1.default `
    query getOpenOrders($listingId: uuid!) {
        order(where: { _and: { listing_id: { _eq: $listingId }, status: { _eq: Open } } }) {
            id
            order_items {
                campaign_id
            }
        }
    }
`;
const GET_ADDON_QTY_BY_ID = graphql_tag_1.default `
    query getAddonQtyById($orderId: uuid!, $addonId: uuid!) {
        order_item(where: { _and: { addon_id: { _eq: $addonId }, order_id: { _eq: $orderId } } }) {
            item_qty
        }
    }
`;
const CHECKOUT_ORDER = graphql_tag_1.default `
    mutation checkoutOrder($orderId: uuid!) {
        checkout_order(orderId: $orderId) {
            success
            fromPaymentMethod
            originId
            orderId
        }
    }
`;
const CLEAR_NORMAL_ADDONS_FROM_ORDER = graphql_tag_1.default `
    mutation clearNormalAddonsFromOrder($orderId: uuid!) {
        clear_normal_addons_from_order(orderId: $orderId) {
            affected_rows
        }
    }
`;
const UPDATE_ORDER_TOTAL_BY_ORDER_ID = graphql_tag_1.default `
    mutation updateOrderTotalByOrderId($orderId: uuid!, $newOrderTotal: numeric) {
        update_order_total(orderId: $orderId, newOrderTotal: $newOrderTotal) {
            affected_rows
        }
    }
`;
const UPDATE_ORDER_ITEM_STATUS = graphql_tag_1.default `
    mutation updateOrderItemStatus($id: uuid!, $status: order_status_enum!) {
        update_order_item(where: { id: { _eq: $id } }, _set: { status: $status }) {
            affected_rows
        }
    }
`;
const UPDATE_ORDER_ITEM_REFUND_STATUS = graphql_tag_1.default `
    mutation updateOrderItemRefundStatus($id: uuid!, $status: String) {
        update_order_item(where: { id: { _eq: $id } }, _set: { refund_status: $status }) {
            affected_rows
        }
    }
`;
const UPDATE_ORDER_ITEM_FULFILLMENT_STATUS = graphql_tag_1.default `
    mutation updateOrderItemFulfillmentStatus($id: uuid!, $status: String) {
        update_order_item(where: { id: { _eq: $id } }, _set: { fulfillment_status: $status }) {
            affected_rows
        }
    }
`;
const IS_ORDER_REFUNDED = graphql_tag_1.default `
    query isOrderRefunded($orderId: uuid!) {
        order_item(where: { order_id: { _eq: $orderId } }) {
            refund_status
        }
    }
`;
const CHECK_ADDON_PURCHASED = graphql_tag_1.default `
    query checkAddonPurchased($listingId: uuid!, $addonCodes: [String!]) {
        order_item_aggregate(
            where: {
                addon: { code: { _in: $addonCodes } }
                order: { listing_id: { _eq: $listingId }, status: { _eq: Paid } }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;
const GET_PURCHASED_ADDON_QTY_BY_LISTING_ID = graphql_tag_1.default `
    query getPurchasedAddonQtyByListingId($listingId: uuid!, $addonCode: String!) {
        order_item_aggregate(
            where: {
                order: { listing_id: { _eq: $listingId }, status: { _eq: Paid } }
                addon: { code: { _eq: $addonCode } }
            }
        ) {
            aggregate {
                sum {
                    item_qty
                }
            }
        }
    }
`;
const GET_ALL_OPEN_ORDERS_FOR_USER = graphql_tag_1.default `
    query getAllOpenOrders {
        order(where: { status: { _eq: Open } }, order_by: { updated_at: desc }) {
            listing_id
            created_from_template
            order_items {
                campaign_id
                type
                campaign {
                    code
                }
            }
        }
    }
`;
const HAS_PAID_ORDER = graphql_tag_1.default `
    query hasPaidOrder($listingId: uuid!) {
        listing_by_pk(id: $listingId) {
            orders(where: { status: { _eq: Paid } }) {
                status
            }
        }
    }
`;
class OrderService {
    constructor(apollo) {
        this.apollo = apollo;
    }
    createOrder(listingId, campaignId = null, createdFromTemplate = false) {
        return this.apollo
            .mutate({
            mutation: CREATE_ORDER_MUTATION,
            variables: {
                listingId,
                campaignId,
                createdFromTemplate,
            },
        })
            .toPromise();
    }
    findById(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ORDER_BY_ID_QUERY,
                variables: {
                    orderId,
                },
            })
                .toPromise();
        });
    }
    getOrderByIdSub(orderId) {
        return this.apollo.subscribe({
            query: GET_ORDER_BY_ID_SUB,
            variables: {
                orderId,
            },
        });
    }
    deleteOrder(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: DELETE_ORDER,
                variables: {
                    orderId,
                },
            })
                .toPromise();
        });
    }
    getListingOrders(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_ORDERS,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    addCampaignToOrder(orderId, campaignId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: ADD_CAMPAIGN_TO_ORDER,
                variables: { orderId, campaignId },
            })
                .toPromise();
        });
    }
    modifyOrderItemAddon(orderId, addonId, qty) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: MODIFY_ORDER_ITEM_ADDON,
                variables: {
                    orderId,
                    addonId,
                    qty,
                },
            })
                .toPromise();
        });
    }
    getUnpaidOrderSubscription(listingId) {
        return this.apollo.subscribe({
            query: GET_UNPAID_ORDER_SUB,
            variables: {
                listingId,
            },
        });
    }
    getTransactionByListingAndStatus(listingId, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_TRANSACTION_BY_LISTING_STATUS,
                variables: {
                    listingId,
                    status,
                },
            })
                .toPromise();
        });
    }
    findOpenTransactionByOrderId(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: FIND_OPEN_TRANSACTION_BY_ORDERID,
                variables: { orderId },
            })
                .toPromise();
        });
    }
    clearAddonsFromOrder(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: CLEAR_ADDONS_FROM_ORDER,
                variables: { orderId },
            })
                .toPromise();
        });
    }
    removeAllOrderItemsFromOrder(orderId) {
        return this.apollo
            .mutate({
            mutation: REMOVE_ALL_ORDER_ITEMS_FROM_ORDER,
            variables: { orderId },
        })
            .toPromise();
    }
    removeOrderItem(orderItemId) {
        return this.apollo
            .mutate({
            mutation: REMOVE_ORDER_ITEM,
            variables: {
                orderItemId,
            },
        })
            .toPromise();
    }
    requestCancelOrder(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: REQUEST_CANCEL_ORDER,
                variables: {
                    orderId,
                },
            })
                .toPromise();
        });
    }
    cancelOrder(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: CANCEL_ORDER,
                variables: {
                    orderId,
                },
            })
                .toPromise();
        });
    }
    payOrder(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: PAY_ORDER,
                variables: {
                    orderId,
                },
            })
                .toPromise();
        });
    }
    getPaidOrders(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_PAID_ORDERS,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    getOpenOrder(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_OPEN_ORDER,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    getAddonsQtyById(orderId, addonId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ADDON_QTY_BY_ID,
                variables: {
                    orderId,
                    addonId,
                },
            })
                .toPromise();
        });
    }
    getAnaltyicsAction(orderItems, qtyToAdd) {
        if (orderItems.length) {
            const addOnQty = orderItems[0].item_qty;
            if (addOnQty === 1 && qtyToAdd < 0) {
                return 'Add-on removed from cart';
            }
            return 'Add-on quantity updated';
        }
        return 'Add-on added to cart';
    }
    checkoutOrder(orderId) {
        return this.apollo
            .mutate({
            mutation: CHECKOUT_ORDER,
            variables: {
                orderId,
            },
        })
            .toPromise();
    }
    clearNormalAddonsFromOrder(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: CLEAR_NORMAL_ADDONS_FROM_ORDER,
                variables: { orderId },
            })
                .toPromise();
        });
    }
    updateOrderTotalByOrderId(orderId, newOrderTotal) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_ORDER_TOTAL_BY_ORDER_ID,
                variables: { orderId, newOrderTotal },
            })
                .toPromise();
        });
    }
    updateOrderItemStatus(id, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_ORDER_ITEM_STATUS,
                variables: { id, status },
            })
                .toPromise();
        });
    }
    updateOrderItemRefundStatus(id, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_ORDER_ITEM_REFUND_STATUS,
                variables: { id, status },
            })
                .toPromise();
        });
    }
    updateOrderItemFulfillmentStatus(id, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_ORDER_ITEM_FULFILLMENT_STATUS,
                variables: { id, status },
            })
                .toPromise();
        });
    }
    isOrderRefunded(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: IS_ORDER_REFUNDED,
                variables: { orderId },
            })
                .toPromise();
            // look for non refunded item, and return false
            for (const orderItem of resp.data.order_item) {
                if (orderItem.refund_status !== 'Refunded') {
                    return false;
                }
            }
            // default
            return true;
        });
    }
    getOrderEventForAnalytics(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const orderResp = yield this.findById(orderId);
            const order = orderResp.data.order[0];
            const products = order.order_items.map((item) => {
                if (item.campaign) {
                    return {
                        product_id: item.campaign.id,
                        sku: item.campaign.code,
                        name: item.campaign.name,
                        price: item.price / 100,
                        quantity: item.item_qty,
                        category: 'Campaign',
                    };
                }
                if (item.addon) {
                    return {
                        product_id: item.addon.id,
                        sku: item.addon.code,
                        name: item.addon.name,
                        price: item.price / 100,
                        quantity: item.item_qty,
                        category: 'Addon',
                    };
                }
            });
            const event = {
                orderId,
                listingId: order.listing_id,
                order_id: orderId,
                total: order.order_total / 100,
                products,
            };
            return event;
        });
    }
    checkAddonPurchased(listingId, addonCodes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.apollo
                .query({
                query: CHECK_ADDON_PURCHASED,
                variables: { listingId, addonCodes },
            })
                .toPromise();
        });
    }
    getPurchasedAddonQtyByListingId(listingId, addonCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.apollo
                .query({
                query: GET_PURCHASED_ADDON_QTY_BY_LISTING_ID,
                variables: { listingId, addonCode },
            })
                .toPromise();
        });
    }
    getOpenOrdersForUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.apollo
                .query({
                query: GET_ALL_OPEN_ORDERS_FOR_USER,
                variables: {},
            })
                .toPromise();
        });
    }
    hasPaidOrder(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: HAS_PAID_ORDER,
                variables: {
                    listingId,
                },
            })
                .toPromise();
            return !!resp.data.listing_by_pk.orders.length;
        });
    }
}
OrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.ɵɵinject(i1.Apollo)); }, token: OrderService, providedIn: "root" });
exports.OrderService = OrderService;
