"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class QuickPanelComponent {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true,
        };
        // Set the private defaults
        this._unsubscribeAll = new rxjs_1.Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Subscribe to the events
        this._httpClient
            .get('api/quick-panel-events')
            .pipe(operators_1.takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
            this.events = response;
        });
        // Subscribe to the notes
        this._httpClient
            .get('api/quick-panel-notes')
            .pipe(operators_1.takeUntil(this._unsubscribeAll))
            .subscribe((response) => {
            this.notes = response;
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
exports.QuickPanelComponent = QuickPanelComponent;
