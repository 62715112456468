"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["quick-panel{display:flex;flex-direction:column;flex:1 1 auto;width:280px;min-width:280px;max-width:280px;z-index:99}quick-panel .mat-slide-toggle-content{flex:1}"];
exports.styles = styles;
