"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const graphql_tag_1 = require("graphql-tag");
const graphql_1 = require("../../generated/graphql");
const operators_1 = require("rxjs/operators");
const listing_model_1 = require("app/shared/models/listing.model");
const campaign_model_1 = require("app/main/pages/campaign/campaign.model");
const i0 = require("@angular/core");
const i1 = require("apollo-angular");
const i2 = require("./experiment.service");
const i3 = require("./listing-onboarding.service");
const i4 = require("./listing.service");
const i5 = require("./analytics.service");
class CampaignService {
    constructor(apollo, experimentService, listingOnboardingService, listingService, analytics) {
        this.apollo = apollo;
        this.experimentService = experimentService;
        this.listingOnboardingService = listingOnboardingService;
        this.listingService = listingService;
        this.analytics = analytics;
    }
    getCampaigns(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const listingSaleType = (yield this.apollo
                .query({
                query: GET_LISTING_SALE_TYPE,
                variables: {
                    listingId,
                },
            })
                .toPromise()).data.listing[0].saleType;
            let campaignCodes;
            if (listingSaleType === listing_model_1.SaleType.Sale) {
                campaignCodes = campaign_model_1.SALE_CAMPAIGNS;
            }
            else {
                campaignCodes = campaign_model_1.RENT_CAMPAIGNS;
            }
            return this.apollo
                .query({
                query: GET_CAMPAIGNS,
                variables: {
                    campaignCodes,
                },
            })
                .toPromise();
        });
    }
    getCampaignsSub() {
        return this.apollo.subscribe({
            query: GET_CAMPAIGNS_SUB,
            variables: {},
        });
    }
    createCampaign(id, name, price, desc = null, priceDescription = '', type = '', order = 1, code, image = '') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            price = price * 100;
            return this.apollo
                .mutate({
                mutation: CREATE_CAMPAIGN,
                variables: {
                    id,
                    name,
                    price,
                    code,
                    desc,
                    priceDescription,
                    type,
                    order,
                    image,
                },
            })
                .toPromise();
        });
    }
    deleteCampaign(campaignId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: DELETE_CAMPAIGN,
                variables: { campaignId },
            })
                .toPromise();
        });
    }
    getCampaignAddonGroups(campaignId) {
        return this.apollo.query({
            query: GET_CAMPAIGN_ADDON_GROUPS,
            variables: {
                campaignId,
            },
        });
    }
    getAllCampaignRecommendations(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.apollo
                .query({
                query: GET_ALL_CAMPAIGN_INCLUSIONS_QUERY,
                variables: {},
            })
                .toPromise();
            const listingContext = yield this.getListingAdvisorContext(listingId);
            for (const campaign of response.data.campaign) {
                for (const inclusionGroup of campaign.campaign_inclusion_groups) {
                    // filter the inclusions based on metro and zone
                    inclusionGroup.inclusion_group.inclusions = inclusionGroup.inclusion_group.inclusions.filter((o) => (!o.metro || o.metro === listingContext.metro) && (!o.zone || o.zone === listingContext.zone));
                    // remove the group without inclusions
                    if (!inclusionGroup.inclusion_group.inclusions.length) {
                        campaign.campaign_inclusion_groups.splice(campaign.campaign_inclusion_groups.indexOf(inclusionGroup), 1);
                    }
                }
            }
            return response;
        });
    }
    getAllCampaignNoInclusion(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.apollo
                .query({
                query: GET_ALL_CAMPAIGN_NO_INCLUSION,
                variables: {},
            })
                .toPromise();
            return response.data.campaign;
        });
    }
    getInclusionsByCampaignId(campaignId, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.apollo
                .query({
                query: GET_INCLUSIONS_BY_CAMPAIGN_ID_QUERY,
                variables: {
                    campaignId,
                },
            })
                .toPromise();
            const listingContext = yield this.getListingAdvisorContext(listingId);
            for (const inclusionGroup of response.data.campaign_inclusion_group) {
                // filter the inclusions based on metro and zone
                inclusionGroup.inclusion_group.inclusions = inclusionGroup.inclusion_group.inclusions.filter((o) => (!o.metro || o.metro === listingContext.metro) && (!o.zone || o.zone === listingContext.zone));
                // remove the group, if dont have any inclusions
                if (!inclusionGroup.inclusion_group.inclusions.length) {
                    response.data.campaign_inclusion_group.splice(response.data.campaign_inclusion_group.indexOf(inclusionGroup), 1);
                }
            }
            return response;
        });
    }
    getAddonsByAddonGroupId(addonGroupId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ADDONS_BY_ADDON_GROUP_ID_QUERY,
                variables: {
                    addonGroupId,
                },
            })
                .toPromise();
        });
    }
    getCampaignById(campaignId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: GET_CAMPAIGN_BY_ID,
                variables: {
                    campaignId,
                },
            })
                .toPromise();
            return resp.data.campaign_by_pk;
        });
    }
    getAddonGroupsByCampaignId(campaignId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ADDON_GROUPS_BY_CAMPAIGN_ID_QUERY,
                variables: {
                    campaignId,
                },
            })
                .toPromise();
        });
    }
    getAddonGroupById(addonGroupId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.apollo
                .query({
                query: GET_ADDON_GROUP_BY_ID,
                variables: {
                    addonGroupId,
                },
            })
                .toPromise();
        });
    }
    getAddonByCampaignId(campaignId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ADDON_BY_CAMPAIGN_ID,
                variables: { campaignId },
            })
                .toPromise();
        });
    }
    getRecommendedCampaignByListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.apollo
                .query({
                query: GET_RECOMMENDED_CAMPAIGNS_BY_LISTING,
                variables: { listingId },
            })
                .pipe(operators_1.map((resp) => {
                // we need to sort campaigns by the index
                // the one with lower index should be shown Primary campaign
                if (resp.data && resp.data.recommended_campaigns && resp.data.recommended_campaigns.length) {
                    resp.data.recommended_campaigns.sort((a, b) => {
                        return a.index - b.index;
                    });
                }
                return resp;
            }))
                .toPromise();
            //
            // Recommendation_A: Present cheaper options first for users with low budget
            //
            // get user experiments
            const userExperiments = yield this.experimentService.getCurrentUserExperiments();
            const budgetResp = yield this.listingOnboardingService.getBudgetSize({ listingId });
            if (budgetResp.data.listing_onboarding[0].budget_size === graphql_1.Onboarding_Budget_Size_Enum.Small &&
                userExperiments.includes(graphql_1.Experiment_Name_Enum.RecommendationA)) {
                // sort campaigns by price starting from cheapest
                response.data.recommended_campaigns.sort((a, b) => {
                    return a.campaign.price - b.campaign.price;
                });
                // Track event
                this.analytics.trackEvent('Experiment Viewed', {
                    experiment_name: 'Recommendation',
                    variation_name: 'Recommendation A',
                });
                // For Mixpanel
                this.analytics.trackEvent('$experiment_started', {
                    'Experiment name': 'Recommendation',
                    'Variant name': 'Recommendation A',
                });
            }
            // End of Recommendation_A
            //
            // Recommendation_B: Present cheaper options first for users outside of Zone 1
            //
            // get zone
            const zone = yield this.listingService.getListingZone(listingId);
            if (zone !== graphql_1.Zone_Type_Enum.Zone_1 && userExperiments.includes(graphql_1.Experiment_Name_Enum.RecommendationB)) {
                // sort campaigns by price starting from cheapest
                response.data.recommended_campaigns.sort((a, b) => {
                    return a.campaign.price - b.campaign.price;
                });
                // Track event
                this.analytics.trackEvent('Experiment Viewed', {
                    experiment_name: 'Recommendation',
                    variation_name: 'Recommendation B',
                });
                // For Mixpanel
                this.analytics.trackEvent('$experiment_started', {
                    'Experiment name': 'Recommendation',
                    'Variant name': 'Recommendation B',
                });
            }
            // End of Recommendation_B
            //
            // Recommendation_C: Present cheaper options first for users regardless of recommendation
            //
            if (userExperiments.includes(graphql_1.Experiment_Name_Enum.RecommendationC)) {
                // sort campaigns by price starting from cheapest
                response.data.recommended_campaigns.sort((a, b) => {
                    return a.campaign.price - b.campaign.price;
                });
                // Track event
                this.analytics.trackEvent('Experiment Viewed', {
                    experiment_name: 'Recommendation',
                    variation_name: 'Recommendation C',
                });
                // For Mixpanel
                this.analytics.trackEvent('$experiment_started', {
                    'Experiment name': 'Recommendation',
                    'Variant name': 'Recommendation C',
                });
            }
            // End of Recommendation C
            return response;
        });
    }
    getRecommendedCampaignURL(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const campaignIds = (yield this.getRecommendedCampaignByListing(listingId)).data.recommended_campaigns.map((o) => o.campaign.id);
                const campaignId = campaignIds[0];
                return ['/pages/listing', listingId, 'campaign', campaignId].join('/');
            }
            catch (e) {
                console.error(e);
                alert('Unable to get recommended campaign, try refreshing the page');
            }
        });
    }
    getRecommendedAddonByCampaignCode(campaignCode, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE,
                variables: { campaign_code: campaignCode, listingId },
            })
                .pipe(operators_1.map((resp) => {
                // we need to sort campaigns by the index
                // the one with lower index should be shown Primary campaign
                if (resp.data && resp.data.recommended_addons && resp.data.recommended_addons.length) {
                    resp.data.recommended_addons.sort((a, b) => {
                        return a.index - b.index;
                    });
                    // update prices of the addons, if have any special prices
                    resp.data.recommended_addons.map((addon) => {
                        if (addon.use_special_price) {
                            addon.addon.price = addon.special_price;
                        }
                    });
                }
                return resp;
            }))
                .toPromise();
        });
    }
    getBasicRecommendedAddonByCampaignCode(campaignCode, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_BASIC_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE,
                variables: { campaign_code: campaignCode, listingId },
            })
                .pipe(operators_1.map((resp) => {
                if (resp.data && resp.data.recommended_addons && resp.data.recommended_addons.length) {
                    // update prices of the addons, if have any special prices
                    resp.data.recommended_addons.map((addon) => {
                        if (addon.use_special_price) {
                            addon.addon.price = addon.special_price;
                        }
                    });
                }
                return resp;
            }))
                .toPromise();
        });
    }
    getAddonGroupsByAddonCode(campaignId, addonCodes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ADDON_GROUP_BY_CODE,
                variables: { campaign_id: campaignId, addon_codes: addonCodes },
            })
                .pipe(operators_1.map((resp) => {
                // we need to sort addons based on the addon codes passed into this method
                if (resp.data && resp.data.addon_group && resp.data.addon_group.length) {
                    for (const addonGroup of resp.data.addon_group) {
                        addonGroup.addons.sort((a, b) => {
                            return addonCodes.indexOf(a.addon.code) - addonCodes.indexOf(b.addon.code);
                        });
                    }
                }
                return resp;
            }))
                .toPromise();
        });
    }
    getListingAdvisorContext(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return (yield this.apollo
                .query({
                query: GET_LISTING_ADVISOR_CONTEXT,
                variables: {
                    listingId,
                },
            })
                .toPromise()).data.get_listing_advisor_context;
        });
    }
    getCampaignByCode(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: GET_CAMPAIGN_BY_CODE,
                variables: {
                    code,
                },
            })
                .toPromise();
            return resp.data.campaign[0];
        });
    }
    getAddonById(addOnId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: GET_ADDON_BY_ID,
                variables: { addOnId },
            })
                .toPromise();
            return resp.data.addon_by_pk;
        });
    }
}
CampaignService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampaignService_Factory() { return new CampaignService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.ExperimentService), i0.ɵɵinject(i3.ListingOnboardingService), i0.ɵɵinject(i4.ListingService), i0.ɵɵinject(i5.AnalyticsService)); }, token: CampaignService, providedIn: "root" });
exports.CampaignService = CampaignService;
//
// Queries
//
const GET_CAMPAIGNS_SUB = graphql_tag_1.default `
    subscription getCampaignsSub {
        campaign {
            id
            name
            desc
            price
        }
    }
`;
const GET_CAMPAIGNS = graphql_tag_1.default `
    query getCampaigns($campaignCodes: [String!]) {
        campaign(where: { code: { _in: $campaignCodes } }) {
            id
            name
            desc
            price
            code
        }
    }
`;
const CREATE_CAMPAIGN = graphql_tag_1.default `
    mutation createCampaign(
        $id: uuid!
        $name: String!
        $price: Int!
        $desc: String
        $priceDescription: String
        $type: String
        $order: Int!
        $code: String!
        $image: String!
    ) {
        insert_campaign(
            objects: [
                {
                    id: $id
                    name: $name
                    price: $price
                    desc: $desc
                    price_description: $priceDescription
                    type: $type
                    order: $order
                    code: $code
                    image: $image
                }
            ]
        ) {
            returning {
                id
            }
        }
    }
`;
const DELETE_CAMPAIGN = graphql_tag_1.default `
    mutation deleteCampaign($campaignId: uuid!) {
        delete_campaign(where: { id: { _eq: $campaignId } }) {
            affected_rows
        }
    }
`;
const GET_CAMPAIGN_ADDON_GROUPS = graphql_tag_1.default `
    query getCampaignAddonGroup($campaignId: uuid!) {
        campaign: campaign_by_pk(id: $campaignId) {
            id
            name
            desc
            price
        }
        addonGroups: campaign_addon_group(
            where: { campaign_id: { _eq: $campaignId } }
            order_by: { addon_group: { order: asc } }
        ) {
            addon_group {
                id
                order
                title
                subtitle
                type
                short_title
                addons: addon_addon_groups(order_by: { addon: { type: asc, group_order: asc } }) {
                    addon {
                        id
                        name
                        type
                        group_order
                        default_status
                        image
                        price
                        min_qty
                        max_qty
                        front_desc_small_screen
                        front_desc_large_screen
                        back_desc_small_screen
                        back_desc_large_screen
                        recommended_flag
                    }
                }
            }
        }
    }
`;
const GET_ADDON_GROUPS_BY_CAMPAIGN_ID_QUERY = graphql_tag_1.default `
    query getAddonGroupsByCampaignId($campaignId: uuid!) {
        addonGroups: campaign_addon_group(
            where: { campaign_id: { _eq: $campaignId } }
            order_by: { addon_group: { order: asc } }
        ) {
            id
            addon_group_id
        }
    }
`;
const GET_ALL_CAMPAIGN_INCLUSIONS_QUERY = graphql_tag_1.default `
    query GetAllCampaignInclusions {
        campaign(order_by: { order: asc }) {
            type
            desc
            price
            price_description
            id
            code
            name
            image
            image_nonprimary_hero_apartment
            image_nonprimary_hero_house
            image_nonprimary_hero_land
            image_nonprimary_hero_townhouse
            image_nonprimary_hero_unit
            campaign_inclusion_groups(order_by: { inclusion_group: { order: asc } }) {
                inclusion_group {
                    inclusions(order_by: { order: asc }) {
                        name
                        description
                        order
                        metro
                        zone
                    }
                }
            }
        }
    }
`;
const GET_ALL_CAMPAIGN_NO_INCLUSION = graphql_tag_1.default `
    query GetAllCampaignNoInclusions {
        campaign(order_by: { order: asc }) {
            type
            desc
            price
            price_description
            id
            code
            name
            image
            image_nonprimary_hero_apartment
            image_nonprimary_hero_house
            image_nonprimary_hero_land
            image_nonprimary_hero_townhouse
            image_nonprimary_hero_unit
        }
    }
`;
const GET_INCLUSIONS_BY_CAMPAIGN_ID_QUERY = graphql_tag_1.default `
    query getInclusionsByCampaignId($campaignId: uuid!) {
        campaign_inclusion_group(
            where: { campaign_id: { _eq: $campaignId } }
            order_by: { inclusion_group: { order: asc } }
        ) {
            inclusion_group {
                name
                inclusions(order_by: { order: asc }) {
                    name
                    description
                    metro
                    zone
                    inclusion_addons {
                        addon {
                            id
                            min_qty
                            type
                        }
                    }
                }
            }
        }
    }
`;
const GET_ADDONS_BY_ADDON_GROUP_ID_QUERY = graphql_tag_1.default `
    query getAddonsByAddonGroupId($addonGroupId: uuid!) {
        addon_group(where: { id: { _eq: $addonGroupId } }, order_by: { order: asc }) {
            id
            title
            subtitle
            type
            short_title
            addon_addon_groups(order_by: { addon: { group_order: asc } }) {
                addon {
                    id
                    name
                    type
                    default_status
                    image
                    price
                    min_qty
                    max_qty
                    front_desc_small_screen
                    front_desc_large_screen
                    back_desc_small_screen
                    back_desc_large_screen
                    recommended_flag
                    benefits(order_by: { order: asc }) {
                        id
                        description
                    }
                }
            }
        }
    }
`;
const GET_CAMPAIGN_BY_ID = graphql_tag_1.default `
    query getCampaignById($campaignId: uuid!) {
        campaign_by_pk(id: $campaignId) {
            id
            name
            desc
            price
            price_description
            type
            code
            image
            image_primary_hero_house
            image_primary_hero_land
            image_primary_hero_townhouse
            image_primary_hero_apartment
            image_primary_hero_unit
            image_primary_overlay_house
            image_primary_overlay_land
            image_primary_overlay_townhouse
            image_primary_overlay_apartment
            image_primary_overlay_unit
        }
    }
`;
const GET_ADDON_GROUP_BY_ID = graphql_tag_1.default `
    query getAddonGroupById($addonGroupId: uuid!) {
        addon_group: addon_group_by_pk(id: $addonGroupId) {
            id
            title
            subtitle
            short_title
            order
            type
            addons: addon_addon_groups {
                addon {
                    id
                    image
                    max_qty
                    min_qty
                    name
                    price
                    type
                    recommended_flag
                    group_order
                    front_desc_small_screen
                    front_desc_large_screen
                    default_status
                    back_desc_small_screen
                    back_desc_large_screen
                    absolute_order
                    exclusion_group
                    benefits(order_by: { order: asc }) {
                        id
                        description
                        order
                    }
                }
            }
        }
    }
`;
const GET_ADDON_BY_CAMPAIGN_ID = graphql_tag_1.default `
    query getCampaignAddons($campaignId: uuid!) {
        addon(
            where: {
                addon_addon_groups: { addon_group: { campaign_addon_groups: { campaign_id: { _eq: $campaignId } } } }
            }
        ) {
            id
        }
    }
`;
const GET_RECOMMENDED_CAMPAIGNS_BY_LISTING = graphql_tag_1.default `
    query getRecommendedCampaignsByListing($listingId: uuid!) {
        recommended_campaigns(listingId: $listingId) {
            index
            campaign_code
            campaign {
                id
                code
                name
                price
            }
        }
    }
`;
const GET_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE = graphql_tag_1.default `
    query getRecommendedAddonsByCampaignCode($campaign_code: String!, $listingId: uuid!) {
        recommended_addons(campaign_code: $campaign_code, listingId: $listingId) {
            addon_code
            index
            use_special_price
            special_price
            addon {
                id
                image
                max_qty
                min_qty
                name
                price
                type
                recommended_flag
                group_order
                front_desc_small_screen
                front_desc_large_screen
                default_status
                back_desc_small_screen
                back_desc_large_screen
                absolute_order
                exclusion_group
                is_one_time_purchase
                code
                benefits(order_by: { order: asc }) {
                    id
                    description
                    order
                }
            }
        }
    }
`;
const GET_ADDON_GROUP_BY_CODE = graphql_tag_1.default `
    query getAddonGroupsByAddonCode($campaign_id: uuid!, $addon_codes: [String!]) {
        addon_group(
            where: {
                campaign_addon_groups: { campaign_id: { _eq: $campaign_id } }
                addon_addon_groups: { addon: { code: { _in: $addon_codes } } }
            }
            order_by: { order: asc }
        ) {
            id
            title
            subtitle
            short_title
            order
            type
            addons: addon_addon_groups(where: { addon: { code: { _in: $addon_codes } } }) {
                addon {
                    id
                    image
                    max_qty
                    min_qty
                    name
                    price
                    type
                    recommended_flag
                    group_order
                    front_desc_small_screen
                    front_desc_large_screen
                    default_status
                    back_desc_small_screen
                    back_desc_large_screen
                    absolute_order
                    exclusion_group
                    is_one_time_purchase
                    code
                    benefits(order_by: { order: asc }) {
                        id
                        description
                        order
                    }
                }
            }
        }
    }
`;
const GET_LISTING_ADVISOR_CONTEXT = graphql_tag_1.default `
    query getListingAdvisorContext($listingId: uuid!) {
        get_listing_advisor_context(listingId: $listingId) {
            zone
            metro
        }
    }
`;
const GET_BASIC_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE = graphql_tag_1.default `
    query getBasicRecommendedAddonsByCampaignCode($campaign_code: String!, $listingId: uuid!) {
        recommended_addons(campaign_code: $campaign_code, listingId: $listingId) {
            addon_code
            use_special_price
            special_price
            addon {
                id
                price
                type
                min_qty
            }
        }
    }
`;
const GET_CAMPAIGN_BY_CODE = graphql_tag_1.default `
    query getCampaignByCode($code: String!) {
        campaign(where: { code: { _eq: $code } }) {
            id
        }
    }
`;
const GET_LISTING_SALE_TYPE = graphql_tag_1.default `
    query getListingSaleType($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            saleType
        }
    }
`;
const GET_ADDON_BY_ID = graphql_tag_1.default `
    query getAddonById($addOnId: uuid!) {
        addon_by_pk(id: $addOnId) {
            id
            name
            price
            image
            code
        }
    }
`;
