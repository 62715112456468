"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
    UserRole["StaffUser"] = "staff_user";
    UserRole["DeActivatedUser"] = "deactivated_user";
    UserRole["UnverifiedUser"] = "unverified_user";
    UserRole["User"] = "user";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
