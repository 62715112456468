"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("@angular/core");
const i1 = require("./jwt.service");
const i2 = require("@angular/router");
class RouterDefaultGuardService {
    constructor(jwt, router) {
        this.jwt = jwt;
        this.router = router;
    }
    canActivate(route, state) {
        if (this.jwt.isAuthenticated()) {
            if (this.jwt.isAdmin()) {
                this.router.navigate(['/admin/dashboard/users']);
                if (this.jwt.isAdminImpersonatingUser()) {
                    this.router.navigate([`/pages/listings-management`]);
                }
            }
            else {
                this.router.navigate(['/pages/listings-management']);
            }
        }
        else {
            this.router.navigate([`/auth/login`]);
        }
        return false;
    }
}
RouterDefaultGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouterDefaultGuardService_Factory() { return new RouterDefaultGuardService(i0.ɵɵinject(i1.JwtService), i0.ɵɵinject(i2.Router)); }, token: RouterDefaultGuardService, providedIn: "root" });
exports.RouterDefaultGuardService = RouterDefaultGuardService;
