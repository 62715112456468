"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const graphql_tag_1 = require("graphql-tag");
const jwt_decode = require("jwt-decode");
const i0 = require("@angular/core");
const i1 = require("apollo-angular");
class ExperimentService {
    constructor(apollo) {
        this.apollo = apollo;
    }
    /**
     * Returns array of experiments enabled for this user, ex.: ['Onboarding_A', 'Recommendation_B', ...]
     */
    getCurrentUserExperiments() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Support for admin super panel. We can override user experiments there
            if (sessionStorage.experiments) {
                const sessionExperiments = JSON.parse(sessionStorage.experiments);
                const expNames = Object.keys(sessionExperiments);
                return expNames.filter((expName) => sessionExperiments[expName]);
            }
            const token = sessionStorage.impersonateToken || localStorage.token;
            const decoded = jwt_decode(token);
            const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
            if (!userId) {
                return null;
            }
            const resp = yield this.apollo
                .query({
                query: GET_USER_EXPERIMENTS,
                variables: { userId },
            })
                .toPromise();
            if (resp.errors) {
                console.error(resp.errors);
            }
            return resp.data.user_by_pk.experiments || [];
        });
    }
    getCurrentUserUniqueNumber() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const token = sessionStorage.impersonateToken || localStorage.token;
            const decoded = jwt_decode(token);
            const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
            if (!userId) {
                return null;
            }
            const resp = yield this.apollo
                .query({
                query: GET_USER_UNIQUE_NUMBER,
                variables: { userId },
            })
                .toPromise();
            if (resp.errors) {
                console.error(resp.errors);
            }
            return resp.data.user_by_pk.unique_number;
        });
    }
    getExperimentNames() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: GET_EXPERIMENT_NAMES,
            })
                .toPromise();
            return resp.data.experiment.map((e) => e.name);
        });
    }
    getExperiments() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: GET_EXPERIMENTS,
            })
                .toPromise();
            if (resp.errors) {
                console.error(resp.errors);
            }
            return resp.data.experiment;
        });
    }
    saveTemporaryExperiments(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            sessionStorage.experiments = JSON.stringify(value);
        });
    }
    resetTemporaryExperiments() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            sessionStorage.experiments = '';
        });
    }
}
ExperimentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExperimentService_Factory() { return new ExperimentService(i0.ɵɵinject(i1.Apollo)); }, token: ExperimentService, providedIn: "root" });
exports.ExperimentService = ExperimentService;
//
// Queries
//
const GET_USER_EXPERIMENTS = graphql_tag_1.default `
    query getUserExperiments($userId: uuid!) {
        user_by_pk(id: $userId) {
            experiments
        }
    }
`;
const GET_USER_UNIQUE_NUMBER = graphql_tag_1.default `
    query getUserUniqueNumber($userId: uuid!) {
        user_by_pk(id: $userId) {
            unique_number
        }
    }
`;
const GET_EXPERIMENT_NAMES = graphql_tag_1.default `
    query getExperimentNames {
        experiment(order_by: { name: asc }) {
            name
        }
    }
`;
const GET_EXPERIMENTS = graphql_tag_1.default `
    query getExperiments {
        experiment(order_by: { name: asc }) {
            name
            desc
        }
    }
`;
