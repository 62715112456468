<h1 class="headline-1-bold" matDialogTitle>{{data.title}}</h1>
<div class="cancel-discard-save-dialog__text" [innerHTML]="data.text" mat-dialog-content></div>
<div mat-dialog-actions style="margin-bottom: -8px">
    <!-- Desktop version -->
    <div fxShow.gt-xs fxHide.xs class="cancel-discard-save-dialog__desktop">
        <button color="accent" mat-button (click)="dialogRef.close(dialogStates.Cancel)" tabindex="1"
                class="cancel-discard-save-dialog__cancel-btn">
            Cancel
        </button>
        <button (click)="closeDialog(dialogStates.Discard)" class="mr-16" color="accent" mat-stroked-button
                tabindex="2"
                style="margin-left: auto">
            Discard changes
        </button>
        <button (click)="closeDialog(dialogStates.Save)" color="accent" mat-raised-button tabindex="3">
            Save and continue
        </button>
    </div>
    <!-- Mobile version -->
    <div fxShow.xs fxHide.gt-xs class="cancel-discard-save-dialog__mobile">
       <div class="cancel-discard-save-dialog__save-mobile-wrapper">
           <button (click)="closeDialog(dialogStates.Save)" color="accent" mat-raised-button tabindex="1">
               Save and continue
           </button>
       </div>
        <div class="cancel-discard-save-dialog__cancel-discard-wrapper">
            <button (click)="closeDialog(dialogStates.Discard)" color="accent" mat-stroked-button
                    tabindex="2">
                Discard changes
            </button>
            <button color="accent" mat-button (click)="dialogRef.close(dialogStates.Cancel)" tabindex="3"
                    class="cancel-discard-save-dialog__cancel-btn">
                Cancel
            </button>
        </div>
    </div>
</div>