"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./navbar.component.scss.ngstyle");
const i1 = require("@angular/core");
const i2 = require("./horizontal/style-1/style-1.component.ngfactory");
const i3 = require("./horizontal/style-1/style-1.component");
const i4 = require("../../../../@fuse/services/config.service");
const i5 = require("../../../../@fuse/components/navigation/navigation.service");
const i6 = require("../../../../@fuse/components/sidebar/sidebar.service");
const i7 = require("./vertical/style-1/style-1.component.ngfactory");
const i8 = require("./vertical/style-1/style-1.component");
const i9 = require("@angular/router");
const i10 = require("../../../service/jwt.service");
const i11 = require("../../../service/user.service");
const i12 = require("./vertical/style-2/style-2.component.ngfactory");
const i13 = require("./vertical/style-2/style-2.component");
const i14 = require("@angular/common");
const i15 = require("./navbar.component");
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NavbarComponent, data: {} });
exports.RenderType_NavbarComponent = RenderType_NavbarComponent;
function View_NavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "navbar-horizontal-style-1", [], null, null, null, i2.View_NavbarHorizontalStyle1Component_0, i2.RenderType_NavbarHorizontalStyle1Component)), i1.ɵdid(2, 245760, null, 0, i3.NavbarHorizontalStyle1Component, [i4.FuseConfigService, i5.FuseNavigationService, i6.FuseSidebarService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_NavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "navbar-vertical-style-1", [], null, null, null, i7.View_NavbarVerticalStyle1Component_0, i7.RenderType_NavbarVerticalStyle1Component)), i1.ɵdid(2, 245760, null, 0, i8.NavbarVerticalStyle1Component, [i4.FuseConfigService, i5.FuseNavigationService, i6.FuseSidebarService, i9.Router, i10.JwtService, i11.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_NavbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "navbar-vertical-style-2", [], null, null, null, i12.View_NavbarVerticalStyle2Component_0, i12.RenderType_NavbarVerticalStyle2Component)), i1.ɵdid(2, 245760, null, 0, i13.NavbarVerticalStyle2Component, [i4.FuseConfigService, i5.FuseNavigationService, i6.FuseSidebarService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarComponent_3)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.variant === "horizontal-style-1"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.variant === "vertical-style-1"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.variant === "vertical-style-2"); _ck(_v, 5, 0, currVal_2); }, null); }
exports.View_NavbarComponent_0 = View_NavbarComponent_0;
function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i15.NavbarComponent, [i1.ElementRef, i1.Renderer2], null, null)], null, null); }
exports.View_NavbarComponent_Host_0 = View_NavbarComponent_Host_0;
var NavbarComponentNgFactory = i1.ɵccf("navbar", i15.NavbarComponent, View_NavbarComponent_Host_0, { variant: "variant" }, {}, []);
exports.NavbarComponentNgFactory = NavbarComponentNgFactory;
