"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["form[_ngcontent-%COMP%]{padding-top:24px}form[_ngcontent-%COMP%]   mat-form-field[_ngcontent-%COMP%]{width:100%}.input-dialog__buttons[_ngcontent-%COMP%]{position:relative;padding-top:24px;width:100%}.input-dialog__checkbox[_ngcontent-%COMP%]{display:none;position:absolute;left:0;bottom:8px}.input-dialog__checkbox--visible[_ngcontent-%COMP%]{display:block}"];
exports.styles = styles;
