"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const core_1 = require("@angular/core");
const router_1 = require("@angular/router");
const operators_1 = require("rxjs/operators");
const graphql_1 = require("generated/graphql");
const base_component_1 = require("app/shared/base/base.component");
const forms_1 = require("@angular/forms");
const campaign_model_1 = require("app/main/pages/campaign/campaign.model");
class AdminSuperpowerPanelComponent extends base_component_1.BaseComponent {
    constructor(listingService, orderService, router, _dialogService, _fuseSidebarService, campaignService, userService, experimentService) {
        super();
        this.listingService = listingService;
        this.orderService = orderService;
        this.router = router;
        this._dialogService = _dialogService;
        this._fuseSidebarService = _fuseSidebarService;
        this.campaignService = campaignService;
        this.userService = userService;
        this.experimentService = experimentService;
        this.disableOrderTotal = true;
        this.listingId$ = this.router.events.pipe(operators_1.filter((e) => e instanceof router_1.ActivationEnd && Object.keys(e.snapshot.params).length > 0), operators_1.map((e) => (e instanceof router_1.ActivationEnd ? e.snapshot.params : {})), operators_1.map((params) => params.listingId)
        // distinct()
        );
        this.listing$ = this.listingId$.pipe(operators_1.switchMap((id) => {
            this.listingId = id;
            if (this.listingId) {
                this.getUserEmailVerified();
            }
            return this.listingService.getListingBasicDetailsById(id);
        }), operators_1.map((listing) => listing.data.listing[0]));
        this.listingId$
            .pipe(operators_1.takeUntil(this.destroyed$), 
        // Get subscription for order update
        operators_1.switchMap((listingId) => this.orderService.getUnpaidOrderSubscription(listingId)), operators_1.map((order) => {
            if (order.data.order[0]) {
                const campaign = order.data.order[0].order_items.filter((o) => o.campaign)[0];
                return {
                    campaignName: campaign ? campaign.campaign.name : undefined,
                    totalAddons: order.data.order[0].order_items.filter((o) => o.addon).length,
                    order: order.data.order[0],
                };
            }
        }))
            .subscribe((order) => {
            this.allData = order;
            if (this.allData) {
                this.form.get('totalInput').setValue(this.allData.order.order_total / 100);
            }
        });
        router.events
            .pipe(operators_1.takeUntil(this.destroyed$), operators_1.filter((e) => e instanceof router_1.ActivationEnd && e.snapshot !== undefined && e.snapshot.data !== undefined), operators_1.map((e) => (e instanceof router_1.ActivationEnd ? e.snapshot.data : undefined)))
            .subscribe((data) => {
            if (data.adminSuperPanelData) {
                this.config = data.adminSuperPanelData;
            }
        });
        this.form = new forms_1.FormGroup({
            totalInput: new forms_1.FormControl(null, [forms_1.Validators.required, forms_1.Validators.min(0)]),
        });
        this.experimentForm = new forms_1.FormGroup({});
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const experiments = yield this.experimentService.getExperiments();
            this.experimentNames = experiments.map((e) => e.name);
            this.currentUserExperiments = yield this.experimentService.getCurrentUserExperiments();
            this.experimentNames.forEach((expName) => {
                this.experimentForm.addControl(expName, new forms_1.FormControl(this.currentUserExperiments.includes(expName)));
            });
            this.experiments = experiments;
        });
    }
    saveExperiments() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.experimentService.saveTemporaryExperiments(this.experimentForm.value);
            this.saveMsg = 'Saved!';
            setTimeout(() => {
                this.saveMsg = '';
            }, 2000);
        });
    }
    resetExperiments() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.experimentService.resetTemporaryExperiments();
            this.currentUserExperiments = yield this.experimentService.getCurrentUserExperiments();
            this.experimentNames.forEach((expName) => {
                this.experimentForm.get(expName).setValue(this.currentUserExperiments.includes(expName));
            });
            this.saveMsg = 'Done!';
            setTimeout(() => {
                this.saveMsg = '';
            }, 2000);
        });
    }
    toggleSidebarOpen(key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
        this.disableOrderTotal = true;
    }
    edit(order, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const campaign = order.order_items.filter((o) => o.campaign)[0];
            let campaignId;
            if (campaign) {
                if (order.created_from_template) {
                    campaignId = (yield this.campaignService.getCampaignByCode(campaign_model_1.CampaignCodes.BUILD_YOUR_OWN)).id;
                }
                else {
                    campaignId = campaign.campaign.id;
                }
            }
            else {
                const paidOrdersResponse = yield this.listingService.getPaidCampaignOrdersByListingId(listingId);
                if (paidOrdersResponse && paidOrdersResponse.data.order.length > 0) {
                    campaignId = paidOrdersResponse.data.order[0].order_items.filter((o) => o.campaign_id)[0].campaign_id;
                }
            }
            yield this.router.navigate(['/pages/listing/' + listingId + '/campaign/' + campaignId + '/campaign']);
        });
    }
    clearOrder(order, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (order.status === graphql_1.Order_Status_Enum.Open || order.status === graphql_1.Order_Status_Enum.Unpaid) {
                if (yield this.openConfirm('Clear order confirmation', this.config && this.config.clearOrderMessage
                    ? this.config.clearOrderMessage
                    : 'This campaign and all add-ons selected will be removed from this order summary.')) {
                    yield this.orderService.removeAllOrderItemsFromOrder(order.id);
                    if (this.config && this.config.redirectToCampaignRecommendationPage) {
                        const campaign = order.order_items.filter((o) => o.campaign);
                        if (campaign && campaign.length > 0) {
                            yield this.router.navigate([
                                '/pages/listing/' + listingId + '/campaign/' + campaign[0].campaign.id,
                            ]);
                        }
                        else {
                            // if we are here, then it is addon order. hence navigate to listing details page
                            yield this.router.navigate(['/pages/wizard/' + listingId]);
                        }
                    }
                }
            }
        });
    }
    publishListing(listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (yield this.openConfirm('Publish listing confirmation', 'This listing status will appear as ‘pending publishing’ until it is published to the listing portals.')) {
                yield this.listingService.pendingPublishListing(listing.id);
            }
        });
    }
    rejectListing(listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const dialogResult = yield this._dialogService.openInput({
                title: 'Confirm',
                text: 'This listing status will appear as rejected until the user makes corrections and publishes again.',
                isCheckboxVisible: true,
                checkboxLabel: 'Send email to the user',
            });
            if (dialogResult.result) {
                this._dialogService.showLoading('Updating listing...');
                yield this.listingService.failPublishListing(listing.id, dialogResult.checkboxValue, dialogResult.message);
                this._dialogService.hideLoading();
            }
        });
    }
    deleteListing(listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (yield this.openConfirm('Delete listing?', 'This listing will be deleted from our system')) {
                yield this.listingService.updateListingStatusById(listing.id, 'deactivated');
                yield this.router.navigate(['/pages/listings-management']);
            }
        });
    }
    openConfirm(title, text) {
        return this._dialogService.openConfirm(title, text, 'Confirm', 'Cancel');
    }
    saveNewOrderTotal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this._dialogService.showLoading('Updating order total');
            const newTotal = this.form.get('totalInput').value;
            this.disableOrderTotal = !this.disableOrderTotal;
            yield this.orderService.updateOrderTotalByOrderId(this.allData.order.id, newTotal * 100);
            this._dialogService.hideLoading();
        });
    }
    toggleDisableOrderTotal() {
        this.disableOrderTotal = !this.disableOrderTotal;
        this.form.get('totalInput').setValue(this.allData.order.order_total / 100);
    }
    getUserEmailVerified() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userData = (yield this.listingService.getListingUserId(this.listingId)).data.listing[0].user;
            this.userEmailVerified = userData.email_verified;
            this.userId = userData.id;
        });
    }
    setEmailVerified() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (yield this.openConfirm('Email Verification confirmation', `Do you want to mark this user's email as verified?`)) {
                const result = yield this.userService.updateUserEmailVerification(this.userId, true);
                if (result.data.update_user_email_verification.affected_rows > 0) {
                    this.divEmailVerification.nativeElement.style.display = 'none';
                    yield this._dialogService.showAlert('Important', `User's email has been marked as verified`);
                }
            }
        });
    }
}
exports.AdminSuperpowerPanelComponent = AdminSuperpowerPanelComponent;
