import { Component, Input } from '@angular/core';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    /**
     * Constructor
     */
    constructor() {
        this._currentYear = new Date().getFullYear();
    }

    private _isMobile = false;
    _currentYear: number;

    @Input()
    get isMobile(): boolean {
        return this._isMobile;
    }

    set isMobile(isMobile: boolean) {
        this._isMobile = !!isMobile;
    }
}
