"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./logout-overlay.component.scss.shim.ngstyle");
const i1 = require("@angular/core");
const i2 = require("@angular/common");
const i3 = require("../profile-circle/profile-circle.component.ngfactory");
const i4 = require("../profile-circle/profile-circle.component");
const i5 = require("../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
const i6 = require("@angular/material/button");
const i7 = require("@angular/cdk/a11y");
const i8 = require("@angular/platform-browser/animations");
const i9 = require("./logout-overlay.component");
const i10 = require("@angular/material/dialog");
const i11 = require("@angular/router");
const i12 = require("../../../service/jwt.service");
const i13 = require("subscriptions-transport-ws/dist/client");
var styles_LogoutOverlayComponent = [i0.styles];
var RenderType_LogoutOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutOverlayComponent, data: {} });
exports.RenderType_LogoutOverlayComponent = RenderType_LogoutOverlayComponent;
function View_LogoutOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "lock-icon"], ["height", "16"], ["src", "../../../../assets/icons/lock_black.svg"], ["width", "8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "padding-left:4px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Impersonating"]))], null, null); }
function View_LogoutOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "user-info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "user-info-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "padding-top-place-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "impersonating-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogoutOverlayComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "user-email"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "profile-circle", [], null, null, null, i3.View_ProfileCircleComponent_0, i3.RenderType_ProfileCircleComponent)), i1.ɵdid(11, 114688, null, 0, i4.ProfileCircleComponent, [], { theme: [0, "theme"], userInfo: [1, "userInfo"], isAdmin: [2, "isAdmin"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "logout-btn"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAdmin; _ck(_v, 5, 0, currVal_0); var currVal_4 = _co.theme; var currVal_5 = _co.userInfo; var currVal_6 = _co.isAdmin; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); var currVal_9 = "primary"; _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userInfo.firstName; var currVal_2 = _co.userInfo.lastName; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.userInfo.email; _ck(_v, 9, 0, currVal_3); var currVal_7 = (i1.ɵnov(_v, 14).disabled || null); var currVal_8 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_7, currVal_8); }); }
exports.View_LogoutOverlayComponent_0 = View_LogoutOverlayComponent_0;
function View_LogoutOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout-overlay", [], null, null, null, View_LogoutOverlayComponent_0, RenderType_LogoutOverlayComponent)), i1.ɵdid(1, 245760, null, 0, i9.LogoutOverlayComponent, [i10.MatDialogRef, i10.MAT_DIALOG_DATA, i11.Router, i10.MatDialog, i12.JwtService, i13.SubscriptionClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LogoutOverlayComponent_Host_0 = View_LogoutOverlayComponent_Host_0;
var LogoutOverlayComponentNgFactory = i1.ɵccf("app-logout-overlay", i9.LogoutOverlayComponent, View_LogoutOverlayComponent_Host_0, {}, {}, []);
exports.LogoutOverlayComponentNgFactory = LogoutOverlayComponentNgFactory;
