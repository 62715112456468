"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const graphql_tag_1 = require("graphql-tag");
const i0 = require("@angular/core");
const i1 = require("apollo-angular");
const GET_USERS_QUERY = graphql_tag_1.default `
    query getUsers {
        user {
            id
            first_name
            email
        }
    }
`;
const GET_USER_BY_ID_QUERY = graphql_tag_1.default `
    query getUserById($userId: uuid!) {
        user(where: { id: { _eq: $userId } }) {
            id
            first_name
            last_name
            email
            email_verified
            submitted_via
            initial_submitted_via
        }
    }
`;
const UPDATE_USER_ROLE = graphql_tag_1.default `
    mutation updateUserRole($userId: uuid!, $role: String, $allowedRole: String) {
        update_user(where: { id: { _eq: $userId } }, _set: { default_role: $role, allowed_roles: $allowedRole }) {
            affected_rows
        }
    }
`;
const SEND_ONBOARDING_EMAIL_QUERY = graphql_tag_1.default `
    query sendOnboardingEmail($userId: String!) {
        send_onboarding_email(userId: $userId) {
            success
            error
        }
    }
`;
const SEND_PURCHASE_EMAIL_QUERY = graphql_tag_1.default `
    query sendPurchaseEmail($orderId: String!) {
        send_purchase_email(orderId: $orderId) {
            success
            error
        }
    }
`;
const GET_STAFF_USERS = graphql_tag_1.default `
    subscription getStaffUsers {
        user(where: { default_role: { _eq: "staff_user" } }) {
            id
            first_name
            last_name
            email
        }
    }
`;
const UPDATE_USER_EMAIL_VERIFICATION = graphql_tag_1.default `
    mutation setUserEmailVerification($userId: uuid!, $email_verified: Boolean) {
        update_user_email_verification(userId: $userId, email_verified: $email_verified) {
            affected_rows
        }
    }
`;
const GET_USER_DETAIL_BY_ID = graphql_tag_1.default `
    query GetUserDetailById($userId: uuid!) {
        user(where: { id: { _eq: $userId } }) {
            id
            phone_number_verified
            listings(order_by: { created_at: asc }) {
                id
                propertyDetails_address_1
                propertyDetails_address_2
                propertyDetails_address_state
                propertyDetails_address_suburb
                propertyDetails_address_postcode
                listing_onboardings {
                    finished
                    skipped
                }
            }
        }
    }
`;
const UPDATE_USER_PHONE_NUMBER = graphql_tag_1.default `
    mutation updateUserPhoneNumber($id: uuid!, $mobileNumber: String!) {
        update_user(where: { id: { _eq: $id } }, _set: { phone_number: $mobileNumber }) {
            affected_rows
        }
    }
`;
class UserService {
    constructor(apollo) {
        this.apollo = apollo;
    }
    getUsers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_USERS_QUERY,
            })
                .toPromise();
        });
    }
    getUserById(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_USER_BY_ID_QUERY,
                variables: {
                    userId,
                },
            })
                .toPromise();
        });
    }
    updateUserRole(userId, defaultRole, allowedRole) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_USER_ROLE,
                variables: {
                    userId,
                    role: defaultRole,
                    allowedRole,
                },
            })
                .toPromise();
        });
    }
    sendOnboardingEmail(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: SEND_ONBOARDING_EMAIL_QUERY,
                variables: { userId },
            })
                .toPromise();
        });
    }
    sendPurchaseEmail(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: SEND_PURCHASE_EMAIL_QUERY,
                variables: { orderId },
            })
                .toPromise();
        });
    }
    getStaffUsers() {
        return this.apollo.subscribe({
            query: GET_STAFF_USERS,
            variables: {},
        });
    }
    updateUserEmailVerification(userId, emailVerified) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_USER_EMAIL_VERIFICATION,
                variables: {
                    userId,
                    email_verified: emailVerified,
                },
            })
                .toPromise();
        });
    }
    getUserDetailById(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_USER_DETAIL_BY_ID,
                variables: {
                    userId,
                },
            })
                .toPromise();
        });
    }
    updateUserPhoneNumber(userId, phoneNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_USER_PHONE_NUMBER,
                variables: {
                    id: userId,
                    mobileNumber: phoneNumber,
                },
            })
                .toPromise();
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.Apollo)); }, token: UserService, providedIn: "root" });
exports.UserService = UserService;
