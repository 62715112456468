import { IConfiguration } from './config';
import { config } from './environment.config';

const environment: IConfiguration = {
    production: true,
    hmr: false,
};

for (const key of Object.keys(config)) {
    environment[key] = config[key];
}
export { environment };
