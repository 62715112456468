"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ɵ0 = {
    NAV: {
        APPLICATIONS: 'Programlar',
        DASHBOARDS: 'Kontrol Paneli',
        CALENDAR: 'Takvim',
        ECOMMERCE: 'E-Ticaret',
        ACADEMY: 'Akademi',
        MAIL: {
            TITLE: 'Posta',
            BADGE: '15',
        },
        MAIL_NGRX: {
            TITLE: 'Posta Ngrx',
            BADGE: '13',
        },
        CHAT: 'Sohbet',
        FILE_MANAGER: 'Dosya Yöneticisi',
        CONTACTS: 'Kişiler',
        TODO: 'Yapılacaklar',
        SCRUMBOARD: 'Proje',
    },
};
exports.ɵ0 = ɵ0;
exports.locale = {
    lang: 'tr',
    data: ɵ0,
};
