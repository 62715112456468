<div *ngIf="!isMobile" class="footer" fxLayout="row">
    <ng-container *ngTemplateOutlet="footerContent"></ng-container>
</div>

<div *ngIf="isMobile" class="footer footer--mobile-nav" fxLayout="column">
    <ng-container *ngTemplateOutlet="footerContent"></ng-container>
</div>

<ng-template #footerContent>
    <span>Copyright {{_currentYear}}. BuyMyHome Pty Ltd. ACN 126 563 746</span>
    <a class="first" href="https://www.buymyplace.com.au/privacy-policy/" target="_blank">Privacy Policy</a>
    <a href="https://www.buymyplace.com.au/faq/" target="_blank">FAQ</a>
    <a href="https://www.buymyplace.com.au/contact-us/" target="_blank">Contact Us</a>
</ng-template>