"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["content{position:relative;display:flex;z-index:1;flex:1 0 auto}content>:not(router-outlet){display:flex;flex:1 0 auto;width:100%;min-width:100%}"];
exports.styles = styles;
