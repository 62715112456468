"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./basic-dialog.component.scss.shim.ngstyle");
const i1 = require("@angular/core");
const i2 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
const i3 = require("@angular/material/button");
const i4 = require("@angular/cdk/a11y");
const i5 = require("@angular/platform-browser/animations");
const i6 = require("@angular/material/dialog");
const i7 = require("@angular/flex-layout/flex");
const i8 = require("@angular/flex-layout/core");
const i9 = require("@angular/cdk/bidi");
const i10 = require("@angular/common");
const i11 = require("./basic-dialog.component");
const i12 = require("@angular/platform-browser");
var styles_BasicDialogComponent = [i0.styles];
var RenderType_BasicDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BasicDialogComponent, data: {} });
exports.RenderType_BasicDialogComponent = RenderType_BasicDialogComponent;
function View_BasicDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "text-wrap"], ["color", "accent"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (((_co.data == null) ? null : _co.data.cancelBtnText) || "Cancel"); _ck(_v, 2, 0, currVal_3); }); }
function View_BasicDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "headline-1-bold mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i6.MatDialogTitle, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "pt-24"], ["fxLayout", "row"], ["fxLayoutAlign", "end"], ["fxLayoutGap", "16px"], ["style", "width: 100%;"]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(7, 1720320, null, 0, i7.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i9.Directionality, i8.StyleUtils, [2, i7.LayoutGapStyleBuilder], i8.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(8, 671744, null, 0, i7.DefaultLayoutAlignDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutAlignStyleBuilder], i8.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BasicDialogComponent_1)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "text-wrap"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = "row"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "16px"; _ck(_v, 7, 0, currVal_4); var currVal_5 = "end"; _ck(_v, 8, 0, currVal_5); var currVal_6 = !_co.data.hideCancel; _ck(_v, 10, 0, currVal_6); var currVal_9 = "accent"; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.text; _ck(_v, 3, 0, currVal_2); var currVal_7 = (i1.ɵnov(_v, 12).disabled || null); var currVal_8 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_7, currVal_8); var currVal_10 = (((_co.data == null) ? null : _co.data.okBtnText) || "OK"); _ck(_v, 13, 0, currVal_10); }); }
exports.View_BasicDialogComponent_0 = View_BasicDialogComponent_0;
function View_BasicDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-basic-dialog", [], null, null, null, View_BasicDialogComponent_0, RenderType_BasicDialogComponent)), i1.ɵdid(1, 49152, null, 0, i11.BasicDialogComponent, [i6.MatDialogRef, i12.DomSanitizer, i6.MAT_DIALOG_DATA], null, null)], null, null); }
exports.View_BasicDialogComponent_Host_0 = View_BasicDialogComponent_Host_0;
var BasicDialogComponentNgFactory = i1.ɵccf("app-basic-dialog", i11.BasicDialogComponent, View_BasicDialogComponent_Host_0, {}, {}, []);
exports.BasicDialogComponentNgFactory = BasicDialogComponentNgFactory;
