import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BasicDialogComponent, BasicDialogData } from 'app/shared/basic-dialog/basic-dialog.component';
import { take } from 'rxjs/operators';
import { LoaderComponent } from 'app/shared/loader/loader.component';
import {
    BuildInfoDialogComponent,
    BuildInfoDialogDialogData,
} from '../shared/build-info-dialog/build-info-dialog.component';
import {
    CancelDiscardSaveDialogComponent,
    CancelDiscardSaveDialogData,
    CancelDiscardSaveDialogStatus,
} from '../shared/cancel-discard-save-dialog/cancel-discard-save-dialog.component';
import {
    FeedbackDialogComponent,
    FeedbackDialogData,
} from '../main/admin/dashboard/shared/feedback-dialog/feedback-dialog.component';
import {
    InputDialogComponent,
    InputDialogData,
} from 'app/main/admin/dashboard/shared/input-dialog/input-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private dialog: MatDialog) {}

    loaderRef: MatDialogRef<LoaderComponent>;
    confirmRef: MatDialogRef<BasicDialogComponent>;
    feedbackRef: MatDialogRef<FeedbackDialogComponent>;
    inpurRef: MatDialogRef<InputDialogComponent>;
    isDialogOpened = false;

    openConfirm(
        title: string,
        text: string,
        okBtnText: string = 'Ok',
        cancelBtnText: string = 'Cancel',
        hideCancel: boolean = false,
        allowClose: boolean = true,
        disableClose: boolean = false
    ): Promise<boolean> {
        return new Promise((resolve) => {
            this.confirmRef = this.dialog.open(BasicDialogComponent, {
                width: '480px',
                disableClose,
                data: {
                    okBtnText,
                    title,
                    text,
                    cancelBtnText,
                    hideCancel,
                    allowClose,
                },
                restoreFocus: false,
            });

            this.confirmRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((result: boolean) => {
                    resolve(result);
                });
        });
    }

    closeConfirm(): void {
        if (this.confirmRef) {
            this.confirmRef.close();
        }
    }

    showAlert(title: string, text: string, okBtnText: string = 'Ok'): Promise<boolean> {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(BasicDialogComponent, {
                width: '480px',
                data: {
                    okBtnText,
                    title,
                    text,
                    hideCancel: true,
                    allowClose: true,
                },
            });

            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((result: boolean) => {
                    resolve(result);
                });
        });
    }

    showLoading(title: string, content = ''): void {
        if (this.loaderRef && this.isDialogOpened) {
            this.loaderRef.componentInstance.title = title;
            this.loaderRef.componentInstance.content = content;
        } else {
            this.isDialogOpened = true;
            this.loaderRef = this.dialog.open(LoaderComponent, {
                disableClose: true,
                width: '480px',
                data: {
                    title,
                    content,
                },
            });
            this.loaderRef
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                    this.isDialogOpened = false;
                    this.loaderRef = null;
                });
        }
    }

    hideLoading(): void {
        if (this.loaderRef) {
            this.loaderRef.close();
        }
    }

    openBuildInfoDialog(text: string): Promise<void> {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open<BuildInfoDialogComponent, BuildInfoDialogDialogData>(
                BuildInfoDialogComponent,
                {
                    width: '480px',
                    panelClass: 'cdk-overlay-pane--build-info-dialog',
                    data: {
                        title: 'Build info',
                        text,
                    },
                }
            );

            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                    resolve();
                });
        });
    }

    showDiscardSaveOrCancel(title: string, text: string): Promise<CancelDiscardSaveDialogStatus> {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open<CancelDiscardSaveDialogComponent, CancelDiscardSaveDialogData>(
                CancelDiscardSaveDialogComponent,
                {
                    width: '480px',
                    disableClose: true,
                    data: {
                        title,
                        text,
                    },
                }
            );

            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((result: CancelDiscardSaveDialogStatus) => {
                    resolve(result);
                });
        });
    }

    /**
     * Resolves to an empty string if user clicked OK
     * Resolves to non empty string if user clicked OK and entered some data
     * Resolves to false if user canceled dialog
     */
    openFeedback(config: FeedbackDialogData): Promise<{ result: boolean; message: string; checkboxValue: boolean }> {
        return new Promise((resolve) => {
            const dialogData: FeedbackDialogData = {
                okBtnText: config.okBtnText || 'Ok',
                title: config.title || '',
                text: config.text || '',
                cancelBtnText: config.cancelBtnText || 'Cancel',
                hideCancel: config.hideCancel || false,
                allowClose: config.allowClose || true,
                isCheckboxVisible: config.isCheckboxVisible,
                checkboxLabel: config.checkboxLabel,
                hideFeedbackInput: config.hideFeedbackInput,
            };

            this.feedbackRef = this.dialog.open(FeedbackDialogComponent, {
                width: '480px',
                data: dialogData,
            });

            this.feedbackRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((result: { result: boolean; message: string; checkboxValue: boolean }) => {
                    resolve(result);
                });
        });
    }

    openInput(config: InputDialogData): Promise<{ result: boolean; message: string; checkboxValue: boolean }> {
        return new Promise((resolve) => {
            const dialogData: InputDialogData = {
                okBtnText: config.okBtnText || 'Ok',
                title: config.title || '',
                text: config.text || '',
                cancelBtnText: config.cancelBtnText || 'Cancel',
                hideCancel: config.hideCancel || false,
                allowClose: config.allowClose || true,
                isCheckboxVisible: config.isCheckboxVisible,
                checkboxLabel: config.checkboxLabel,
                hideInput: config.hideInput,
            };

            this.inpurRef = this.dialog.open(InputDialogComponent, {
                width: '480px',
                data: dialogData,
            });

            this.inpurRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((result: { result: boolean; message: string; checkboxValue: boolean }) => {
                    resolve(result);
                });
        });
    }
}
