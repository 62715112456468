"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const core_1 = require("@angular/core");
const base_component_1 = require("../../../shared/base/base.component");
const operators_1 = require("rxjs/operators");
const jwt_decode = require("jwt-decode");
const basic_dialog_component_1 = require("app/shared/basic-dialog/basic-dialog.component");
const logout_overlay_component_1 = require("../logout-overlay/logout-overlay.component");
const phone_number_constant_1 = require("../../../constants/phone-number.constant");
class TopNavComponent extends base_component_1.BaseComponent {
    constructor(router, mediaObserver, dialog, jwtService, buildInfoService, wsClient) {
        super();
        this.router = router;
        this.mediaObserver = mediaObserver;
        this.dialog = dialog;
        this.jwtService = jwtService;
        this.buildInfoService = buildInfoService;
        this.wsClient = wsClient;
        this.mobileNavState = 'hidden';
        this.menuItems = [
            {
                title: 'My Listings',
                icon: 'view_list',
                url: '/pages/listings-management',
            },
        ];
        this.showUserInfoOverlay = false;
        this.roleThemes = {
            ['admin']: {
                backgroundColor: '#FFFFFF',
                textColour: '#000D52',
            },
            ['user']: {
                backgroundColor: '#3540F4',
                textColour: '#FFFFFF',
            },
        };
        this.phoneNumber = phone_number_constant_1.PHONE_NUMBER;
        this.phoneNumberFormatted = phone_number_constant_1.PHONE_NUMBER_FORMATTED;
    }
    ngOnInit() {
        // hide mobile nav on navigation events
        this.router.events.pipe(operators_1.takeUntil(this.destroyed$)).subscribe((val) => {
            // see also
            this.hideMobileNav();
        });
        // update current user when logged in
        this.jwtService.isLoggedIn.pipe(operators_1.takeUntil(this.destroyed$)).subscribe((isNewUser) => {
            this.decodeCurrentUserInfo();
        });
        // hide mobile nav when we switched from mobile to desktop
        this.mediaObserver
            .asObservable()
            .pipe(operators_1.mergeMap((t) => t))
            .subscribe((change) => {
            if (change.mqAlias.match(/(sm|md|lg)/gi)) {
                this.hideMobileNav();
            }
        });
        this.isAdmin = this.jwtService.isAdmin();
        this.mobileBackgroundHeight = this.isAdmin ? '158px' : '133px';
        this.setTheme();
        this.decodeCurrentUserInfo();
    }
    setTheme() {
        if (sessionStorage.getItem('impersonateToken') && this.isAdmin) {
            this.theme = this.roleThemes['admin'];
        }
        else {
            this.theme = this.roleThemes['user'];
        }
    }
    decodeCurrentUserInfo() {
        let userToken;
        if (sessionStorage.getItem('impersonateToken')) {
            userToken = sessionStorage.getItem('impersonateToken');
        }
        else if (localStorage.getItem('token')) {
            userToken = localStorage.getItem('token');
        }
        if (!userToken) {
            return;
        }
        const userInfo = jwt_decode(userToken)['https://buymyplace.com.au/jwt/claims'];
        this.userInfo = Object.assign({}, userInfo, { initials: `${userInfo.firstName ? userInfo.firstName[0].toUpperCase() : ''}${userInfo.lastName ? userInfo.lastName[0].toUpperCase() : ''}` });
    }
    showLogoutOverlay() {
        const dialogConfig = {
            panelClass: 'no-padding-dialog',
            minWidth: '176px',
            minHeight: '238px',
            position: {
                top: '64px',
                right: '16px',
            },
            data: {
                userInfo: this.userInfo,
                theme: this.theme,
                isAdmin: this.isAdmin,
            },
        };
        this.dialog.open(logout_overlay_component_1.LogoutOverlayComponent, dialogConfig);
    }
    showMobileNav() {
        this.mobileNavState = 'visible';
    }
    hideMobileNav() {
        this.mobileNavState = 'hidden';
    }
    reloadPage() {
        document.location.href = document.location.href;
    }
    logout() {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(basic_dialog_component_1.BasicDialogComponent, {
                width: '480px',
                data: {
                    okBtnText: 'Confirm',
                    title: 'Important!',
                    text: 'Are you sure you want to logout?',
                },
            });
            dialogRef
                .afterClosed()
                .pipe(operators_1.takeUntil(this.destroyed$))
                .subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (result) {
                    const isAdminImpersonatingUser = this.jwtService.isAdminImpersonatingUser();
                    this.jwtService.deleteSession(true);
                    this.wsClient.close();
                    if (!isAdminImpersonatingUser) {
                        this.router.navigate(['/auth/login']);
                    }
                    else {
                        this.router.navigate(['/admin/dashboard']);
                    }
                }
            }));
        });
    }
    logoClick() {
        // register click, if you keep clicking you'll see a dialog with build info
        this.buildInfoService.click();
    }
}
exports.TopNavComponent = TopNavComponent;
