import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { JwtModule } from '@auth0/angular-jwt';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { environment } from '../environments/environment';
import { BaseComponent } from './shared/base/base.component';
import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';
import { ErrorFormComponent } from './shared/base/error-form.component';
import * as jwt_decode from 'jwt-decode';
import { ToastrModule } from 'ngx-toastr';
import { AdminGuardService } from './service/admin-guard.service';
import { BasicDialogComponent } from './shared/basic-dialog/basic-dialog.component';
import { LoaderComponent } from './shared/loader/loader.component';
import {
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
} from '@angular/material';
import { AnalyticsService } from './service/analytics.service';
import { AuthenticationGuard } from './service/authentication-guard';
import { UserPagesGuardService } from './service/user-pages-guard.service';
import { CancelDiscardSaveDialogComponent } from './shared/cancel-discard-save-dialog/cancel-discard-save-dialog.component';
import { BuildInfoDialogComponent } from './shared/build-info-dialog/build-info-dialog.component';
import { BaseQueryParamComponent } from './shared/base/base-queryparam.component';
import { UserRole } from './shared/models/user.model';
import { AdminSuperpowerPanelComponent } from './shared/admin-superpower-panel/admin-superpower-panel.component';
import { JwtService } from './service/jwt.service';
import { FeedbackDialogComponent } from './main/admin/dashboard/shared/feedback-dialog/feedback-dialog.component';
import { InputDialogComponent } from './main/admin/dashboard/shared/input-dialog/input-dialog.component';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import * as Sentry from '@sentry/angular';
import { RouterDefaultGuardService } from './service/router-default-guard.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: './main/authentication/authentication.module#AuthenticationModule',
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule',
        canActivate: [AuthenticationGuard, UserPagesGuardService],
    },
    {
        path: 'admin',
        loadChildren: './main/admin/admin.module#AdminModule',
        canActivate: [AdminGuardService],
    },
    {
        path: '**',
        canActivate: [RouterDefaultGuardService],
        redirectTo: '',
    },
];

const appProviders: any[] = [
    {
        provide: SubscriptionClient,
        useFactory: createSubscriptionClient,
        deps: [],
    },
];

if (environment.application.branch === 'bmp-production') {
    appProviders.push({
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
        }),
    });
    appProviders.push({
        provide: Sentry.TraceService,
        deps: [Router],
    });
    appProviders.push({
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
    });
}

export function jwtTokenGetter(): string {
    return localStorage.getItem('token');
}

export function createSubscriptionClient(): SubscriptionClient {
    const connectionParams = () => {
        if (sessionStorage.getItem('impersonateToken')) {
            const token = sessionStorage.getItem('impersonateToken');
            return token ? { headers: { Authorization: 'Bearer ' + token } } : {};
        } else if (localStorage.getItem('token')) {
            // never init login/register session with JWT token, always clear it out
            // as we can only login either with empty token or correct token
            // there is no guaranty that current token is valid
            if (['/auth/login', '/auth/register'].includes(document.location.pathname)) {
                this.jwt.deleteSession();
            }
            const token = localStorage.getItem('token');
            return token ? { headers: { Authorization: 'Bearer ' + token } } : {};
        }
    };
    return new SubscriptionClient(environment.services.data.endpoint, {
        lazy: true,
        reconnect: true,
        connectionParams,
    });
}

@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
        BaseQueryParamComponent,
        ErrorFormComponent,
        BasicDialogComponent,
        LoaderComponent,
        BuildInfoDialogComponent,
        CancelDiscardSaveDialogComponent,
        AdminSuperpowerPanelComponent,
        FeedbackDialogComponent,
        InputDialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTooltipModule,
        MatInputModule,
        MatFormFieldModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        // Custom modules
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
            },
        }),

        // Apollo
        ApolloModule,
        HttpLinkModule,
        // Toaster
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            timeOut: 2000,
            tapToDismiss: false,
        }),
        MatDialogModule,
        MatCheckboxModule,
    ],
    providers: appProviders,
    exports: [LoaderComponent],
    entryComponents: [
        BasicDialogComponent,
        LoaderComponent,
        BuildInfoDialogComponent,
        CancelDiscardSaveDialogComponent,
        FeedbackDialogComponent,
        InputDialogComponent,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        apollo: Apollo,
        private router: Router,
        private analytics: AnalyticsService,
        private jwt: JwtService,
        wsClient: SubscriptionClient
    ) {
        const webSocketLink = new WebSocketLink(wsClient);
        // to track GraphQL query count with the same name
        let requestCount: { [key: string]: number } = {};
        let timeout;

        // Middleware to catch JWT error and redirect to login page
        const jwtMiddleware = onError((data) => {
            if (data.networkError) {
                // Print the most useful message during error.
                console.log('Error: ', JSON.stringify(data.networkError, null, 2));
            } else {
                // if no networkError, print whatever we got
                console.log('Error: ', JSON.stringify(data, null, 2));
            }

            this.analytics.trackEvent('Error', {
                Error: JSON.stringify(data, null, 2),
            });
            const regExp = /JWSError|JWTExpired|connection_init failed|header is expected|no subscriptions exist/gi;
            if (data && data.networkError && data.networkError.message) {
                if (data.networkError.message.match(regExp)) {
                    // clear session so after redirect, user can login
                    // if we do not clear session here, old token will be used for a login request
                    this.jwt.deleteSession();
                    wsClient.close();
                    // do a full app refresh, due to the fact that we can not re-connect Apollo client
                    // with new credentials at runtime
                    this.router.navigate(['/auth/login']);
                    return;
                } else if (sessionStorage.getItem('impersonateToken')) {
                    const role = jwt_decode(sessionStorage.getItem('impersonateToken'))['https://hasura.io/jwt/claims'][
                        'x-hasura-default-role'
                    ];
                    if (!['user', UserRole.StaffUser].includes(role)) {
                        router.navigate(['/auth/validate']);
                    }
                } else if (localStorage.getItem('token')) {
                    const role = jwt_decode(sessionStorage.getItem('token'))['https://hasura.io/jwt/claims'][
                        'x-hasura-default-role'
                    ];
                    if (!['user', UserRole.StaffUser].includes(role)) {
                        router.navigate(['/auth/validate']);
                    }
                }
                /**
                 * analytics code to track user's events
                 */
                // ANALYTICS_START
                this.analytics.run(() => {
                    this.analytics.identifyUser(
                        sessionStorage.getItem('impersonateToken') || localStorage.getItem('token')
                    );
                });
                // ANALYTICS_END
            }
        });

        /**
         * This middleware tracks all GraphQL requests and their responses
         * calculates duration for every request and prints them in the dev tools console
         * requests which take longer than 3 seconds are styled in red, others are green
         * To enable, enter: DEBUG=1 in the Chrome dev tools console
         * To disable, enter: DEBUG=0 in the Chrome dev tools console, or refresh the page
         */
        const testMiddleware = new ApolloLink((operation, forward) => {
            // Debug mode is enabled if you type "DEBUG=1" in the dev tools and hit enter
            // or it's enabled by default on localhost addresses
            let isDebug = false;
            if (!window['DEBUG']) {
                return forward(operation);
            } else {
                isDebug = true;
            }
            const opName = operation.operationName;
            console.log(`⬆️ ${opName}`);
            const query = operation.query.loc.source.body;
            const variables = operation.variables;
            const started = Date.now();

            // Don't capture stats for subscriptions, this doesn't make sense
            // subscriptions run for a long time, and can not give use useful info on performance
            // they may also not works correctly if we pass them through this middleware
            // as we're doing conversion zenToRx and rxToZen which can introduce some issues
            if (!!query.match(/subscription/gi)) {
                if (isDebug) {
                    console.log(`⬆️ ${opName} not trackable (subscription)`);
                }
                return forward(operation);
            } else {
                if (isDebug) {
                    console.log(`⬆️ ${opName}`);
                }
            }

            requestCount[opName] = requestCount[opName] ? requestCount[opName] + 1 : 1;

            // after 10 seconds print summary of GraphQL requests and their count
            // this way we can detect multiple requests of the same name
            // and make optimisations
            if (!timeout) {
                timeout = setTimeout(() => {
                    console.log(
                        'Request count:',
                        Object.keys(requestCount).length,
                        JSON.stringify(requestCount, null, 4)
                    );
                    requestCount = {};
                    clearTimeout(timeout);
                    timeout = undefined;
                }, 10000);
            }

            const zenToRx = <T>(zenObservable: any): Observable<T> => {
                return new Observable((observer) => zenObservable.subscribe(observer));
            };
            const forwardReturn = zenToRx(forward(operation)).pipe(shareReplay(1));
            forwardReturn.subscribe((resp) => {
                const ended = Date.now();
                const durationSec = (ended - started) / 1000;
                let color = 'color:green;';
                if (durationSec > 3.0) {
                    color = 'color:red;';
                }
                const codeStyle = color;
                const headerStyle = `font-weight: bold;${color}`;
                console.log(`%c--- QUERY ${opName} took ${durationSec} sec ---`, headerStyle);
                console.log(`%c${query}`, codeStyle);
                console.log(`--- VARS for ${opName} ---`);
                console.log(JSON.stringify(variables, null, 4));
                console.log(`--- RESP for ${opName} ---`);
                console.log(JSON.stringify(resp, null, 4));
            });
            return forwardReturn as any;
        });

        apollo.create({
            cache: new InMemoryCache(),
            link: from([jwtMiddleware, testMiddleware, webSocketLink]),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
        /**
         * analytics code to track user's events
         */
        // ANALYTICS_START
        this.analytics.run(() => {
            this.analytics.identifyUser(sessionStorage.getItem('impersonateToken') || localStorage.getItem('token'));
        });
        // ANALYTICS_END
    }
}
