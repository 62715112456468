"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ɵ0 = {
    NAV: {
        APPLICATIONS: 'Applications',
        DASHBOARDS: 'Dashboards',
        CALENDAR: 'Calendar',
        ECOMMERCE: 'E-Commerce',
        ACADEMY: 'Academy',
        MAIL: {
            TITLE: 'Mail',
            BADGE: '25',
        },
        MAIL_NGRX: {
            TITLE: 'Mail Ngrx',
            BADGE: '13',
        },
        CHAT: 'Chat',
        FILE_MANAGER: 'File Manager',
        CONTACTS: 'Contacts',
        TODO: 'To-Do',
        SCRUMBOARD: 'Scrumboard',
    },
};
exports.ɵ0 = ɵ0;
exports.locale = {
    lang: 'en',
    data: ɵ0,
};
