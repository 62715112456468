"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FuseNavHorizontalItemComponent {
    /**
     * Constructor
     */
    constructor() {
        this.classes = 'nav-item';
    }
}
exports.FuseNavHorizontalItemComponent = FuseNavHorizontalItemComponent;
