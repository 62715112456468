import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import {
    GetReasonSellingQuery,
    GetReasonSellingQueryVariables,
    UpdateReasonSellingMutation,
    UpdateReasonSellingMutationVariables,
    CreateOnboardingProgressMutation,
    CreateOnboardingProgressMutationVariables,
    GetOnboardingProgressQuery,
    GetOnboardingProgressQueryVariables,
    UpdateStepMutation,
    UpdateStepMutationVariables,
    UpdateFinishMutation,
    UpdateFinishMutationVariables,
    UpdateSkipMutation,
    UpdateSkipMutationVariables,
    GetTimeFrameSellingQuery,
    GetTimeFrameSellingQueryVariables,
    UpdateTimeframeSellingMutation,
    UpdateTimeframeSellingMutationVariables,
    GetFeedbackQuery,
    GetFeedbackQueryVariables,
    UpdateFeedbackMutation,
    UpdateFeedbackMutationVariables,
    GetOnboardingProgressByUserQuery,
    GetOnboardingProgressByUserQueryVariables,
    GetBudgetSizeQuery,
    GetBudgetSizeQueryVariables,
    UpdateBudgetSizeMutation,
    UpdateBudgetSizeMutationVariables,
    GetOnboardingDataByListingIdQuery,
    GetOnboardingDataByListingIdQueryVariables,
} from '../../generated/graphql';
import { ApolloQueryResult } from 'apollo-client';
import { FetchResult } from 'apollo-link';

const GET_REASON_SELLING = gql`
    query GetReasonSelling($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            reason_selling
            reason_selling_other
            listing {
                propertyDetails_propertyType
            }
        }
    }
`;

const UPDATE_REASON_SELLING = gql`
    mutation UpdateReasonSelling(
        $listingId: uuid!
        $reasonSelling: onboarding_reason_selling_enum
        $reasonSellingOther: String
    ) {
        update_listing_onboarding(
            where: { listing_id: { _eq: $listingId } }
            _set: { reason_selling: $reasonSelling, reason_selling_other: $reasonSellingOther }
        ) {
            returning {
                id
            }
        }
    }
`;

const GET_TIMEFRAME_SELLING = gql`
    query getTimeFrameSelling($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            timeframe_selling
        }
    }
`;

const UPDATE_TIMEFRAME_SELLING = gql`
    mutation updateTimeframeSelling($listingId: uuid!, $timeframeSelling: onboarding_timeframe_selling_enum) {
        update_listing_onboarding(
            where: { listing_id: { _eq: $listingId } }
            _set: { timeframe_selling: $timeframeSelling }
        ) {
            returning {
                id
            }
        }
    }
`;

const GET_BUDGET_SIZE = gql`
    query getBudgetSize($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            budget_size
        }
    }
`;

const UPDATE_BUDGET_SIZE = gql`
    mutation updateBudgetSize($listingId: uuid!, $budgetSize: onboarding_budget_size_enum) {
        update_listing_onboarding(where: { listing_id: { _eq: $listingId } }, _set: { budget_size: $budgetSize }) {
            returning {
                id
            }
        }
    }
`;

const GET_FEEDBACK_QUERY = gql`
    query GetFeedback($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            id
            rating_inspection
            rating_negotiation
            rating_overall
            rating_technology
            rating_valuing
        }
    }
`;

const UPDATE_FEEDBACK_MUTATION = gql`
    mutation UpdateFeedback(
        $listingId: uuid!
        $ratingInspection: Int
        $ratingNegotiation: Int
        $ratingOverall: Int
        $ratingTechnology: Int
        $ratingValuing: Int
    ) {
        update_listing_onboarding(
            where: { listing_id: { _eq: $listingId } }
            _set: {
                rating_inspection: $ratingInspection
                rating_negotiation: $ratingNegotiation
                rating_overall: $ratingOverall
                rating_technology: $ratingTechnology
                rating_valuing: $ratingValuing
            }
        ) {
            returning {
                rating_inspection
                rating_negotiation
                rating_overall
                rating_technology
                rating_valuing
            }
        }
    }
`;

const CREATE_ONBOARDING_PROGRESS_MUTATION = gql`
    mutation createOnboardingProgress($listingId: uuid!) {
        insert_listing_onboarding(objects: { listing_id: $listingId }) {
            returning {
                id
            }
        }
    }
`;

const GET_ONBOARDING_PROGRESS_QUERY = gql`
    query getOnboardingProgress($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            id
            finished
            skipped
            step_completed
            user_id
        }
    }
`;

const UPDATE_STEP_MUTATION = gql`
    mutation updateStep($onboardingId: uuid!, $step_completed: Int) {
        update_listing_onboarding(where: { id: { _eq: $onboardingId } }, _set: { step_completed: $step_completed }) {
            affected_rows
        }
    }
`;

const UPDATE_FINISH_MUTATION = gql`
    mutation updateFinish($onboardingId: uuid!, $finished: Boolean) {
        update_listing_onboarding(where: { id: { _eq: $onboardingId } }, _set: { finished: $finished }) {
            affected_rows
        }
    }
`;

const UPDATE_SKIP_MUTATION = gql`
    mutation updateSkip($onboardingId: uuid!, $skipped: Boolean) {
        update_listing_onboarding(where: { id: { _eq: $onboardingId } }, _set: { skipped: $skipped }) {
            affected_rows
        }
    }
`;

const GET_ONBOARDING_PROGRESS_BY_USER = gql`
    query getOnboardingProgressByUser($userId: uuid!) {
        listing_onboarding(where: { user_id: { _eq: $userId } }) {
            id
            finished
            skipped
            listing_id
            step_completed
        }
    }
`;

const GET_ONBOARDING_DATA_BY_LISTING_ID = gql`
    query getOnboardingDataByListingId($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            finished
            skipped
            step_completed
            rating_inspection
            rating_technology
            rating_negotiation
            rating_valuing
            rating_overall
            reason_selling
            reason_selling_other
            timeframe_selling
            budget_size
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ListingOnboardingService {
    constructor(private apollo: Apollo) {}

    async getSellingReason(
        variables: GetReasonSellingQueryVariables
    ): Promise<ApolloQueryResult<GetReasonSellingQuery>> {
        return this.apollo
            .query<GetReasonSellingQuery, GetReasonSellingQueryVariables>({
                query: GET_REASON_SELLING,
                variables,
            })
            .toPromise();
    }

    async updateSellingReason(
        variables: UpdateReasonSellingMutationVariables
    ): Promise<FetchResult<UpdateReasonSellingMutation>> {
        return this.apollo
            .mutate<UpdateReasonSellingMutation, UpdateReasonSellingMutationVariables>({
                mutation: UPDATE_REASON_SELLING,
                variables,
            })
            .toPromise();
    }

    async getBudgetSize(variables: GetBudgetSizeQueryVariables): Promise<ApolloQueryResult<GetBudgetSizeQuery>> {
        return this.apollo
            .query<GetBudgetSizeQuery, GetBudgetSizeQueryVariables>({
                query: GET_BUDGET_SIZE,
                variables,
            })
            .toPromise();
    }

    async updateBudgetSize(
        variables: UpdateBudgetSizeMutationVariables
    ): Promise<FetchResult<UpdateBudgetSizeMutation>> {
        return this.apollo
            .mutate<UpdateBudgetSizeMutation, UpdateBudgetSizeMutationVariables>({
                mutation: UPDATE_BUDGET_SIZE,
                variables,
            })
            .toPromise();
    }

    async getTimeframeSelling(
        variables: GetTimeFrameSellingQueryVariables
    ): Promise<ApolloQueryResult<GetTimeFrameSellingQuery>> {
        return this.apollo
            .query<GetTimeFrameSellingQuery, GetTimeFrameSellingQueryVariables>({
                query: GET_TIMEFRAME_SELLING,
                variables,
            })
            .toPromise();
    }

    async updateTimeframeSelling(
        variables: UpdateTimeframeSellingMutationVariables
    ): Promise<FetchResult<UpdateTimeframeSellingMutation>> {
        return this.apollo
            .mutate<UpdateTimeframeSellingMutation, UpdateTimeframeSellingMutationVariables>({
                mutation: UPDATE_TIMEFRAME_SELLING,
                variables,
            })
            .toPromise();
    }

    async getExistingFeedback(variables: GetFeedbackQueryVariables): Promise<ApolloQueryResult<GetFeedbackQuery>> {
        return this.apollo
            .query<GetFeedbackQuery, GetFeedbackQueryVariables>({
                query: GET_FEEDBACK_QUERY,
                variables,
            })
            .toPromise();
    }

    async updateFeedback(variables: UpdateFeedbackMutationVariables): Promise<FetchResult<UpdateFeedbackMutation>> {
        return this.apollo
            .mutate<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>({
                mutation: UPDATE_FEEDBACK_MUTATION,
                variables,
            })
            .toPromise();
    }

    async createOnboardingProgress(listingId: string): Promise<FetchResult<CreateOnboardingProgressMutation>> {
        return this.apollo
            .mutate<CreateOnboardingProgressMutation, CreateOnboardingProgressMutationVariables>({
                mutation: CREATE_ONBOARDING_PROGRESS_MUTATION,
                variables: { listingId },
            })
            .toPromise();
    }

    async getOnboardingProgress(listingId: string): Promise<ApolloQueryResult<GetOnboardingProgressQuery>> {
        return this.apollo
            .query<GetOnboardingProgressQuery, GetOnboardingProgressQueryVariables>({
                query: GET_ONBOARDING_PROGRESS_QUERY,
                variables: { listingId },
            })
            .toPromise();
    }

    async updateStep(stepCompleted: number, onboardingId: string): Promise<FetchResult<UpdateStepMutation>> {
        return this.apollo
            .mutate<UpdateStepMutation, UpdateStepMutationVariables>({
                mutation: UPDATE_STEP_MUTATION,
                variables: {
                    onboardingId,
                    step_completed: stepCompleted,
                },
            })
            .toPromise();
    }

    async updateFinish(finished: boolean, onboardingId: string): Promise<FetchResult<UpdateFinishMutation>> {
        return this.apollo
            .mutate<UpdateFinishMutation, UpdateFinishMutationVariables>({
                mutation: UPDATE_FINISH_MUTATION,
                variables: {
                    onboardingId,
                    finished,
                },
            })
            .toPromise();
    }

    async updateSkip(skipped: boolean, onboardingId: string): Promise<FetchResult<UpdateSkipMutation>> {
        return this.apollo
            .mutate<UpdateSkipMutation, UpdateSkipMutationVariables>({
                mutation: UPDATE_SKIP_MUTATION,
                variables: {
                    onboardingId,
                    skipped,
                },
            })
            .toPromise();
    }

    async getOnboardingProgressByUserId(userId: string): Promise<ApolloQueryResult<GetOnboardingProgressByUserQuery>> {
        return this.apollo
            .query<GetOnboardingProgressByUserQuery, GetOnboardingProgressByUserQueryVariables>({
                query: GET_ONBOARDING_PROGRESS_BY_USER,
                variables: { userId },
            })
            .toPromise();
    }

    async getOnboardingDataByListingId(
        listingId: string
    ): Promise<GetOnboardingDataByListingIdQuery['listing_onboarding'][0]> {
        const resp = await this.apollo
            .query<GetOnboardingDataByListingIdQuery, GetOnboardingDataByListingIdQueryVariables>({
                query: GET_ONBOARDING_DATA_BY_LISTING_ID,
                variables: { listingId },
            })
            .toPromise();
        return resp.data.listing_onboarding[0];
    }
}
