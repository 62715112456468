import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface CancelDiscardSaveDialogData {
    title: string;
    text: SafeHtml | string;
}

export enum CancelDiscardSaveDialogStatus {
    Cancel = 'close',
    Discard = 'discard',
    Save = 'save',
}

@Component({
    selector: 'app-cancel-discard-save-dialog',
    templateUrl: './cancel-discard-save-dialog.component.html',
    styleUrls: ['./cancel-discard-save-dialog.component.scss'],
})
export class CancelDiscardSaveDialogComponent {
    dialogStates = CancelDiscardSaveDialogStatus;

    constructor(
        public dialogRef: MatDialogRef<CancelDiscardSaveDialogComponent>,
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: CancelDiscardSaveDialogData
    ) {
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
    }

    closeDialog(result: CancelDiscardSaveDialogStatus): void {
        this.dialogRef.close(result);
    }
}
