import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtService } from './jwt.service';

@Injectable({
    providedIn: 'root',
})
export class AdminGuardService {
    constructor(private authService: JwtService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.isAuthenticated()) {
            if (this.authService.isAdmin()) {
                return true;
            } else {
                this.router.navigate(['/pages/listings-management']);
                return false;
            }
        }
        this.router.navigate([`/auth/login`], { queryParams: { return: `${state.url}` } });
        return false;
    }
}
