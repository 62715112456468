<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- ADMIN PANEL -->
<ng-container *ngIf="(showAdminSuperPanel$ | async) === true">
    <button mat-icon-button class="mat-elevation-z2 admin-panel-button"
        (click)="toggleSidebarOpen('admin-superpower-panel')">
        <mat-icon>chevron_left</mat-icon>
    </button>
    
    <fuse-sidebar name="admin-superpower-panel" class="admin-panel animations-enabled" position="right"
        [invisibleOverlay]="true">
        <app-admin-superpower-panel></app-admin-superpower-panel>
    </fuse-sidebar>
</ng-container>
<!-- / ADMIN PANEL -->