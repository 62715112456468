import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { FooterModule } from '../footer/footer.module';
import { LogoutOverlayComponent } from '../logout-overlay/logout-overlay.component';
import { ProfileCircleModule } from '../profile-circle/profile-circle/profile-circle.module';

@NgModule({
    declarations: [TopNavComponent],
    entryComponents: [LogoutOverlayComponent],
    imports: [
        CommonModule,
        FlexModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        FlexLayoutModule,
        FooterModule,
        ProfileCircleModule,
    ],
    exports: [TopNavComponent],
})
export class TopNavModule {}
