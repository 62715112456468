"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const environment_config_1 = require("./environment.config");
const environment = {
    production: true,
    hmr: false,
};
exports.environment = environment;
for (const key of Object.keys(environment_config_1.config)) {
    environment[key] = environment_config_1.config[key];
}
