"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dialog_1 = require("@angular/material/dialog");
const forms_1 = require("@angular/forms");
class InputDialogComponent {
    constructor(dialogRef, domSanitizer, data) {
        this.dialogRef = dialogRef;
        this.domSanitizer = domSanitizer;
        this.data = data;
        // checkbox is on by default
        this.checkboxValue = true;
        this.inputField = new forms_1.FormControl('');
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
        // compose reactive form
        this.form = new forms_1.FormGroup({
            feedback: this.inputField,
        });
    }
    closeDialog(result, message = '') {
        if (this.data.allowClose !== false) {
            this.dialogRef.close({
                result,
                message,
                checkboxValue: this.checkboxValue,
            });
        }
    }
    checkboxChange() {
        const control = this.form.get('feedback');
        control.clearValidators();
        if (this.checkboxValue) {
            control.enable();
        }
        else {
            control.setValue('');
            control.disable();
        }
        control.updateValueAndValidity();
    }
}
exports.InputDialogComponent = InputDialogComponent;
