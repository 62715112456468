"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FooterComponent {
    /**
     * Constructor
     */
    constructor() {
        this._isMobile = false;
        this._currentYear = new Date().getFullYear();
    }
    get isMobile() {
        return this._isMobile;
    }
    set isMobile(isMobile) {
        this._isMobile = !!isMobile;
    }
}
exports.FooterComponent = FooterComponent;
