"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dialog_1 = require("@angular/material/dialog");
class BasicDialogComponent {
    constructor(dialogRef, domSanitizer, data) {
        this.dialogRef = dialogRef;
        this.domSanitizer = domSanitizer;
        this.data = data;
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
    }
    closeDialog(result) {
        if (this.data.allowClose !== false) {
            this.dialogRef.close(result);
        }
    }
}
exports.BasicDialogComponent = BasicDialogComponent;
