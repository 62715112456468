import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { IHasuraJWT } from './jwt.service';
import * as jwt_decode from 'jwt-decode';
import { Apollo } from 'apollo-angular';
import {
    Experiment_Name_Enum,
    GetExperimentNamesQuery,
    GetExperimentNamesQueryVariables,
    GetExperimentsQuery,
    GetExperimentsQueryVariables,
    GetUserExperimentsQuery,
    GetUserExperimentsQueryVariables,
    GetUserUniqueNumberQuery,
    GetUserUniqueNumberQueryVariables,
} from '../../generated/graphql';

@Injectable({
    providedIn: 'root',
})
export class ExperimentService {
    constructor(private apollo: Apollo) {}

    /**
     * Returns array of experiments enabled for this user, ex.: ['Onboarding_A', 'Recommendation_B', ...]
     */
    async getCurrentUserExperiments(): Promise<Experiment_Name_Enum[]> {
        // Support for admin super panel. We can override user experiments there
        if (sessionStorage.experiments) {
            const sessionExperiments = JSON.parse(sessionStorage.experiments);
            const expNames = Object.keys(sessionExperiments) as Experiment_Name_Enum[];
            return expNames.filter((expName) => sessionExperiments[expName]);
        }

        const token = sessionStorage.impersonateToken || localStorage.token;
        const decoded: IHasuraJWT = jwt_decode(token);
        const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
        if (!userId) {
            return null;
        }
        const resp = await this.apollo
            .query<GetUserExperimentsQuery, GetUserExperimentsQueryVariables>({
                query: GET_USER_EXPERIMENTS,
                variables: { userId },
            })
            .toPromise();
        if (resp.errors) {
            console.error(resp.errors);
        }
        return resp.data.user_by_pk.experiments || [];
    }

    async getCurrentUserUniqueNumber(): Promise<number | null> {
        const token = sessionStorage.impersonateToken || localStorage.token;
        const decoded: IHasuraJWT = jwt_decode(token);
        const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
        if (!userId) {
            return null;
        }
        const resp = await this.apollo
            .query<GetUserUniqueNumberQuery, GetUserUniqueNumberQueryVariables>({
                query: GET_USER_UNIQUE_NUMBER,
                variables: { userId },
            })
            .toPromise();
        if (resp.errors) {
            console.error(resp.errors);
        }
        return resp.data.user_by_pk.unique_number;
    }

    async getExperimentNames(): Promise<Experiment_Name_Enum[]> {
        const resp = await this.apollo
            .query<GetExperimentNamesQuery, GetExperimentNamesQueryVariables>({
                query: GET_EXPERIMENT_NAMES,
            })
            .toPromise();
        return resp.data.experiment.map((e) => e.name);
    }

    async getExperiments(): Promise<GetExperimentsQuery['experiment']> {
        const resp = await this.apollo
            .query<GetExperimentsQuery, GetExperimentsQueryVariables>({
                query: GET_EXPERIMENTS,
            })
            .toPromise();
        if (resp.errors) {
            console.error(resp.errors);
        }
        return resp.data.experiment;
    }

    async saveTemporaryExperiments(value: { [key: string]: boolean }): Promise<void> {
        sessionStorage.experiments = JSON.stringify(value);
    }

    async resetTemporaryExperiments(): Promise<void> {
        sessionStorage.experiments = '';
    }
}

//
// Queries
//
const GET_USER_EXPERIMENTS = gql`
    query getUserExperiments($userId: uuid!) {
        user_by_pk(id: $userId) {
            experiments
        }
    }
`;

const GET_USER_UNIQUE_NUMBER = gql`
    query getUserUniqueNumber($userId: uuid!) {
        user_by_pk(id: $userId) {
            unique_number
        }
    }
`;

const GET_EXPERIMENT_NAMES = gql`
    query getExperimentNames {
        experiment(order_by: { name: asc }) {
            name
        }
    }
`;

const GET_EXPERIMENTS = gql`
    query getExperiments {
        experiment(order_by: { name: asc }) {
            name
            desc
        }
    }
`;
