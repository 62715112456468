import { NgModule } from '@angular/core';
import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';
import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LogoutOverlayComponent } from './components/logout-overlay/logout-overlay.component';
import { ProfileCircleComponent } from './components/profile-circle/profile-circle.component';
import { CommonModule } from '@angular/common';
import { ProfileCircleModule } from './components/profile-circle/profile-circle/profile-circle.module';
import { MatButtonModule } from '@angular/material';

@NgModule({
    declarations: [LogoutOverlayComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,
        FlexLayoutModule,
        HorizontalLayout1Module,
        ProfileCircleModule,
    ],
    exports: [VerticalLayout1Module, VerticalLayout2Module, VerticalLayout3Module, HorizontalLayout1Module],
})
export class LayoutModule {}
