"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./app.component.scss.shim.ngstyle");
const i1 = require("@angular/core");
const i2 = require("./layout/vertical/layout-1/layout-1.component.ngfactory");
const i3 = require("./layout/vertical/layout-1/layout-1.component");
const i4 = require("../@fuse/services/config.service");
const i5 = require("./layout/vertical/layout-2/layout-2.component.ngfactory");
const i6 = require("./layout/vertical/layout-2/layout-2.component");
const i7 = require("./layout/vertical/layout-3/layout-3.component.ngfactory");
const i8 = require("./layout/vertical/layout-3/layout-3.component");
const i9 = require("./layout/horizontal/layout-1/layout-1.component.ngfactory");
const i10 = require("./layout/horizontal/layout-1/layout-1.component");
const i11 = require("../../node_modules/@angular/material/button/typings/index.ngfactory");
const i12 = require("@angular/material/button");
const i13 = require("@angular/cdk/a11y");
const i14 = require("@angular/platform-browser/animations");
const i15 = require("../../node_modules/@angular/material/icon/typings/index.ngfactory");
const i16 = require("@angular/material/icon");
const i17 = require("../@fuse/components/sidebar/sidebar.component.ngfactory");
const i18 = require("../@fuse/components/sidebar/sidebar.component");
const i19 = require("@angular/animations");
const i20 = require("../@fuse/services/match-media.service");
const i21 = require("../@fuse/components/sidebar/sidebar.service");
const i22 = require("@angular/flex-layout/core");
const i23 = require("./shared/admin-superpower-panel/admin-superpower-panel.component.ngfactory");
const i24 = require("./shared/admin-superpower-panel/admin-superpower-panel.component");
const i25 = require("./service/listing.service");
const i26 = require("./service/order.service");
const i27 = require("@angular/router");
const i28 = require("./service/dialog.service");
const i29 = require("./service/campaign.service");
const i30 = require("./service/user.service");
const i31 = require("./service/experiment.service");
const i32 = require("../@fuse/components/progress-bar/progress-bar.component.ngfactory");
const i33 = require("../@fuse/components/progress-bar/progress-bar.component");
const i34 = require("../@fuse/components/progress-bar/progress-bar.service");
const i35 = require("@angular/common");
const i36 = require("./app.component");
const i37 = require("../@fuse/components/navigation/navigation.service");
const i38 = require("../@fuse/services/splash-screen.service");
const i39 = require("../@fuse/services/translation-loader.service");
const i40 = require("@ngx-translate/core");
const i41 = require("@angular/cdk/platform");
const i42 = require("./service/jwt.service");
const i43 = require("./service/analytics.service");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-1", [], null, null, null, i2.View_VerticalLayout1Component_0, i2.RenderType_VerticalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i3.VerticalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-2", [], null, null, null, i5.View_VerticalLayout2Component_0, i5.RenderType_VerticalLayout2Component)), i1.ɵdid(2, 245760, null, 0, i6.VerticalLayout2Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "vertical-layout-3", [], null, null, null, i7.View_VerticalLayout3Component_0, i7.RenderType_VerticalLayout3Component)), i1.ɵdid(2, 245760, null, 0, i8.VerticalLayout3Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "horizontal-layout-1", [], null, null, null, i9.View_HorizontalLayout1Component_0, i9.RenderType_HorizontalLayout1Component)), i1.ɵdid(2, 245760, null, 0, i10.HorizontalLayout1Component, [i4.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "mat-elevation-z2 admin-panel-button"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarOpen("admin-superpower-panel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i15.View_MatIcon_0, i15.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i16.MatIcon, [i1.ElementRef, i16.MatIconRegistry, [8, null], [2, i16.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_left"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "fuse-sidebar", [["class", "admin-panel animations-enabled"], ["name", "admin-superpower-panel"], ["position", "right"]], [[2, "open", null], [2, "locked-open", null], [2, "unfolded", null], [2, "animations-enabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i17.View_FuseSidebarComponent_0, i17.RenderType_FuseSidebarComponent)), i1.ɵdid(7, 245760, null, 0, i18.FuseSidebarComponent, [i19.AnimationBuilder, i1.ChangeDetectorRef, i1.ElementRef, i4.FuseConfigService, i20.FuseMatchMediaService, i21.FuseSidebarService, i22.MediaObserver, i1.Renderer2], { name: [0, "name"], position: [1, "position"], invisibleOverlay: [2, "invisibleOverlay"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-admin-superpower-panel", [], null, null, null, i23.View_AdminSuperpowerPanelComponent_0, i23.RenderType_AdminSuperpowerPanelComponent)), i1.ɵdid(9, 245760, null, 0, i24.AdminSuperpowerPanelComponent, [i25.ListingService, i26.OrderService, i27.Router, i28.DialogService, i21.FuseSidebarService, i29.CampaignService, i30.UserService, i31.ExperimentService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); var currVal_8 = "admin-superpower-panel"; var currVal_9 = "right"; var currVal_10 = true; _ck(_v, 7, 0, currVal_8, currVal_9, currVal_10); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 7).opened; var currVal_5 = i1.ɵnov(_v, 7).isLockedOpen; var currVal_6 = i1.ɵnov(_v, 7).unfolded; var currVal_7 = i1.ɵnov(_v, 7)._animationsEnabled; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, i32.View_FuseProgressBarComponent_0, i32.RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i33.FuseProgressBarComponent, [i34.FuseProgressBarService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i35.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i35.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(7, 16384, null, 0, i35.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(9, 16384, null, 0, i35.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_5)), i1.ɵdid(11, 16384, null, 0, i35.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i35.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.fuseConfig.layout.style === "vertical-layout-1"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.fuseConfig.layout.style === "vertical-layout-2"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.fuseConfig.layout.style === "vertical-layout-3"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.fuseConfig.layout.style === "horizontal-layout-1"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.showAdminSuperPanel$)) === true); _ck(_v, 11, 0, currVal_4); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4440064, null, 0, i36.AppComponent, [i35.DOCUMENT, i4.FuseConfigService, i37.FuseNavigationService, i21.FuseSidebarService, i38.FuseSplashScreenService, i39.FuseTranslationLoaderService, i40.TranslateService, i41.Platform, i42.JwtService, i27.Router, i43.AnalyticsService, i27.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("app", i36.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
