"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CampaignBuildStep;
(function (CampaignBuildStep) {
    CampaignBuildStep["Campaign"] = "Campaign";
    CampaignBuildStep["AddOns"] = "Add-ons";
    CampaignBuildStep["Payment"] = "Payment";
    CampaignBuildStep["Confirmation"] = "Confirmation";
})(CampaignBuildStep = exports.CampaignBuildStep || (exports.CampaignBuildStep = {}));
var CampaignCodes;
(function (CampaignCodes) {
    CampaignCodes["BASIC"] = "basic";
    CampaignCodes["BASIC_PLUS"] = "basic_plus";
    CampaignCodes["PRO"] = "pro";
    CampaignCodes["ULTIMATE"] = "ultimate";
    CampaignCodes["BUILD_YOUR_OWN"] = "byo";
    CampaignCodes["RENTAL"] = "rental";
    CampaignCodes["ONLINE"] = "online";
})(CampaignCodes = exports.CampaignCodes || (exports.CampaignCodes = {}));
exports.SALE_CAMPAIGNS = [
    CampaignCodes.BASIC,
    CampaignCodes.BASIC_PLUS,
    CampaignCodes.PRO,
    CampaignCodes.ULTIMATE,
    CampaignCodes.BUILD_YOUR_OWN,
];
exports.RENT_CAMPAIGNS = [CampaignCodes.RENTAL, CampaignCodes.ONLINE];
