import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtService } from './jwt.service';
import { SubscriptionClient } from 'subscriptions-transport-ws';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard {
    constructor(public auth: JwtService, public router: Router, private wsClient: SubscriptionClient) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.isAuthenticated()) {
            return true;
        } else {
            // clear session so after redirect, user can login
            // if we do not clear session here, old token will be used for a login request
            this.auth.deleteSession();
            this.wsClient.close();
            // do a full app refresh, due to the fact that we can not re-connect Apollo client
            // with new credentials at runtime
            this.router.navigate([`/auth/login`], { queryParams: { return: `${state.url}` } });
            return false;
        }
    }
}
