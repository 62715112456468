import { Injectable } from '@angular/core';
import { Apollo, SubscriptionResult } from 'apollo-angular';
import gql from 'graphql-tag';
import {
    CreateCampaignMutation,
    CreateCampaignMutationVariables,
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables,
    Experiment_Name_Enum,
    GetAddonByIdQuery,
    GetAddonByIdQueryVariables,
    GetAddonGroupByIdQuery,
    GetAddonGroupByIdQueryVariables,
    GetAddonGroupsByAddonCodeQuery,
    GetAddonGroupsByAddonCodeQueryVariables,
    GetAddonGroupsByCampaignIdQuery,
    GetAddonGroupsByCampaignIdQueryVariables,
    GetAddonsByAddonGroupIdQuery,
    GetAddonsByAddonGroupIdQueryVariables,
    GetAllCampaignInclusionsQuery,
    GetAllCampaignInclusionsQueryVariables,
    GetAllCampaignNoInclusionsQuery,
    GetAllCampaignNoInclusionsQueryVariables,
    GetBasicRecommendedAddonsByCampaignCodeQuery,
    GetBasicRecommendedAddonsByCampaignCodeQueryVariables,
    GetCampaignAddonGroupQuery,
    GetCampaignAddonGroupQueryVariables,
    GetCampaignAddonsQuery,
    GetCampaignAddonsQueryVariables,
    GetCampaignByCodeQuery,
    GetCampaignByCodeQueryVariables,
    GetCampaignByIdQuery,
    GetCampaignByIdQueryVariables,
    GetCampaignsQuery,
    GetCampaignsQueryVariables,
    GetCampaignsSubSubscription,
    GetCampaignsSubSubscriptionVariables,
    GetInclusionsByCampaignIdQuery,
    GetInclusionsByCampaignIdQueryVariables,
    GetListingAdvisorContextQuery,
    GetListingAdvisorContextQueryVariables,
    GetListingSaleTypeQuery,
    GetListingSaleTypeQueryVariables,
    GetRecommendedAddonsByCampaignCodeQuery,
    GetRecommendedAddonsByCampaignCodeQueryVariables,
    GetRecommendedCampaignsByListingQuery,
    GetRecommendedCampaignsByListingQueryVariables,
    Listing_Advisor_Context_Response,
    Onboarding_Budget_Size_Enum,
    Zone_Type_Enum,
} from '../../generated/graphql';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { map } from 'rxjs/operators';
import { SaleType } from 'app/shared/models/listing.model';
import { RENT_CAMPAIGNS, SALE_CAMPAIGNS } from 'app/main/pages/campaign/campaign.model';
import { ExperimentService } from './experiment.service';
import { ListingOnboardingService } from './listing-onboarding.service';
import { ListingService } from './listing.service';
import { AnalyticsService } from './analytics.service';

@Injectable({
    providedIn: 'root',
})
export class CampaignService {
    constructor(
        private apollo: Apollo,
        private experimentService: ExperimentService,
        private listingOnboardingService: ListingOnboardingService,
        private listingService: ListingService,
        private analytics: AnalyticsService
    ) {}

    async getCampaigns(listingId: string): Promise<ApolloQueryResult<GetCampaignsQuery>> {
        const listingSaleType: string = (
            await this.apollo
                .query<GetListingSaleTypeQuery, GetListingSaleTypeQueryVariables>({
                    query: GET_LISTING_SALE_TYPE,
                    variables: {
                        listingId,
                    },
                })
                .toPromise()
        ).data.listing[0].saleType;

        let campaignCodes;
        if (listingSaleType === SaleType.Sale) {
            campaignCodes = SALE_CAMPAIGNS;
        } else {
            campaignCodes = RENT_CAMPAIGNS;
        }

        return this.apollo
            .query<GetCampaignsQuery, GetCampaignsQueryVariables>({
                query: GET_CAMPAIGNS,
                variables: {
                    campaignCodes,
                },
            })
            .toPromise();
    }

    getCampaignsSub(): Observable<SubscriptionResult<GetCampaignsSubSubscription>> {
        return this.apollo.subscribe<GetCampaignsSubSubscription, GetCampaignsSubSubscriptionVariables>({
            query: GET_CAMPAIGNS_SUB,
            variables: {},
        });
    }

    async createCampaign(
        id: string,
        name: string,
        price: number,
        desc: string = null,
        priceDescription: string = '',
        type: string = '',
        order: number = 1,
        code: string,
        image: string = ''
    ): Promise<FetchResult<CreateCampaignMutation>> {
        price = price * 100;
        return this.apollo
            .mutate<CreateCampaignMutation, CreateCampaignMutationVariables>({
                mutation: CREATE_CAMPAIGN,
                variables: {
                    id,
                    name,
                    price,
                    code,
                    desc,
                    priceDescription,
                    type,
                    order,
                    image,
                },
            })
            .toPromise();
    }

    async deleteCampaign(campaignId: string): Promise<FetchResult<DeleteCampaignMutation>> {
        return this.apollo
            .mutate<DeleteCampaignMutation, DeleteCampaignMutationVariables>({
                mutation: DELETE_CAMPAIGN,
                variables: { campaignId },
            })
            .toPromise();
    }

    getCampaignAddonGroups(campaignId: string): Observable<ApolloQueryResult<GetCampaignAddonGroupQuery>> {
        return this.apollo.query<GetCampaignAddonGroupQuery, GetCampaignAddonGroupQueryVariables>({
            query: GET_CAMPAIGN_ADDON_GROUPS,
            variables: {
                campaignId,
            },
        });
    }

    async getAllCampaignRecommendations(listingId: string): Promise<ApolloQueryResult<GetAllCampaignInclusionsQuery>> {
        const response = await this.apollo
            .query<GetAllCampaignInclusionsQuery, GetAllCampaignInclusionsQueryVariables>({
                query: GET_ALL_CAMPAIGN_INCLUSIONS_QUERY,
                variables: {},
            })
            .toPromise();

        const listingContext = await this.getListingAdvisorContext(listingId);

        for (const campaign of response.data.campaign) {
            for (const inclusionGroup of campaign.campaign_inclusion_groups) {
                // filter the inclusions based on metro and zone
                inclusionGroup.inclusion_group.inclusions = inclusionGroup.inclusion_group.inclusions.filter(
                    (o) => (!o.metro || o.metro === listingContext.metro) && (!o.zone || o.zone === listingContext.zone)
                );

                // remove the group without inclusions
                if (!inclusionGroup.inclusion_group.inclusions.length) {
                    campaign.campaign_inclusion_groups.splice(
                        campaign.campaign_inclusion_groups.indexOf(inclusionGroup),
                        1
                    );
                }
            }
        }

        return response;
    }

    async getAllCampaignNoInclusion(listingId: string): Promise<GetAllCampaignNoInclusionsQuery['campaign']> {
        const response = await this.apollo
            .query<GetAllCampaignNoInclusionsQuery, GetAllCampaignNoInclusionsQueryVariables>({
                query: GET_ALL_CAMPAIGN_NO_INCLUSION,
                variables: {},
            })
            .toPromise();
        return response.data.campaign;
    }

    async getInclusionsByCampaignId(
        campaignId: string,
        listingId: string
    ): Promise<ApolloQueryResult<GetInclusionsByCampaignIdQuery>> {
        const response = await this.apollo
            .query<GetInclusionsByCampaignIdQuery, GetInclusionsByCampaignIdQueryVariables>({
                query: GET_INCLUSIONS_BY_CAMPAIGN_ID_QUERY,
                variables: {
                    campaignId,
                },
            })
            .toPromise();

        const listingContext = await this.getListingAdvisorContext(listingId);

        for (const inclusionGroup of response.data.campaign_inclusion_group) {
            // filter the inclusions based on metro and zone
            inclusionGroup.inclusion_group.inclusions = inclusionGroup.inclusion_group.inclusions.filter(
                (o) => (!o.metro || o.metro === listingContext.metro) && (!o.zone || o.zone === listingContext.zone)
            );

            // remove the group, if dont have any inclusions
            if (!inclusionGroup.inclusion_group.inclusions.length) {
                response.data.campaign_inclusion_group.splice(
                    response.data.campaign_inclusion_group.indexOf(inclusionGroup),
                    1
                );
            }
        }

        return response;
    }

    async getAddonsByAddonGroupId(addonGroupId: string): Promise<ApolloQueryResult<GetAddonsByAddonGroupIdQuery>> {
        return this.apollo
            .query<GetAddonsByAddonGroupIdQuery, GetAddonsByAddonGroupIdQueryVariables>({
                query: GET_ADDONS_BY_ADDON_GROUP_ID_QUERY,
                variables: {
                    addonGroupId,
                },
            })
            .toPromise();
    }

    async getCampaignById(campaignId: string): Promise<GetCampaignByIdQuery['campaign_by_pk']> {
        const resp = await this.apollo
            .query<GetCampaignByIdQuery, GetCampaignByIdQueryVariables>({
                query: GET_CAMPAIGN_BY_ID,
                variables: {
                    campaignId,
                },
            })
            .toPromise();
        return resp.data.campaign_by_pk;
    }

    async getAddonGroupsByCampaignId(campaignId: string): Promise<ApolloQueryResult<GetAddonGroupsByCampaignIdQuery>> {
        return this.apollo
            .query<GetAddonGroupsByCampaignIdQuery, GetAddonGroupsByCampaignIdQueryVariables>({
                query: GET_ADDON_GROUPS_BY_CAMPAIGN_ID_QUERY,
                variables: {
                    campaignId,
                },
            })
            .toPromise();
    }

    async getAddonGroupById(addonGroupId: string): Promise<ApolloQueryResult<GetAddonGroupByIdQuery>> {
        return await this.apollo
            .query<GetAddonGroupByIdQuery, GetAddonGroupByIdQueryVariables>({
                query: GET_ADDON_GROUP_BY_ID,
                variables: {
                    addonGroupId,
                },
            })
            .toPromise();
    }

    async getAddonByCampaignId(campaignId: string): Promise<ApolloQueryResult<GetCampaignAddonsQuery>> {
        return this.apollo
            .query<GetCampaignAddonsQuery, GetCampaignAddonsQueryVariables>({
                query: GET_ADDON_BY_CAMPAIGN_ID,
                variables: { campaignId },
            })
            .toPromise();
    }

    async getRecommendedCampaignByListing(
        listingId: string
    ): Promise<ApolloQueryResult<GetRecommendedCampaignsByListingQuery>> {
        const response = await this.apollo
            .query<GetRecommendedCampaignsByListingQuery, GetRecommendedCampaignsByListingQueryVariables>({
                query: GET_RECOMMENDED_CAMPAIGNS_BY_LISTING,
                variables: { listingId },
            })
            .pipe(
                map((resp) => {
                    // we need to sort campaigns by the index
                    // the one with lower index should be shown Primary campaign
                    if (resp.data && resp.data.recommended_campaigns && resp.data.recommended_campaigns.length) {
                        resp.data.recommended_campaigns.sort((a, b) => {
                            return a.index - b.index;
                        });
                    }
                    return resp;
                })
            )
            .toPromise();
        //
        // Recommendation_A: Present cheaper options first for users with low budget
        //

        // get user experiments
        const userExperiments = await this.experimentService.getCurrentUserExperiments();

        const budgetResp = await this.listingOnboardingService.getBudgetSize({ listingId });
        if (
            budgetResp.data.listing_onboarding[0].budget_size === Onboarding_Budget_Size_Enum.Small &&
            userExperiments.includes(Experiment_Name_Enum.RecommendationA)
        ) {
            // sort campaigns by price starting from cheapest
            response.data.recommended_campaigns.sort((a, b) => {
                return a.campaign.price - b.campaign.price;
            });
            // Track event
            this.analytics.trackEvent('Experiment Viewed', {
                experiment_name: 'Recommendation',
                variation_name: 'Recommendation A',
            });
            // For Mixpanel
            this.analytics.trackEvent('$experiment_started', {
                'Experiment name': 'Recommendation',
                'Variant name': 'Recommendation A',
            });
        }
        // End of Recommendation_A

        //
        // Recommendation_B: Present cheaper options first for users outside of Zone 1
        //

        // get zone
        const zone = await this.listingService.getListingZone(listingId);

        if (zone !== Zone_Type_Enum.Zone_1 && userExperiments.includes(Experiment_Name_Enum.RecommendationB)) {
            // sort campaigns by price starting from cheapest
            response.data.recommended_campaigns.sort((a, b) => {
                return a.campaign.price - b.campaign.price;
            });
            // Track event
            this.analytics.trackEvent('Experiment Viewed', {
                experiment_name: 'Recommendation',
                variation_name: 'Recommendation B',
            });
            // For Mixpanel
            this.analytics.trackEvent('$experiment_started', {
                'Experiment name': 'Recommendation',
                'Variant name': 'Recommendation B',
            });
        }
        // End of Recommendation_B

        //
        // Recommendation_C: Present cheaper options first for users regardless of recommendation
        //
        if (userExperiments.includes(Experiment_Name_Enum.RecommendationC)) {
            // sort campaigns by price starting from cheapest
            response.data.recommended_campaigns.sort((a, b) => {
                return a.campaign.price - b.campaign.price;
            });
            // Track event
            this.analytics.trackEvent('Experiment Viewed', {
                experiment_name: 'Recommendation',
                variation_name: 'Recommendation C',
            });
            // For Mixpanel
            this.analytics.trackEvent('$experiment_started', {
                'Experiment name': 'Recommendation',
                'Variant name': 'Recommendation C',
            });
        }
        // End of Recommendation C
        return response;
    }

    async getRecommendedCampaignURL(listingId: string): Promise<string> {
        try {
            const campaignIds = (await this.getRecommendedCampaignByListing(listingId)).data.recommended_campaigns.map(
                (o) => o.campaign.id
            );
            const campaignId = campaignIds[0];
            return ['/pages/listing', listingId, 'campaign', campaignId].join('/');
        } catch (e) {
            console.error(e);
            alert('Unable to get recommended campaign, try refreshing the page');
        }
    }

    async getRecommendedAddonByCampaignCode(
        campaignCode: string,
        listingId: string
    ): Promise<ApolloQueryResult<GetRecommendedAddonsByCampaignCodeQuery>> {
        return this.apollo
            .query<GetRecommendedAddonsByCampaignCodeQuery, GetRecommendedAddonsByCampaignCodeQueryVariables>({
                query: GET_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE,
                variables: { campaign_code: campaignCode, listingId },
            })
            .pipe(
                map((resp) => {
                    // we need to sort campaigns by the index
                    // the one with lower index should be shown Primary campaign
                    if (resp.data && resp.data.recommended_addons && resp.data.recommended_addons.length) {
                        resp.data.recommended_addons.sort((a, b) => {
                            return a.index - b.index;
                        });

                        // update prices of the addons, if have any special prices
                        resp.data.recommended_addons.map((addon) => {
                            if (addon.use_special_price) {
                                addon.addon.price = addon.special_price;
                            }
                        });
                    }
                    return resp;
                })
            )
            .toPromise();
    }

    async getBasicRecommendedAddonByCampaignCode(
        campaignCode: string,
        listingId: string
    ): Promise<ApolloQueryResult<GetBasicRecommendedAddonsByCampaignCodeQuery>> {
        return this.apollo
            .query<GetBasicRecommendedAddonsByCampaignCodeQuery, GetBasicRecommendedAddonsByCampaignCodeQueryVariables>(
                {
                    query: GET_BASIC_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE,
                    variables: { campaign_code: campaignCode, listingId },
                }
            )
            .pipe(
                map((resp) => {
                    if (resp.data && resp.data.recommended_addons && resp.data.recommended_addons.length) {
                        // update prices of the addons, if have any special prices
                        resp.data.recommended_addons.map((addon) => {
                            if (addon.use_special_price) {
                                addon.addon.price = addon.special_price;
                            }
                        });
                    }
                    return resp;
                })
            )
            .toPromise();
    }

    async getAddonGroupsByAddonCode(
        campaignId: string,
        addonCodes: string[]
    ): Promise<ApolloQueryResult<GetAddonGroupsByAddonCodeQuery>> {
        return this.apollo
            .query<GetAddonGroupsByAddonCodeQuery, GetAddonGroupsByAddonCodeQueryVariables>({
                query: GET_ADDON_GROUP_BY_CODE,
                variables: { campaign_id: campaignId, addon_codes: addonCodes },
            })
            .pipe(
                map((resp) => {
                    // we need to sort addons based on the addon codes passed into this method
                    if (resp.data && resp.data.addon_group && resp.data.addon_group.length) {
                        for (const addonGroup of resp.data.addon_group) {
                            addonGroup.addons.sort((a, b) => {
                                return addonCodes.indexOf(a.addon.code) - addonCodes.indexOf(b.addon.code);
                            });
                        }
                    }
                    return resp;
                })
            )
            .toPromise();
    }

    async getListingAdvisorContext(listingId: string): Promise<Listing_Advisor_Context_Response> {
        return (
            await this.apollo
                .query<GetListingAdvisorContextQuery, GetListingAdvisorContextQueryVariables>({
                    query: GET_LISTING_ADVISOR_CONTEXT,
                    variables: {
                        listingId,
                    },
                })
                .toPromise()
        ).data.get_listing_advisor_context;
    }

    async getCampaignByCode(code: string): Promise<GetCampaignByCodeQuery['campaign'][0]> {
        const resp = await this.apollo
            .query<GetCampaignByCodeQuery, GetCampaignByCodeQueryVariables>({
                query: GET_CAMPAIGN_BY_CODE,
                variables: {
                    code,
                },
            })
            .toPromise();
        return resp.data.campaign[0];
    }

    async getAddonById(addOnId: string): Promise<GetAddonByIdQuery['addon_by_pk']> {
        const resp = await this.apollo
            .query<GetAddonByIdQuery, GetAddonByIdQueryVariables>({
                query: GET_ADDON_BY_ID,
                variables: { addOnId },
            })
            .toPromise();
        return resp.data.addon_by_pk;
    }
}

//
// Queries
//
const GET_CAMPAIGNS_SUB = gql`
    subscription getCampaignsSub {
        campaign {
            id
            name
            desc
            price
        }
    }
`;

const GET_CAMPAIGNS = gql`
    query getCampaigns($campaignCodes: [String!]) {
        campaign(where: { code: { _in: $campaignCodes } }) {
            id
            name
            desc
            price
            code
        }
    }
`;

const CREATE_CAMPAIGN = gql`
    mutation createCampaign(
        $id: uuid!
        $name: String!
        $price: Int!
        $desc: String
        $priceDescription: String
        $type: String
        $order: Int!
        $code: String!
        $image: String!
    ) {
        insert_campaign(
            objects: [
                {
                    id: $id
                    name: $name
                    price: $price
                    desc: $desc
                    price_description: $priceDescription
                    type: $type
                    order: $order
                    code: $code
                    image: $image
                }
            ]
        ) {
            returning {
                id
            }
        }
    }
`;

const DELETE_CAMPAIGN = gql`
    mutation deleteCampaign($campaignId: uuid!) {
        delete_campaign(where: { id: { _eq: $campaignId } }) {
            affected_rows
        }
    }
`;

const GET_CAMPAIGN_ADDON_GROUPS = gql`
    query getCampaignAddonGroup($campaignId: uuid!) {
        campaign: campaign_by_pk(id: $campaignId) {
            id
            name
            desc
            price
        }
        addonGroups: campaign_addon_group(
            where: { campaign_id: { _eq: $campaignId } }
            order_by: { addon_group: { order: asc } }
        ) {
            addon_group {
                id
                order
                title
                subtitle
                type
                short_title
                addons: addon_addon_groups(order_by: { addon: { type: asc, group_order: asc } }) {
                    addon {
                        id
                        name
                        type
                        group_order
                        default_status
                        image
                        price
                        min_qty
                        max_qty
                        front_desc_small_screen
                        front_desc_large_screen
                        back_desc_small_screen
                        back_desc_large_screen
                        recommended_flag
                    }
                }
            }
        }
    }
`;

const GET_ADDON_GROUPS_BY_CAMPAIGN_ID_QUERY = gql`
    query getAddonGroupsByCampaignId($campaignId: uuid!) {
        addonGroups: campaign_addon_group(
            where: { campaign_id: { _eq: $campaignId } }
            order_by: { addon_group: { order: asc } }
        ) {
            id
            addon_group_id
        }
    }
`;

const GET_ALL_CAMPAIGN_INCLUSIONS_QUERY = gql`
    query GetAllCampaignInclusions {
        campaign(order_by: { order: asc }) {
            type
            desc
            price
            price_description
            id
            code
            name
            image
            image_nonprimary_hero_apartment
            image_nonprimary_hero_house
            image_nonprimary_hero_land
            image_nonprimary_hero_townhouse
            image_nonprimary_hero_unit
            campaign_inclusion_groups(order_by: { inclusion_group: { order: asc } }) {
                inclusion_group {
                    inclusions(order_by: { order: asc }) {
                        name
                        description
                        order
                        metro
                        zone
                    }
                }
            }
        }
    }
`;

const GET_ALL_CAMPAIGN_NO_INCLUSION = gql`
    query GetAllCampaignNoInclusions {
        campaign(order_by: { order: asc }) {
            type
            desc
            price
            price_description
            id
            code
            name
            image
            image_nonprimary_hero_apartment
            image_nonprimary_hero_house
            image_nonprimary_hero_land
            image_nonprimary_hero_townhouse
            image_nonprimary_hero_unit
        }
    }
`;

const GET_INCLUSIONS_BY_CAMPAIGN_ID_QUERY = gql`
    query getInclusionsByCampaignId($campaignId: uuid!) {
        campaign_inclusion_group(
            where: { campaign_id: { _eq: $campaignId } }
            order_by: { inclusion_group: { order: asc } }
        ) {
            inclusion_group {
                name
                inclusions(order_by: { order: asc }) {
                    name
                    description
                    metro
                    zone
                    inclusion_addons {
                        addon {
                            id
                            min_qty
                            type
                        }
                    }
                }
            }
        }
    }
`;

const GET_ADDONS_BY_ADDON_GROUP_ID_QUERY = gql`
    query getAddonsByAddonGroupId($addonGroupId: uuid!) {
        addon_group(where: { id: { _eq: $addonGroupId } }, order_by: { order: asc }) {
            id
            title
            subtitle
            type
            short_title
            addon_addon_groups(order_by: { addon: { group_order: asc } }) {
                addon {
                    id
                    name
                    type
                    default_status
                    image
                    price
                    min_qty
                    max_qty
                    front_desc_small_screen
                    front_desc_large_screen
                    back_desc_small_screen
                    back_desc_large_screen
                    recommended_flag
                    benefits(order_by: { order: asc }) {
                        id
                        description
                    }
                }
            }
        }
    }
`;

const GET_CAMPAIGN_BY_ID = gql`
    query getCampaignById($campaignId: uuid!) {
        campaign_by_pk(id: $campaignId) {
            id
            name
            desc
            price
            price_description
            type
            code
            image
            image_primary_hero_house
            image_primary_hero_land
            image_primary_hero_townhouse
            image_primary_hero_apartment
            image_primary_hero_unit
            image_primary_overlay_house
            image_primary_overlay_land
            image_primary_overlay_townhouse
            image_primary_overlay_apartment
            image_primary_overlay_unit
        }
    }
`;

const GET_ADDON_GROUP_BY_ID = gql`
    query getAddonGroupById($addonGroupId: uuid!) {
        addon_group: addon_group_by_pk(id: $addonGroupId) {
            id
            title
            subtitle
            short_title
            order
            type
            addons: addon_addon_groups {
                addon {
                    id
                    image
                    max_qty
                    min_qty
                    name
                    price
                    type
                    recommended_flag
                    group_order
                    front_desc_small_screen
                    front_desc_large_screen
                    default_status
                    back_desc_small_screen
                    back_desc_large_screen
                    absolute_order
                    exclusion_group
                    benefits(order_by: { order: asc }) {
                        id
                        description
                        order
                    }
                }
            }
        }
    }
`;

const GET_ADDON_BY_CAMPAIGN_ID = gql`
    query getCampaignAddons($campaignId: uuid!) {
        addon(
            where: {
                addon_addon_groups: { addon_group: { campaign_addon_groups: { campaign_id: { _eq: $campaignId } } } }
            }
        ) {
            id
        }
    }
`;

const GET_RECOMMENDED_CAMPAIGNS_BY_LISTING = gql`
    query getRecommendedCampaignsByListing($listingId: uuid!) {
        recommended_campaigns(listingId: $listingId) {
            index
            campaign_code
            campaign {
                id
                code
                name
                price
            }
        }
    }
`;

const GET_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE = gql`
    query getRecommendedAddonsByCampaignCode($campaign_code: String!, $listingId: uuid!) {
        recommended_addons(campaign_code: $campaign_code, listingId: $listingId) {
            addon_code
            index
            use_special_price
            special_price
            addon {
                id
                image
                max_qty
                min_qty
                name
                price
                type
                recommended_flag
                group_order
                front_desc_small_screen
                front_desc_large_screen
                default_status
                back_desc_small_screen
                back_desc_large_screen
                absolute_order
                exclusion_group
                is_one_time_purchase
                code
                benefits(order_by: { order: asc }) {
                    id
                    description
                    order
                }
            }
        }
    }
`;

const GET_ADDON_GROUP_BY_CODE = gql`
    query getAddonGroupsByAddonCode($campaign_id: uuid!, $addon_codes: [String!]) {
        addon_group(
            where: {
                campaign_addon_groups: { campaign_id: { _eq: $campaign_id } }
                addon_addon_groups: { addon: { code: { _in: $addon_codes } } }
            }
            order_by: { order: asc }
        ) {
            id
            title
            subtitle
            short_title
            order
            type
            addons: addon_addon_groups(where: { addon: { code: { _in: $addon_codes } } }) {
                addon {
                    id
                    image
                    max_qty
                    min_qty
                    name
                    price
                    type
                    recommended_flag
                    group_order
                    front_desc_small_screen
                    front_desc_large_screen
                    default_status
                    back_desc_small_screen
                    back_desc_large_screen
                    absolute_order
                    exclusion_group
                    is_one_time_purchase
                    code
                    benefits(order_by: { order: asc }) {
                        id
                        description
                        order
                    }
                }
            }
        }
    }
`;

const GET_LISTING_ADVISOR_CONTEXT = gql`
    query getListingAdvisorContext($listingId: uuid!) {
        get_listing_advisor_context(listingId: $listingId) {
            zone
            metro
        }
    }
`;

const GET_BASIC_RECOMMENDED_ADDONS_BY_CAMPAIGN_CODE = gql`
    query getBasicRecommendedAddonsByCampaignCode($campaign_code: String!, $listingId: uuid!) {
        recommended_addons(campaign_code: $campaign_code, listingId: $listingId) {
            addon_code
            use_special_price
            special_price
            addon {
                id
                price
                type
                min_qty
            }
        }
    }
`;

const GET_CAMPAIGN_BY_CODE = gql`
    query getCampaignByCode($code: String!) {
        campaign(where: { code: { _eq: $code } }) {
            id
        }
    }
`;

const GET_LISTING_SALE_TYPE = gql`
    query getListingSaleType($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            saleType
        }
    }
`;

const GET_ADDON_BY_ID = gql`
    query getAddonById($addOnId: uuid!) {
        addon_by_pk(id: $addOnId) {
            id
            name
            price
            image
            code
        }
    }
`;
