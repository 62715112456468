import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as jwt_decode from 'jwt-decode';
import { AnalyticsService } from './analytics.service';
import { UserRole } from 'app/shared/models/user.model';
import { BehaviorSubject } from 'rxjs';

export interface IHasuraJWT {
    'https://buymyplace.com.au/jwt/claims': IBMPClaims;
    'old-jwt'?: string;
    'https://hasura.io/jwt/claims'?: IHasuraClaims;
    iat?: number;
    exp?: number;
}

export interface IHasuraClaims {
    'x-hasura-allowed-roles'?: string[];
    'x-hasura-default-role'?: string;
    'x-hasura-user-id'?: string;
}
export interface IBMPUserInfo {
    firstName?: string;
    lastName?: string;
    email: string;
    userId?: string;
}
export interface IBMPClaims extends IBMPUserInfo {
    impersonatedBy?: IBMPUserInfo;
}

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    public isLoggedIn = new BehaviorSubject<object>(null);
    constructor(private jwtHelper: JwtHelperService, private analytics: AnalyticsService) {}

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        // Check whether the token is expired and return
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
    }

    getUserId(): string {
        const currentSession = this.getSession();
        if (!currentSession) {
            return null;
        }
        const splitJwt = currentSession.split('.');
        const decodedJwtBodyString = atob(splitJwt[1]);
        const decodedJwtBody: IHasuraJWT = JSON.parse(decodedJwtBodyString);
        return decodedJwtBody['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    }

    getSession(): string {
        if (sessionStorage.getItem('impersonateToken')) {
            return sessionStorage.getItem('impersonateToken');
        }
        return localStorage.getItem('token');
    }

    async setSession(jwt: string): Promise<void> {
        localStorage.setItem('token', jwt);
        this.analytics.run(async () => {
            await this.analytics.identifyUser(jwt);
        });
        this.isLoggedIn.next(new Object());
    }

    setUserSession(jwt: string): void {
        sessionStorage.setItem('impersonateToken', jwt);
        this.isLoggedIn.next(new Object());
    }

    deleteSession(checkImpersonationSession: boolean = false): void {
        this.analytics.run(() => {
            this.analytics.do.reset();
        });
        if (checkImpersonationSession && this.isAdminImpersonatingUser()) {
            sessionStorage.removeItem('impersonateToken');
        } else {
            localStorage.removeItem('token');
            sessionStorage.removeItem('impersonateToken');
            localStorage.removeItem('redirectUrl');
        }
    }

    isAdmin(): boolean {
        if (localStorage.getItem('token')) {
            const decoded = jwt_decode(localStorage.getItem('token'));
            const role = decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'];
            return role === UserRole.StaffUser || role === UserRole.Admin;
        }
    }

    isAdminImpersonatingUser(): boolean {
        const impersonateToken = sessionStorage.getItem('impersonateToken');
        const token = localStorage.getItem('token');

        return impersonateToken && token ? true : false;
    }

    saveCampaignInSession(campaignCode: string): void {
        sessionStorage.setItem('selectedCampaignCode', campaignCode);
    }

    getCampaignFromSession(): string {
        return sessionStorage.getItem('selectedCampaignCode');
    }

    removeCampaignFromSession(): void {
        sessionStorage.removeItem('selectedCampaignCode');
    }
}
