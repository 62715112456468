import { Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
    @Input() title = '';
    @Input() content: SafeHtml | string = '';

    constructor(
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: { title: string; content: SafeHtml | string }
    ) {
        this.title = data.title;
        if (typeof data.content === 'string') {
            this.content = this.domSanitizer.bypassSecurityTrustHtml(data.content);
        }
    }
}
