"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dialog_1 = require("@angular/material/dialog");
const forms_1 = require("@angular/forms");
class FeedbackDialogComponent {
    constructor(dialogRef, domSanitizer, data) {
        this.dialogRef = dialogRef;
        this.domSanitizer = domSanitizer;
        this.data = data;
        this.feedbackField = new forms_1.FormControl('', [forms_1.Validators.required]);
        // checkbox is on by default
        this.checkboxValue = true;
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
        // compose reactive form
        this.form = new forms_1.FormGroup({
            feedback: this.feedbackField,
        });
    }
    closeDialog(result, message = '') {
        if (this.data.allowClose !== false) {
            this.dialogRef.close({
                result,
                message,
                checkboxValue: this.checkboxValue,
            });
        }
    }
}
exports.FeedbackDialogComponent = FeedbackDialogComponent;
