<div *ngIf="!config.layout.toolbar.hidden" class="app-top-nav">
    <!-- Top Bar (Mobile / Desktop) -->
    <div class="app-top-nav__top-bar" fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-icon (click)="showMobileNav()" fxHide.gt-xs>menu</mat-icon>
        <img alt="buymyplace logo" fxFlex="0 1 auto" height="46" src="/assets/images/logos/top-nav-logo.svg" width="150"
            (click)="logoClick()">
        <div class="space" fxFlex="1 1 auto">
            <div class="app-top-nav__link" fxHide.xs> <a [routerLink]="'/pages/listings'">My Listings</a> </div>
        </div>
        <div class="app-top-nav__contact-number"><a href="tel:{{phoneNumber}}">{{phoneNumberFormatted}}</a></div>
        <div class="app-top-nav__avatar-small"
            [ngStyle]="{'background': theme?.backgroundColor, 'color': theme?.textColour}" (click)="showLogoutOverlay()"
            fxFlex="0 1 auto">{{userInfo?.initials}}</div>
        <div class="app-top-nav__lock-icon-small" *ngIf="isAdmin">
            <img width="6.67" height="8.75" src="../../../../assets/icons/lock_white.svg">
        </div>
    </div>

    <!-- Desktop Menu -->
    <nav *ngIf="!config.layout.hideDesktopMenuOnly" class="app-top-nav__desktop-nav" fxHide.xs>
        <ul fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <li (click)="hideMobileNav()" *ngFor="let nav of menuItems" [routerLink]="[nav.url]">
                <mat-icon>{{nav.icon}}</mat-icon>
                {{nav.title}}
            </li>
        </ul>
    </nav>

    <!-- Mobile Menu -->
    <div [@mobileNavOverlayState]="mobileNavState" class="app-top-nav__mobile-menu-overlay" (click)="hideMobileNav()">
    </div>
    <nav [@mobileNavState]="mobileNavState" class="app-top-nav__mobile-nav" fxFlex="1 0 auto" fxLayout="column"
        fxLayoutAlign="start stretch">
        <div class="app-top-nav__top-bar" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between center">
            <img alt="buymyplace logo" fxFlex="0 0 auto" height="46" src="/assets/images/logos/top-nav-logo.svg"
                width="150">
            <div fxFlex="1 0 auto">
                <!-- spacer -->
            </div>
            <mat-icon (click)="hideMobileNav()" fxHide.gt-xs>arrow_back
            </mat-icon>
        </div>

        <!-- User details -->
        <div class="app-top-nav__user-info">
            <div *ngIf="isAdmin" class="impersonating-title">
                <img class="lock-icon" src="../../../../assets/icons/lock_black.svg" />
                <span>Impersonating</span>
            </div>
            <div class="app-top-nav__user-name"><span>{{userInfo?.firstName}} {{userInfo?.lastName}}</span></div>
            <div class="app-top-nav__user-email"><span>{{userInfo?.email}}</span></div>
            <profile-circle class="profile-circle" [theme]="theme" [userInfo]="userInfo" [isAdmin]="isAdmin">
            </profile-circle>
        </div>

        <div class="app-top-nav__mobile-menu" fxFlex="1 1 auto" fxLayout="column">
            <h3>Property management</h3>
            <ul fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="start stretch">
                <li (click)="hideMobileNav()" *ngFor="let nav of menuItems" [routerLink]="[nav.url]">
                    <mat-icon>{{nav.icon}}</mat-icon>
                    {{nav.title}}
                </li>
            </ul>
        </div>

        <!-- Footer in mobile menu -->
        <div fxLayout="column">
            <h3 class="footer-title">Footer</h3>
            <footer isMobile="true"></footer>
            <button mat-raised-button color="primary" class="logout-btn" (click)="logout()">Logout</button>
        </div>
    </nav>
</div>