"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("@angular/core");
const i1 = require("./jwt.service");
const i2 = require("@angular/router");
class AdminGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(route, state) {
        if (this.authService.isAuthenticated()) {
            if (this.authService.isAdmin()) {
                return true;
            }
            else {
                this.router.navigate(['/pages/listings-management']);
                return false;
            }
        }
        this.router.navigate([`/auth/login`], { queryParams: { return: `${state.url}` } });
        return false;
    }
}
AdminGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuardService_Factory() { return new AdminGuardService(i0.ɵɵinject(i1.JwtService), i0.ɵɵinject(i2.Router)); }, token: AdminGuardService, providedIn: "root" });
exports.AdminGuardService = AdminGuardService;
