import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { JwtService } from 'app/service/jwt.service';
import { MatDialog } from '@angular/material';
import * as jwt_decode from 'jwt-decode';
import { FuseConfig } from '@fuse/types';
import { BasicDialogComponent } from 'app/shared/basic-dialog/basic-dialog.component';
import { LogoutOverlayComponent } from '../logout-overlay/logout-overlay.component';
import { BuildInfoService } from '../../../service/build-info.service';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { PHONE_NUMBER, PHONE_NUMBER_FORMATTED } from '../../../constants/phone-number.constant';

export interface Theme {
    backgroundColor: string;
    textColour: string;
}

export interface UserInfo {
    email?: string;
    firstName?: string;
    lastName?: string;
    userId?: string;
    initials?: string;
}

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
    animations: [
        trigger('mobileNavState', [
            state('hidden', style({ transform: 'translate3d(-100%, 0, 0)', visibility: 'hidden' })),
            state('visible', style({ transform: 'none', visibility: 'visible' })),
            transition('* => *', animate('200ms cubic-bezier(0.35, 0, 0.25, 1)')),
        ]),
        trigger('mobileNavOverlayState', [
            state('hidden', style({ opacity: '0', visibility: 'hidden' })),
            state('visible', style({ opacity: '0.7', visibility: 'visible' })),
            transition('* => *', animate('200ms cubic-bezier(0.35, 0, 0.25, 1)')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavComponent extends BaseComponent implements OnInit {
    @ViewChild('logoutOverlayComponent', { static: true })
    public logoutOverlayComponent: LogoutOverlayComponent;
    @Input() config: FuseConfig;
    mobileNavState: 'visible' | 'hidden' = 'hidden';
    menuItems = [
        {
            title: 'My Listings',
            icon: 'view_list',
            url: '/pages/listings-management',
        },
    ];
    public userInfo: UserInfo;
    public theme: Theme;
    public isAdmin: boolean;
    public showUserInfoOverlay = false;
    public overlayColour: string;
    public mobileBackgroundHeight: string;
    public roleThemes: { [key: string]: Theme } = {
        ['admin']: {
            backgroundColor: '#FFFFFF',
            textColour: '#000D52',
        },
        ['user']: {
            backgroundColor: '#3540F4',
            textColour: '#FFFFFF',
        },
    };
    phoneNumber = PHONE_NUMBER;
    phoneNumberFormatted = PHONE_NUMBER_FORMATTED;

    constructor(
        private router: Router,
        private mediaObserver: MediaObserver,
        public dialog: MatDialog,
        private jwtService: JwtService,
        private buildInfoService: BuildInfoService,
        private wsClient: SubscriptionClient
    ) {
        super();
    }

    ngOnInit(): void {
        // hide mobile nav on navigation events
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
            // see also
            this.hideMobileNav();
        });
        // update current user when logged in
        this.jwtService.isLoggedIn.pipe(takeUntil(this.destroyed$)).subscribe((isNewUser) => {
            this.decodeCurrentUserInfo();
        });

        // hide mobile nav when we switched from mobile to desktop
        this.mediaObserver
            .asObservable()
            .pipe(mergeMap((t) => t))
            .subscribe((change: MediaChange) => {
                if (change.mqAlias.match(/(sm|md|lg)/gi)) {
                    this.hideMobileNav();
                }
            });
        this.isAdmin = this.jwtService.isAdmin();
        this.mobileBackgroundHeight = this.isAdmin ? '158px' : '133px';
        this.setTheme();
        this.decodeCurrentUserInfo();
    }

    setTheme(): void {
        if (sessionStorage.getItem('impersonateToken') && this.isAdmin) {
            this.theme = this.roleThemes['admin'];
        } else {
            this.theme = this.roleThemes['user'];
        }
    }

    decodeCurrentUserInfo(): void {
        let userToken;
        if (sessionStorage.getItem('impersonateToken')) {
            userToken = sessionStorage.getItem('impersonateToken');
        } else if (localStorage.getItem('token')) {
            userToken = localStorage.getItem('token');
        }

        if (!userToken) {
            return;
        }

        const userInfo = jwt_decode(userToken)['https://buymyplace.com.au/jwt/claims'];
        this.userInfo = {
            ...userInfo,
            initials: `${userInfo.firstName ? userInfo.firstName[0].toUpperCase() : ''}${
                userInfo.lastName ? userInfo.lastName[0].toUpperCase() : ''
            }`,
        };
    }

    showLogoutOverlay(): void {
        const dialogConfig = {
            panelClass: 'no-padding-dialog',
            minWidth: '176px',
            minHeight: '238px',
            position: {
                top: '64px',
                right: '16px',
            },
            data: {
                userInfo: this.userInfo,
                theme: this.theme,
                isAdmin: this.isAdmin,
            },
        };
        this.dialog.open(LogoutOverlayComponent, dialogConfig);
    }

    showMobileNav(): void {
        this.mobileNavState = 'visible';
    }

    hideMobileNav(): void {
        this.mobileNavState = 'hidden';
    }

    reloadPage(): void {
        document.location.href = document.location.href;
    }

    logout(): Promise<boolean> {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(BasicDialogComponent, {
                width: '480px',
                data: {
                    okBtnText: 'Confirm',
                    title: 'Important!',
                    text: 'Are you sure you want to logout?',
                },
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.destroyed$))
                .subscribe(async (result) => {
                    if (result) {
                        const isAdminImpersonatingUser = this.jwtService.isAdminImpersonatingUser();

                        this.jwtService.deleteSession(true);
                        this.wsClient.close();
                        if (!isAdminImpersonatingUser) {
                            this.router.navigate(['/auth/login']);
                        } else {
                            this.router.navigate(['/admin/dashboard']);
                        }
                    }
                });
        });
    }

    logoClick(): void {
        // register click, if you keep clicking you'll see a dialog with build info
        this.buildInfoService.click();
    }
}
