import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo, SubscriptionResult } from 'apollo-angular';
import {
    GetStaffUsersSubscription,
    GetStaffUsersSubscriptionVariables,
    GetUsersQuery,
    GetUsersQueryVariables,
    GetUserByIdQuery,
    GetUserByIdQueryVariables,
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables,
    SendOnboardingEmailQuery,
    SendOnboardingEmailQueryVariables,
    SendPurchaseEmailQuery,
    SendPurchaseEmailQueryVariables,
    SetUserEmailVerificationMutation,
    SetUserEmailVerificationMutationVariables,
    GetUserDetailByIdQuery,
    GetUserDetailByIdQueryVariables,
    UpdateUserPhoneNumberMutation,
    UpdateUserPhoneNumberMutationVariables,
} from '../../generated/graphql';
import { ApolloQueryResult } from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { Observable } from 'rxjs';

const GET_USERS_QUERY = gql`
    query getUsers {
        user {
            id
            first_name
            email
        }
    }
`;

const GET_USER_BY_ID_QUERY = gql`
    query getUserById($userId: uuid!) {
        user(where: { id: { _eq: $userId } }) {
            id
            first_name
            last_name
            email
            email_verified
            submitted_via
            initial_submitted_via
        }
    }
`;

const UPDATE_USER_ROLE = gql`
    mutation updateUserRole($userId: uuid!, $role: String, $allowedRole: String) {
        update_user(where: { id: { _eq: $userId } }, _set: { default_role: $role, allowed_roles: $allowedRole }) {
            affected_rows
        }
    }
`;

const SEND_ONBOARDING_EMAIL_QUERY = gql`
    query sendOnboardingEmail($userId: String!) {
        send_onboarding_email(userId: $userId) {
            success
            error
        }
    }
`;

const SEND_PURCHASE_EMAIL_QUERY = gql`
    query sendPurchaseEmail($orderId: String!) {
        send_purchase_email(orderId: $orderId) {
            success
            error
        }
    }
`;

const GET_STAFF_USERS = gql`
    subscription getStaffUsers {
        user(where: { default_role: { _eq: "staff_user" } }) {
            id
            first_name
            last_name
            email
        }
    }
`;

const UPDATE_USER_EMAIL_VERIFICATION = gql`
    mutation setUserEmailVerification($userId: uuid!, $email_verified: Boolean) {
        update_user_email_verification(userId: $userId, email_verified: $email_verified) {
            affected_rows
        }
    }
`;

const GET_USER_DETAIL_BY_ID = gql`
    query GetUserDetailById($userId: uuid!) {
        user(where: { id: { _eq: $userId } }) {
            id
            phone_number_verified
            listings(order_by: { created_at: asc }) {
                id
                propertyDetails_address_1
                propertyDetails_address_2
                propertyDetails_address_state
                propertyDetails_address_suburb
                propertyDetails_address_postcode
                listing_onboardings {
                    finished
                    skipped
                }
            }
        }
    }
`;

const UPDATE_USER_PHONE_NUMBER = gql`
    mutation updateUserPhoneNumber($id: uuid!, $mobileNumber: String!) {
        update_user(where: { id: { _eq: $id } }, _set: { phone_number: $mobileNumber }) {
            affected_rows
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private apollo: Apollo) {}

    async getUsers(): Promise<ApolloQueryResult<GetUsersQuery>> {
        return this.apollo
            .query<GetUsersQuery, GetUsersQueryVariables>({
                query: GET_USERS_QUERY,
            })
            .toPromise();
    }

    async getUserById(userId: string): Promise<ApolloQueryResult<GetUserByIdQuery>> {
        return this.apollo
            .query<GetUserByIdQuery, GetUserByIdQueryVariables>({
                query: GET_USER_BY_ID_QUERY,
                variables: {
                    userId,
                },
            })
            .toPromise();
    }

    async updateUserRole(
        userId: string,
        defaultRole: string,
        allowedRole: string
    ): Promise<FetchResult<UpdateUserRoleMutation>> {
        return this.apollo
            .mutate<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>({
                mutation: UPDATE_USER_ROLE,
                variables: {
                    userId,
                    role: defaultRole,
                    allowedRole,
                },
            })
            .toPromise();
    }

    async sendOnboardingEmail(userId: string): Promise<ApolloQueryResult<SendOnboardingEmailQuery>> {
        return this.apollo
            .query<SendOnboardingEmailQuery, SendOnboardingEmailQueryVariables>({
                query: SEND_ONBOARDING_EMAIL_QUERY,
                variables: { userId },
            })
            .toPromise();
    }

    async sendPurchaseEmail(orderId: string): Promise<ApolloQueryResult<SendPurchaseEmailQuery>> {
        return this.apollo
            .query<SendPurchaseEmailQuery, SendPurchaseEmailQueryVariables>({
                query: SEND_PURCHASE_EMAIL_QUERY,
                variables: { orderId },
            })
            .toPromise();
    }

    getStaffUsers(): Observable<SubscriptionResult<GetStaffUsersSubscription>> {
        return this.apollo.subscribe<GetStaffUsersSubscription, GetStaffUsersSubscriptionVariables>({
            query: GET_STAFF_USERS,
            variables: {},
        });
    }

    async updateUserEmailVerification(
        userId: string,
        emailVerified: boolean
    ): Promise<FetchResult<SetUserEmailVerificationMutation>> {
        return this.apollo
            .mutate<SetUserEmailVerificationMutation, SetUserEmailVerificationMutationVariables>({
                mutation: UPDATE_USER_EMAIL_VERIFICATION,
                variables: {
                    userId,
                    email_verified: emailVerified,
                },
            })
            .toPromise();
    }

    async getUserDetailById(userId: string): Promise<ApolloQueryResult<GetUserDetailByIdQuery>> {
        return this.apollo
            .query<GetUserDetailByIdQuery, GetUserDetailByIdQueryVariables>({
                query: GET_USER_DETAIL_BY_ID,
                variables: {
                    userId,
                },
            })
            .toPromise();
    }

    async updateUserPhoneNumber(
        userId: string,
        phoneNumber: string
    ): Promise<FetchResult<UpdateUserPhoneNumberMutation>> {
        return this.apollo
            .mutate<UpdateUserPhoneNumberMutation, UpdateUserPhoneNumberMutationVariables>({
                mutation: UPDATE_USER_PHONE_NUMBER,
                variables: {
                    id: userId,
                    mobileNumber: phoneNumber,
                },
            })
            .toPromise();
    }
}
