"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("@angular/flex-layout/core");
class FuseMatchMediaService {
    /**
     * Constructor
     *
     * @param {MediaObserver} _mediaObserver
     */
    constructor(_mediaObserver) {
        this._mediaObserver = _mediaObserver;
        this.onMediaChange = new rxjs_1.BehaviorSubject('');
        // Set the defaults
        this.activeMediaQuery = '';
        // Initialize
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    _init() {
        this._mediaObserver
            .asObservable()
            .pipe(operators_1.mergeMap((t) => t), operators_1.debounceTime(500), operators_1.distinctUntilChanged())
            .subscribe((change) => {
            if (this.activeMediaQuery !== change.mqAlias) {
                this.activeMediaQuery = change.mqAlias;
                this.onMediaChange.next(change.mqAlias);
            }
        });
    }
}
FuseMatchMediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseMatchMediaService_Factory() { return new FuseMatchMediaService(i0.ɵɵinject(i1.MediaObserver)); }, token: FuseMatchMediaService, providedIn: "root" });
exports.FuseMatchMediaService = FuseMatchMediaService;
