"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".avatar-big-container[_ngcontent-%COMP%]{height:88px}.app-top-nav__avatar-big[_ngcontent-%COMP%]{border-radius:50%;height:88px;width:88px;display:flex;justify-content:center;align-items:center;font-weight:400;font-size:24px;line-height:40px;text-align:center;letter-spacing:.18px;color:#000d52;align-self:center;border:8px solid #91d6b9}.app-top-nav__lock-icon-big[_ngcontent-%COMP%]{background:#000d52;height:24px;width:24px;border-radius:50%;display:flex;justify-content:center;align-items:center;position:relative;left:60px;top:-83px;cursor:pointer}"];
exports.styles = styles;
