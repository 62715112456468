<h1 class="headline-1-bold" matDialogTitle>{{data.title}}</h1>
<div [innerHTML]="data.text" mat-dialog-content></div>
<div mat-dialog-content>
    <form action="" *ngIf="!data.hideInput">
        <mat-form-field>
            <mat-label>Feedback in the Email</mat-label>
            <textarea matInput [formControl]="inputField" formControlName="feedback"></textarea>
            <!-- <mat-error *ngIf="inputField.hasError('required')">Please provide feedback for the user</mat-error> -->
        </mat-form-field>
    </form>
</div>
<div class="input-dialog__buttons" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
    <div class="input-dialog__checkbox" [ngClass]="{'input-dialog__checkbox--visible': data.isCheckboxVisible}">
        <mat-checkbox [(ngModel)]="checkboxValue" (change)="checkboxChange()">{{data.checkboxLabel}}
        </mat-checkbox>
    </div>

    <button *ngIf="!data.hideCancel" (click)="closeDialog(false)" color="accent" mat-stroked-button class="text-wrap">
        {{data?.cancelBtnText || 'Cancel'}}
    </button>
    <button (click)="closeDialog(true, inputField.value)" color="accent" mat-raised-button class="text-wrap">
        {{data?.okBtnText || 'OK'}}
    </button>
</div>