import { TemplateRef } from '@angular/core';
import { Campaign } from 'generated/graphql';

export interface IOrderCreateModel {
    showCampaignStep: boolean;
    showAddonsStep: boolean;
    showCoupon: boolean;
    lastOrderedCampaignId?: string;
    lastOrderedCampaign?: Pick<Campaign, 'id' | 'code'>;
    preFilledData?: OrderPreFilledModel;
    showListingAddress: boolean;
}

export interface WizardStep {
    step: CampaignBuildStep;
    label: string;
    templateRef?: TemplateRef<any>;
    isEditable: boolean;
    isCompleted: boolean;
}

export enum CampaignBuildStep {
    Campaign = 'Campaign',
    AddOns = 'Add-ons',
    Payment = 'Payment',
    Confirmation = 'Confirmation',
}

export interface OrderPreFilledModel {
    addons: { id: string; qty: number }[];
}

export enum CampaignCodes {
    BASIC = 'basic',
    BASIC_PLUS = 'basic_plus',
    PRO = 'pro',
    ULTIMATE = 'ultimate',
    BUILD_YOUR_OWN = 'byo',
    RENTAL = 'rental',
    ONLINE = 'online',
}

export const SALE_CAMPAIGNS = [
    CampaignCodes.BASIC,
    CampaignCodes.BASIC_PLUS,
    CampaignCodes.PRO,
    CampaignCodes.ULTIMATE,
    CampaignCodes.BUILD_YOUR_OWN,
];

export const RENT_CAMPAIGNS = [CampaignCodes.RENTAL, CampaignCodes.ONLINE];
