// tslint:disable-next-line: no-reference
/// <reference path="./../../../node_modules/@types/segment-analytics/index.d.ts" />
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { IHasuraJWT } from './jwt.service';
import { environment } from '../../environments/environment';
import { ListingService } from './listing.service';
import { ListingOnboardingService } from './listing-onboarding.service';
import { GetOrderByIdQuery } from '../../generated/graphql';
import { ExperimentService } from './experiment.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

const SEGMENT_LIB_URL = `/assets/js/segment-analytics.js`;
declare var ga: any;

export interface IUTMParameters {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    submitted_via?: string;
    gclid?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    isEnabled: boolean;
    isLoaded: Promise<void>;

    public get do(): SegmentAnalytics.AnalyticsJS {
        return analytics;
    }

    isLoggedIn$ = new BehaviorSubject<boolean>(false);
    isLoaded$ = new BehaviorSubject<boolean>(false);

    constructor(
        private listingService: ListingService,
        private onboardingService: ListingOnboardingService,
        private experimentService: ExperimentService,
        private authService: AuthService,
        private userService: UserService
    ) {
        this.isEnabled = environment.services.analytics.segment.enabled;
        if (this.isEnabled) {
            this.isLoaded = this.addMapsScript();
        }

        combineLatest([this.isLoaded$, this.isLoggedIn$])
            .pipe(
                filter((obj) => obj[0] && obj[1]),
                take(1)
            )
            .subscribe(async (obj) => {
                // Save client id in user
                const gaClientId = await this.getGaClientId();
                let jwt = null;
                if (sessionStorage.getItem('impersonateToken')) {
                    jwt = sessionStorage.getItem('impersonateToken');
                } else {
                    jwt = localStorage.getItem('token');
                }
                const decoded: IHasuraJWT = jwt_decode(jwt);
                const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
                this.authService.updateGaClientId(userId, gaClientId).subscribe();
            });
    }

    run(func: () => void): void {
        if (!this.isEnabled) {
            return;
        }

        this.isLoaded.then(() => {
            this.isLoaded$.next(true);
            analytics.ready(func);
        });
    }

    /**
     * This will add a new <script> tag for Segment Analytics if it's not already present
     * Returns promise which will be resolved when all JS files of Segment Analytics are loaded
     */
    async addMapsScript(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!document.querySelectorAll(`[src="${SEGMENT_LIB_URL}"]`).length) {
                document.body.appendChild(
                    Object.assign(document.createElement('script'), {
                        type: 'text/javascript',
                        src: SEGMENT_LIB_URL,
                        onload: () => {
                            this.initAnalytics(resolve);
                        },
                    })
                );
            } else {
                resolve();
            }
        });
    }

    public async getGaClientId(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                if (ga) {
                    ga((tracker: any) => {
                        resolve(tracker.get('clientId'));
                    });
                } else {
                    resolve(null);
                }
            } catch (error) {
                resolve(null);
            }
        });
    }

    public async identifyUser(jwt: string, order?: GetOrderByIdQuery['order'][0]): Promise<void> {
        if (!jwt) {
            return;
        }
        let extraTraitData: { [key: string]: any } = {};

        // Get extra traits data
        if (order) {
            // listing
            const resp = await this.listingService.getListingById(order.listing_id);
            const listing = resp.data.listing[0];
            // onboarding
            const onboarding = await this.onboardingService.getOnboardingDataByListingId(order.listing_id);
            // this.listing.getListingUserWithOnBoardId()
            extraTraitData = {
                // listing
                property_type: listing.propertyDetails_propertyType,
                property_price: listing.price_asking,
                listing_type: listing.saleType,
                state: listing.propertyDetails_address_state,
                suburb: listing.propertyDetails_address_suburb,
                postcode: listing.propertyDetails_address_postcode,
            };
            // onboarding
            if (onboarding.finished) {
                extraTraitData = {
                    ...extraTraitData,
                    rating_inspection: onboarding.rating_inspection,
                    rating_technology: onboarding.rating_technology,
                    rating_negotiation: onboarding.rating_negotiation,
                    rating_valuing: onboarding.rating_valuing,
                    rating_overall: onboarding.rating_overall,
                };
            }
            if (onboarding.reason_selling) {
                extraTraitData.reason_selling = onboarding.reason_selling;
            }
            if (onboarding.timeframe_selling) {
                extraTraitData.timeframe_selling = onboarding.timeframe_selling;
            }
            if (onboarding.budget_size) {
                extraTraitData.budget_size = onboarding.budget_size;
            }
            // campaign
            for (const orderItem of order.order_items) {
                if (orderItem.campaign) {
                    extraTraitData.campaign = orderItem.campaign.name;
                    break;
                }
            }
        }

        try {
            const decoded: IHasuraJWT = jwt_decode(jwt);
            const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
            if (userId) {
                // get user's experiments
                const userExperiments = await this.experimentService.getCurrentUserExperiments();

                // fill default after authentication
                const gclid = decoded['https://buymyplace.com.au/jwt/claims']['gclid'];

                const userInfo = decoded['https://buymyplace.com.au/jwt/claims'];
                const userDetails = (await this.userService.getUserById(userId)).data.user[0];
                if (userInfo.impersonatedBy) {
                    this.do.identify(
                        userId,
                        {
                            email: userInfo.email,
                            displayName: `[${userInfo.firstName} ${userInfo.lastName}] by ${userInfo.impersonatedBy.email}`,
                            role: decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'],
                            impersonated: true,
                            impersonatedBy: userInfo.impersonatedBy,
                            userExperiments,
                            gclid,
                            current_submitted_via: userDetails.submitted_via,
                            initial_submitted_via: userDetails.initial_submitted_via,
                            ...extraTraitData,
                        },
                        {
                            integrations: {
                                Salesforce: false,
                            },
                        }
                    );
                } else {
                    this.do.identify(
                        userId,
                        {
                            email: userInfo.email,
                            displayName: `${userInfo.firstName} ${userInfo.lastName}`,
                            role: decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'],
                            userExperiments,
                            gclid,
                            current_submitted_via: userDetails.submitted_via,
                            initial_submitted_via: userDetails.initial_submitted_via,
                            ...extraTraitData,
                        },
                        {
                            integrations: {
                                Salesforce: false,
                            },
                        }
                    );
                }
            }
        } catch (error) {
            console.error(`Unexpected error happened during user identification to the analytics service`, error);
        }
    }

    trackEvent(name: string, eventData): void {
        this.run(() => {
            let userId = '';
            if (localStorage.getItem('token')) {
                const decoded: IHasuraJWT = jwt_decode(localStorage.getItem('token'));
                userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
            }
            eventData.currentUserId = userId;
            eventData.userId = userId;

            this.do.track(name, eventData);
        });
    }

    /**
     * This will trigger loading of another JS file
     * and we have added custom skychute integration in src/assets/js/segment-analytics.js file
     * which should be update in case segment update their integration code snippet
     * @param resolve
     */
    private initAnalytics(resolve: () => void): void {
        analytics.load(environment.services.analytics.segment.writeKey, {
            integrations: {
                skychute: {
                    onload: () => {
                        resolve();
                    },
                    writeKey: environment.services.analytics.segment.writeKey,
                },
            },
        });
        analytics.page();
    }

    setUTMParameters(utmParameters: IUTMParameters): void {
        if (
            utmParameters.utm_source ||
            utmParameters.utm_medium ||
            utmParameters.utm_campaign ||
            utmParameters.utm_term ||
            utmParameters.utm_content ||
            utmParameters.gclid
        ) {
            localStorage.setItem('utm_source', utmParameters.utm_source);
            localStorage.setItem('utm_medium', utmParameters.utm_medium);
            localStorage.setItem('utm_campaign', utmParameters.utm_campaign);
            localStorage.setItem('utm_term', utmParameters.utm_term);
            localStorage.setItem('utm_content', utmParameters.utm_content);
            localStorage.setItem('gclid', utmParameters.gclid);
        }
        if (utmParameters.submitted_via) {
            localStorage.setItem('submitted_via', utmParameters.submitted_via);
        }
    }

    getUTMParameters(): IUTMParameters {
        const utmParams: IUTMParameters = {
            utm_source: localStorage.getItem('utm_source'),
            utm_medium: localStorage.getItem('utm_medium'),
            utm_campaign: localStorage.getItem('utm_campaign'),
            utm_term: localStorage.getItem('utm_term'),
            utm_content: localStorage.getItem('utm_content'),
            submitted_via: localStorage.getItem('submitted_via'),
            gclid: localStorage.getItem('gclid'),
        };

        if (Object.values(utmParams).every((o) => o === null)) {
            return null;
        }
        return utmParams;
    }

    deleteUTMParameters(): void {
        localStorage.removeItem('utm_source');
        localStorage.removeItem('utm_medium');
        localStorage.removeItem('utm_campaign');
        localStorage.removeItem('utm_term');
        localStorage.removeItem('utm_content');
        localStorage.removeItem('submitted_via');
        localStorage.removeItem('gclid');
    }
}
