"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
class BaseComponent {
    constructor() {
        this.destroyed$ = new rxjs_1.ReplaySubject(1);
    }
    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
exports.BaseComponent = BaseComponent;
