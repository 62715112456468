import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BasicDialogComponent } from 'app/shared/basic-dialog/basic-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'app/shared/base/base.component';
import { JwtService } from 'app/service/jwt.service';
import { UserInfo, Theme } from '../top-nav/top-nav.component';
import { SubscriptionClient } from 'subscriptions-transport-ws';

@Component({
    selector: 'app-logout-overlay',
    templateUrl: './logout-overlay.component.html',
    styleUrls: ['./logout-overlay.component.scss'],
})
export class LogoutOverlayComponent extends BaseComponent implements OnInit {
    public userInfo: UserInfo;
    public theme: Theme;
    public isAdmin: boolean;
    constructor(
        public dialogRef: MatDialogRef<LogoutOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private router: Router,
        private dialog: MatDialog,
        private _jwt: JwtService,
        private wsClient: SubscriptionClient
    ) {
        super();
    }

    ngOnInit(): void {
        this.userInfo = this.data.userInfo;
        this.theme = this.data.theme;
        this.isAdmin = this.data.isAdmin;
    }

    logout(): Promise<boolean> {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(BasicDialogComponent, {
                width: '480px',
                data: {
                    okBtnText: 'Confirm',
                    title: 'Important!',
                    text: 'Are you sure you want to logout?',
                },
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.destroyed$))
                .subscribe(async (result) => {
                    if (result) {
                        this.dialogRef.close();
                        const isAdminImpersonatingUser = this._jwt.isAdminImpersonatingUser();
                        this._jwt.deleteSession(true);
                        this.wsClient.close();
                        if (!isAdminImpersonatingUser) {
                            this.router.navigate(['/auth/login']);
                        } else {
                            this.router.navigate(['/admin/dashboard']);
                        }
                    }
                });
        });
    }
}
