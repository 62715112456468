"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class FuseProgressBarComponent {
    /**
     * Constructor
     *
     * @param {FuseProgressBarService} _fuseProgressBarService
     */
    constructor(_fuseProgressBarService) {
        // Set the defaults
        this._fuseProgressBarService = _fuseProgressBarService;
        // Set the private defaults
        this._unsubscribeAll = new rxjs_1.Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Subscribe to the progress bar service properties
        // Buffer value
        this._fuseProgressBarService.bufferValue.pipe(operators_1.takeUntil(this._unsubscribeAll)).subscribe((bufferValue) => {
            this.bufferValue = bufferValue;
        });
        // Mode
        this._fuseProgressBarService.mode.pipe(operators_1.takeUntil(this._unsubscribeAll)).subscribe((mode) => {
            this.mode = mode;
        });
        // Value
        this._fuseProgressBarService.value.pipe(operators_1.takeUntil(this._unsubscribeAll)).subscribe((value) => {
            this.value = value;
        });
        // Visible
        this._fuseProgressBarService.visible.pipe(operators_1.takeUntil(this._unsubscribeAll)).subscribe((visible) => {
            this.visible = visible;
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
exports.FuseProgressBarComponent = FuseProgressBarComponent;
