"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const environment_1 = require("../../environments/environment");
const i0 = require("@angular/core");
const i1 = require("./dialog.service");
const i2 = require("./jwt.service");
const i3 = require("./analytics.service");
const i4 = require("./experiment.service");
/**
 * The primary role of this service is to show some build info like CommitID, Git branch, build number etc.
 * So we can understand what kind of build we have right now.
 * The idea is that user should click some element on the page a few times, and then a dialog with
 * build info will be shown.
 */
class BuildInfoService {
    constructor(_dialogService, _jwtService, analytics, experimentService) {
        this._dialogService = _dialogService;
        this._jwtService = _jwtService;
        this.analytics = analytics;
        this.experimentService = experimentService;
        // click logo 5 times to see hidden build info
        this.logoClickCountBeforeBuildInfo = 5;
        this.logoClickCount = 0;
        this.version = environment_1.environment.application.version;
        this.branch = environment_1.environment.application.branch;
        this.buildDate = environment_1.environment.application.buildDate;
        this.buildNumber = environment_1.environment.application.buildNumber;
        this.gitHead = environment_1.environment.application.gitHead;
    }
    /**
     * This is the method which counts clicks and shows a dialog when certain amount of clicks has been reached
     */
    click() {
        this.logoClickCount += 1;
        if (this.logoClickCount >= this.logoClickCountBeforeBuildInfo) {
            this.showSessionInfo();
            this.logoClickCount = 0;
        }
    }
    showSessionInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.segmentUid = analytics.user().id();
            }
            catch (_a) {
                this.segmentUid = null;
            }
            try {
                // @ts-ignore
                this.fullStorySession = FS.getCurrentSession();
            }
            catch (_b) {
                this.fullStorySession = null;
            }
            try {
                this.userId = this._jwtService.getUserId();
            }
            catch (_c) {
                this.userId = 'not available';
            }
            try {
                this.jwtToken = this._jwtService.getSession();
            }
            catch (_d) {
                this.jwtToken = 'not available';
            }
            // ANALYTICS START
            this.analytics.trackEvent('Build info dialogue viewed', {
                fullstorySessionId: this.fullStorySession,
            });
            // ANALYTICS END
            let experiments = null;
            let uniqueNumber = null;
            try {
                experiments = yield this.experimentService.getCurrentUserExperiments();
                uniqueNumber = yield this.experimentService.getCurrentUserUniqueNumber();
            }
            catch (e) {
                console.log(e);
            }
            const dateTime = new Date().toString();
            const timestamp = Date.now();
            const text = `<strong>Version:</strong> ${this.version}<br>
<strong>Branch:</strong> ${this.branch}<br>
<strong>Build date:</strong> ${this.buildDate}<br>
<strong>Build number:</strong> ${this.buildNumber}<br>
<strong>Git Head:</strong> ${this.gitHead}<br>
<strong>Segment uid:</strong> ${this.segmentUid}<br>
<strong>FullStory session:</strong> ${this.fullStorySession}<br>
<strong>User ID:</strong> ${this.userId}<br>
<strong>Date & Time: </strong>${dateTime}<br>
<strong>Timestamp: </strong>${timestamp}<br>
<strong>JWT Token:</strong> ${this.jwtToken}<br>
<strong>Unique number: ${uniqueNumber}</strong><br>
<strong>Experiments:</strong> ${JSON.stringify(experiments, null, 4)}
`;
            this._dialogService.openBuildInfoDialog(text);
        });
    }
}
BuildInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BuildInfoService_Factory() { return new BuildInfoService(i0.ɵɵinject(i1.DialogService), i0.ɵɵinject(i2.JwtService), i0.ɵɵinject(i3.AnalyticsService), i0.ɵɵinject(i4.ExperimentService)); }, token: BuildInfoService, providedIn: "root" });
exports.BuildInfoService = BuildInfoService;
