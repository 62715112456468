import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtService } from './jwt.service';

@Injectable({
    providedIn: 'root',
})
export class RouterDefaultGuardService {
    constructor(private jwt: JwtService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.jwt.isAuthenticated()) {
            if (this.jwt.isAdmin()) {
                this.router.navigate(['/admin/dashboard/users']);
                if (this.jwt.isAdminImpersonatingUser()) {
                    this.router.navigate([`/pages/listings-management`]);
                }
            } else {
                this.router.navigate(['/pages/listings-management']);
            }
        } else {
            this.router.navigate([`/auth/login`]);
        }
        return false;
    }
}
