"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
class NavbarComponent {
    /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     * @param {Renderer2} _renderer
     */
    constructor(_elementRef, _renderer) {
        this._elementRef = _elementRef;
        this._renderer = _renderer;
        // Set the private defaults
        this._variant = 'vertical-style-1';
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Variant
     */
    get variant() {
        return this._variant;
    }
    set variant(value) {
        // Remove the old class name
        this._renderer.removeClass(this._elementRef.nativeElement, this.variant);
        // Store the variant value
        this._variant = value;
        // Add the new class name
        this._renderer.addClass(this._elementRef.nativeElement, value);
    }
}
exports.NavbarComponent = NavbarComponent;
