"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FusePerfectScrollbarGeometry {
    constructor(x, y, w, h) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }
}
exports.FusePerfectScrollbarGeometry = FusePerfectScrollbarGeometry;
class FusePerfectScrollbarPosition {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}
exports.FusePerfectScrollbarPosition = FusePerfectScrollbarPosition;
