import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileCircleComponent } from '../profile-circle.component';

@NgModule({
    declarations: [ProfileCircleComponent],
    imports: [CommonModule],
    exports: [ProfileCircleComponent],
})
export class ProfileCircleModule {}
