"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GO = '[Router] Go';
exports.BACK = '[Router] Back';
exports.FORWARD = '[Router] Forward';
class Go {
    /**
     * Constructor
     *
     * @param {{path: any[]; query?: object; extras?: NavigationExtras}} payload
     */
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GO;
    }
}
exports.Go = Go;
class Back {
    constructor() {
        this.type = exports.BACK;
    }
}
exports.Back = Back;
class Forward {
    constructor() {
        this.type = exports.FORWARD;
    }
}
exports.Forward = Forward;
