<div class="user-info-container">
    <div class="user-info-overlay">
        <div class="padding-top-place-holder">
            <div class="impersonating-title">
                <div *ngIf="isAdmin">
                    <img width="8" height="16" class="lock-icon" src="../../../../assets/icons/lock_black.svg" />
                    <span style="padding-left:4px">Impersonating</span>
                </div>
            </div>
        </div>
        <span class="user-name">{{userInfo.firstName}} {{userInfo.lastName}}</span>
        <span class="user-email">{{userInfo.email}}</span>
        <profile-circle [theme]="theme" [userInfo]="userInfo" [isAdmin]="isAdmin"></profile-circle>
        <div mat-dialog-actions>
            <button mat-raised-button color="primary" class="logout-btn" (click)="logout()">Logout</button>
        </div>
    </div>
</div>