"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dialog_1 = require("@angular/material/dialog");
class BuildInfoDialogComponent {
    constructor(dialogRef, domSanitizer, data) {
        this.dialogRef = dialogRef;
        this.domSanitizer = domSanitizer;
        this.data = data;
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
    }
    closeDialog(result) {
        this.dialogRef.close(result);
    }
    copyText(text) {
        // solution from: https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
exports.BuildInfoDialogComponent = BuildInfoDialogComponent;
