"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const router_1 = require("@angular/router");
const utils_1 = require("@fuse/utils");
const jwt_decode = require("jwt-decode");
const i0 = require("@angular/core");
const i1 = require("./jwt.service");
const i2 = require("@angular/router");
class UserPagesGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate() {
        if (sessionStorage.getItem('impersonateToken')) {
            const impersonateToken = jwt_decode(sessionStorage.getItem('impersonateToken'));
            if (impersonateToken) {
                return true;
            }
        }
        if (this.authService.isAdmin()) {
            this.router.navigate([utils_1.FuseUtils._DEFAULT_ADMIN_PAGE_PATH]);
            return false;
        }
        return true;
    }
}
UserPagesGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserPagesGuardService_Factory() { return new UserPagesGuardService(i0.ɵɵinject(i1.JwtService), i0.ɵɵinject(i2.Router)); }, token: UserPagesGuardService, providedIn: "root" });
exports.UserPagesGuardService = UserPagesGuardService;
