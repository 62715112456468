"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const _ = require("lodash");
const navigation_1 = require("app/navigation/navigation");
const basic_dialog_component_1 = require("app/shared/basic-dialog/basic-dialog.component");
const base_component_1 = require("app/shared/base/base.component");
class ToolbarComponent extends base_component_1.BaseComponent {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(_fuseConfigService, _fuseSidebarService, _translateService, _jwt, _userService, dialog, router) {
        super();
        this._fuseConfigService = _fuseConfigService;
        this._fuseSidebarService = _fuseSidebarService;
        this._translateService = _translateService;
        this._jwt = _jwt;
        this._userService = _userService;
        this.dialog = dialog;
        this.router = router;
        this.userData = [];
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50',
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107',
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336',
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD',
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161',
            },
        ];
        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us',
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr',
            },
        ];
        const data = {
            first_name: '',
            last_name: '',
            id: '',
            email: '',
            email_verified: false,
        };
        this.userData.push(data);
        this.navigation = navigation_1.navigation;
        // Set the private defaults
        this._unsubscribeAll = new rxjs_1.Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // get User info
        if (localStorage.getItem('token')) {
            this.userId = this._jwt.getUserId();
            this.getUserData();
        }
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(operators_1.takeUntil(this._unsubscribeAll)).subscribe((settings) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }
    getUserData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userData = (yield this._userService.getUserById(this.userId)).data.user;
        });
    }
    logout() {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(basic_dialog_component_1.BasicDialogComponent, {
                width: '480px',
                data: {
                    okBtnText: 'Confirm',
                    title: 'Important!',
                    text: 'Are you sure you want to logout?',
                },
            });
            dialogRef
                .afterClosed()
                .pipe(operators_1.takeUntil(this.destroyed$))
                .subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (result) {
                    this._jwt.deleteSession();
                    document.location.href = `${document.location.origin}/auth/login`;
                }
            }));
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    /**
     * Search
     *
     * @param value
     */
    search(value) {
        // Do your search here...
        console.log(value);
    }
    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang) {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
exports.ToolbarComponent = ToolbarComponent;
