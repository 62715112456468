"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jwt_decode = require("jwt-decode");
const environment_1 = require("../../environments/environment");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("./listing.service");
const i2 = require("./listing-onboarding.service");
const i3 = require("./experiment.service");
const i4 = require("./auth.service");
const i5 = require("./user.service");
const SEGMENT_LIB_URL = `/assets/js/segment-analytics.js`;
class AnalyticsService {
    constructor(listingService, onboardingService, experimentService, authService, userService) {
        this.listingService = listingService;
        this.onboardingService = onboardingService;
        this.experimentService = experimentService;
        this.authService = authService;
        this.userService = userService;
        this.isLoggedIn$ = new rxjs_1.BehaviorSubject(false);
        this.isLoaded$ = new rxjs_1.BehaviorSubject(false);
        this.isEnabled = environment_1.environment.services.analytics.segment.enabled;
        if (this.isEnabled) {
            this.isLoaded = this.addMapsScript();
        }
        rxjs_1.combineLatest([this.isLoaded$, this.isLoggedIn$])
            .pipe(operators_1.filter((obj) => obj[0] && obj[1]), operators_1.take(1))
            .subscribe((obj) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Save client id in user
            const gaClientId = yield this.getGaClientId();
            let jwt = null;
            if (sessionStorage.getItem('impersonateToken')) {
                jwt = sessionStorage.getItem('impersonateToken');
            }
            else {
                jwt = localStorage.getItem('token');
            }
            const decoded = jwt_decode(jwt);
            const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
            this.authService.updateGaClientId(userId, gaClientId).subscribe();
        }));
    }
    get do() {
        return analytics;
    }
    run(func) {
        if (!this.isEnabled) {
            return;
        }
        this.isLoaded.then(() => {
            this.isLoaded$.next(true);
            analytics.ready(func);
        });
    }
    /**
     * This will add a new <script> tag for Segment Analytics if it's not already present
     * Returns promise which will be resolved when all JS files of Segment Analytics are loaded
     */
    addMapsScript() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                if (!document.querySelectorAll(`[src="${SEGMENT_LIB_URL}"]`).length) {
                    document.body.appendChild(Object.assign(document.createElement('script'), {
                        type: 'text/javascript',
                        src: SEGMENT_LIB_URL,
                        onload: () => {
                            this.initAnalytics(resolve);
                        },
                    }));
                }
                else {
                    resolve();
                }
            });
        });
    }
    getGaClientId() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    if (ga) {
                        ga((tracker) => {
                            resolve(tracker.get('clientId'));
                        });
                    }
                    else {
                        resolve(null);
                    }
                }
                catch (error) {
                    resolve(null);
                }
            });
        });
    }
    identifyUser(jwt, order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!jwt) {
                return;
            }
            let extraTraitData = {};
            // Get extra traits data
            if (order) {
                // listing
                const resp = yield this.listingService.getListingById(order.listing_id);
                const listing = resp.data.listing[0];
                // onboarding
                const onboarding = yield this.onboardingService.getOnboardingDataByListingId(order.listing_id);
                // this.listing.getListingUserWithOnBoardId()
                extraTraitData = {
                    // listing
                    property_type: listing.propertyDetails_propertyType,
                    property_price: listing.price_asking,
                    listing_type: listing.saleType,
                    state: listing.propertyDetails_address_state,
                    suburb: listing.propertyDetails_address_suburb,
                    postcode: listing.propertyDetails_address_postcode,
                };
                // onboarding
                if (onboarding.finished) {
                    extraTraitData = Object.assign({}, extraTraitData, { rating_inspection: onboarding.rating_inspection, rating_technology: onboarding.rating_technology, rating_negotiation: onboarding.rating_negotiation, rating_valuing: onboarding.rating_valuing, rating_overall: onboarding.rating_overall });
                }
                if (onboarding.reason_selling) {
                    extraTraitData.reason_selling = onboarding.reason_selling;
                }
                if (onboarding.timeframe_selling) {
                    extraTraitData.timeframe_selling = onboarding.timeframe_selling;
                }
                if (onboarding.budget_size) {
                    extraTraitData.budget_size = onboarding.budget_size;
                }
                // campaign
                for (const orderItem of order.order_items) {
                    if (orderItem.campaign) {
                        extraTraitData.campaign = orderItem.campaign.name;
                        break;
                    }
                }
            }
            try {
                const decoded = jwt_decode(jwt);
                const userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
                if (userId) {
                    // get user's experiments
                    const userExperiments = yield this.experimentService.getCurrentUserExperiments();
                    // fill default after authentication
                    const gclid = decoded['https://buymyplace.com.au/jwt/claims']['gclid'];
                    const userInfo = decoded['https://buymyplace.com.au/jwt/claims'];
                    const userDetails = (yield this.userService.getUserById(userId)).data.user[0];
                    if (userInfo.impersonatedBy) {
                        this.do.identify(userId, Object.assign({ email: userInfo.email, displayName: `[${userInfo.firstName} ${userInfo.lastName}] by ${userInfo.impersonatedBy.email}`, role: decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'], impersonated: true, impersonatedBy: userInfo.impersonatedBy, userExperiments,
                            gclid, current_submitted_via: userDetails.submitted_via, initial_submitted_via: userDetails.initial_submitted_via }, extraTraitData), {
                            integrations: {
                                Salesforce: false,
                            },
                        });
                    }
                    else {
                        this.do.identify(userId, Object.assign({ email: userInfo.email, displayName: `${userInfo.firstName} ${userInfo.lastName}`, role: decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'], userExperiments,
                            gclid, current_submitted_via: userDetails.submitted_via, initial_submitted_via: userDetails.initial_submitted_via }, extraTraitData), {
                            integrations: {
                                Salesforce: false,
                            },
                        });
                    }
                }
            }
            catch (error) {
                console.error(`Unexpected error happened during user identification to the analytics service`, error);
            }
        });
    }
    trackEvent(name, eventData) {
        this.run(() => {
            let userId = '';
            if (localStorage.getItem('token')) {
                const decoded = jwt_decode(localStorage.getItem('token'));
                userId = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
            }
            eventData.currentUserId = userId;
            eventData.userId = userId;
            this.do.track(name, eventData);
        });
    }
    /**
     * This will trigger loading of another JS file
     * and we have added custom skychute integration in src/assets/js/segment-analytics.js file
     * which should be update in case segment update their integration code snippet
     * @param resolve
     */
    initAnalytics(resolve) {
        analytics.load(environment_1.environment.services.analytics.segment.writeKey, {
            integrations: {
                skychute: {
                    onload: () => {
                        resolve();
                    },
                    writeKey: environment_1.environment.services.analytics.segment.writeKey,
                },
            },
        });
        analytics.page();
    }
    setUTMParameters(utmParameters) {
        if (utmParameters.utm_source ||
            utmParameters.utm_medium ||
            utmParameters.utm_campaign ||
            utmParameters.utm_term ||
            utmParameters.utm_content ||
            utmParameters.gclid) {
            localStorage.setItem('utm_source', utmParameters.utm_source);
            localStorage.setItem('utm_medium', utmParameters.utm_medium);
            localStorage.setItem('utm_campaign', utmParameters.utm_campaign);
            localStorage.setItem('utm_term', utmParameters.utm_term);
            localStorage.setItem('utm_content', utmParameters.utm_content);
            localStorage.setItem('gclid', utmParameters.gclid);
        }
        if (utmParameters.submitted_via) {
            localStorage.setItem('submitted_via', utmParameters.submitted_via);
        }
    }
    getUTMParameters() {
        const utmParams = {
            utm_source: localStorage.getItem('utm_source'),
            utm_medium: localStorage.getItem('utm_medium'),
            utm_campaign: localStorage.getItem('utm_campaign'),
            utm_term: localStorage.getItem('utm_term'),
            utm_content: localStorage.getItem('utm_content'),
            submitted_via: localStorage.getItem('submitted_via'),
            gclid: localStorage.getItem('gclid'),
        };
        if (Object.values(utmParams).every((o) => o === null)) {
            return null;
        }
        return utmParams;
    }
    deleteUTMParameters() {
        localStorage.removeItem('utm_source');
        localStorage.removeItem('utm_medium');
        localStorage.removeItem('utm_campaign');
        localStorage.removeItem('utm_term');
        localStorage.removeItem('utm_content');
        localStorage.removeItem('submitted_via');
        localStorage.removeItem('gclid');
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.ListingService), i0.ɵɵinject(i2.ListingOnboardingService), i0.ɵɵinject(i3.ExperimentService), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.UserService)); }, token: AnalyticsService, providedIn: "root" });
exports.AnalyticsService = AnalyticsService;
