"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class LoaderComponent {
    constructor(domSanitizer, data) {
        this.domSanitizer = domSanitizer;
        this.data = data;
        this.title = '';
        this.content = '';
        this.title = data.title;
        if (typeof data.content === 'string') {
            this.content = this.domSanitizer.bypassSecurityTrustHtml(data.content);
        }
    }
}
exports.LoaderComponent = LoaderComponent;
