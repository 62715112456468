import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DialogService } from './dialog.service';
import { JwtService } from './jwt.service';
import { AnalyticsService } from './analytics.service';
import { ExperimentService } from './experiment.service';

/**
 * The primary role of this service is to show some build info like CommitID, Git branch, build number etc.
 * So we can understand what kind of build we have right now.
 * The idea is that user should click some element on the page a few times, and then a dialog with
 * build info will be shown.
 */

@Injectable({
    providedIn: 'root',
})
export class BuildInfoService {
    // click logo 5 times to see hidden build info
    logoClickCountBeforeBuildInfo = 5;
    logoClickCount = 0;

    // build info
    version: string; // package.json version
    branch: string; // BRANCH_NAME env variable, `local` for local development
    buildDate: string; // date & time of last deployment
    buildNumber: string;
    gitHead: string;
    segmentUid: string; // User id from Segment analytics
    fullStorySession: string;
    userId: string;
    jwtToken: string;

    constructor(
        private _dialogService: DialogService,
        private _jwtService: JwtService,
        private analytics: AnalyticsService,
        private experimentService: ExperimentService
    ) {
        this.version = environment.application.version;
        this.branch = environment.application.branch;
        this.buildDate = environment.application.buildDate;
        this.buildNumber = environment.application.buildNumber;
        this.gitHead = environment.application.gitHead;
    }

    /**
     * This is the method which counts clicks and shows a dialog when certain amount of clicks has been reached
     */
    click(): void {
        this.logoClickCount += 1;
        if (this.logoClickCount >= this.logoClickCountBeforeBuildInfo) {
            this.showSessionInfo();
            this.logoClickCount = 0;
        }
    }

    private async showSessionInfo(): Promise<void> {
        try {
            this.segmentUid = analytics.user().id();
        } catch {
            this.segmentUid = null;
        }

        try {
            // @ts-ignore
            this.fullStorySession = FS.getCurrentSession();
        } catch {
            this.fullStorySession = null;
        }

        try {
            this.userId = this._jwtService.getUserId();
        } catch {
            this.userId = 'not available';
        }

        try {
            this.jwtToken = this._jwtService.getSession();
        } catch {
            this.jwtToken = 'not available';
        }

        // ANALYTICS START
        this.analytics.trackEvent('Build info dialogue viewed', {
            fullstorySessionId: this.fullStorySession,
        });
        // ANALYTICS END

        let experiments = null;
        let uniqueNumber = null;
        try {
            experiments = await this.experimentService.getCurrentUserExperiments();
            uniqueNumber = await this.experimentService.getCurrentUserUniqueNumber();
        } catch (e) {
            console.log(e);
        }

        const dateTime = new Date().toString();
        const timestamp = Date.now();

        const text = `<strong>Version:</strong> ${this.version}<br>
<strong>Branch:</strong> ${this.branch}<br>
<strong>Build date:</strong> ${this.buildDate}<br>
<strong>Build number:</strong> ${this.buildNumber}<br>
<strong>Git Head:</strong> ${this.gitHead}<br>
<strong>Segment uid:</strong> ${this.segmentUid}<br>
<strong>FullStory session:</strong> ${this.fullStorySession}<br>
<strong>User ID:</strong> ${this.userId}<br>
<strong>Date & Time: </strong>${dateTime}<br>
<strong>Timestamp: </strong>${timestamp}<br>
<strong>JWT Token:</strong> ${this.jwtToken}<br>
<strong>Unique number: ${uniqueNumber}</strong><br>
<strong>Experiments:</strong> ${JSON.stringify(experiments, null, 4)}
`;
        this._dialogService.openBuildInfoDialog(text);
    }
}
