"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./navigation.component.scss.ngstyle");
const i1 = require("@angular/core");
const i2 = require("./vertical/group/group.component.ngfactory");
const i3 = require("./vertical/group/group.component");
const i4 = require("./navigation.service");
const i5 = require("./vertical/collapsable/collapsable.component.ngfactory");
const i6 = require("./vertical/collapsable/collapsable.component");
const i7 = require("@angular/router");
const i8 = require("./vertical/item/item.component.ngfactory");
const i9 = require("./vertical/item/item.component");
const i10 = require("@angular/common");
const i11 = require("./horizontal/collapsable/collapsable.component.ngfactory");
const i12 = require("./horizontal/collapsable/collapsable.component");
const i13 = require("../../services/config.service");
const i14 = require("./horizontal/item/item.component.ngfactory");
const i15 = require("./horizontal/item/item.component");
const i16 = require("./navigation.component");
var styles_FuseNavigationComponent = [i0.styles];
var RenderType_FuseNavigationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FuseNavigationComponent, data: {} });
exports.RenderType_FuseNavigationComponent = RenderType_FuseNavigationComponent;
function View_FuseNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, i2.View_FuseNavVerticalGroupComponent_0, i2.RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 245760, null, 0, i3.FuseNavVerticalGroupComponent, [i1.ChangeDetectorRef, i4.FuseNavigationService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-collapsable", [], [[8, "className", 0], [2, "open", null]], null, null, i5.View_FuseNavVerticalCollapsableComponent_0, i5.RenderType_FuseNavVerticalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i6.FuseNavVerticalCollapsableComponent, [i1.ChangeDetectorRef, i4.FuseNavigationService, i7.Router], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavigationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-item", [], [[8, "className", 0]], null, null, i8.View_FuseNavVerticalItemComponent_0, i8.RenderType_FuseNavVerticalItemComponent)), i1.ɵdid(1, 245760, null, 0, i9.FuseNavVerticalItemComponent, [i1.ChangeDetectorRef, i4.FuseNavigationService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_3)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_4)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_5)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapsable"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_2)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigation; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FuseNavigationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-collapsable", [], [[8, "className", 0]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).open() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_FuseNavHorizontalCollapsableComponent_0, i11.RenderType_FuseNavHorizontalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i12.FuseNavHorizontalCollapsableComponent, [i13.FuseConfigService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-collapsable", [], [[8, "className", 0]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).open() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_FuseNavHorizontalCollapsableComponent_0, i11.RenderType_FuseNavHorizontalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i12.FuseNavHorizontalCollapsableComponent, [i13.FuseConfigService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-horizontal-item", [], [[8, "className", 0]], null, null, i14.View_FuseNavHorizontalItemComponent_0, i14.RenderType_FuseNavHorizontalItemComponent)), i1.ɵdid(1, 49152, null, 0, i15.FuseNavHorizontalItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavigationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_8)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_9)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_10)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapsable"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavigationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_7)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigation; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FuseNavigationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "nav"]], null, null, null, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "horizontal": 0, "vertical": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavigationComponent_6)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nav"; var currVal_1 = _ck(_v, 3, 0, (_co.layout === "horizontal"), (_co.layout === "vertical")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.layout === "vertical"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.layout === "horizontal"); _ck(_v, 7, 0, currVal_3); }, null); }
exports.View_FuseNavigationComponent_0 = View_FuseNavigationComponent_0;
function View_FuseNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-navigation", [], null, null, null, View_FuseNavigationComponent_0, RenderType_FuseNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i16.FuseNavigationComponent, [i1.ChangeDetectorRef, i4.FuseNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FuseNavigationComponent_Host_0 = View_FuseNavigationComponent_Host_0;
var FuseNavigationComponentNgFactory = i1.ɵccf("fuse-navigation", i16.FuseNavigationComponent, View_FuseNavigationComponent_Host_0, { layout: "layout", navigation: "navigation" }, {}, []);
exports.FuseNavigationComponentNgFactory = FuseNavigationComponentNgFactory;
