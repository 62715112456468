"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const router_1 = require("@angular/router");
require("hammerjs");
const apollo_angular_1 = require("apollo-angular");
const apollo_cache_inmemory_1 = require("apollo-cache-inmemory");
const apollo_link_ws_1 = require("apollo-link-ws");
const environment_1 = require("../environments/environment");
const apollo_link_error_1 = require("apollo-link-error");
const apollo_link_1 = require("apollo-link");
const jwt_decode = require("jwt-decode");
const admin_guard_service_1 = require("./service/admin-guard.service");
const authentication_guard_1 = require("./service/authentication-guard");
const user_pages_guard_service_1 = require("./service/user-pages-guard.service");
const user_model_1 = require("./shared/models/user.model");
const subscriptions_transport_ws_1 = require("subscriptions-transport-ws");
const Sentry = require("@sentry/angular");
const router_default_guard_service_1 = require("./service/router-default-guard.service");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const appRoutes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: './main/authentication/authentication.module#AuthenticationModule',
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule',
        canActivate: [authentication_guard_1.AuthenticationGuard, user_pages_guard_service_1.UserPagesGuardService],
    },
    {
        path: 'admin',
        loadChildren: './main/admin/admin.module#AdminModule',
        canActivate: [admin_guard_service_1.AdminGuardService],
    },
    {
        path: '**',
        canActivate: [router_default_guard_service_1.RouterDefaultGuardService],
        redirectTo: '',
    },
];
const appProviders = [
    {
        provide: subscriptions_transport_ws_1.SubscriptionClient,
        useFactory: createSubscriptionClient,
        deps: [],
    },
];
if (environment_1.environment.application.branch === 'bmp-production') {
    appProviders.push({
        provide: core_1.ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
        }),
    });
    appProviders.push({
        provide: Sentry.TraceService,
        deps: [router_1.Router],
    });
    appProviders.push({
        provide: core_1.APP_INITIALIZER,
        useFactory: () => () => { },
        deps: [Sentry.TraceService],
        multi: true,
    });
}
function jwtTokenGetter() {
    return localStorage.getItem('token');
}
exports.jwtTokenGetter = jwtTokenGetter;
function createSubscriptionClient() {
    const connectionParams = () => {
        if (sessionStorage.getItem('impersonateToken')) {
            const token = sessionStorage.getItem('impersonateToken');
            return token ? { headers: { Authorization: 'Bearer ' + token } } : {};
        }
        else if (localStorage.getItem('token')) {
            // never init login/register session with JWT token, always clear it out
            // as we can only login either with empty token or correct token
            // there is no guaranty that current token is valid
            if (['/auth/login', '/auth/register'].includes(document.location.pathname)) {
                this.jwt.deleteSession();
            }
            const token = localStorage.getItem('token');
            return token ? { headers: { Authorization: 'Bearer ' + token } } : {};
        }
    };
    return new subscriptions_transport_ws_1.SubscriptionClient(environment_1.environment.services.data.endpoint, {
        lazy: true,
        reconnect: true,
        connectionParams,
    });
}
exports.createSubscriptionClient = createSubscriptionClient;
class AppModule {
    constructor(apollo, router, analytics, jwt, wsClient) {
        this.router = router;
        this.analytics = analytics;
        this.jwt = jwt;
        const webSocketLink = new apollo_link_ws_1.WebSocketLink(wsClient);
        // to track GraphQL query count with the same name
        let requestCount = {};
        let timeout;
        // Middleware to catch JWT error and redirect to login page
        const jwtMiddleware = apollo_link_error_1.onError((data) => {
            if (data.networkError) {
                // Print the most useful message during error.
                console.log('Error: ', JSON.stringify(data.networkError, null, 2));
            }
            else {
                // if no networkError, print whatever we got
                console.log('Error: ', JSON.stringify(data, null, 2));
            }
            this.analytics.trackEvent('Error', {
                Error: JSON.stringify(data, null, 2),
            });
            const regExp = /JWSError|JWTExpired|connection_init failed|header is expected|no subscriptions exist/gi;
            if (data && data.networkError && data.networkError.message) {
                if (data.networkError.message.match(regExp)) {
                    // clear session so after redirect, user can login
                    // if we do not clear session here, old token will be used for a login request
                    this.jwt.deleteSession();
                    wsClient.close();
                    // do a full app refresh, due to the fact that we can not re-connect Apollo client
                    // with new credentials at runtime
                    this.router.navigate(['/auth/login']);
                    return;
                }
                else if (sessionStorage.getItem('impersonateToken')) {
                    const role = jwt_decode(sessionStorage.getItem('impersonateToken'))['https://hasura.io/jwt/claims']['x-hasura-default-role'];
                    if (!['user', user_model_1.UserRole.StaffUser].includes(role)) {
                        router.navigate(['/auth/validate']);
                    }
                }
                else if (localStorage.getItem('token')) {
                    const role = jwt_decode(sessionStorage.getItem('token'))['https://hasura.io/jwt/claims']['x-hasura-default-role'];
                    if (!['user', user_model_1.UserRole.StaffUser].includes(role)) {
                        router.navigate(['/auth/validate']);
                    }
                }
                /**
                 * analytics code to track user's events
                 */
                // ANALYTICS_START
                this.analytics.run(() => {
                    this.analytics.identifyUser(sessionStorage.getItem('impersonateToken') || localStorage.getItem('token'));
                });
                // ANALYTICS_END
            }
        });
        /**
         * This middleware tracks all GraphQL requests and their responses
         * calculates duration for every request and prints them in the dev tools console
         * requests which take longer than 3 seconds are styled in red, others are green
         * To enable, enter: DEBUG=1 in the Chrome dev tools console
         * To disable, enter: DEBUG=0 in the Chrome dev tools console, or refresh the page
         */
        const testMiddleware = new apollo_link_1.ApolloLink((operation, forward) => {
            // Debug mode is enabled if you type "DEBUG=1" in the dev tools and hit enter
            // or it's enabled by default on localhost addresses
            let isDebug = false;
            if (!window['DEBUG']) {
                return forward(operation);
            }
            else {
                isDebug = true;
            }
            const opName = operation.operationName;
            console.log(`⬆️ ${opName}`);
            const query = operation.query.loc.source.body;
            const variables = operation.variables;
            const started = Date.now();
            // Don't capture stats for subscriptions, this doesn't make sense
            // subscriptions run for a long time, and can not give use useful info on performance
            // they may also not works correctly if we pass them through this middleware
            // as we're doing conversion zenToRx and rxToZen which can introduce some issues
            if (!!query.match(/subscription/gi)) {
                if (isDebug) {
                    console.log(`⬆️ ${opName} not trackable (subscription)`);
                }
                return forward(operation);
            }
            else {
                if (isDebug) {
                    console.log(`⬆️ ${opName}`);
                }
            }
            requestCount[opName] = requestCount[opName] ? requestCount[opName] + 1 : 1;
            // after 10 seconds print summary of GraphQL requests and their count
            // this way we can detect multiple requests of the same name
            // and make optimisations
            if (!timeout) {
                timeout = setTimeout(() => {
                    console.log('Request count:', Object.keys(requestCount).length, JSON.stringify(requestCount, null, 4));
                    requestCount = {};
                    clearTimeout(timeout);
                    timeout = undefined;
                }, 10000);
            }
            const zenToRx = (zenObservable) => {
                return new rxjs_1.Observable((observer) => zenObservable.subscribe(observer));
            };
            const forwardReturn = zenToRx(forward(operation)).pipe(operators_1.shareReplay(1));
            forwardReturn.subscribe((resp) => {
                const ended = Date.now();
                const durationSec = (ended - started) / 1000;
                let color = 'color:green;';
                if (durationSec > 3.0) {
                    color = 'color:red;';
                }
                const codeStyle = color;
                const headerStyle = `font-weight: bold;${color}`;
                console.log(`%c--- QUERY ${opName} took ${durationSec} sec ---`, headerStyle);
                console.log(`%c${query}`, codeStyle);
                console.log(`--- VARS for ${opName} ---`);
                console.log(JSON.stringify(variables, null, 4));
                console.log(`--- RESP for ${opName} ---`);
                console.log(JSON.stringify(resp, null, 4));
            });
            return forwardReturn;
        });
        apollo.create({
            cache: new apollo_cache_inmemory_1.InMemoryCache(),
            link: apollo_link_1.from([jwtMiddleware, testMiddleware, webSocketLink]),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
        /**
         * analytics code to track user's events
         */
        // ANALYTICS_START
        this.analytics.run(() => {
            this.analytics.identifyUser(sessionStorage.getItem('impersonateToken') || localStorage.getItem('token'));
        });
        // ANALYTICS_END
    }
}
exports.AppModule = AppModule;
