"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const graphql_tag_1 = require("graphql-tag");
const graphql_1 = require("../../generated/graphql");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("apollo-angular");
class ListingService {
    constructor(apollo) {
        this.apollo = apollo;
    }
    createEmptyListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const insertData = [
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Rea,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Domain,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Bmp,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Homesales,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Homely,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Allhomes,
                },
            ];
            return this.apollo
                .mutate({
                mutation: CREATE_EMPTY_LISTING_MUTATION,
                variables: {
                    insertPublishObject: insertData,
                },
            })
                .toPromise();
        });
    }
    getListings() {
        return this.apollo.subscribe({
            query: GET_LISTINGS_SUBSCRIPTION,
        });
    }
    getListingUserId(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_USER_ID_QUERY,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    getListingAddressDetailsById(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_ADDRESS_DETAILS_BY_ID_QUERY,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    getDuplicateListingsForAddress(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_DUPLICATE_LISTINGS_FOR_ADDRESS_QUERY,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    getListingById(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_BY_ID_QUERY,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    getOnboardingPropertyDetailById(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ONBOARDING_PROPERTY_DETAIL_BY_ID_QUERY,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    updateOnboardingPropertyDetail(listingId, detail) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_ONBOARDING_PROPERTY_DETAIL_MUTATION,
                variables: Object.assign({ listingId }, detail),
            })
                .toPromise();
        });
    }
    updateAddressDetails(listingId, addressDetails) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_ADDRESS_DETAILS_MUTATION,
                variables: {
                    listingId,
                    street: addressDetails.streetName,
                    streetNumber: addressDetails.streetNumber,
                    state: addressDetails.state,
                    suburb: addressDetails.suburb,
                    postcode: addressDetails.postcode,
                    unitNumber: addressDetails.unitNumber,
                    displayOption: addressDetails.displayOption,
                    lat: addressDetails.lat,
                    lon: addressDetails.lon,
                    address1: addressDetails.address1,
                    address2: addressDetails.address2,
                },
            })
                .toPromise();
        });
    }
    updateDescription(listingId, desc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_DESCRIPTION_MUTATION,
                variables: {
                    listingId,
                    headline: desc.headline,
                    description: desc.description,
                },
            })
                .toPromise();
        });
    }
    deleteListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: DELETE_LISTING_MUTATION,
                variables: { listingId },
            })
                .toPromise();
        });
    }
    updateDetails(listingId, details) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            details.yearBuilt = details.yearBuilt || null;
            return this.apollo
                .mutate({
                mutation: UPDATE_DETAILS_MUTATION,
                variables: Object.assign({ listingId }, details),
            })
                .toPromise();
        });
    }
    updateFeatures(listingId, features) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_FEATURES_MUTATION,
                variables: {
                    listingId,
                    features: features.toString(),
                },
            })
                .toPromise();
        });
    }
    updatePrice(listingId, price) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.apollo
                .mutate({
                mutation: UPDATE_PRICE_MUTATION,
                variables: {
                    listingId,
                    askingPrice: price.askingPrice || null,
                    singlePrice: price.singlePrice || null,
                    minPrice: price.minPrice || null,
                    maxPrice: price.maxPrice || null,
                    displayOption: price.displayOption || '',
                    displayText: price.displayText || null,
                    textPriceOption: price.textPriceOption || null,
                },
            })
                .toPromise();
        });
    }
    getListingsWithPagination(limit, offset, orderBy) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTINGS_WITH_PAGINATION,
                variables: {
                    offset,
                    limit,
                    orderBy,
                },
            })
                .toPromise();
        });
    }
    getListingsLengthQuery() {
        return this.apollo.subscribe({
            query: GET_LISTINGS_LENGTH_QUERY,
        });
    }
    getListingEventsSubscription(listingId) {
        return this.apollo.subscribe({
            query: GET_LISTING_EVENTS_SUBSCRIPTION,
            variables: {
                listingId,
            },
        });
    }
    createInspection(listingId, input) {
        return this.apollo.mutate({
            mutation: INSERT_INSPECTION,
            variables: Object.assign({ listingId }, input),
        });
    }
    updateInspection(inspectionId, input) {
        return this.apollo.mutate({
            mutation: UPDATE_INSPECTION,
            variables: Object.assign({ inspectionId }, input),
        });
    }
    deleteInspection(inspectionId) {
        return this.apollo.mutate({
            mutation: DELETE_INSPECTION,
            variables: {
                inspectionId,
            },
        });
    }
    setAuction(listingId, input) {
        return this.apollo.mutate({
            mutation: SET_AUCTION,
            variables: Object.assign({ listingId }, input),
        });
    }
    updateListingStatus(userId, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.apollo
                .mutate({
                mutation: UPDATE_LISTING_STATUS,
                variables: {
                    userId,
                    status,
                },
            })
                .toPromise();
        });
    }
    getListingByUserId(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_BY_USER_ID_QUERY,
                variables: {
                    userId,
                },
            })
                .toPromise();
        });
    }
    reactivateListing(listingId) {
        return this.apollo
            .mutate({
            mutation: REACTIVATE_LISTING_MUTATION,
            variables: {
                listingId,
            },
        })
            .toPromise();
    }
    getListOfListings(commercialStatus, publishingStatus) {
        return this.apollo.subscribe({
            query: GET_LIST_OF_LISTINGS,
            variables: {
                commercialStatus,
                publishingStatus,
            },
        });
    }
    /**
     * Returns feedback count for a given listing
     */
    getFeedbackCountForListing(listingId, eventType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_FEEDBACK_COUNT,
                variables: { listingId, eventType },
            })
                .toPromise();
        });
    }
    /**
     * Returns listing details
     */
    getListingData(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_DATA,
                variables: { listingId },
            })
                .toPromise();
        });
    }
    getListingBasicDetailsById(listingId) {
        return this.apollo.subscribe({
            query: GET_BASIC_LISTING_DETAILS_BY_ID,
            variables: {
                listingId,
            },
        });
    }
    updateListingStatusById(listingId, status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_LISTING_STATUS_BY_ID,
                variables: {
                    listingId,
                    status,
                },
            })
                .toPromise();
        });
    }
    getPaidCampaignOrdersByListingId(listingId) {
        return this.apollo
            .query({
            query: GET_PAID_CAMPAIGN_ORDERS_BY_LISTING_ID,
            variables: {
                listingId,
            },
        })
            .toPromise();
    }
    getOpenCampaignOrdersByListingId(listingId) {
        return this.apollo
            .query({
            query: GET_OPEN_CAMPAIGN_ORDERS_BY_LISTING_ID,
            variables: {
                listingId,
            },
        })
            .toPromise();
    }
    getRelistingAddon(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_RELISTING_ADDON,
                variables: { listingId },
            })
                .toPromise();
        });
    }
    updateListingCommercialStatus(listingId, commercialStatus) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_LISTING_COMMERCIAL_STATUS,
                variables: {
                    listingId,
                    commercialStatus,
                },
            })
                .toPromise();
        });
    }
    sellListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: SELL_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    rentListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: RENT_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    underReviewListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UNDER_REVIEW_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    withdrawListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: WITHDRAW_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    onHoldListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: ON_HOLD_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    underOfferListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UNDER_OFFER_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    listListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: LIST_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    pendingPublishListing(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: PENDING_PUBLISH_LISTING_MUTATION,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    failPublishListing(listingId, emailSend = false, message = '') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: FAIL_PUBLISH_LISTING_MUTATION,
                variables: {
                    listingId,
                    emailSend,
                    message,
                },
            })
                .toPromise();
        });
    }
    checkAddonsBoughtByListingId(listingId, addonIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: CHECK_ADDONS_BOUGHT_BY_LISTING_ID,
                variables: {
                    listingId,
                    addon_ids: addonIds,
                },
            })
                .toPromise();
        });
    }
    getListingUserWithOnBoardId(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_USER_ON_BOARD_ID_QUERY,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    checkOverlappingEvents(listingId, fromDate, toDate, listingInspectionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return ((yield this.apollo
                .query({
                query: CHECK_OVERLAPPING_EVENTS,
                variables: {
                    listing_id: listingId,
                    from: fromDate,
                    to: toDate,
                    listing_inspection_id: listingInspectionId,
                },
            })
                .toPromise()).data.listing_inspection_aggregate.aggregate.count > 0);
        });
    }
    checkAuctionOverlappingInspectionEvents(listingId, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return ((yield this.apollo
                .query({
                query: CHECK_AUCTION_OVERLAPPING_INSPECTION_EVENTS,
                variables: {
                    listing_id: listingId,
                    date,
                },
            })
                .toPromise()).data.listing_inspection_aggregate.aggregate.count > 0);
        });
    }
    getListingDetails(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_LISTING_DETAILS,
                variables: { id },
            })
                .toPromise();
        });
    }
    updateListingPublishingStatus(listingId, publishingStatus) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_LISTING_PUBLISHING_STATUS,
                variables: {
                    listingId,
                    publishingStatus,
                },
            })
                .toPromise();
        });
    }
    insertListingMetadata(listingId, type, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: INSERT_LISTING_METADATA,
                variables: {
                    listingId,
                    type,
                    value,
                },
            })
                .toPromise();
        });
    }
    getListingMetadataValue(listingId, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_NON_PROCESSED_LISTING_METADATA,
                variables: { listingId, type },
            })
                .pipe(operators_1.map((response) => response.data.listing_metadata[0]))
                .toPromise();
        });
    }
    markListingMetadataAsProcessed(listingId, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: MARK_LISTING_METADATA_PROCESSED,
                variables: {
                    listingId,
                    type,
                },
            })
                .pipe(operators_1.map((response) => (response.data.update_listing_metadata.affected_rows > 0 ? true : false)))
                .toPromise();
        });
    }
    updateAddressDetailsForPayment(listingId, addressDetails) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_ADDRESS_DETAILS_FOR_PAYMENT_MUTATION,
                variables: {
                    listingId,
                    street: addressDetails.streetName,
                    streetNumber: addressDetails.streetNumber,
                    state: addressDetails.state,
                    suburb: addressDetails.suburb,
                    postcode: addressDetails.postcode,
                    unitNumber: addressDetails.unitNumber,
                    displayOption: addressDetails.displayOption,
                    lat: addressDetails.lat,
                    lon: addressDetails.lon,
                    address1: addressDetails.address1,
                    address2: addressDetails.address2,
                },
            })
                .toPromise();
        });
    }
    updateDeliveryAddressForPayment(listingId, deliveryAddress, deliverToPropertyAddress) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_DELIVERY_ADDRESS_FOR_PAYMENT_MUTATION,
                variables: {
                    listingId,
                    deliverToPropertyAddress,
                    street: deliveryAddress.streetName,
                    streetNumber: deliveryAddress.streetNumber,
                    state: deliveryAddress.state,
                    suburb: deliveryAddress.suburb,
                    postcode: deliveryAddress.postcode,
                    unitNumber: deliveryAddress.unitNumber,
                    lat: deliveryAddress.lat,
                    lon: deliveryAddress.lon,
                    address1: deliveryAddress.address1,
                    address2: deliveryAddress.address2,
                },
            })
                .toPromise();
        });
    }
    deListFromAllportals(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.apollo
                .mutate({
                mutation: DELIST_LISTING,
                variables: {
                    listingId,
                },
            })
                .toPromise();
        });
    }
    getListingZone(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: GET_LISTING_ZONE,
                variables: { listingId },
            })
                .toPromise();
            return resp.data.listing_by_pk.zone;
        });
    }
    getAllListings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ALL_LISTINGS,
                variables: {},
            })
                .pipe(operators_1.map((res) => res.data.listing))
                .toPromise();
        });
    }
    /**
     * Returns string date from listing.created_at, or empty string
     * @param listingId
     */
    listingCreatedAt(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: LISTING_CREATED_AT,
                variables: {
                    listingId,
                },
            })
                .pipe(operators_1.map((res) => {
                return res.data.listing_by_pk.created_at || '';
            }))
                .toPromise();
        });
    }
    createEmptyListingByAdmin(ownerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const portalStatuses = [
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Rea,
                    publish_status: graphql_1.Listing_Publish_Status_Type_Enum.Draft,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Domain,
                    publish_status: graphql_1.Listing_Publish_Status_Type_Enum.Draft,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Bmp,
                    publish_status: graphql_1.Listing_Publish_Status_Type_Enum.Draft,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Homesales,
                    publish_status: graphql_1.Listing_Publish_Status_Type_Enum.Draft,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Homely,
                    publish_status: graphql_1.Listing_Publish_Status_Type_Enum.Draft,
                },
                {
                    portal_source: graphql_1.Listing_Publish_Portal_Source_Enum.Allhomes,
                    publish_status: graphql_1.Listing_Publish_Status_Type_Enum.Draft,
                },
            ];
            return this.apollo
                .mutate({
                mutation: CREATE_EMPTY_LISTING_BY_ADMIN_MUTATION,
                variables: {
                    ownerId,
                    portalStatuses,
                },
            })
                .toPromise();
        });
    }
}
ListingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ListingService_Factory() { return new ListingService(i0.ɵɵinject(i1.Apollo)); }, token: ListingService, providedIn: "root" });
exports.ListingService = ListingService;
//
// Queries
//
const GET_LISTING_ZONE = graphql_tag_1.default `
    query getListingZone($listingId: uuid!) {
        listing_by_pk(id: $listingId) {
            zone
        }
    }
`;
const GET_LISTING_BY_ID_QUERY = graphql_tag_1.default `
    query getListingById($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            id
            summary
            description
            propertyDetails_address_1
            propertyDetails_address_2
            propertyDetails_address_street
            propertyDetails_address_streetNumber
            propertyDetails_address_state
            propertyDetails_address_suburb
            propertyDetails_address_municipality
            propertyDetails_address_postcode
            propertyDetails_address_unitNumber
            propertyDetails_address_displayOption
            propertyDetails_address_streetDirectory_page
            propertyDetails_address_streetDirectory_type
            propertyDetails_address_streetDirectory_reference
            propertyDetails_address_suggestedGeoLocation
            propertyDetails_address_suggestedGeoLocation_latitude
            propertyDetails_address_suggestedGeoLocation_longitude
            delivery_address_1
            delivery_address_2
            delivery_address_street
            delivery_address_streetNumber
            delivery_address_state
            delivery_address_suburb
            delivery_address_postcode
            delivery_address_unitNumber
            delivery_address_suggestedGeoLocation_latitude
            delivery_address_suggestedGeoLocation_longitude
            delivery_to_property_address
            propertyDetails_propertyType
            propertyDetails_bedRooms
            propertyDetails_bathRooms
            propertyDetails_landArea_unit
            propertyDetails_landArea_value
            propertyDetails_area_value
            propertyDetails_area_unit
            propertyDetails_carSpaces
            availableFrom
            saleType
            propertyDetails_yearBuilt
            features
            price_asking
            price_single
            price_from
            price_to
            price_displayOption
            price_displayText
            price_textOption
            created_at
            updated_at
            status
            auction_dateTime
            auction_online
            commercial_status
            publishing_status
            proof_of_ownership_status
            propertyDetails_garageSpaces
            propertyDetails_carportSpaces
            propertyDetails_energyEfficiencyRating
            listing_inspections {
                id
                from
                to
                is_private
                repeat
            }
            user {
                id
                phone_number
                phone_number_verified
            }
            paidOrders: orders(
                where: { _and: [{ order_items: { campaign: { id: { _is_null: false } } } }, { status: { _eq: Paid } }] }
            ) {
                id
                status
            }
        }
    }
`;
const GET_BASIC_LISTING_DETAILS_BY_ID = graphql_tag_1.default `
    subscription getBasicListingDetailsById($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            id
            summary
            status
            commercial_status
            publishing_status
            saleType
            proof_of_ownership_status
            propertyDetails_address_state
            paidOrders: orders(
                where: { _and: [{ order_items: { campaign: { id: { _is_null: false } } } }, { status: { _eq: Paid } }] }
            ) {
                id
                status
                order_items(where: { campaign: { id: { _is_null: false } } }) {
                    campaign_id
                }
            }
            openOrders: orders(
                where: {
                    _and: [
                        {
                            order_items: {
                                _or: [{ campaign: { id: { _is_null: false } } }, { addon: { id: { _is_null: false } } }]
                            }
                        }
                        { status: { _eq: Open } }
                    ]
                }
            ) {
                id
                status
                order_items {
                    campaign_id
                    addon_id
                }
            }
            listing_media(where: { type: { _eq: STATEMENT_OF_INFORMATION }, url: { _is_null: false } }) {
                id
            }
        }
    }
`;
const GET_LISTINGS_SUBSCRIPTION = graphql_tag_1.default `
    subscription getListings {
        listing {
            id
            enquiry_code
            propertyDetails_address_street
            propertyDetails_address_streetNumber
            propertyDetails_address_state
            propertyDetails_address_suburb
            propertyDetails_address_municipality
            propertyDetails_address_postcode
            propertyDetails_address_unitNumber
            propertyDetails_address_displayOption
            propertyDetails_address_streetDirectory_page
            propertyDetails_address_streetDirectory_type
            propertyDetails_address_streetDirectory_reference
            propertyDetails_address_suggestedGeoLocation
            propertyDetails_address_suggestedGeoLocation_latitude
            propertyDetails_address_suggestedGeoLocation_longitude
            status
            created_at
            updated_at
            commercial_status
            paidOrders: orders(
                where: { _and: [{ order_items: { campaign: { id: { _is_null: false } } } }, { status: { _eq: Paid } }] }
            ) {
                id
                status
            }
        }
    }
`;
const GET_ONBOARDING_PROPERTY_DETAIL_BY_ID_QUERY = graphql_tag_1.default `
    query getOnboardingPropertyDetailById($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            id
            saleType
            propertyDetails_propertyType
            propertyDetails_bedRooms
            propertyDetails_landArea_unit
            propertyDetails_landArea_value
            propertyDetails_address_suburb
            propertyDetails_address_state
            propertyDetails_address_postcode
        }
    }
`;
const UPDATE_ONBOARDING_PROPERTY_DETAIL_MUTATION = graphql_tag_1.default `
    mutation updateOnboardingPropertyDetail(
        $listingId: uuid!
        $saleType: String
        $propertyType: String
        $bedrooms: Int
        $landArea: numeric
        $landAreaUnit: String
        $suburb: String
        $state: String
        $postCode: String
    ) {
        update_listing(
            where: { id: { _eq: $listingId } }
            _set: {
                saleType: $saleType
                propertyDetails_propertyType: $propertyType
                propertyDetails_bedRooms: $bedrooms
                propertyDetails_landArea_value: $landArea
                propertyDetails_landArea_unit: $landAreaUnit
                propertyDetails_address_suburb: $suburb
                propertyDetails_address_state: $state
                propertyDetails_address_postcode: $postCode
                propertyDetails_address_suburb_before_checkout: $suburb
                propertyDetails_address_state_before_checkout: $state
                propertyDetails_address_postcode_before_checkout: $postCode
            }
        ) {
            affected_rows
        }
    }
`;
const CREATE_EMPTY_LISTING_MUTATION = graphql_tag_1.default `
    mutation createEmptyListing($insertPublishObject: [listing_publish_status_insert_input!]!) {
        insert_listing(objects: { listing_publish_statuses: { data: $insertPublishObject } }) {
            affected_rows
            returning {
                id
                created_at
            }
        }
    }
`;
const DELETE_LISTING_MUTATION = graphql_tag_1.default `
    mutation deleteListing($listingId: uuid!) {
        delete_listing(where: { id: { _eq: $listingId } }) {
            affected_rows
        }
    }
`;
const UPDATE_ADDRESS_DETAILS_MUTATION = graphql_tag_1.default `
    mutation updateAddressDetails(
        $listingId: uuid!
        $street: String
        $streetNumber: String
        $state: String
        $suburb: String
        $postcode: String
        $unitNumber: String
        $displayOption: String
        $lat: numeric
        $lon: numeric
        $address1: String
        $address2: String
    ) {
        update_listing(
            where: { id: { _eq: $listingId } }
            _set: {
                propertyDetails_address_1: $address1
                propertyDetails_address_2: $address2
                propertyDetails_address_street: $street
                propertyDetails_address_streetNumber: $streetNumber
                propertyDetails_address_state: $state
                propertyDetails_address_suburb: $suburb
                propertyDetails_address_postcode: $postcode
                propertyDetails_address_unitNumber: $unitNumber
                propertyDetails_address_displayOption: $displayOption
                propertyDetails_address_suggestedGeoLocation_latitude: $lat
                propertyDetails_address_suggestedGeoLocation_longitude: $lon
                propertyDetails_address_state_before_checkout: $state
                propertyDetails_address_suburb_before_checkout: $suburb
                propertyDetails_address_postcode_before_checkout: $postcode
            }
        ) {
            affected_rows
        }
    }
`;
const UPDATE_ADDRESS_DETAILS_FOR_PAYMENT_MUTATION = graphql_tag_1.default `
    mutation updateAddressDetailsForPayment(
        $listingId: uuid!
        $street: String
        $streetNumber: String
        $state: String
        $suburb: String
        $postcode: String
        $unitNumber: String
        $displayOption: String
        $lat: numeric
        $lon: numeric
        $address1: String
        $address2: String
    ) {
        update_listing(
            where: { id: { _eq: $listingId } }
            _set: {
                propertyDetails_address_1: $address1
                propertyDetails_address_2: $address2
                propertyDetails_address_street: $street
                propertyDetails_address_streetNumber: $streetNumber
                propertyDetails_address_state: $state
                propertyDetails_address_suburb: $suburb
                propertyDetails_address_postcode: $postcode
                propertyDetails_address_unitNumber: $unitNumber
                propertyDetails_address_displayOption: $displayOption
                propertyDetails_address_suggestedGeoLocation_latitude: $lat
                propertyDetails_address_suggestedGeoLocation_longitude: $lon
            }
        ) {
            affected_rows
        }
    }
`;
const UPDATE_DELIVERY_ADDRESS_FOR_PAYMENT_MUTATION = graphql_tag_1.default `
    mutation updateDeliveryAddressForPayment(
        $listingId: uuid!
        $street: String
        $streetNumber: String
        $state: String
        $suburb: String
        $postcode: String
        $unitNumber: String
        $lat: numeric
        $lon: numeric
        $address1: String
        $address2: String
        $deliverToPropertyAddress: Boolean!
    ) {
        update_listing(
            where: { id: { _eq: $listingId } }
            _set: {
                delivery_to_property_address: $deliverToPropertyAddress
                delivery_address_1: $address1
                delivery_address_2: $address2
                delivery_address_street: $street
                delivery_address_streetNumber: $streetNumber
                delivery_address_state: $state
                delivery_address_suburb: $suburb
                delivery_address_postcode: $postcode
                delivery_address_unitNumber: $unitNumber
                delivery_address_suggestedGeoLocation_latitude: $lat
                delivery_address_suggestedGeoLocation_longitude: $lon
            }
        ) {
            affected_rows
        }
    }
`;
const GET_LISTING_USER_ID_QUERY = graphql_tag_1.default `
    query GetListingOwner($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            paidOrders: orders(where: { status: { _eq: Paid } }) {
                id
            }
            user {
                id
                email_verified
            }
        }
    }
`;
const GET_LISTINGS_WITH_PAGINATION = graphql_tag_1.default `
    query getListingsWithPagination($limit: Int!, $offset: Int!, $orderBy: [listing_order_by!]) {
        listing(limit: $limit, offset: $offset, order_by: $orderBy) {
            id
            status
            propertyDetails_address_state
            propertyDetails_address_postcode
            propertyDetails_address_suburb
            proof_of_ownership_status
            user {
                id
                email
            }
        }
    }
`;
const GET_LISTINGS_LENGTH_QUERY = graphql_tag_1.default `
    subscription getListingsLength {
        listing_aggregate {
            aggregate {
                count
            }
        }
    }
`;
const GET_LISTING_ADDRESS_DETAILS_BY_ID_QUERY = graphql_tag_1.default `
    query getListingAddressDetailsByIdQuery($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            id
            propertyDetails_address_street
            propertyDetails_address_streetNumber
            propertyDetails_address_state
            propertyDetails_address_suburb
            propertyDetails_address_postcode
            propertyDetails_address_unitNumber
            saleType
        }
    }
`;
const GET_DUPLICATE_LISTINGS_FOR_ADDRESS_QUERY = graphql_tag_1.default `
    query getDuplicateListingsForAddressQuery($listingId: String!) {
        check_duplicate_listing_for_address(listingId: $listingId) {
            duplicate
        }
    }
`;
const UPDATE_DESCRIPTION_MUTATION = graphql_tag_1.default `
    mutation updateDescription($listingId: uuid!, $headline: String!, $description: String!) {
        update_listing(where: { id: { _eq: $listingId } }, _set: { summary: $headline, description: $description }) {
            affected_rows
        }
    }
`;
const UPDATE_LISTING_STATUS = graphql_tag_1.default `
    mutation updateListingStatus($userId: uuid!, $status: String) {
        update_listing(where: { user: { id: { _eq: $userId } } }, _set: { status: $status }) {
            affected_rows
        }
    }
`;
const UPDATE_DETAILS_MUTATION = graphql_tag_1.default `
    mutation updateDetails(
        $listingId: uuid!
        $saleType: String!
        $propertyType: String!
        $bedrooms: Int
        $bathrooms: Int
        $availableFrom: timestamptz
        $landArea: numeric
        $landAreaUnit: String
        $propertyArea: numeric
        $propertyAreaUnit: String
        $carSpaces: Int
        $yearBuilt: Int
        $garageSpaces: Int
        $carportSpaces: Int
        $energyRating: numeric
    ) {
        update_listing(
            where: { id: { _eq: $listingId } }
            _set: {
                saleType: $saleType
                propertyDetails_propertyType: $propertyType
                propertyDetails_bedRooms: $bedrooms
                propertyDetails_bathRooms: $bathrooms
                availableFrom: $availableFrom
                propertyDetails_landArea_value: $landArea
                propertyDetails_landArea_unit: $landAreaUnit
                propertyDetails_area_value: $propertyArea
                propertyDetails_area_unit: $propertyAreaUnit
                propertyDetails_carSpaces: $carSpaces
                propertyDetails_yearBuilt: $yearBuilt
                propertyDetails_garageSpaces: $garageSpaces
                propertyDetails_carportSpaces: $carportSpaces
                propertyDetails_energyEfficiencyRating: $energyRating
            }
        ) {
            affected_rows
        }
    }
`;
const UPDATE_FEATURES_MUTATION = graphql_tag_1.default `
    mutation updateFeatures($listingId: uuid!, $features: String!) {
        update_listing(where: { id: { _eq: $listingId } }, _set: { features: $features }) {
            affected_rows
        }
    }
`;
const UPDATE_PRICE_MUTATION = graphql_tag_1.default `
    mutation updatePrice(
        $listingId: uuid!
        $askingPrice: Int
        $singlePrice: Int!
        $minPrice: Int
        $maxPrice: Int
        $displayText: String
        $displayOption: String!
        $textPriceOption: String
    ) {
        update_listing(
            where: { id: { _eq: $listingId } }
            _set: {
                price_asking: $askingPrice
                price_single: $singlePrice
                price_from: $minPrice
                price_to: $maxPrice
                price_displayText: $displayText
                price_displayOption: $displayOption
                price_textOption: $textPriceOption
            }
        ) {
            affected_rows
        }
    }
`;
const INSERT_INSPECTION = graphql_tag_1.default `
    mutation insertInspection(
        $listingId: uuid!
        $from: timestamptz
        $to: timestamptz
        $isPrivate: Boolean
        $repeat: Boolean
    ) {
        insert_listing_inspection(
            objects: [{ from: $from, to: $to, listing_id: $listingId, repeat: $repeat, is_private: $isPrivate }]
        ) {
            returning {
                id
                from
                to
                repeat
                is_private
            }
        }
    }
`;
const UPDATE_INSPECTION = graphql_tag_1.default `
    mutation updateInspection(
        $inspectionId: uuid!
        $from: timestamptz
        $to: timestamptz
        $isPrivate: Boolean
        $repeat: Boolean
    ) {
        update_listing_inspection(
            where: { id: { _eq: $inspectionId } }
            _set: { from: $from, to: $to, is_private: $isPrivate, repeat: $repeat }
        ) {
            returning {
                id
                from
                to
                is_private
                repeat
            }
        }
    }
`;
const DELETE_INSPECTION = graphql_tag_1.default `
    mutation deleteInspection($inspectionId: uuid!) {
        delete_listing_inspection(where: { id: { _eq: $inspectionId } }) {
            affected_rows
        }
    }
`;
const SET_AUCTION = graphql_tag_1.default `
    mutation setAuction($listingId: uuid!, $timestamp: timestamptz, $isOnline: Boolean) {
        update_listing(
            where: { id: { _eq: $listingId } }
            _set: { auction_dateTime: $timestamp, auction_online: $isOnline }
        ) {
            returning {
                id
                auction_dateTime
                auction_online
            }
        }
    }
`;
const GET_LISTING_EVENTS_SUBSCRIPTION = graphql_tag_1.default `
    subscription getListingEvents($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            id
            auction_dateTime
            auction_online
            listing_inspections(order_by: { from: asc }) {
                id
                from
                to
                is_private
                repeat
            }
        }
    }
`;
const GET_LISTING_BY_USER_ID_QUERY = graphql_tag_1.default `
    query getListingByUserId($userId: uuid!) {
        listing(where: { owner_id: { _eq: $userId } }) {
            id
        }
    }
`;
const GET_LIST_OF_LISTINGS = graphql_tag_1.default `
    subscription getListOfListings(
        $commercialStatus: [commercial_status_type_enum!]
        $publishingStatus: [publishing_status_type_enum!]
    ) {
        listing(
            where: {
                _and: { commercial_status: { _in: $commercialStatus }, publishing_status: { _in: $publishingStatus } }
            }
            order_by: { updated_at: desc }
        ) {
            id
            price_displayText
            enquiry_code
            propertyDetails_area_value
            propertyDetails_area_unit
            propertyDetails_bathRooms
            propertyDetails_bedRooms
            propertyDetails_carSpaces
            propertyDetails_propertyType
            propertyDetails_landArea_value
            propertyDetails_landArea_unit
            propertyDetails_address_streetNumber
            propertyDetails_address_unitNumber
            propertyDetails_address_street
            propertyDetails_address_1
            propertyDetails_address_2
            propertyDetails_address_displayOption
            propertyDetails_address_state
            propertyDetails_address_suburb
            propertyDetails_address_postcode
            saleType
            price_displayText
            price_textOption
            price_displayOption
            price_single
            price_from
            price_to
            price_asking
            commercial_status
            publishing_status
            proof_of_ownership_status
            auction_dateTime
            availableFrom
            summary
            description
            features
            owner_id
            propertyDetails_garageSpaces
            propertyDetails_carportSpaces
            propertyDetails_energyEfficiencyRating
            listing_media(order_by: { order: asc }, limit: 1) {
                id
                url
            }
            listing_onboardings {
                finished
                skipped
            }
            user {
                phone_number_verified
            }
            listing_inspections {
                id
            }
            paidOrders: orders(
                where: { _and: [{ order_items: { campaign: { id: { _is_null: false } } } }, { status: { _eq: Paid } }] }
            ) {
                id
            }
            feedbacks_aggregate {
                aggregate {
                    count
                }
            }
            visitedPreview: listing_events_aggregate(where: { event_type: { _eq: visited_preview } }) {
                aggregate {
                    count
                }
            }
            visitedCampaign: listing_events_aggregate(where: { event_type: { _eq: visited_campaign } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;
const UPDATE_LISTING_STATUS_BY_ID = graphql_tag_1.default `
    mutation updateListingStatusById($listingId: uuid!, $status: String) {
        update_listing(where: { id: { _eq: $listingId } }, _set: { status: $status }) {
            affected_rows
        }
    }
`;
const GET_PAID_CAMPAIGN_ORDERS_BY_LISTING_ID = graphql_tag_1.default `
    query getPaidCampaignOrdersByListingId($listingId: uuid!) {
        order(
            where: {
                listing_id: { _eq: $listingId }
                status: { _eq: Paid }
                order_items: { campaign_id: { _is_null: false } }
            }
        ) {
            id
            order_items(where: { campaign_id: { _is_null: false } }) {
                campaign_id
                campaign {
                    code
                }
            }
        }
    }
`;
const GET_OPEN_CAMPAIGN_ORDERS_BY_LISTING_ID = graphql_tag_1.default `
    query getOpenCampaignOrdersByListingId($listingId: uuid!) {
        order(
            where: {
                listing_id: { _eq: $listingId }
                order_items: { campaign_id: { _is_null: false } }
                status: { _eq: Open }
            }
        ) {
            id
            created_from_template
            order_items {
                campaign_id
                campaign {
                    code
                }
            }
        }
    }
`;
const GET_FEEDBACK_COUNT = graphql_tag_1.default `
    query getFeedbackCount($listingId: uuid!, $eventType: listing_event_type_enum) {
        feedback_aggregate(where: { _and: { listing_id: { _eq: $listingId }, event_type: { _eq: $eventType } } }) {
            aggregate {
                count
            }
        }
    }
`;
const REACTIVATE_LISTING_MUTATION = graphql_tag_1.default `
    mutation reactivateListing($listingId: uuid!) {
        reactivate_listing(listingId: $listingId) {
            updated
        }
    }
`;
const GET_RELISTING_ADDON = graphql_tag_1.default `
    query getRelistingAddon($listingId: String!) {
        get_relisting_addon(listingId: $listingId) {
            id
            price
        }
    }
`;
const UPDATE_LISTING_COMMERCIAL_STATUS = graphql_tag_1.default `
    mutation updateListingCommercialStatus($listingId: uuid!, $commercialStatus: commercial_status_type_enum!) {
        update_listing(where: { id: { _eq: $listingId } }, _set: { commercial_status: $commercialStatus }) {
            affected_rows
        }
    }
`;
const SELL_LISTING_MUTATION = graphql_tag_1.default `
    mutation sellListing($listingId: uuid!) {
        sell_listing(listingId: $listingId) {
            updated
        }
    }
`;
const RENT_LISTING_MUTATION = graphql_tag_1.default `
    mutation rentListing($listingId: uuid!) {
        rent_listing(listingId: $listingId) {
            updated
        }
    }
`;
const UNDER_REVIEW_LISTING_MUTATION = graphql_tag_1.default `
    mutation underReviewListing($listingId: uuid!) {
        under_review_listing(listingId: $listingId) {
            updated
        }
    }
`;
const WITHDRAW_LISTING_MUTATION = graphql_tag_1.default `
    mutation withdrawListing($listingId: uuid!) {
        withdraw_listing(listingId: $listingId) {
            updated
        }
    }
`;
const ON_HOLD_LISTING_MUTATION = graphql_tag_1.default `
    mutation onHoldListing($listingId: uuid!) {
        on_hold_listing(listingId: $listingId) {
            updated
        }
    }
`;
const UNDER_OFFER_LISTING_MUTATION = graphql_tag_1.default `
    mutation underOfferListing($listingId: uuid!) {
        under_offer_listing(listingId: $listingId) {
            updated
        }
    }
`;
const LIST_LISTING_MUTATION = graphql_tag_1.default `
    mutation listListing($listingId: uuid!) {
        list_listing(listingId: $listingId) {
            updated
        }
    }
`;
const CHECK_ADDONS_BOUGHT_BY_LISTING_ID = graphql_tag_1.default `
    query checkAddonsBoughtByListingId($listingId: uuid!, $addon_ids: [uuid!]) {
        order_item(
            where: {
                order: { listing_id: { _eq: $listingId }, status: { _eq: Paid } }
                addon_id: { _is_null: false, _in: $addon_ids }
            }
        ) {
            addon {
                id
            }
        }
    }
`;
const GET_LISTING_DATA = graphql_tag_1.default `
    query getListingData($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            saleType
            commercial_status
            publishing_status
            auction_dateTime
            proof_of_ownership_status
            user {
                phone_number_verified
            }
            listing_inspections {
                id
            }
        }
    }
`;
const FAIL_PUBLISH_LISTING_MUTATION = graphql_tag_1.default `
    mutation failPublishListing($listingId: uuid!, $emailSend: Boolean, $message: String) {
        fail_publish_listing(listingId: $listingId, emailSend: $emailSend, message: $message) {
            updated
        }
    }
`;
const PENDING_PUBLISH_LISTING_MUTATION = graphql_tag_1.default `
    mutation pendingPublishListing($listingId: uuid!) {
        pending_publish_listing(listingId: $listingId) {
            updated
        }
    }
`;
const GET_LISTING_USER_ON_BOARD_ID_QUERY = graphql_tag_1.default `
    query GetListingOwnerWithOnBoard($listingId: uuid!) {
        listing(where: { id: { _eq: $listingId } }) {
            user {
                id
            }
            listing_onboardings {
                id
                finished
                skipped
                step_completed
                user_id
            }
        }
    }
`;
const CHECK_OVERLAPPING_EVENTS = graphql_tag_1.default `
    query checkOverlappingEvents(
        $listing_id: uuid!
        $from: timestamptz!
        $to: timestamptz!
        $listing_inspection_id: uuid
    ) {
        listing_inspection_aggregate(
            where: {
                _and: {
                    listing_id: { _eq: $listing_id }
                    _or: [
                        { listing: { auction_dateTime: { _gte: $from, _lt: $to } } }
                        {
                            id: { _neq: $listing_inspection_id }
                            _or: [
                                { listing: { auction_dateTime: { _gt: $from, _lt: $to } } }
                                { _and: { from: { _lt: $from }, to: { _gt: $from } } }
                                { _and: { from: { _lt: $to }, to: { _gt: $to } } }
                                { _and: { from: { _gt: $from, _lt: $to } } }
                                { _and: { to: { _gt: $from, _lt: $to } } }
                            ]
                        }
                    ]
                }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;
const CHECK_AUCTION_OVERLAPPING_INSPECTION_EVENTS = graphql_tag_1.default `
    query checkAuctionOverlappingInspectionEvents($listing_id: uuid!, $date: timestamptz!) {
        listing_inspection_aggregate(
            where: {
                _and: { listing_id: { _eq: $listing_id }, _and: [{ from: { _lte: $date } }, { to: { _gt: $date } }] }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;
const GET_LISTING_DETAILS = graphql_tag_1.default `
    query getListingDetails($id: uuid!) {
        listing(where: { id: { _eq: $id } }) {
            id
            propertyDetails_bathRooms
            propertyDetails_bedRooms
            propertyDetails_carSpaces
            propertyDetails_propertyType
            propertyDetails_landArea_value
            propertyDetails_address_streetNumber
            propertyDetails_address_unitNumber
            propertyDetails_address_street
            propertyDetails_address_displayOption
            propertyDetails_address_state
            propertyDetails_address_suburb
            propertyDetails_address_postcode
            saleType
            commercial_status
            publishing_status
            proof_of_ownership_status
            auction_dateTime
            availableFrom
            features
            description
            summary
            price_asking
            propertyDetails_garageSpaces
            propertyDetails_carportSpaces
            propertyDetails_energyEfficiencyRating
            listing_media(order_by: { order: asc }, limit: 1) {
                id
                url
            }
            listing_onboardings {
                finished
                skipped
            }
            user {
                phone_number_verified
            }
            listing_inspections {
                id
            }
            paidOrders: orders(
                where: { _and: [{ order_items: { campaign: { id: { _is_null: false } } } }, { status: { _eq: Paid } }] }
            ) {
                id
            }
            visitedPreview: listing_events_aggregate(where: { event_type: { _eq: visited_preview } }) {
                aggregate {
                    count
                }
            }
            visitedCampaign: listing_events_aggregate(where: { event_type: { _eq: visited_campaign } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;
const UPDATE_LISTING_PUBLISHING_STATUS = graphql_tag_1.default `
    mutation updateListingPublishingStatus($listingId: uuid!, $publishingStatus: publishing_status_type_enum!) {
        update_listing(where: { id: { _eq: $listingId } }, _set: { publishing_status: $publishingStatus }) {
            affected_rows
        }
    }
`;
const INSERT_LISTING_METADATA = graphql_tag_1.default `
    mutation insertListingMetadata($listingId: uuid!, $type: String, $value: String) {
        insert_listing_metadata_one(
            object: { listing_id: $listingId, type: $type, value: $value, is_processed: false }
        ) {
            id
        }
    }
`;
const GET_NON_PROCESSED_LISTING_METADATA = graphql_tag_1.default `
    query getNonProcessedListingMetadata($listingId: uuid!, $type: String) {
        listing_metadata(
            where: { is_processed: { _eq: false }, type: { _eq: $type }, listing_id: { _eq: $listingId } }
            order_by: { created_at: desc }
        ) {
            value
            listing {
                saleType
            }
        }
    }
`;
const MARK_LISTING_METADATA_PROCESSED = graphql_tag_1.default `
    mutation markListingMetadataProcessed($listingId: uuid!, $type: String) {
        update_listing_metadata(
            where: { listing_id: { _eq: $listingId }, type: { _eq: $type } }
            _set: { is_processed: true }
        ) {
            affected_rows
        }
    }
`;
const DELIST_LISTING = graphql_tag_1.default `
    mutation delistListing($listingId: uuid!) {
        delist_from_all_portals(listingId: $listingId) {
            success
            error
        }
    }
`;
const GET_ALL_LISTINGS = graphql_tag_1.default `
    query getAllListings {
        listing(order_by: { updated_at: desc }) {
            id
            listing_onboardings {
                step_completed
            }
            orders {
                status
                processed_by_id
                order_items {
                    campaign_id
                }
            }
        }
    }
`;
const CREATE_EMPTY_LISTING_BY_ADMIN_MUTATION = graphql_tag_1.default `
    mutation createEmptyListingByAdmin($ownerId: uuid!, $portalStatuses: [listing_publish_status_insert_input!]!) {
        insert_listing(objects: { owner_id: $ownerId, listing_publish_statuses: { data: $portalStatuses } }) {
            affected_rows
            returning {
                id
                created_at
            }
        }
    }
`;
const LISTING_CREATED_AT = graphql_tag_1.default `
    query listingCreatedAt($listingId: uuid!) {
        listing_by_pk(id: $listingId) {
            created_at
        }
    }
`;
