"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const store_1 = require("@ngrx/store");
const fromRouter = require("@ngrx/router-store");
exports.reducers = {
    routerReducer: fromRouter.routerReducer,
};
exports.getRouterState = store_1.createFeatureSelector('routerReducer');
class CustomSerializer {
    serialize(routerState) {
        const { url } = routerState;
        const { queryParams } = routerState.root;
        let state = routerState.root;
        while (state.firstChild) {
            state = state.firstChild;
        }
        const { params } = state;
        return {
            url,
            queryParams,
            params,
        };
    }
}
exports.CustomSerializer = CustomSerializer;
