"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./progress-bar.component.scss.ngstyle");
const i1 = require("@angular/core");
const i2 = require("../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory");
const i3 = require("@angular/material/progress-bar");
const i4 = require("@angular/platform-browser/animations");
const i5 = require("@angular/common");
const i6 = require("./progress-bar.component");
const i7 = require("./progress-bar.service");
var styles_FuseProgressBarComponent = [i0.styles];
var RenderType_FuseProgressBarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FuseProgressBarComponent, data: {} });
exports.RenderType_FuseProgressBarComponent = RenderType_FuseProgressBarComponent;
function View_FuseProgressBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "accent"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], value: [1, "value"], bufferValue: [2, "bufferValue"], mode: [3, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "accent"; var currVal_4 = _co.value; var currVal_5 = _co.bufferValue; var currVal_6 = _co.mode; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FuseProgressBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseProgressBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_FuseProgressBarComponent_0 = View_FuseProgressBarComponent_0;
function View_FuseProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, View_FuseProgressBarComponent_0, RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i6.FuseProgressBarComponent, [i7.FuseProgressBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FuseProgressBarComponent_Host_0 = View_FuseProgressBarComponent_Host_0;
var FuseProgressBarComponentNgFactory = i1.ɵccf("fuse-progress-bar", i6.FuseProgressBarComponent, View_FuseProgressBarComponent_Host_0, {}, {}, []);
exports.FuseProgressBarComponentNgFactory = FuseProgressBarComponentNgFactory;
