import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface BuildInfoDialogDialogData {
    title: string;
    text: SafeHtml | string;
}

@Component({
    selector: 'app-build-info-dialog',
    templateUrl: './build-info-dialog.component.html',
    styleUrls: ['./build-info-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None, // so we can override angular dialog styles in this component
})
export class BuildInfoDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<BuildInfoDialogComponent>,
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: BuildInfoDialogDialogData
    ) {
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
    }

    closeDialog(result: boolean): void {
        this.dialogRef.close(result);
    }

    copyText(text: string): void {
        // solution from: https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
