"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const router_1 = require("@angular/router");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("@angular/router");
class FuseProgressBarService {
    /**
     * Constructor
     *
     * @param {Router} _router
     */
    constructor(_router) {
        this._router = _router;
        // Initialize the service
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Buffer value
     */
    get bufferValue() {
        return this._bufferValue.asObservable();
    }
    setBufferValue(value) {
        this._bufferValue.next(value);
    }
    /**
     * Mode
     */
    get mode() {
        return this._mode.asObservable();
    }
    setMode(value) {
        this._mode.next(value);
    }
    /**
     * Value
     */
    get value() {
        return this._value.asObservable();
    }
    setValue(value) {
        this._value.next(value);
    }
    /**
     * Visible
     */
    get visible() {
        return this._visible.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    _init() {
        // Initialize the behavior subjects
        this._bufferValue = new rxjs_1.BehaviorSubject(0);
        this._mode = new rxjs_1.BehaviorSubject('indeterminate');
        this._value = new rxjs_1.BehaviorSubject(0);
        this._visible = new rxjs_1.BehaviorSubject(false);
        // Subscribe to the router events to show/hide the loading bar
        this._router.events.pipe(operators_1.filter((event) => event instanceof router_1.NavigationStart)).subscribe(() => {
            this.show();
        });
        this._router.events
            .pipe(operators_1.filter((event) => event instanceof router_1.NavigationEnd ||
            event instanceof router_1.NavigationError ||
            event instanceof router_1.NavigationCancel))
            .subscribe(() => {
            this.hide();
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Show the progress bar
     */
    show() {
        this._visible.next(true);
    }
    /**
     * Hide the progress bar
     */
    hide() {
        this._visible.next(false);
    }
}
FuseProgressBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseProgressBarService_Factory() { return new FuseProgressBarService(i0.ɵɵinject(i1.Router)); }, token: FuseProgressBarService, providedIn: "root" });
exports.FuseProgressBarService = FuseProgressBarService;
