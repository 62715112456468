import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtService } from './jwt.service';
import { FuseUtils } from '@fuse/utils';
import * as jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class UserPagesGuardService {
    constructor(private authService: JwtService, private router: Router) {}
    canActivate(): boolean {
        if (sessionStorage.getItem('impersonateToken')) {
            const impersonateToken = jwt_decode(sessionStorage.getItem('impersonateToken'));
            if (impersonateToken) {
                return true;
            }
        }
        if (this.authService.isAdmin()) {
            this.router.navigate([FuseUtils._DEFAULT_ADMIN_PAGE_PATH]);
            return false;
        }
        return true;
    }
}
