"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const core_1 = require("@angular/core");
const material_1 = require("@angular/material");
const basic_dialog_component_1 = require("app/shared/basic-dialog/basic-dialog.component");
const operators_1 = require("rxjs/operators");
const base_component_1 = require("app/shared/base/base.component");
class LogoutOverlayComponent extends base_component_1.BaseComponent {
    constructor(dialogRef, data, router, dialog, _jwt, wsClient) {
        super();
        this.dialogRef = dialogRef;
        this.data = data;
        this.router = router;
        this.dialog = dialog;
        this._jwt = _jwt;
        this.wsClient = wsClient;
    }
    ngOnInit() {
        this.userInfo = this.data.userInfo;
        this.theme = this.data.theme;
        this.isAdmin = this.data.isAdmin;
    }
    logout() {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(basic_dialog_component_1.BasicDialogComponent, {
                width: '480px',
                data: {
                    okBtnText: 'Confirm',
                    title: 'Important!',
                    text: 'Are you sure you want to logout?',
                },
            });
            dialogRef
                .afterClosed()
                .pipe(operators_1.takeUntil(this.destroyed$))
                .subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (result) {
                    this.dialogRef.close();
                    const isAdminImpersonatingUser = this._jwt.isAdminImpersonatingUser();
                    this._jwt.deleteSession(true);
                    this.wsClient.close();
                    if (!isAdminImpersonatingUser) {
                        this.router.navigate(['/auth/login']);
                    }
                    else {
                        this.router.navigate(['/admin/dashboard']);
                    }
                }
            }));
        });
    }
}
exports.LogoutOverlayComponent = LogoutOverlayComponent;
