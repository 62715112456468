<div class="admin-super-panel" fxLayout="column" fusePerfectScrollbar *ngIf="(listing$ | async) as listing">
    <div class="header" fxFlex="0 1 *" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title text-truncate" matTooltip="{{listing.summary}}">
            Admin actions{{(listing.summary && listing.summary.trim()) ? ' - ' + listing.summary.trim() : ''}}
        </span>
        <button class="line-height-22" mat-icon-button (click)="toggleSidebarOpen('admin-superpower-panel')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="content" fxLayout="column" fxFlex="1 1 auto">
        <div fxLayout="row" fxLayoutGap="26px" fxLayoutAlign="none end">
            <h2>Order summary</h2>
            <button *ngIf="allData && allData.order && allData.order.order_items.length > 0" class="mb-12"
                mat-icon-button (click)="edit(allData.order, listing.id)">
                <mat-icon class="material-icons-round">edit</mat-icon>
            </button>
        </div>
        <div class="bg-white p-16" fxLayout="column">
            <ng-container *ngIf="(allData && allData.order && allData.order.order_items.length > 0) else emptyOrder">
                <h3 *ngIf="allData.campaignName" class="text-truncate" matTooltip="{{allData.campaignName}}">
                    {{allData.campaignName}} <br/><span *ngIf="allData.totalAddons > 0">+
                    {{allData.totalAddons}} add-ons</span></h3>
                <h3 *ngIf="!allData.campaignName && allData.totalAddons > 0" class="text-truncate">
                    <span>Add ons ({{allData.totalAddons}})</span>
                </h3>

                <div fxLayout="row">
                    <h4>Total:
                        <strong [hidden]="!disableOrderTotal">{{ allData.order.order_total / 100 | currency:
                            'AUD':'symbol-narrow':'1.0-0'}}</strong>
                    </h4>
                    <form [formGroup]="form" [hidden]="disableOrderTotal">
                        <span class="prefix">$</span>
                        <input type="number" formControlName="totalInput" min="0">
                    </form>
                    <button mat-icon-button class="button-edit" *ngIf="disableOrderTotal"
                        (click)="toggleDisableOrderTotal()">
                        <mat-icon class="material-icons-round">edit</mat-icon>
                    </button>
                    <button mat-icon-button class="button-edit" *ngIf="!disableOrderTotal"
                        (click)="toggleDisableOrderTotal()">
                        <mat-icon class="material-icons-round color-red">close</mat-icon>
                    </button>
                    <button mat-icon-button class="button-edit" *ngIf="!disableOrderTotal" [disabled]="!form.valid"
                        (click)="saveNewOrderTotal()">
                        <mat-icon class="material-icons-round">check</mat-icon>
                    </button>
                </div>
                <mat-error *ngIf="form.get('totalInput').hasError('required')">
                    Order total is required
                </mat-error>
                <mat-error *ngIf="form.get('totalInput').hasError('min')">
                    Invalid order total
                </mat-error>
                <button mat-raised-button color="accent" (click)="clearOrder(allData.order, listing.id)">Clear
                    order
                </button>

            </ng-container>
            <ng-template #emptyOrder>
                <svg class="mt-28 mat-icon-center" width="52" height="59" viewBox="0 0 52 59" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M37.6186 0H5.37409C2.4049 0 0 2.40491 0 5.37409V37.6186C0 40.5878 2.4049 42.9927 5.37409 42.9927H37.6186C40.5878 42.9927 42.9927 40.5878 42.9927 37.6186V5.37409C42.9927 2.40491 40.5878 0 37.6186 0ZM34.9316 24.1834H8.06114V20.6259H34.9316V24.1834ZM24.1834 34.9316H8.06114V31.3741H24.1834V34.9316ZM34.9316 13.4352H8.06114V9.87773H34.9316V13.4352Z"
                        fill="#E8EAF6"/>
                    <path
                        d="M41.3553 31.9037C39.2543 28.1576 33.8622 28.1576 31.7612 31.9037L25.2184 43.5694C23.1622 47.2356 25.812 51.7598 30.0155 51.7598H43.101C47.3045 51.7598 49.9542 47.2356 47.898 43.5693L41.3553 31.9037Z"
                        fill="#E8EAF6" stroke="white" stroke-width="3"/>
                    <rect x="35.4219" y="34.5151" width="2.72489" height="8.17467" fill="white"/>
                    <rect x="35.4219" y="44.5068" width="2.72489" height="2.72489" fill="white"/>
                </svg>
                <p class="mb-28 mt-0">There are no items in the order summary</p>
            </ng-template>
        </div>
        <div #divEmailVerification fxLayout="row" fxLayoutGap="26px" fxLayoutAlign="none end" class="mt-16"
            *ngIf="!userEmailVerified">
            <div class="bg-white p-16 full-width" fxLayout="column">
                <button mat-raised-button color="accent" class="mat-success" (click)="setEmailVerified()">Verify
                    Email
                </button>
            </div>
        </div>

        <h2 fxFlex>Listing actions</h2>
        <div class="bg-white p-16" fxLayout="column" fxLayoutGap="16px">
            <button mat-raised-button color="accent" class="mat-success"
                [disabled]="listing.publishing_status !== 'UNDER_REVIEW'"
                (click)="publishListing(listing)">Publish
            </button>
            <button mat-raised-button color="warn" [disabled]="listing.publishing_status !== 'UNDER_REVIEW'"
                (click)="rejectListing(listing)">Reject
            </button>
            <button mat-stroked-button color="warn" class="border-warn-strong"
                [disabled]="listing.paidOrders.length > 0" (click)="deleteListing(listing)">Delete
            </button>
        </div>
        <!-- Experiments -->
        <form [formGroup]="experimentForm">
            <h2>Experiments</h2>
            <div *ngFor="let exp of experiments">
                <mat-checkbox [formControlName]="exp.name">
                    {{exp.name}}
                </mat-checkbox>
                <div class="experiment-desc">{{exp.desc}}</div>
<!--                <label>-->
<!--                    <input type="checkbox" [formControlName]="exp.name"> {{exp.name}}<br>-->
<!--                    {{exp.desc}}-->
<!--                </label>-->
            </div>
            <div fxLayout="row" class="experiment-buttons">
                <button mat-stroked-button color="warn" (click)="resetExperiments()">Reset</button>
                <button mat-raised-button color="accent" class="mat-success" (click)="saveExperiments()">Save</button>
                <span *ngIf="saveMsg" class="save-msg">{{saveMsg}}</span>
            </div>
        </form>
    </div>
</div>