"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const graphql_tag_1 = require("graphql-tag");
const i0 = require("@angular/core");
const i1 = require("apollo-angular");
class AuthService {
    constructor(apollo) {
        this.apollo = apollo;
    }
    /**
     * Some URLs for Email verification and password reset contains token
     * this method verifies if this token is valid and we can proceed
     * @param hash
     */
    verifyHash(hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: VERIFY_HASH,
                variables: { hash },
            })
                .toPromise();
        });
    }
    signUp(email, firstName, lastName, password, utmSource, utmMedium, utmCampaign, utmTerm, utmContent, submittedVia, gaClientId, gclid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: SIGN_UP_MUTATION,
                variables: {
                    email,
                    firstName,
                    lastName,
                    password,
                    utmSource,
                    utmMedium,
                    utmCampaign,
                    utmTerm,
                    utmContent,
                    submittedVia,
                    gaClientId,
                    gclid,
                },
            })
                .toPromise();
        });
    }
    UpdateUserPasswordPhone(userId, password, mobileNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_USER_PASSWORD_PHONE_NUMBER,
                variables: { userId, mobileNumber, password },
            })
                .toPromise();
        });
    }
    setPassword(password, verifyToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: SET_PASSWORD_MUTATION,
                variables: { password, verifyToken },
            })
                .toPromise();
        });
    }
    verifyUserEmail(hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: VERIFY_USER_EMAIL,
                variables: { hash },
            })
                .toPromise();
        });
    }
    resetPassword(password, verifyToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: RESET_PASSWORD_MUTATION,
                variables: { password, verifyToken },
            })
                .toPromise();
        });
    }
    mobileVerification(mobileNumber, type, channel) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: MOBILE_VERIFICATION_QUERY,
                variables: { mobileNumber, type, channel: channel || 'sms' },
            })
                .toPromise();
        });
    }
    sendEmail(email, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: SEND_EMAIL_QUERY,
                variables: { email, type },
            })
                .toPromise();
        });
    }
    codeVerification(mobileNumber, code, verificationSid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: CODE_VERIFICATION_QUERY,
                variables: { mobileNumber, code, verificationSid },
            })
                .toPromise();
        });
    }
    signIn(email, password, rememberLogin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: SIGN_IN_QUERY,
                variables: { email, password, rememberLogin },
            })
                .toPromise();
        });
    }
    impersonateAsUserWithId(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: IMPERSONATE_SIGN_IN,
                variables: {
                    userId,
                },
            })
                .toPromise();
        });
    }
    impersonateSignOut() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: IMPERSONATE_SIGN_OUT,
            })
                .toPromise();
        });
    }
    googleAuthUrl(webAppUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GOOGLE_AUTH_URL,
                variables: {
                    webAppUrl,
                },
            })
                .toPromise();
        });
    }
    loginWithGoogle(webAppRedirectUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const googleResp = yield this.googleAuthUrl(webAppRedirectUrl);
                if (googleResp.data && googleResp.data.google_auth_url && googleResp.data.google_auth_url.url) {
                    window.location.href = googleResp.data.google_auth_url.url;
                }
                else {
                    alert(googleResp.data.google_auth_url.error);
                }
            }
            catch (e) {
                alert('Unable to connect to the database');
            }
        });
    }
    resendEmailVerification(redirectUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: RESEND_EMAIL_VERIFICATION_LINK_QUERY,
                variables: { redirectUrl },
            })
                .toPromise();
        });
    }
    updateUserUTMParams(userId, utmSource, utmMedium, utmCampaign, utmTerm, utmContent, submittedVia, gclid) {
        return this.apollo.mutate({
            mutation: UPDATE_USER_UTM_PARAMS,
            variables: {
                userId,
                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmContent,
                submittedVia,
                gclid,
            },
        });
    }
    updateGaClientId(userId, gaClientId) {
        return this.apollo.mutate({
            mutation: UPDATE_GA_CLIENT_ID,
            variables: {
                userId,
                gaClientId,
            },
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Apollo)); }, token: AuthService, providedIn: "root" });
exports.AuthService = AuthService;
//
// Queries moved here, to keep the most important at the top
//
const SIGN_UP_MUTATION = graphql_tag_1.default `
    mutation signUp(
        $email: String!
        $firstName: String!
        $lastName: String!
        $password: String!
        $utmCampaign: String
        $utmContent: String
        $utmMedium: String
        $utmSource: String
        $utmTerm: String
        $submittedVia: String
        $gaClientId: String
        $gclid: String
    ) {
        sign_up(
            email: $email
            firstName: $firstName
            lastName: $lastName
            password: $password
            utmCampaign: $utmCampaign
            utmContent: $utmContent
            utmMedium: $utmMedium
            utmSource: $utmSource
            utmTerm: $utmTerm
            submittedVia: $submittedVia
            gaClientId: $gaClientId
            gclid: $gclid
        ) {
            jwt
            redirectUrl
        }
    }
`;
const SET_PASSWORD_MUTATION = graphql_tag_1.default `
    mutation setPassword($password: String!, $verifyToken: String!) {
        set_password(password: $password, verifyToken: $verifyToken) {
            jwt
        }
    }
`;
const RESET_PASSWORD_MUTATION = graphql_tag_1.default `
    mutation resetPassword($password: String!, $verifyToken: String!) {
        reset_password(password: $password, verifyToken: $verifyToken) {
            success
            error
        }
    }
`;
const VERIFY_USER_EMAIL = graphql_tag_1.default `
    mutation verifyUserEmail($hash: String!) {
        verify_user_email(hash: $hash) {
            success
            message
            data
        }
    }
`;
const MOBILE_VERIFICATION_QUERY = graphql_tag_1.default `
    query mobileVerification($mobileNumber: String!, $type: String!, $channel: String!) {
        verify_mobile_number(mobileNumber: $mobileNumber, type: $type, channel: $channel) {
            serviceSid
            status
            to
            channel
            error
        }
    }
`;
const CODE_VERIFICATION_QUERY = graphql_tag_1.default `
    query codeVerification($mobileNumber: String!, $code: String!, $verificationSid: String!) {
        mobile_code_verification(mobileNumber: $mobileNumber, code: $code, sid: $verificationSid) {
            status
            error
            jwt
        }
    }
`;
const SEND_EMAIL_QUERY = graphql_tag_1.default `
    query sendEmail($email: String!, $type: String!) {
        send_email(email: $email, type: $type) {
            success
            error
        }
    }
`;
const SIGN_IN_QUERY = graphql_tag_1.default `
    query signIn($email: String!, $password: String!, $rememberLogin: Boolean!) {
        sign_in(email: $email, password: $password, rememberLogin: $rememberLogin) {
            jwt
        }
    }
`;
const IMPERSONATE_SIGN_IN = graphql_tag_1.default `
    query impersonateSignIn($userId: uuid!) {
        impersonate_sign_in(userId: $userId) {
            jwt
        }
    }
`;
const IMPERSONATE_SIGN_OUT = graphql_tag_1.default `
    query impersonateSignOut {
        impersonate_logout {
            jwt
        }
    }
`;
const GOOGLE_AUTH_URL = graphql_tag_1.default `
    query getGoogleAuthUrl($webAppUrl: String!) {
        google_auth_url(webAppUrl: $webAppUrl) {
            url
            error
        }
    }
`;
const RESEND_EMAIL_VERIFICATION_LINK_QUERY = graphql_tag_1.default `
    query resendEmailVerificationLink($redirectUrl: String) {
        resend_email_verification_link(redirectUrl: $redirectUrl) {
            success
            error
        }
    }
`;
const VERIFY_HASH = graphql_tag_1.default `
    query verifyHash($hash: String!) {
        verify_hash(hash: $hash) {
            success
            message
            data
        }
    }
`;
const UPDATE_USER_UTM_PARAMS = graphql_tag_1.default `
    mutation updateUserUTMParams(
        $userId: uuid!
        $utmCampaign: String
        $utmContent: String
        $utmMedium: String
        $utmSource: String
        $utmTerm: String
        $submittedVia: String
        $gclid: String
    ) {
        update: update_user(
            where: { id: { _eq: $userId }, _or: [{ utm_source: { _is_null: true } }, { utm_source: { _ilike: "" } }] }
            _set: {
                utm_campaign: $utmCampaign
                utm_content: $utmContent
                utm_medium: $utmMedium
                utm_source: $utmSource
                utm_term: $utmTerm
                gclid: $gclid
            }
        ) {
            affected_rows
        }
        update_submittedvia: update_user(
            where: {
                id: { _eq: $userId }
                _or: [{ submitted_via: { _is_null: true } }, { submitted_via: { _ilike: "" } }]
            }
            _set: { submitted_via: $submittedVia }
        ) {
            affected_rows
        }
        update_gclid: update_user(
            where: { id: { _eq: $userId }, _or: [{ gclid: { _is_null: true } }, { gclid: { _ilike: "" } }] }
            _set: { gclid: $gclid }
        ) {
            affected_rows
        }
    }
`;
const UPDATE_GA_CLIENT_ID = graphql_tag_1.default `
    mutation updateGaClientId($userId: uuid!, $gaClientId: String) {
        update_ga_client_id: update_user(
            where: {
                id: { _eq: $userId }
                _or: [{ ga_client_id: { _is_null: true } }, { ga_client_id: { _ilike: "" } }]
            }
            _set: { ga_client_id: $gaClientId }
        ) {
            affected_rows
        }
    }
`;
const UPDATE_USER_PASSWORD_PHONE_NUMBER = graphql_tag_1.default `
    mutation updateUserPasswordPhoneNumber($userId: String!, $mobileNumber: String!, $password: String!) {
        update_user_password_phone(userId: $userId, phone_number: $mobileNumber, password: $password) {
            message
        }
    }
`;
