"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./profile-circle.component.scss.shim.ngstyle");
const i1 = require("@angular/core");
const i2 = require("@angular/common");
const i3 = require("./profile-circle.component");
var styles_ProfileCircleComponent = [i0.styles];
var RenderType_ProfileCircleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileCircleComponent, data: {} });
exports.RenderType_ProfileCircleComponent = RenderType_ProfileCircleComponent;
function View_ProfileCircleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-top-nav__lock-icon-big"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["height", "11.67"], ["src", "../../../../assets/icons/lock_white.svg"], ["width", "8.89"]], null, null, null, null, null))], null, null); }
function View_ProfileCircleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "avatar-big-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "app-top-nav__avatar-big"], ["fxFlex", "0 1 auto"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background": 0, "color": 1 }), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileCircleComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, ((_co.theme == null) ? null : _co.theme.backgroundColor), ((_co.theme == null) ? null : _co.theme.textColour)); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.isAdmin; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.userInfo == null) ? null : _co.userInfo.initials); _ck(_v, 5, 0, currVal_1); }); }
exports.View_ProfileCircleComponent_0 = View_ProfileCircleComponent_0;
function View_ProfileCircleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-circle", [], null, null, null, View_ProfileCircleComponent_0, RenderType_ProfileCircleComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProfileCircleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProfileCircleComponent_Host_0 = View_ProfileCircleComponent_Host_0;
var ProfileCircleComponentNgFactory = i1.ɵccf("profile-circle", i3.ProfileCircleComponent, View_ProfileCircleComponent_Host_0, { theme: "theme", userInfo: "userInfo", isAdmin: "isAdmin" }, {}, []);
exports.ProfileCircleComponentNgFactory = ProfileCircleComponentNgFactory;
