"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const basic_dialog_component_1 = require("app/shared/basic-dialog/basic-dialog.component");
const operators_1 = require("rxjs/operators");
const loader_component_1 = require("app/shared/loader/loader.component");
const build_info_dialog_component_1 = require("../shared/build-info-dialog/build-info-dialog.component");
const cancel_discard_save_dialog_component_1 = require("../shared/cancel-discard-save-dialog/cancel-discard-save-dialog.component");
const feedback_dialog_component_1 = require("../main/admin/dashboard/shared/feedback-dialog/feedback-dialog.component");
const input_dialog_component_1 = require("app/main/admin/dashboard/shared/input-dialog/input-dialog.component");
const i0 = require("@angular/core");
const i1 = require("@angular/material/dialog");
class DialogService {
    constructor(dialog) {
        this.dialog = dialog;
        this.isDialogOpened = false;
    }
    openConfirm(title, text, okBtnText = 'Ok', cancelBtnText = 'Cancel', hideCancel = false, allowClose = true, disableClose = false) {
        return new Promise((resolve) => {
            this.confirmRef = this.dialog.open(basic_dialog_component_1.BasicDialogComponent, {
                width: '480px',
                disableClose,
                data: {
                    okBtnText,
                    title,
                    text,
                    cancelBtnText,
                    hideCancel,
                    allowClose,
                },
                restoreFocus: false,
            });
            this.confirmRef
                .afterClosed()
                .pipe(operators_1.take(1))
                .subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeConfirm() {
        if (this.confirmRef) {
            this.confirmRef.close();
        }
    }
    showAlert(title, text, okBtnText = 'Ok') {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(basic_dialog_component_1.BasicDialogComponent, {
                width: '480px',
                data: {
                    okBtnText,
                    title,
                    text,
                    hideCancel: true,
                    allowClose: true,
                },
            });
            dialogRef
                .afterClosed()
                .pipe(operators_1.take(1))
                .subscribe((result) => {
                resolve(result);
            });
        });
    }
    showLoading(title, content = '') {
        if (this.loaderRef && this.isDialogOpened) {
            this.loaderRef.componentInstance.title = title;
            this.loaderRef.componentInstance.content = content;
        }
        else {
            this.isDialogOpened = true;
            this.loaderRef = this.dialog.open(loader_component_1.LoaderComponent, {
                disableClose: true,
                width: '480px',
                data: {
                    title,
                    content,
                },
            });
            this.loaderRef
                .afterClosed()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                this.isDialogOpened = false;
                this.loaderRef = null;
            });
        }
    }
    hideLoading() {
        if (this.loaderRef) {
            this.loaderRef.close();
        }
    }
    openBuildInfoDialog(text) {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(build_info_dialog_component_1.BuildInfoDialogComponent, {
                width: '480px',
                panelClass: 'cdk-overlay-pane--build-info-dialog',
                data: {
                    title: 'Build info',
                    text,
                },
            });
            dialogRef
                .afterClosed()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                resolve();
            });
        });
    }
    showDiscardSaveOrCancel(title, text) {
        return new Promise((resolve) => {
            const dialogRef = this.dialog.open(cancel_discard_save_dialog_component_1.CancelDiscardSaveDialogComponent, {
                width: '480px',
                disableClose: true,
                data: {
                    title,
                    text,
                },
            });
            dialogRef
                .afterClosed()
                .pipe(operators_1.take(1))
                .subscribe((result) => {
                resolve(result);
            });
        });
    }
    /**
     * Resolves to an empty string if user clicked OK
     * Resolves to non empty string if user clicked OK and entered some data
     * Resolves to false if user canceled dialog
     */
    openFeedback(config) {
        return new Promise((resolve) => {
            const dialogData = {
                okBtnText: config.okBtnText || 'Ok',
                title: config.title || '',
                text: config.text || '',
                cancelBtnText: config.cancelBtnText || 'Cancel',
                hideCancel: config.hideCancel || false,
                allowClose: config.allowClose || true,
                isCheckboxVisible: config.isCheckboxVisible,
                checkboxLabel: config.checkboxLabel,
                hideFeedbackInput: config.hideFeedbackInput,
            };
            this.feedbackRef = this.dialog.open(feedback_dialog_component_1.FeedbackDialogComponent, {
                width: '480px',
                data: dialogData,
            });
            this.feedbackRef
                .afterClosed()
                .pipe(operators_1.take(1))
                .subscribe((result) => {
                resolve(result);
            });
        });
    }
    openInput(config) {
        return new Promise((resolve) => {
            const dialogData = {
                okBtnText: config.okBtnText || 'Ok',
                title: config.title || '',
                text: config.text || '',
                cancelBtnText: config.cancelBtnText || 'Cancel',
                hideCancel: config.hideCancel || false,
                allowClose: config.allowClose || true,
                isCheckboxVisible: config.isCheckboxVisible,
                checkboxLabel: config.checkboxLabel,
                hideInput: config.hideInput,
            };
            this.inpurRef = this.dialog.open(input_dialog_component_1.InputDialogComponent, {
                width: '480px',
                data: dialogData,
            });
            this.inpurRef
                .afterClosed()
                .pipe(operators_1.take(1))
                .subscribe((result) => {
                resolve(result);
            });
        });
    }
}
DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
exports.DialogService = DialogService;
