"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./group.component.scss.shim.ngstyle");
const i1 = require("@angular/core");
const i2 = require("./group.component");
const i3 = require("../../navigation.service");
const i4 = require("../collapsable/collapsable.component.ngfactory");
const i5 = require("../collapsable/collapsable.component");
const i6 = require("@angular/router");
const i7 = require("../item/item.component.ngfactory");
const i8 = require("../item/item.component");
const i9 = require("@angular/common");
const i10 = require("@ngx-translate/core");
var styles_FuseNavVerticalGroupComponent = [i0.styles];
var RenderType_FuseNavVerticalGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseNavVerticalGroupComponent, data: {} });
exports.RenderType_FuseNavVerticalGroupComponent = RenderType_FuseNavVerticalGroupComponent;
function View_FuseNavVerticalGroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, View_FuseNavVerticalGroupComponent_0, RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 245760, null, 0, i2.FuseNavVerticalGroupComponent, [i1.ChangeDetectorRef, i3.FuseNavigationService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavVerticalGroupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-collapsable", [], [[8, "className", 0], [2, "open", null]], null, null, i4.View_FuseNavVerticalCollapsableComponent_0, i4.RenderType_FuseNavVerticalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i5.FuseNavVerticalCollapsableComponent, [i1.ChangeDetectorRef, i3.FuseNavigationService, i6.Router], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavVerticalGroupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-item", [], [[8, "className", 0]], null, null, i7.View_FuseNavVerticalItemComponent_0, i7.RenderType_FuseNavVerticalItemComponent)), i1.ɵdid(1, 245760, null, 0, i8.FuseNavVerticalItemComponent, [i1.ChangeDetectorRef, i3.FuseNavigationService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavVerticalGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_3)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_4)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_5)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapsable"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavVerticalGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "group-title"]], null, null, null, null, null)), i1.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "hint-text"]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i10.TranslateDirective, [i10.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "group-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_2)), i1.ɵdid(9, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group-title"; var currVal_1 = _co.item.classes; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.item.translate; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.item.children; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.item.title; _ck(_v, 6, 0, currVal_3); }); }
function View_FuseNavVerticalGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_FuseNavVerticalGroupComponent_0 = View_FuseNavVerticalGroupComponent_0;
function View_FuseNavVerticalGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, View_FuseNavVerticalGroupComponent_0, RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 245760, null, 0, i2.FuseNavVerticalGroupComponent, [i1.ChangeDetectorRef, i3.FuseNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
exports.View_FuseNavVerticalGroupComponent_Host_0 = View_FuseNavVerticalGroupComponent_Host_0;
var FuseNavVerticalGroupComponentNgFactory = i1.ɵccf("fuse-nav-vertical-group", i2.FuseNavVerticalGroupComponent, View_FuseNavVerticalGroupComponent_Host_0, { item: "item" }, {}, []);
exports.FuseNavVerticalGroupComponentNgFactory = FuseNavVerticalGroupComponentNgFactory;
