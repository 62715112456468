"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["h2{font-family:DM Sans,sans-serif;font-style:normal;font-weight:400;font-size:20px;line-height:26px;letter-spacing:.18px;color:#000d52;-webkit-margin-before:32px;margin-block-start:32px;-webkit-margin-after:22px;margin-block-end:22px}h3{font-family:DM Sans,sans-serif;font-style:normal;font-weight:500;font-size:16px;line-height:24px;letter-spacing:.18px;color:#000d52;-webkit-margin-before:0;margin-block-start:0;-webkit-margin-after:24px;margin-block-end:24px}h4{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:400;font-size:15px;line-height:24px;letter-spacing:.18px;-webkit-margin-after:24px;margin-block-end:24px;color:rgba(0,0,0,.54)}.text-body1-bold{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:700!important;font-size:14px;line-height:24px;letter-spacing:.18px;color:#000d52}.text-tiny{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:700;font-size:11px;line-height:24px;letter-spacing:.18px}.text-body1,p{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:400;font-size:14px;line-height:24px;letter-spacing:.18px;color:#000d52}.text-caption{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:400;font-size:12px;line-height:18px;letter-spacing:.18px}.text-subheading-2{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:400;font-size:14px;line-height:24px;letter-spacing:.18px}.headline-1-bold{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:700;font-size:24px;line-height:34px;letter-spacing:.18px}.text-subheading-1{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:400;font-size:15px;line-height:24px;letter-spacing:.18px}.text-headline-1-bold{font-family:\"DM Sans\",sans-serif;font-style:normal;font-weight:700;font-size:24px;line-height:34px;letter-spacing:.18px}.heading-1{font-size:20px;line-height:30px;text-align:center;letter-spacing:.18px}fuse-navigation{display:flex;flex:1 0 auto}fuse-navigation>.nav{margin:0;padding:0;width:100%}"];
exports.styles = styles;
