import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface BasicDialogData {
    title: string;
    text: SafeHtml | string;
    okBtnText?: string;
    cancelBtnText?: string;
    hideCancel?: boolean;
    allowClose?: boolean;
}

@Component({
    selector: 'app-basic-dialog',
    templateUrl: './basic-dialog.component.html',
    styleUrls: ['./basic-dialog.component.scss'],
})
export class BasicDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<BasicDialogComponent>,
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: BasicDialogData
    ) {
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }
    }

    closeDialog(result: boolean): void {
        if (this.data.allowClose !== false) {
            this.dialogRef.close(result);
        }
    }
}
