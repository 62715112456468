import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';

export interface InputDialogData {
    title: string;
    text: SafeHtml | string;
    okBtnText?: string;
    cancelBtnText?: string;
    hideCancel?: boolean;
    allowClose?: boolean;
    isCheckboxVisible?: boolean;
    checkboxLabel?: string;
    hideInput?: boolean;
}

@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent {
    form: FormGroup;
    // checkbox is on by default
    checkboxValue = true;

    inputField = new FormControl('');

    constructor(
        public dialogRef: MatDialogRef<InputDialogComponent>,
        private domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: InputDialogData
    ) {
        if (typeof data.text === 'string') {
            data.text = this.domSanitizer.bypassSecurityTrustHtml(data.text);
        }

        // compose reactive form
        this.form = new FormGroup({
            feedback: this.inputField,
        });
    }

    closeDialog(result: boolean, message = ''): void {
        if (this.data.allowClose !== false) {
            this.dialogRef.close({
                result,
                message,
                checkboxValue: this.checkboxValue,
            });
        }
    }

    checkboxChange(): void {
        const control = this.form.get('feedback');
        control.clearValidators();
        if (this.checkboxValue) {
            control.enable();
        } else {
            control.setValue('');
            control.disable();
        }
        control.updateValueAndValidity();
    }
}
