import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { OrderService } from 'app/service/order.service';
import {
    GetUnpaidOrderSubSubscription,
    GetBasicListingDetailsByIdSubscription,
    Order_Status_Enum,
    Experiment_Name_Enum,
    GetExperimentsQuery,
} from 'generated/graphql';
import { ListingService } from 'app/service/listing.service';
import { BaseComponent } from 'app/shared/base/base.component';
import { DialogService } from 'app/service/dialog.service';
import { AdminSuperPanelConfig } from './admin-superpower-panel.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignService } from 'app/service/campaign.service';
import { CampaignCodes } from 'app/main/pages/campaign/campaign.model';
import { UserService } from 'app/service/user.service';
import { ExperimentService } from '../../service/experiment.service';

@Component({
    selector: 'app-admin-superpower-panel',
    templateUrl: './admin-superpower-panel.component.html',
    styleUrls: ['./admin-superpower-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AdminSuperpowerPanelComponent extends BaseComponent implements OnInit {
    listingId$: Observable<string>;
    listing$: Observable<GetBasicListingDetailsByIdSubscription['listing'][0]>;

    allData: {
        campaignName: string;
        totalAddons: number;
        order: GetUnpaidOrderSubSubscription['order'][0];
    };

    private config: AdminSuperPanelConfig;
    disableOrderTotal = true;
    form: FormGroup;
    experimentForm: FormGroup;
    experiments: GetExperimentsQuery['experiment'];
    saveMsg: string;
    listingId: string;
    userEmailVerified: boolean;
    userId: string;
    experimentNames: Experiment_Name_Enum[];
    currentUserExperiments: Experiment_Name_Enum[];

    @ViewChild('divEmailVerification', { static: false })
    private divEmailVerification: ElementRef;

    constructor(
        private listingService: ListingService,
        private orderService: OrderService,
        private router: Router,
        private _dialogService: DialogService,
        private _fuseSidebarService: FuseSidebarService,
        private campaignService: CampaignService,
        private userService: UserService,
        private experimentService: ExperimentService
    ) {
        super();

        this.listingId$ = this.router.events.pipe(
            filter((e) => e instanceof ActivationEnd && Object.keys(e.snapshot.params).length > 0),
            map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {})),
            map((params) => params.listingId)
            // distinct()
        );

        this.listing$ = this.listingId$.pipe(
            switchMap((id) => {
                this.listingId = id;
                if (this.listingId) {
                    this.getUserEmailVerified();
                }
                return this.listingService.getListingBasicDetailsById(id);
            }),
            map((listing) => listing.data.listing[0])
        );

        this.listingId$
            .pipe(
                takeUntil(this.destroyed$),
                // Get subscription for order update
                switchMap((listingId) => this.orderService.getUnpaidOrderSubscription(listingId)),
                map((order) => {
                    if (order.data.order[0]) {
                        const campaign = order.data.order[0].order_items.filter((o) => o.campaign)[0];

                        return {
                            campaignName: campaign ? campaign.campaign.name : undefined,
                            totalAddons: order.data.order[0].order_items.filter((o) => o.addon).length,
                            order: order.data.order[0],
                        };
                    }
                })
            )
            .subscribe((order) => {
                this.allData = order;
                if (this.allData) {
                    this.form.get('totalInput').setValue(this.allData.order.order_total / 100);
                }
            });

        router.events
            .pipe(
                takeUntil(this.destroyed$),
                filter((e) => e instanceof ActivationEnd && e.snapshot !== undefined && e.snapshot.data !== undefined),
                map((e) => (e instanceof ActivationEnd ? e.snapshot.data : undefined))
            )
            .subscribe((data) => {
                if (data.adminSuperPanelData) {
                    this.config = data.adminSuperPanelData;
                }
            });

        this.form = new FormGroup({
            totalInput: new FormControl(null, [Validators.required, Validators.min(0)]),
        });

        this.experimentForm = new FormGroup({});
    }

    async ngOnInit(): Promise<void> {
        const experiments = await this.experimentService.getExperiments();
        this.experimentNames = experiments.map((e) => e.name);
        this.currentUserExperiments = await this.experimentService.getCurrentUserExperiments();
        this.experimentNames.forEach((expName) => {
            this.experimentForm.addControl(expName, new FormControl(this.currentUserExperiments.includes(expName)));
        });
        this.experiments = experiments;
    }

    async saveExperiments(): Promise<void> {
        await this.experimentService.saveTemporaryExperiments(this.experimentForm.value);
        this.saveMsg = 'Saved!';
        setTimeout(() => {
            this.saveMsg = '';
        }, 2000);
    }

    async resetExperiments(): Promise<void> {
        await this.experimentService.resetTemporaryExperiments();
        this.currentUserExperiments = await this.experimentService.getCurrentUserExperiments();
        this.experimentNames.forEach((expName) => {
            this.experimentForm.get(expName).setValue(this.currentUserExperiments.includes(expName));
        });
        this.saveMsg = 'Done!';
        setTimeout(() => {
            this.saveMsg = '';
        }, 2000);
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
        this.disableOrderTotal = true;
    }

    async edit(order: GetUnpaidOrderSubSubscription['order'][0], listingId: string): Promise<void> {
        const campaign = order.order_items.filter((o) => o.campaign)[0];
        let campaignId;
        if (campaign) {
            if (order.created_from_template) {
                campaignId = (await this.campaignService.getCampaignByCode(CampaignCodes.BUILD_YOUR_OWN)).id;
            } else {
                campaignId = campaign.campaign.id;
            }
        } else {
            const paidOrdersResponse = await this.listingService.getPaidCampaignOrdersByListingId(listingId);

            if (paidOrdersResponse && paidOrdersResponse.data.order.length > 0) {
                campaignId = paidOrdersResponse.data.order[0].order_items.filter((o) => o.campaign_id)[0].campaign_id;
            }
        }
        await this.router.navigate(['/pages/listing/' + listingId + '/campaign/' + campaignId + '/campaign']);
    }

    async clearOrder(order: GetUnpaidOrderSubSubscription['order'][0], listingId: string): Promise<void> {
        if (order.status === Order_Status_Enum.Open || order.status === Order_Status_Enum.Unpaid) {
            if (
                await this.openConfirm(
                    'Clear order confirmation',
                    this.config && this.config.clearOrderMessage
                        ? this.config.clearOrderMessage
                        : 'This campaign and all add-ons selected will be removed from this order summary.'
                )
            ) {
                await this.orderService.removeAllOrderItemsFromOrder(order.id);

                if (this.config && this.config.redirectToCampaignRecommendationPage) {
                    const campaign = order.order_items.filter((o) => o.campaign);

                    if (campaign && campaign.length > 0) {
                        await this.router.navigate([
                            '/pages/listing/' + listingId + '/campaign/' + campaign[0].campaign.id,
                        ]);
                    } else {
                        // if we are here, then it is addon order. hence navigate to listing details page
                        await this.router.navigate(['/pages/wizard/' + listingId]);
                    }
                }
            }
        }
    }

    async publishListing(listing: GetBasicListingDetailsByIdSubscription['listing'][0]): Promise<void> {
        if (
            await this.openConfirm(
                'Publish listing confirmation',
                'This listing status will appear as ‘pending publishing’ until it is published to the listing portals.'
            )
        ) {
            await this.listingService.pendingPublishListing(listing.id);
        }
    }

    async rejectListing(listing: GetBasicListingDetailsByIdSubscription['listing'][0]): Promise<void> {
        const dialogResult = await this._dialogService.openInput({
            title: 'Confirm',
            text: 'This listing status will appear as rejected until the user makes corrections and publishes again.',
            isCheckboxVisible: true,
            checkboxLabel: 'Send email to the user',
        });
        if (dialogResult.result) {
            this._dialogService.showLoading('Updating listing...');
            await this.listingService.failPublishListing(listing.id, dialogResult.checkboxValue, dialogResult.message);

            this._dialogService.hideLoading();
        }
    }

    async deleteListing(listing: GetBasicListingDetailsByIdSubscription['listing'][0]): Promise<void> {
        if (await this.openConfirm('Delete listing?', 'This listing will be deleted from our system')) {
            await this.listingService.updateListingStatusById(listing.id, 'deactivated');
            await this.router.navigate(['/pages/listings-management']);
        }
    }

    private openConfirm(title: string, text: string): Promise<boolean> {
        return this._dialogService.openConfirm(title, text, 'Confirm', 'Cancel');
    }

    async saveNewOrderTotal(): Promise<void> {
        this._dialogService.showLoading('Updating order total');
        const newTotal = this.form.get('totalInput').value;
        this.disableOrderTotal = !this.disableOrderTotal;
        await this.orderService.updateOrderTotalByOrderId(this.allData.order.id, newTotal * 100);
        this._dialogService.hideLoading();
    }

    toggleDisableOrderTotal(): void {
        this.disableOrderTotal = !this.disableOrderTotal;
        this.form.get('totalInput').setValue(this.allData.order.order_total / 100);
    }

    async getUserEmailVerified(): Promise<void> {
        const userData = (await this.listingService.getListingUserId(this.listingId)).data.listing[0].user;
        this.userEmailVerified = userData.email_verified;
        this.userId = userData.id;
    }

    async setEmailVerified(): Promise<void> {
        if (
            await this.openConfirm(
                'Email Verification confirmation',
                `Do you want to mark this user's email as verified?`
            )
        ) {
            const result = await this.userService.updateUserEmailVerification(this.userId, true);
            if (result.data.update_user_email_verification.affected_rows > 0) {
                this.divEmailVerification.nativeElement.style.display = 'none';
                await this._dialogService.showAlert('Important', `User's email has been marked as verified`);
            }
        }
    }
}
