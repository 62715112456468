"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./cancel-discard-save-dialog.component.scss.shim.ngstyle");
const i1 = require("@angular/core");
const i2 = require("@angular/material/dialog");
const i3 = require("@angular/flex-layout/extended");
const i4 = require("@angular/flex-layout/core");
const i5 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
const i6 = require("@angular/material/button");
const i7 = require("@angular/cdk/a11y");
const i8 = require("@angular/platform-browser/animations");
const i9 = require("./cancel-discard-save-dialog.component");
const i10 = require("@angular/platform-browser");
var styles_CancelDiscardSaveDialogComponent = [i0.styles];
var RenderType_CancelDiscardSaveDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CancelDiscardSaveDialogComponent, data: {} });
exports.RenderType_CancelDiscardSaveDialogComponent = RenderType_CancelDiscardSaveDialogComponent;
function View_CancelDiscardSaveDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "headline-1-bold mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "cancel-discard-save-dialog__text mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 25, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""], ["style", "margin-bottom: -8px"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "cancel-discard-save-dialog__desktop"], ["fxHide.xs", ""], ["fxShow.gt-xs", ""]], null, null, null, null, null)), i1.ɵdid(8, 4866048, null, 0, i3.DefaultShowHideDirective, [i1.ElementRef, i3.ShowHideStyleBuilder, i4.StyleUtils, i4.MediaMarshaller, i4.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i4.SERVER_TOKEN]], { "fxShow.gt-xs": [0, "fxShow.gt-xs"], "fxHide.xs": [1, "fxHide.xs"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "cancel-discard-save-dialog__cancel-btn"], ["color", "accent"], ["mat-button", ""], ["tabindex", "1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(_co.dialogStates.Cancel) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Cancel "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "mr-16"], ["color", "accent"], ["mat-stroked-button", ""], ["style", "margin-left: auto"], ["tabindex", "2"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(_co.dialogStates.Discard) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Discard changes "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""], ["tabindex", "3"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(_co.dialogStates.Save) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Save and continue "])), (_l()(), i1.ɵeld(18, 0, null, null, 12, "div", [["class", "cancel-discard-save-dialog__mobile"], ["fxHide.gt-xs", ""], ["fxShow.xs", ""]], null, null, null, null, null)), i1.ɵdid(19, 4866048, null, 0, i3.DefaultShowHideDirective, [i1.ElementRef, i3.ShowHideStyleBuilder, i4.StyleUtils, i4.MediaMarshaller, i4.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i4.SERVER_TOKEN]], { "fxShow.xs": [0, "fxShow.xs"], "fxHide.gt-xs": [1, "fxHide.gt-xs"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "cancel-discard-save-dialog__save-mobile-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""], ["tabindex", "1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(_co.dialogStates.Save) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Save and continue "])), (_l()(), i1.ɵeld(24, 0, null, null, 6, "div", [["class", "cancel-discard-save-dialog__cancel-discard-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "button", [["color", "accent"], ["mat-stroked-button", ""], ["tabindex", "2"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(_co.dialogStates.Discard) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(26, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Discard changes "])), (_l()(), i1.ɵeld(28, 0, null, null, 2, "button", [["class", "cancel-discard-save-dialog__cancel-btn"], ["color", "accent"], ["mat-button", ""], ["tabindex", "3"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(_co.dialogStates.Cancel) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(29, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Cancel "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = ""; var currVal_4 = ""; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_7 = "accent"; _ck(_v, 10, 0, currVal_7); var currVal_10 = "accent"; _ck(_v, 13, 0, currVal_10); var currVal_13 = "accent"; _ck(_v, 16, 0, currVal_13); var currVal_14 = ""; var currVal_15 = ""; _ck(_v, 19, 0, currVal_14, currVal_15); var currVal_18 = "accent"; _ck(_v, 22, 0, currVal_18); var currVal_21 = "accent"; _ck(_v, 26, 0, currVal_21); var currVal_24 = "accent"; _ck(_v, 29, 0, currVal_24); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.text; _ck(_v, 3, 0, currVal_2); var currVal_5 = (i1.ɵnov(_v, 10).disabled || null); var currVal_6 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 13).disabled || null); var currVal_9 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_8, currVal_9); var currVal_11 = (i1.ɵnov(_v, 16).disabled || null); var currVal_12 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_11, currVal_12); var currVal_16 = (i1.ɵnov(_v, 22).disabled || null); var currVal_17 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); _ck(_v, 21, 0, currVal_16, currVal_17); var currVal_19 = (i1.ɵnov(_v, 26).disabled || null); var currVal_20 = (i1.ɵnov(_v, 26)._animationMode === "NoopAnimations"); _ck(_v, 25, 0, currVal_19, currVal_20); var currVal_22 = (i1.ɵnov(_v, 29).disabled || null); var currVal_23 = (i1.ɵnov(_v, 29)._animationMode === "NoopAnimations"); _ck(_v, 28, 0, currVal_22, currVal_23); }); }
exports.View_CancelDiscardSaveDialogComponent_0 = View_CancelDiscardSaveDialogComponent_0;
function View_CancelDiscardSaveDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cancel-discard-save-dialog", [], null, null, null, View_CancelDiscardSaveDialogComponent_0, RenderType_CancelDiscardSaveDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.CancelDiscardSaveDialogComponent, [i2.MatDialogRef, i10.DomSanitizer, i2.MAT_DIALOG_DATA], null, null)], null, null); }
exports.View_CancelDiscardSaveDialogComponent_Host_0 = View_CancelDiscardSaveDialogComponent_Host_0;
var CancelDiscardSaveDialogComponentNgFactory = i1.ɵccf("app-cancel-discard-save-dialog", i9.CancelDiscardSaveDialogComponent, View_CancelDiscardSaveDialogComponent_Host_0, {}, {}, []);
exports.CancelDiscardSaveDialogComponentNgFactory = CancelDiscardSaveDialogComponentNgFactory;
