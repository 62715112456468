"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const graphql_tag_1 = require("graphql-tag");
const i0 = require("@angular/core");
const i1 = require("apollo-angular");
const GET_REASON_SELLING = graphql_tag_1.default `
    query GetReasonSelling($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            reason_selling
            reason_selling_other
            listing {
                propertyDetails_propertyType
            }
        }
    }
`;
const UPDATE_REASON_SELLING = graphql_tag_1.default `
    mutation UpdateReasonSelling(
        $listingId: uuid!
        $reasonSelling: onboarding_reason_selling_enum
        $reasonSellingOther: String
    ) {
        update_listing_onboarding(
            where: { listing_id: { _eq: $listingId } }
            _set: { reason_selling: $reasonSelling, reason_selling_other: $reasonSellingOther }
        ) {
            returning {
                id
            }
        }
    }
`;
const GET_TIMEFRAME_SELLING = graphql_tag_1.default `
    query getTimeFrameSelling($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            timeframe_selling
        }
    }
`;
const UPDATE_TIMEFRAME_SELLING = graphql_tag_1.default `
    mutation updateTimeframeSelling($listingId: uuid!, $timeframeSelling: onboarding_timeframe_selling_enum) {
        update_listing_onboarding(
            where: { listing_id: { _eq: $listingId } }
            _set: { timeframe_selling: $timeframeSelling }
        ) {
            returning {
                id
            }
        }
    }
`;
const GET_BUDGET_SIZE = graphql_tag_1.default `
    query getBudgetSize($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            budget_size
        }
    }
`;
const UPDATE_BUDGET_SIZE = graphql_tag_1.default `
    mutation updateBudgetSize($listingId: uuid!, $budgetSize: onboarding_budget_size_enum) {
        update_listing_onboarding(where: { listing_id: { _eq: $listingId } }, _set: { budget_size: $budgetSize }) {
            returning {
                id
            }
        }
    }
`;
const GET_FEEDBACK_QUERY = graphql_tag_1.default `
    query GetFeedback($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            id
            rating_inspection
            rating_negotiation
            rating_overall
            rating_technology
            rating_valuing
        }
    }
`;
const UPDATE_FEEDBACK_MUTATION = graphql_tag_1.default `
    mutation UpdateFeedback(
        $listingId: uuid!
        $ratingInspection: Int
        $ratingNegotiation: Int
        $ratingOverall: Int
        $ratingTechnology: Int
        $ratingValuing: Int
    ) {
        update_listing_onboarding(
            where: { listing_id: { _eq: $listingId } }
            _set: {
                rating_inspection: $ratingInspection
                rating_negotiation: $ratingNegotiation
                rating_overall: $ratingOverall
                rating_technology: $ratingTechnology
                rating_valuing: $ratingValuing
            }
        ) {
            returning {
                rating_inspection
                rating_negotiation
                rating_overall
                rating_technology
                rating_valuing
            }
        }
    }
`;
const CREATE_ONBOARDING_PROGRESS_MUTATION = graphql_tag_1.default `
    mutation createOnboardingProgress($listingId: uuid!) {
        insert_listing_onboarding(objects: { listing_id: $listingId }) {
            returning {
                id
            }
        }
    }
`;
const GET_ONBOARDING_PROGRESS_QUERY = graphql_tag_1.default `
    query getOnboardingProgress($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            id
            finished
            skipped
            step_completed
            user_id
        }
    }
`;
const UPDATE_STEP_MUTATION = graphql_tag_1.default `
    mutation updateStep($onboardingId: uuid!, $step_completed: Int) {
        update_listing_onboarding(where: { id: { _eq: $onboardingId } }, _set: { step_completed: $step_completed }) {
            affected_rows
        }
    }
`;
const UPDATE_FINISH_MUTATION = graphql_tag_1.default `
    mutation updateFinish($onboardingId: uuid!, $finished: Boolean) {
        update_listing_onboarding(where: { id: { _eq: $onboardingId } }, _set: { finished: $finished }) {
            affected_rows
        }
    }
`;
const UPDATE_SKIP_MUTATION = graphql_tag_1.default `
    mutation updateSkip($onboardingId: uuid!, $skipped: Boolean) {
        update_listing_onboarding(where: { id: { _eq: $onboardingId } }, _set: { skipped: $skipped }) {
            affected_rows
        }
    }
`;
const GET_ONBOARDING_PROGRESS_BY_USER = graphql_tag_1.default `
    query getOnboardingProgressByUser($userId: uuid!) {
        listing_onboarding(where: { user_id: { _eq: $userId } }) {
            id
            finished
            skipped
            listing_id
            step_completed
        }
    }
`;
const GET_ONBOARDING_DATA_BY_LISTING_ID = graphql_tag_1.default `
    query getOnboardingDataByListingId($listingId: uuid!) {
        listing_onboarding(where: { listing_id: { _eq: $listingId } }) {
            finished
            skipped
            step_completed
            rating_inspection
            rating_technology
            rating_negotiation
            rating_valuing
            rating_overall
            reason_selling
            reason_selling_other
            timeframe_selling
            budget_size
        }
    }
`;
class ListingOnboardingService {
    constructor(apollo) {
        this.apollo = apollo;
    }
    getSellingReason(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_REASON_SELLING,
                variables,
            })
                .toPromise();
        });
    }
    updateSellingReason(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_REASON_SELLING,
                variables,
            })
                .toPromise();
        });
    }
    getBudgetSize(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_BUDGET_SIZE,
                variables,
            })
                .toPromise();
        });
    }
    updateBudgetSize(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_BUDGET_SIZE,
                variables,
            })
                .toPromise();
        });
    }
    getTimeframeSelling(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_TIMEFRAME_SELLING,
                variables,
            })
                .toPromise();
        });
    }
    updateTimeframeSelling(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_TIMEFRAME_SELLING,
                variables,
            })
                .toPromise();
        });
    }
    getExistingFeedback(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_FEEDBACK_QUERY,
                variables,
            })
                .toPromise();
        });
    }
    updateFeedback(variables) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_FEEDBACK_MUTATION,
                variables,
            })
                .toPromise();
        });
    }
    createOnboardingProgress(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: CREATE_ONBOARDING_PROGRESS_MUTATION,
                variables: { listingId },
            })
                .toPromise();
        });
    }
    getOnboardingProgress(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ONBOARDING_PROGRESS_QUERY,
                variables: { listingId },
            })
                .toPromise();
        });
    }
    updateStep(stepCompleted, onboardingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_STEP_MUTATION,
                variables: {
                    onboardingId,
                    step_completed: stepCompleted,
                },
            })
                .toPromise();
        });
    }
    updateFinish(finished, onboardingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_FINISH_MUTATION,
                variables: {
                    onboardingId,
                    finished,
                },
            })
                .toPromise();
        });
    }
    updateSkip(skipped, onboardingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .mutate({
                mutation: UPDATE_SKIP_MUTATION,
                variables: {
                    onboardingId,
                    skipped,
                },
            })
                .toPromise();
        });
    }
    getOnboardingProgressByUserId(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.apollo
                .query({
                query: GET_ONBOARDING_PROGRESS_BY_USER,
                variables: { userId },
            })
                .toPromise();
        });
    }
    getOnboardingDataByListingId(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apollo
                .query({
                query: GET_ONBOARDING_DATA_BY_LISTING_ID,
                variables: { listingId },
            })
                .toPromise();
            return resp.data.listing_onboarding[0];
        });
    }
}
ListingOnboardingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ListingOnboardingService_Factory() { return new ListingOnboardingService(i0.ɵɵinject(i1.Apollo)); }, token: ListingOnboardingService, providedIn: "root" });
exports.ListingOnboardingService = ListingOnboardingService;
