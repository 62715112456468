"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
const i0 = require("./build-info-dialog.component.scss.ngstyle");
const i1 = require("@angular/core");
const i2 = require("@angular/material/dialog");
const i3 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
const i4 = require("@angular/material/button");
const i5 = require("@angular/cdk/a11y");
const i6 = require("@angular/platform-browser/animations");
const i7 = require("./build-info-dialog.component");
const i8 = require("@angular/platform-browser");
var styles_BuildInfoDialogComponent = [i0.styles];
var RenderType_BuildInfoDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BuildInfoDialogComponent, data: {} });
exports.RenderType_BuildInfoDialogComponent = RenderType_BuildInfoDialogComponent;
function View_BuildInfoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "headline-1-bold mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, [["text", 1]], null, 1, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["color", "accent"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyText(i1.ɵnov(_v, 3).textContent) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Copy "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Close "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_5 = "accent"; _ck(_v, 8, 0, currVal_5); var currVal_8 = "accent"; _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.text; _ck(_v, 3, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 8).disabled || null); var currVal_4 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 11).disabled || null); var currVal_7 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_6, currVal_7); }); }
exports.View_BuildInfoDialogComponent_0 = View_BuildInfoDialogComponent_0;
function View_BuildInfoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-build-info-dialog", [], null, null, null, View_BuildInfoDialogComponent_0, RenderType_BuildInfoDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.BuildInfoDialogComponent, [i2.MatDialogRef, i8.DomSanitizer, i2.MAT_DIALOG_DATA], null, null)], null, null); }
exports.View_BuildInfoDialogComponent_Host_0 = View_BuildInfoDialogComponent_Host_0;
var BuildInfoDialogComponentNgFactory = i1.ɵccf("app-build-info-dialog", i7.BuildInfoDialogComponent, View_BuildInfoDialogComponent_Host_0, {}, {}, []);
exports.BuildInfoDialogComponentNgFactory = BuildInfoDialogComponentNgFactory;
