
/* ATTENTION!
This file is automatically generated by scripts/set-env.ts
Please do not change or commit it
*/
import { IConfiguration } from './config';
function isEqual(a: any, b: any): boolean {
    return a === b;
}
export const config: IConfiguration = {
    production: isEqual('false', 'true'),
    hmr: isEqual('false', 'true'),
    services: {
        data: {
            endpoint: 'wss://dev-hasura.buymyplace.com.au/v1/graphql',
        },
        googleMaps: {
            key: '***'
        },
        analytics: {
            segment: {
                enabled: isEqual('false', 'true'),
                writeKey: '***',
            },
        },
        sentry: {
            sentryDsnVendor: '***',
        },
    },
    application: {
        branch: 'local',
        buildDate: 'Thu, 22 Aug 2024 12:21:50 GMT',
        buildNumber: 'undefined',
        version: '0.0.10',
        gitHead: 'undefined'
    }
};
