"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const fuse_perfect_scrollbar_directive_1 = require("@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
class ChatPanelComponent {
    /**
     * Constructor
     *
     * @param {ChatPanelService} _chatPanelService
     * @param {HttpClient} _httpClient
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(_chatPanelService, _httpClient, _fuseSidebarService) {
        this._chatPanelService = _chatPanelService;
        this._httpClient = _httpClient;
        this._fuseSidebarService = _fuseSidebarService;
        // Set the defaults
        this.selectedContact = null;
        this.sidebarFolded = true;
        // Set the private defaults
        this._unsubscribeAll = new rxjs_1.Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Load the contacts
        this._chatPanelService.loadContacts().then(() => {
            this.contacts = this._chatPanelService.contacts;
            this.user = this._chatPanelService.user;
        });
        // Subscribe to the foldedChanged observable
        this._fuseSidebarService
            .getSidebar('chatPanel')
            .foldedChanged.pipe(operators_1.takeUntil(this._unsubscribeAll))
            .subscribe((folded) => {
            this.sidebarFolded = folded;
        });
    }
    /**
     * After view init
     */
    ngAfterViewInit() {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Prepare the chat for the replies
     */
    _prepareChatForReplies() {
        setTimeout(() => {
            // Focus to the reply input
            // this._replyInput.nativeElement.focus();
            // Scroll to the bottom of the messages list
            if (this._chatViewScrollbar) {
                this._chatViewScrollbar.update();
                setTimeout(() => {
                    this._chatViewScrollbar.scrollToBottom(0);
                });
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily() {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
    }
    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily() {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
    }
    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen() {
        this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
    }
    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    shouldShowContactAvatar(message, i) {
        return (message.who === this.selectedContact.id &&
            ((this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== this.selectedContact.id) ||
                !this.chat.dialog[i + 1]));
    }
    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isFirstMessageOfGroup(message, i) {
        return i === 0 || (this.chat.dialog[i - 1] && this.chat.dialog[i - 1].who !== message.who);
    }
    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    isLastMessageOfGroup(message, i) {
        return (i === this.chat.dialog.length - 1 ||
            (this.chat.dialog[i + 1] && this.chat.dialog[i + 1].who !== message.who));
    }
    /**
     * Toggle chat with the contact
     *
     * @param contact
     */
    toggleChat(contact) {
        // If the contact equals to the selectedContact,
        // that means we will deselect the contact and
        // unload the chat
        if (this.selectedContact && contact.id === this.selectedContact.id) {
            // Reset
            this.resetChat();
        }
        // Otherwise, we will select the contact, open
        // the sidebar and start the chat
        else {
            // Unfold the sidebar temporarily
            this.unfoldSidebarTemporarily();
            // Set the selected contact
            this.selectedContact = contact;
            // Load the chat
            this._chatPanelService.getChat(contact.id).then((chat) => {
                // Set the chat
                this.chat = chat;
                // Prepare the chat for the replies
                this._prepareChatForReplies();
            });
        }
    }
    /**
     * Remove the selected contact and unload the chat
     */
    resetChat() {
        // Set the selected contact as null
        this.selectedContact = null;
        // Set the chat as null
        this.chat = null;
    }
    /**
     * Reply
     */
    reply(event) {
        event.preventDefault();
        if (!this._replyForm.form.value.message) {
            return;
        }
        // Message
        const message = {
            who: this.user.id,
            message: this._replyForm.form.value.message,
            time: new Date().toISOString(),
        };
        // Add the message to the chat
        this.chat.dialog.push(message);
        // Reset the reply form
        this._replyForm.reset();
        // Update the server
        this._chatPanelService.updateChat(this.chat.id, this.chat.dialog).then((response) => {
            // Prepare the chat for the replies
            this._prepareChatForReplies();
        });
    }
}
exports.ChatPanelComponent = ChatPanelComponent;
