"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const RouterActions = require("app/store/actions/router.action");
class RouterEffects {
    /**
     * Constructor
     *
     * @param {Actions} actions$
     * @param {Router} router
     * @param {Location} location
     */
    constructor(actions$, router, location) {
        this.actions$ = actions$;
        this.router = router;
        this.location = location;
        /**
         * Navigate
         */
        this.navigate$ = this.actions$.pipe(effects_1.ofType(RouterActions.GO), operators_1.map((action) => action.payload), operators_1.tap(({ path, query: queryParams, extras }) => {
            this.router.navigate(path, Object.assign({}, queryParams, extras));
        }));
        /**
         * Navigate back
         * @type {Observable<any>}
         */
        this.navigateBack$ = this.actions$.pipe(effects_1.ofType(RouterActions.BACK), operators_1.tap(() => this.location.back()));
        /**
         * Navigate forward
         * @type {Observable<any>}
         */
        this.navigateForward$ = this.actions$.pipe(effects_1.ofType(RouterActions.FORWARD), operators_1.tap(() => this.location.forward()));
    }
}
tslib_1.__decorate([
    effects_1.Effect({ dispatch: false })
], RouterEffects.prototype, "navigate$", void 0);
tslib_1.__decorate([
    effects_1.Effect({ dispatch: false })
], RouterEffects.prototype, "navigateBack$", void 0);
tslib_1.__decorate([
    effects_1.Effect({ dispatch: false })
], RouterEffects.prototype, "navigateForward$", void 0);
exports.RouterEffects = RouterEffects;
